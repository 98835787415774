import { useTheme } from "@emotion/react";
import { Box, Button, Rating, TextField, Typography } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import axios from "axios";
import clsx from "clsx";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useTranslate } from "../../../context/translate/translate.context";
import AlertComponent from "../../AlertComponent";
import MentupModal from "../../MentupModal/MentupModal";
import LocalButton from "../../UI/Button";
import CheckCircle from "../../svg/CheckCircle";
import { TranslateText } from "../../translate-text";
import styles from "./styles.module.css";
import { LoadingButton } from "@mui/lab";

const style = {
  boxSizing: "border-box",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  maxHeight: "90vh",
  overflow: "scroll",
  "@media (max-width: 650px)": {
    width: "61%",
  },
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function FeedbackModal(props) {
  const { product, order, type, buyer, seller } = props;

  const { id } = useParams();
  const theme = useTheme();
  const { translate } = useTranslate();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [noRatingWarning, setNoRatingWarning] = useState(false);
  const [feedback, setFeedback] = useState({ text: "", rating: null });
  const [snackBar, setSnackBar] = useState({
    open: false,
    vertical: "bottom",
    horizontal: "right",
    message: "",
  });

  const handleOpen = () => {
    setFeedback({ text: "", rating: null });
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const resetAlertMessage = () => {
    setAlertMessage("");
  };

  const feedbackSubmission = (e) => {
    e.preventDefault();

    if (!id) {
      return;
    }

    if (!feedback.rating) {
      setNoRatingWarning(true);
    }

    const token = Cookies.get("refreshToken");
    const user = localStorage.getItem("user");
    if (token && user && product && order?._id) {
      setLoading(true);
      let aboutUser = null;
      if (type === "sale" && seller?._id) {
        aboutUser = seller._id;
      } else if (type === "purchaseProduct" && buyer?._id) {
        aboutUser = buyer._id;
      }
      if (aboutUser) {
        const url = `${
          process.env.REACT_APP_SERVER_URL || "http://localhost:3030/api"
        }/trust`;

        try {
          axios
            .post(
              url,
              {
                text: feedback.text,
                rating: feedback.rating,
                product: props.product._id,
                user: JSON.parse(user)._id,
                aboutUser,
                orderId: props.order._id,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then(
              (res) => {
                if (
                  res.data?.productUpdate?._id &&
                  props.product?._id &&
                  res.data.productUpdate._id === props.product._id
                ) {
                  setSnackBar({
                    ...snackBar,
                    open: true,
                    message: translate("orders.feedbackModal.sentMessage"),
                  });
                  setFeedback({ text: "", rating: null });
                  setNoRatingWarning(false);
                  handleClose();
                }
              },
              (error) => {
                setFeedback({ text: "", rating: null });
                setNoRatingWarning(false);
                handleClose();
                setSnackBar({
                  open: false,
                  vertical: "bottom",
                  horizontal: "right",
                  message: "",
                });
                setAlertMessage(
                  error.response?.data?.message ||
                    translate("orders.feedbackModal.errorMessage")
                );
              }
            );
        } catch (err) {
          setFeedback({ text: "", rating: null });
          setNoRatingWarning(false);
          handleClose();
          setSnackBar({
            open: false,
            vertical: "bottom",
            horizontal: "right",
            message: "",
          });
        } finally {
          setLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    setNoRatingWarning(!feedback.rating);
  }, [feedback.rating]);

  useEffect(() => {
    setFeedback({ text: "", rating: null });
    setNoRatingWarning(false);
    handleClose();
    setSnackBar({
      open: false,
      vertical: "bottom",
      horizontal: "right",
      message: "",
    });
  }, [id]);

  return (
    <>
      <LocalButton
        onClick={handleOpen}
        variant="outlined"
        sx={{
          textTransform: "uppercase",
          fontSize: "16px",
          fontWeight: "500",
          lineHeight: "13px",
          height: "40px",
          width: "auto",
        }}
      >
        <TranslateText value="orders.feedbackModal.leaveReviewButtonLabel" />
      </LocalButton>

      <AlertComponent
        message={alertMessage}
        resetMessageInParent={resetAlertMessage}
      />

      <Snackbar
        anchorOrigin={{
          vertical: snackBar.vertical,
          horizontal: snackBar.horizontal,
        }}
        open={snackBar.open}
        autoHideDuration={4000}
        onClose={() => setSnackBar({ ...snackBar, open: false })}
        key={snackBar.vertical + snackBar.horizontal}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          {snackBar.message}
        </Alert>
      </Snackbar>

      <MentupModal open={open} close={handleClose}>
        <Box className={styles.confirmContainer}>
          <Box className={styles.headerContainer}>
            <CheckCircle
              color={theme.palette.mode === "dark" ? "#EDF67D" : "#8142f6"}
            />
            <Typography className={styles.alertTitle}>
              <TranslateText value="orders.feedbackModal.title" />
            </Typography>
          </Box>

          <form style={{ width: "100%" }} onSubmit={feedbackSubmission}>
            <Typography className={styles.formTitle}>
              <TranslateText value="orders.feedbackModal.rating" />
            </Typography>

            <Rating
              value={
                feedback.rating && feedback.rating <= 5 ? feedback.rating : 0
              }
              defaultValue={0}
              precision={0.5}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value))) {
                  setFeedback({
                    ...feedback,
                    rating: Number(e.target.value),
                  });
                }
              }}
            />
            <Typography hidden={!noRatingWarning} className={styles.helper}>
              <TranslateText value="orders.feedbackModal.setRatingMessage" />
            </Typography>

            <Typography className={styles.inputTitle}>
              <TranslateText value="orders.feedbackModal.ratingTitle" />
            </Typography>
            <TextField
              required
              label={translate("orders.feedbackModal.feedbackInput.label")}
              sx={{ width: "100%" }}
              value={feedback.text}
              error={feedback.text?.length > 300}
              onChange={(e) =>
                setFeedback({
                  ...feedback,
                  text: e.target.value.replace(/(\n)|(\s)/g, "").length
                    ? e.target.value
                    : null,
                })
              }
              onBlur={() => {
                if (feedback.text?.length > 300) {
                  setFeedback({
                    ...feedback,
                    text: feedback.text.substring(0, 300),
                  });
                }
              }}
              rows={5}
              multiline
            />

            <Typography className={styles.helper}>
              <TranslateText value="orders.feedbackModal.feedbackInput.errorMessage" />
            </Typography>

            <Box className={styles.buttonsContainer}>
              <Button
                className={clsx(styles.button, styles.confirmButton)}
                onClick={handleClose}
              >
                <TranslateText value="orders.feedbackModal.cancelButtonLabel" />
              </Button>
              <LoadingButton
                type="submit"
                loading={loading}
                className={clsx(styles.button, styles.cancelButton)}
              >
                <TranslateText value="orders.feedbackModal.submitButtonLabel" />
              </LoadingButton>
            </Box>
          </form>
        </Box>
      </MentupModal>
    </>
  );
}

export default FeedbackModal;
