import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect } from "react";

import { changeTelegramNotifications } from "../../../api/settings/notifications";
import { useTranslate } from "../../../context/translate/translate.context";
import styles from "./styles.module.css";

const TelegramFinishNotifications = ({ next }) => {
  const { translate } = useTranslate();

  const turnOnNotifications = async () => {
    try {
      await Promise.all([
        await changeTelegramNotifications({ telegram: true }),
        new Promise((res) => setTimeout(res, 1500)),
      ]);

      next();
    } catch (error) {
      //console.log("error", error);
      setTimeout(sendInvoice, 1000);
    }
  };

  useEffect(() => {
    turnOnNotifications();
  }, []);

  return (
    <Box className={styles.container}>
      <CircularProgress />

      <Typography variant="h5" className={styles.title}>
        {translate("vendorDashboard.settings.notifications.telegram.steps.step3.title")}
      </Typography>
    </Box>
  );
};

export default TelegramFinishNotifications;
