import { createSlice } from "@reduxjs/toolkit";

const walletStore = createSlice({
  name: "walletStore",
  initialState: {
    wallet: localStorage.getItem("user")?.wallet || {},
  },
  reducers: {
    setWallet: (state, action) => {
      return {
        ...state,
        wallet: action.payload || state.wallet,
      };
    },
  },
});

export const { setWallet } = walletStore.actions;
export const walletReducer = walletStore.reducer;
