import axios from 'axios'
import Cookies from 'js-cookie';

const API = axios.create({
    baseURL: process.env.NODE_ENV.includes('production')
        ? process.env.REACT_APP_SERVER_URL
            ? process.env.REACT_APP_SERVER_URL
            : ''
        : 'http://localhost:3030/api/',
});


const header = () => {
    const token = Cookies.get('refreshToken');
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };
}

export const updateProductFulfilled = (productId, order, status) => API.patch('/order/purchase/updateFulfiled/', {productId, order, status}, header());

export const getOrderbyID = (id) => API.get('/order/verify/'+id, header());

export const getOrderPost = (id, country) => API.get('/order/'+id+'?country='+country, header());

export const getSingleOrderbyID = (id, country) => API.get('/order/single/'+id+'?country='+country, header());

export const getLatestOrderByUserSlug = (slug) => API.get('/order/singleSlug/'+slug, header())
