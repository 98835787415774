import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Badge,
  Box,
  Card,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { useTranslate } from "../../context/translate/translate.context";
import { setNotificationsCount } from "../../store/notificationStore";
import MentupModal from "../MentupModal/MentupModal";

function Notification({ notification }) {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const token = Cookies.get("refreshToken");

  const [open, setOpen] = React.useState(false);

  const handleOpen = async () => {
    setOpen(true);
    try {
      let url;
      if (
        process.env.NODE_ENV === "production" &&
        process.env.REACT_APP_SERVER_URL
      ) {
        url = `${process.env.REACT_APP_SERVER_URL}/notification/read/${notification._id}`;
      } else {
        url = `http://localhost:3030/api/notification/read/${notification._id}`;
      }

      const response = await axios.put(
        url,
        { read: true },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch(setNotificationsCount({ count: response.data?.count }));
      notification.read = true;
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };
  const handleClose = () => setOpen(false);

  const handleDate = (data) => {
    // convert to DD/MM/YYYY : HH:MM
    const date = new Date(data);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedDate = `${day}/${month}/${year} - ${hours}:${minutes}`;
    return formattedDate;
  };

  // Get language redux
  const selectedLanguage = useSelector((state) => state.language);

  return (
    <>
      <Box
        sx={{
          boxSizing: "border-box",
          cursor: "pointer",
          "&:hover": {
            opacity: "0.6",
          },
          "&:active": {
            opacity: "1",
          },
          display: "flex",
          flexDirection: "column",
          alignItems: "space-between",
          width: "100%",
        }}
        onClick={handleOpen}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            marginLeft: "2rem",
            "@media (max-width: 630px)": {
              marginLeft: "0rem",
            },
          }}
        >
          <Box sx={{ marginRight: "1rem" }}>
            {
              // Add Badge if notification is not read
            }
            <Badge
              variant="dot"
              color="error"
              invisible={notification.read == true}
            >
              {notification.fromUser && notification.fromUser.avatar ? (
                <Avatar
                  sx={{
                    width: "3rem",
                    height: "3rem",
                  }}
                  alt="M"
                  src={`${notification.fromUser.avatar}`}
                />
              ) : (
                <AccountCircle sx={{ fontSize: "2.5rem" }} />
              )}
            </Badge>
          </Box>

          <Box
            sx={{
              width: "70%",
              "@media (max-width: 630px)": {
                width: "85%",
              },
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "1.1rem",
                marginBottom: "0.5rem",
              }}
            >
              {translate("notificationsPage.notification.from")}{" "}
              {notification.fromUser.username}
            </Typography>
            <Typography>
              {notification.message.split(" ").splice(0, 25).join(" ") + "..."}
            </Typography>
          </Box>

          <Box
            sx={{
              width: "15%",
              opacity: "0.7",
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              "@media (max-width: 630px)": {
                display: "none",
                width: "0%",
              },
            }}
          >
            <Typography>
              {
                // convrt to date format

                handleDate(notification.createdAt)
              }
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ marginY: "0.8rem" }} />
      </Box>
      <MentupModal
        open={open}
        close={handleClose}
        sx={{ padding: "0px !important" }}
      >
        <Card
          sx={{
            boxSizing: "border-box",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "1rem",
            borderRadius: "10px 10px 0px 0px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Box sx={{ marginRight: "1rem" }}>
              <Avatar
                sx={{
                  width: "2.5rem",
                  height: "2.5rem",
                }}
                alt="M"
                src={`${notification.fromUser.avatar}`}
              />
            </Box>
            <Box>
              <Typography
                variant="notificationModalText"
                sx={{
                  fontWeight: "bold",
                  fontSize: "1rem",
                }}
              >
                {translate("notificationsPage.notification.from")}{" "}
                {notification.fromUser.username}
              </Typography>
            </Box>
          </Box>
          <IconButton
            aria-label="close"
            onClick={() => {
              handleClose();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Card>
        <Box
          sx={{
            padding: "1.5rem",
            paddingTop: "1.5rem",
            paddingBottom: "1.8rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              width: "100%",
              marginBottom: "1rem",
            }}
          >
            <Typography variant="notificationModalText" sx={{ opacity: "0.7" }}>
              {handleDate(notification.createdAt)}
            </Typography>
          </Box>
          <Typography
            variant="notificationModalText"
            sx={{ fontSize: "1.1rem" }}
          >
            {notification.message}
          </Typography>
        </Box>
      </MentupModal>
    </>
  );
}

export default Notification;
