import axios from "axios";
import Cookies from "js-cookie";

const API = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL || "http://localhost:3030/api/",
});

export const createProduct = (productData, country) => {
  const token = Cookies.get("refreshToken");
  return API.post(
    `/item/create`,
    { productData, country },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
