import { Box, Button, Typography } from "@mui/material";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { confirmRejectProductBuyerApi } from "../../../api/order/ConfirmRejectProductBuyer";
import { confirmRefundApi } from "../../../api/order/RefundOrder";
import { useTranslate } from "../../../context/translate/translate.context";
import { priceLabel } from "../../../utils/strings/price";
import { TranslateText } from "../../translate-text";
import BuyerConfirmOrderModal from "../BuyerConfirmOrderModal";
import BuyerRejectOrderModal from "../BuyerRejectOrderModal";
import DeliverOrderModal from "../DeliverOrderModal";
import DisputeOrderModal from "../DisputeOrderModal";
import FeedbackModal from "../FeedbackModal";
import RefundOrderModal from "../RefundOrderModal";
import styles from "./styles.module.css";
import AlertComponent from "../../AlertComponent";

function OrderProduct(props) {
  const navigate = useNavigate();
  const { translate } = useTranslate();

  const { product, isPurchase, currencySign, buyer, order, reset } = props;

  const [loadingReject, setLoadingReject] = useState(false);
  const [refundMenuOpen, setRefundMenuOpen] = useState(false);
  const [buyerConfirmOpen, setBuyerConfirmOpen] = useState(false);
  const [buyerRejectOpen, setBuyerRejectOpen] = useState(false);
  const [disputeOpen, setDisputeOpen] = useState(false);
  const [deliverOpen, setDeliverOpen] = useState(false);
  const [loadingRefund, setLoadingRefund] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const [orderStatus, setOrderStatus] = useState(product?.status);

  useEffect(() => {
    setOrderStatus((prev) => product?.status || prev);
  }, [product?.status]);

  const handleCloseRefund = () => setRefundMenuOpen(false);
  const handleOpenRefund = () => setRefundMenuOpen(true);

  const handleCloseDispute = () => setDisputeOpen(false);
  const handleOpenDispute = () => setDisputeOpen(true);

  const handleCloseDeliver = () => setDeliverOpen(false);
  const handleOpenDeliver = () => setDeliverOpen(true);

  const goToProduct = () => {
    if (product?.product?.user) {
      navigate(
        `/profile/${product.product.user.userSlug}/${product.product.slug}`
      );
    }
  };

  const confirmRefund = async () => {
    setLoadingRefund(true);
    try {
      const response = await confirmRefundApi({
        product,
        buyer,
        order,
        orderStatus: order?.status,
        productStatus: product?.status,
      });
      if (response.data?.statusChanged) {
        setAlertMessage(translate("orders.deliverOrderModal.statusChanged"));
        return;
      }
      if (response) {
        setOrderStatus("refunded");
        handleCloseRefund();
      } else {
        setErrorMessage(translate("orders.orderProduct.errorRefundMessage"));
      }
    } catch (e) {
      navigate('/404')
    } finally {
      setLoadingRefund(false);
      reset?.();
    }
  };

  const buyerConfirmOrder = async (status) => {
    try {
      setLoadingReject(true);
      if (order && product) {
        const res = await confirmRejectProductBuyerApi({
          productId: product.product._id,
          order: order._id,
          status,
          orderStatus: order?.status,
          productStatus: product?.status,
        });
        if (res.data?.statusChanged) {
          setAlertMessage(translate("orders.deliverOrderModal.statusChanged"));
          return;
        }

        reset?.();
      }
    } catch (e) {
      navigate('/404')
    } finally {
      setBuyerConfirmOpen(false);
      setBuyerRejectOpen(false);
      setLoadingReject(false);
    }
  };

  if (!product) {
    return null;
  }

  const currentDate = new Date(); // Current date
  const createdAt = new Date(order.createdAt); // Date when the order was created

  // Calculate the difference in milliseconds between current date and createdAt date
  const differenceInMilliseconds = currentDate.getTime() - createdAt.getTime();

  const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

  // Check if the difference is greater than or equal to one day
  const dayHasPassed = differenceInMilliseconds >= oneDayInMilliseconds;

  return (
    <Box className={styles.container}>
      <AlertComponent
        message={alertMessage}
        resetMessageInParent={() => setAlertMessage("")}
      />

      {!!orderStatus && (
        <Button
          variant="outlined"
          color={
            orderStatus === "confirmed"
              ? "success"
              : orderStatus === "created"
              ? "success"
              : "error"
          }
          className={styles.statusBtn}
        >
          <TranslateText
            value={`orders.orderProduct.orderStatus.${orderStatus}`}
          />
        </Button>
      )}

      <Box className={styles.productContainer}>
        <Box
          component="img"
          src={
            product?.product?.thumb?.[0] ||
            product?.product?.media?.[0] ||
            "url(/images/product_placeholder.png)"
          }
          className={styles.productImage}
        />

        <Box className={styles.productInfo}>
          <Box>
            <Typography className={styles.productTitle} onClick={goToProduct}>
              {product?.product?.name}
            </Typography>
            <Typography className={styles.productQuantity}>
              {`${translate("orders.orderProduct.quantity")}: ${
                product?.quantity || 0
              } ${translate("orders.orderProduct.items")}`}
            </Typography>
          </Box>

          <Box className={styles.pricesContainer}>
            <Box>
              <Typography className={styles.priceTitle}>
                <TranslateText value="orders.orderProduct.price" />
              </Typography>
              <Typography className={styles.priceValue}>
                {product?.product?.promocode?.discount ? (
                  <>
                    <Box className={styles.price__strike} component="span">
                      {priceLabel(product?.price, { sign: currencySign })}
                    </Box>
                    {priceLabel(
                      product?.price -
                        (product?.price *
                          (product?.product?.promocode?.discount || 0)) /
                          100.0,
                      { sign: currencySign }
                    )}
                  </>
                ) : (
                  priceLabel(product?.price, { sign: currencySign })
                )}
              </Typography>
            </Box>
            <Box>
              <Typography className={styles.priceTitle}>
                <TranslateText value="orders.orderProduct.total" />
              </Typography>
              <Typography className={styles.priceValue}>
                {priceLabel(
                  (product?.price -
                    (product?.price *
                      (product?.product?.promocode?.discount || 0)) /
                      100.0) *
                    product?.quantity || 0
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      {isPurchase ? (
        <Box className={styles.buyerButtonsContainer}>
          <Box className={styles.buyerSecondContainer}>
            {/* dayHasPassed && product.status === "created" && (
              <Button
                color="secondary"
                className={clsx(styles.button, styles.rejectButton)}
                disabled={
                  !["created", "pending", "delivered", "refunded"].includes(
                    orderStatus
                  )
                }
                onClick={() => setBuyerRejectOpen(true)}
              >
                <TranslateText value="orders.orderProduct.reject" />
              </Button>
            ) */}
            <Button
              color="secondary"
              className={clsx(styles.button, styles.deliverButton)}
              disabled={[
                "created",
                "pending",
                "refunded",
                "confirmed",
                "dispute"
              ].includes(orderStatus)}
              onClick={() => setBuyerConfirmOpen(true)}
            >
              <TranslateText value="orders.orderProduct.confirm" />
            </Button>
          </Box>
          {["confirmed"].includes(orderStatus) && (
            <Box
              sx={{ flex: 1, width: "-webkit-fill-available" }}
              className={styles.feedbackButton}
            >
              <FeedbackModal
                type="sale"
                order={order}
                buyer={order?.buyer}
                seller={order?.seller}
                product={order?.productsInfo?.[0]?.product}
              />
            </Box>
          )}
          <Button
            disabled={["refunded", "dispute"].includes(orderStatus)}
            className={clsx(styles.button, styles.returnButton)}
            onClick={handleOpenDispute}
          >
            <TranslateText value="orders.disputeOrderModal.disputeButtonLabel" />
          </Button>
        </Box>
      ) : (
        <Box className={styles.salesButtonsContainer}>
          <Button
            color="secondary"
            className={clsx(styles.button, styles.deliverButton)}
            disabled={!["created", "pending"].includes(orderStatus)}
            onClick={handleOpenDeliver}
          >
            {translate("orders.deliverOrderModal.deliverButtonLabel")}
          </Button>

          <Box className={styles.salesSecondContainer}>
            <Button
              disabled={["refunded", "confirmed"].includes(
                orderStatus
              )}
              className={clsx(styles.button, styles.returnButton)}
              onClick={handleOpenRefund}
            >
              <TranslateText value="orders.orderProduct.refund" />
            </Button>
            {/*
            <Button
              disabled={["refunded", "dispute"].includes(orderStatus)}
              className={clsx(styles.button, styles.returnButton)}
              onClick={handleOpenDispute}
            >
              <TranslateText value="orders.disputeOrderModal.disputeButtonLabel" />
            </Button> */}
          </Box>
        </Box>
      )}

      <DeliverOrderModal
        open={deliverOpen}
        close={handleCloseDeliver}
        orderId={order?._id}
        productId={product?.product?._id}
        setNewStatus={setOrderStatus}
        orderStatus={order?.status}
        productStatus={product?.status}
      />
      <DisputeOrderModal
        type={isPurchase ? "buyerPurchase" : "buyerSale"}
        open={disputeOpen}
        close={handleCloseDispute}
        orderId={order?._id}
        productId={product?.product?._id}
        setNewStatus={setOrderStatus}
        orderStatus={order?.status}
        productStatus={product?.status}
      />
      <RefundOrderModal
        open={refundMenuOpen}
        loading={loadingRefund}
        confirm={confirmRefund}
        close={handleCloseRefund}
      />
      <BuyerRejectOrderModal
        open={buyerRejectOpen}
        loading={loadingReject}
        confirm={() => buyerConfirmOrder(false)}
        close={() => setBuyerRejectOpen(false)}
      />
      <BuyerConfirmOrderModal
        open={buyerConfirmOpen}
        loading={loadingReject}
        confirm={() => buyerConfirmOrder(true)}
        close={() => setBuyerConfirmOpen(false)}
      />
    </Box>
  );
}

export default OrderProduct;
