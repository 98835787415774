import axios from "axios";

const API = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL || "http://localhost:3030/api/",
});

export const getAllReviews = (data) => {
    const { limit, page } = data
    return API.get(`/trust?limit=${limit}&page=${page}`);
};

export const getTotalReviews = () => {
    return API.get('/trust/totalReviews')
}
