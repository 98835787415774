import React from 'react'
import { Box, Container, Typography, useTheme } from '@mui/material'
import styles from './styles.module.css'
import steamLogo from '../../../../images/aboutUsPage/steam.png'
import { useTranslate } from '../../../../context/translate/translate.context'


const SteamAccounts = () => {
    const { translate } = useTranslate()
  return (
    <>
    <Box className={styles.wrapper}>
        <Container>
            <Box className={styles.sell}>
            <Typography className={styles.heading} sx={{maxWidth:'652px'}}>{translate('aboutUsPage.steamAccounts.steamAccountsHeading')}</Typography> 
            <Box className={styles.advantage} sx={{mb:'28px'}}>
                <Typography className={styles.advantage__text}>{translate('aboutUsPage.steamAccounts.steamAccountsAdvantage')}</Typography> 
            </Box>
            <Typography className={styles.text} sx={{maxWidth:'580px', mb:'30px'}}>{translate('aboutUsPage.steamAccounts.steamAccountsText1')}</Typography> 
            <Typography className={styles.text} sx={{maxWidth:'580px'}}>{translate('aboutUsPage.steamAccounts.steamAccountsText2')}</Typography> 
            </Box>
            <Box className={styles.best}>
                <Typography className={styles.heading} sx={{textAlign:'center'}}>{translate('aboutUsPage.best.bestHeading')}</Typography>
                <Typography className={styles.text} sx={{textAlign:'center', maxWidth:'1034px', mb:'43px'}}>{translate('aboutUsPage.best.bestText')}</Typography>
                <Box className={styles.advantage} sx={{mb:'0px'}}>
                    <Typography className={styles.advantage__text} sx={{maxWidth:'500px', textAlign:'center'}}>{translate('aboutUsPage.best.bestAdvantage')}</Typography>
                </Box>
            </Box>
        </Container>
        <img className={styles.steamLogo} src={steamLogo} alt="Steam" />
    </Box>
    <Box className={styles.squadBackgroundWrapper}>
        <Box className={styles.squadBackground}></Box>
    </Box>
    </>
  )
}

export default SteamAccounts