import { createSlice } from '@reduxjs/toolkit';
const userSettingsStore = createSlice({
    name: 'userSettings',
    initialState: {},
    reducers: {
        setUserSettings: (state, action) => {
            return {...state, ...action.payload};
        },
    },
});

export const { setUserSettings } = userSettingsStore.actions;
export const userSettingsReducer = userSettingsStore.reducer;
