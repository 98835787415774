import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Grow,
  IconButton,
  InputBase,
  List,
  ListItem,
  Popper,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { searchGlobal } from "../../../api/Search";
import { useTranslate } from "../../../context/translate/translate.context";
import SearchIcon from "../../svg/SearchIcon";
import Account from "../Account";
import Category from "../Category";
import Product from "../Product";
import { SEARCH_THRESHOLD } from "./constants";
import styles from "./styles.module.css";
import { setSearchValue } from "../../../store/searchStore";

const Search = ({ className, isMobile }) => {
  const { translate, language } = useTranslate();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);
  const searchRedux = useSelector((state) => state.search);

  const ref = useRef(null);

  const [value, setValue] = useState("");
  const [data, setData] = useState(null);

  const handleSearch = useCallback(async (value, country) => {
    if (!value || value.length < SEARCH_THRESHOLD) {
      return setData(null);
    }

    try {
      const { data: searchData } = await searchGlobal(
        encodeURIComponent(value),
        country
      );

      setData(searchData);
    } catch (error) {
      navigate('/404')
    }
  }, []);

  const searchCallback = useCallback((v) => {
    navigate(`/search/${encodeURIComponent(v)}`);
  }, []);

  const debouncedResults = useMemo(() => debounce(handleSearch, 300), []);

  useEffect(() => {
    dispatch(setSearchValue(value));
    if (!searchRedux.isSearchScreen) {
      debouncedResults(value, user.country);
    }

    return () => {
      debouncedResults?.cancel?.();
    };
  }, [value, searchRedux.isSearchScreen]);

  useEffect(() => {
    if (!window.location.pathname.includes("/search")) {
      setValue("");
    }
    setData(null);
  }, [window.location.pathname]);

  const renderResultsContent = useCallback(
    ({ categories, products, accounts }) => (
      <Box className={styles.resultsContainer}>
        {!!categories.length && (
          <Box component="section">
            <Box className={styles.header}>
              <Typography className={styles.header__heading} variant="h2">
                {translate("search.categories")}
              </Typography>
              <Link to="/games">
                {`${translate("search.seeAll")} ${translate(
                  "search.categories"
                )}`}
              </Link>
            </Box>

            <List className={styles.list}>
              {categories.slice(0, 3).map((category) => (
                <ListItem key={category._id} disablePadding>
                  <Category {...category} />
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        {!!products.length && (
          <Box component="section">
            <Box className={styles.header}>
              <Typography className={styles.header__heading} variant="h2">
                {translate("search.products")}
              </Typography>
              <Link to="/games">
                {`${translate("search.seeAll")} ${translate(
                  "search.products"
                )}`}
              </Link>
            </Box>

            <List className={styles.list}>
              {products.slice(0, 3).map((product) => (
                <ListItem key={product._id} disablePadding>
                  <Product {...product} />
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        {!!accounts.length && (
          <Box component="section">
            <Box className={styles.header}>
              <Typography className={styles.header__heading} variant="h2">
                {translate("search.accounts")}
              </Typography>
            </Box>

            <List className={styles.list}>
              {accounts.slice(0, 3).map((account) => (
                <ListItem key={account._id} disablePadding>
                  <Account {...account} />
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </Box>
    ),
    [language]
  );

  const popperOpen =
    !!data && (!!data.categories.length || !!data.products.length || !!data.accounts.length);

  return (
    <ClickAwayListener onClickAway={() => setData(null)}>
      <Box className={clsx(styles.container, className)}>
        <InputBase
          ref={ref}
          className={styles.input}
          value={value}
          onChange={({ target }) => setValue(target.value)}
          onFocus={({ target }) =>
            !popperOpen &&
            !searchRedux.isSearchScreen &&
            debouncedResults(target.value, user.country)
          }
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              searchCallback(value);
            }
          }}
          placeholder={translate("header.search")}
        />
        <IconButton
          className={styles.iconContainer}
          aria-label="clear search input"
          onClick={() => setValue("")}
          disabled={!value}
        >
          {value ? <ClearIcon /> : <SearchIcon />}
        </IconButton>

        <Popper
          className={styles.popper}
          open={popperOpen && !searchRedux.isSearchScreen}
          anchorEl={ref.current}
          placement="bottom-start"
        >
          <Grow in>
            <Box>{data && renderResultsContent(data)}</Box>
          </Grow>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

export default Search;
