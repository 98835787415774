export const isoToReadableDate = (isoDate) => {
    if (isoDate) {
        const splitDate = isoDate.slice(0, 10).split('-');
        const readableDate = `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`;
        return readableDate;
    } else {
        return null;
    }
};

export const isoToReadableHour = (isoDate) => {
    if (isoDate) {
        const splitDate = isoDate.slice(11, 19).split(':');
        const readableHour = `${splitDate[0]}:${splitDate[1]}:${splitDate[2]}`;
        return readableHour;
    } else {
        return null;
    }
};
