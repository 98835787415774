import ContentCopy from "@mui/icons-material/ContentCopy";
import {
  Avatar,
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import getItemsOfCategory from "../../../../api/categories/getItemsOfCategory";
import { getProductBySlug } from "../../../../api/product/getProductPage";
import { useTranslate } from "../../../../context/translate/translate.context";
import SEOHelmet from "../../../../lib/SEOHelmet";
import { addProduct } from "../../../../store/cartStore";
import { priceLabel } from "../../../../utils/strings/price";
import AlertComponent from "../../../AlertComponent";
import Loading from "../../../Loading";
import PaymentInformation from "../../../PaymentInformation";
import ProductSwiper from "../../../ProductsSwiper";
import ReviewsStars from "../../../Reviews";
import Button from "../../../UI/Button";
import ShareIcon from "../../../vendor/DashboardComponents/UserInfoCard/svg/Share";
import ProductCarousel from "./ProductCarousel";
import ProductReviews from "./ProductReviews";
import styles from "./styles.module.css";
import MentupModal from "../../../MentupModal/MentupModal";
import CartModalComponent from "../../../cart-page/CartModalComponent";
import BreadcrumbsNavigation from "../../../BreadcrumbsNavigation";
import { getUserReviews } from "../../../../api/personalOffice/requests";
import ShoppingBasket from "../../../svg/ShoppingBasket";

const ProductPage = () => {
  const { slug, userSlug } = useParams();
  const { translate } = useTranslate();
  const { country } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [openBuyNow, setOpenBuyNow] = useState(false);
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [alertMessage, setAlertMessage] = useState(null);
  const [userReviews, setUserReviews] = useState(null);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const { data } = await getProductBySlug(slug, userSlug, country);
        setProduct(data);

        const { type, category: { name: categoryName } } = data;
        const { data: similarProductsData } = await getItemsOfCategory(
          categoryName, 25, country, type._id
        );
        const filteredProducts = similarProductsData.filter(({ _id }) => _id !== data._id);
        setSimilarProducts(filteredProducts.map(({ software, description, region, ...rest }) => rest));

        if (data.user?._id) {
          const response = await getUserReviews({
            user: data.user._id,
            limit: 10000,
            page: 0,
          });
          setUserReviews(response.data.items);
        }
        
        // Handle referral
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.has("referral")) {
          const referralId = queryParams.get("referral");
          localStorage.setItem("referral", JSON.stringify({
            productId: data._id,
            referralId,
          }));
        }
      } catch (error) {
        navigate('/404')
      } finally {
        setLoading(false);
      }
    };

    if (slug) {
      fetchProductData();
    }
  }, [slug, userSlug, country]);

  if (loading) {
    return <Loading fullHeight />;
  }

  const {
    name,
    description,
    price,
    promocode,
    reviews,
    currency,
    media,
    thumb,
    user,
  } = product;

  const handleCopy = (stringToCopy) => {
    navigator.clipboard.writeText(stringToCopy);
    setAlertMessage(translate("products.productPage.linkCopied"));
  };

  const handleRedirect = (href) => () => {
    if (href) {
      navigate(href);
    }
  };

  const handleAddToCart = () => {
    dispatch(addProduct({ ...product, country }));
    // yandex metrica
    ym(97684982,'reachGoal','backet')
    // calculate price - prmocode discount
    const price = parseFloat(product.price);
    const quantity = 1;
    const discount = product.promocode?.discount || 0;
    const totalPrice = price * quantity * (1 - discount / 100);
    const productToSend = {
      id: product._id,
      name: product.name,
      price: parseFloat(totalPrice),
      category: product.category?.name,
      quantity: 1
    }
    window.dataLayer.push({
      "ecommerce": {
          "currencyCode": "RUB",    
          "add": {
              "products": [
                  {...productToSend}
              ]
          }
      }
  });
    // alert
    setAlertMessage(
      translate("products.productPage.addedToCart", { product: name })
    );
  };

  const utilsButtons = (
    <Box>
      <IconButton
        className={styles.titleContainer__button}
        onClick={() => handleCopy(location.href)}
      >
        <ShareIcon />
      </IconButton>
    </Box>
  );

  const userFromLocalStorage = JSON.parse(localStorage.getItem("user"));
  const isProductOwner = userFromLocalStorage?._id === user._id;

  return (
    <>
      <Container className={styles.container}>
        <SEOHelmet
          productId={product?._id}
          categoryId={product?.category._id}
          title={`Mentup - ${product?.name || "Product"}`}
          description={"Mentup - " + product?.name}
          name={"Mentup - " + product?.name}
          type="productPage"
          keywords={["mentup", "mentup-store", ""+product?.name]}
        />

        <AlertComponent
          message={alertMessage}
          severity="custom"
          resetMessageInParent={() => setAlertMessage(null)}
        />

        <Box sx={{ marginBottom: '30px', marginX: '15px' }}>
          <BreadcrumbsNavigation
            breadcrumbs={[
              { label: "navigation.home", path: "/" },
              { label: "navigation.catalog", path: "/games" },
              { label: `${product.category.name}`, path: `/games/${product.category.slug}` },
              { label: product.type.name, path: `/games/${product.category.slug}/${product.type.slug}?attr=all` }
            ]}
          />
        </Box>

        <Grid container columnSpacing="36px">
          <Grid item xs={12} md={7}>
            <ProductCarousel images={thumb !== null && thumb !== undefined && thumb.length !== 0 ? thumb : media} />
            {!isMobile && <ProductReviews reviews={userReviews} />}
          </Grid>
          <Grid item xs={12} md={5}>
            <Box className={styles.titleContainer}>
              <Typography className={styles.titleContainer__title} variant="h2">
                {name}
              </Typography>
              {!isMobile && utilsButtons}
            </Box>
            <ReviewsStars reviews={user.reviews} />

            <Box className={styles.priceContainer}>
              <Typography className={styles.price}>
                {promocode ? (
                  <>
                    <Box className={styles.price__strike} component="span">
                      {priceLabel(price, { currency })}
                    </Box>
                    {priceLabel(price - (price * promocode.discount) / 100.0, {
                      currency,
                    })}
                  </>
                ) : (
                  priceLabel(price, { currency })
                )}
              </Typography>
              {!!isMobile && utilsButtons}
            </Box>
            <Box sx={{
              marginBottom: '1rem',
              width: '100%',
              display: 'flex',
              gap: '1rem',
            }}>
              <Button
                  className={clsx(
                    styles.descriptionContainer__buttonBuyNow,
                    styles.descriptionContainer__button_orange
                  )}
                  disabled={
                    product.soldOut || product.disabled || isProductOwner
                  }
                  onClick={() => (
                    setOpenBuyNow(true),
                    ym(97684982,'reachGoal','buy_now')
                  )}
                >
                  {translate("products.productPage.buyNow")}
              </Button>
              <Button
                disabled={
                  product.soldOut || product.disabled || isProductOwner
                }
                sx={{
                  ':disabled': {
                    opacity: 0.5
                  }
                }}
                id='cartButton'
                color="secondary"
                startIcon={<ShoppingBasket />}
                onClick={handleAddToCart}
              >
              </Button>
              {/*
              <Button
                  disabled={
                    product.soldOut || product.disabled || isProductOwner
                  }
                  onClick={handleAddToCart}
                  className={clsx(
                    styles.descriptionContainer__button,
                    styles.descriptionContainer__button_yellow
                  )}
                  color="secondary"
                >
                  {translate("products.productPage.addToCart")}
                </Button> */}
            </Box>

            <Divider />

            <Box className={styles.ownerInformationContainer}>
              <Box
                className={styles.ownerInformationContainer__owner}
                onClick={handleRedirect(
                  user.userSlug && `/profile/${user.userSlug}`
                )}
                aria-label="Go to user profile"
              >
                <Avatar
                  className={styles.avatar}
                  alt="User avatar"
                  src={user.avatar}
                />
                <Box>
                  <Typography
                    className={styles.ownerInformationContainer__username}
                  >
                    {user.username}
                  </Typography>
                  <ReviewsStars size="medium" reviews={user.reviews} />
                </Box>
              </Box>
              {/*
              <Typography
                className={styles.ownerInformationContainer__userDescription}
              >
                {user.description}
              </Typography>
              */}
            </Box>
            
            <Box className={styles.descriptionContainer}>
              
              <Typography
                style={{
                  whiteSpace: "pre-line",
                  wordBreak: "break-word",
                  overflowWrap: "break-word",
                }}
                className={styles.descriptionContainer__description}
              >
                {description}
              </Typography>
            </Box>

            <Box className={styles.descriptionContainer__buttons}>
                <MentupModal
                  open={openBuyNow}
                  close={() => setOpenBuyNow(false)}
                  isBuyNow={true}
                >
                  <CartModalComponent defaultProduct={product} />
                </MentupModal>

                {/*
                <Typography className={styles.descriptionContainer__note}>
                  {translate("products.productPage.commissionPaidByBuyer")}
                </Typography> */}
              </Box>

            <Divider />

            <Box sx={{ margin: "24px 0" }}>
              <PaymentInformation />
            </Box>

            {userFromLocalStorage && (
              <>
                <Divider />

                <Typography className={styles.inviteFriend}>
                  {translate("products.productPage.inviteFriend")}{" "}
                  <ArrowRightIcon />
                </Typography>
                <Box className={styles.commissionContainer}>
                  <Typography
                    className={styles.commissionContainer__percentage}
                  >
                    +10%
                  </Typography>
                  <Typography>
                    {translate("products.productPage.commissionForInvite")}
                  </Typography>
                </Box>
                <Typography
                  className={styles.copyLink}
                  onClick={() =>
                    handleCopy(
                      `${location.origin}${location.pathname}?referral=${userFromLocalStorage._id}`
                    )
                  }
                  aria-label="Copy link"
                  component="a"
                >
                  <ContentCopy /> {translate("products.productPage.copyLink")}
                </Typography>
              </>
            )}
          </Grid>
          {isMobile && (
            <Grid item xs={12}>
              <ProductReviews reviews={userReviews} />
            </Grid>
          )}
        </Grid>
      </Container>
      <Box className={styles.similarProductsContainer}>
        {similarProducts.length > 0 && (
          <Box className={styles.similarProducts}>
            <Typography
              className={styles.similarProducts__heading}
              variant="h3"
            >
              {translate("products.productPage.similarProducts")}
            </Typography>
            <ProductSwiper products={similarProducts} cardVariant="small" />
          </Box>
        )}
      </Box>
    </>
  );
};

export default ProductPage;
