export const defaultQuestions = [
  "how_to_buy",
  "guarantees_and_return",
  "selling_goods",
  "premium",
  "withdrawal",
  "mentup_profile",
  "profile_blocking",
  "technical_problems",
];

export const questionsByParent = {
  how_to_buy: [
    "how_is_the_deal_going",
    "tips",
    "security",
    "receipt_confirmation",
  ],
  guarantees_and_return: [
    "refund",
    "problem_with_the_purchase",
    "where_are_the_funds_returned",
  ],
  selling_goods: [
    "how_is_the_deal_going",
    "what_is_forbidden",
    "product_moderation",
    "product_photo",
    "product_description",
    "product_details",
  ],
  premium: ["refund_for_premium", "abuse_of_returns"],
  withdrawal: [
    "how_long_should_I_wait_for_payment",
    "withdrawal_limits",
    "exchange_rates",
    "commission",
  ],
  mentup_profile: [
    "balance",
    "profile_security",
    "notifications",
    "profile_avatar",
  ],
};
