import { Box } from "@mui/material";
import Cookies from "js-cookie";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { logInEmail, logInGoogle } from "../../../api/auth/login";
import { useSocketProvider } from "../../../context/socket";
import { useTranslate } from "../../../context/translate/translate.context";
import { setUserSettings } from "../../../store/userSettingsStore";
import { setUser } from "../../../store/userStore";
import FormCheckbox from "../../FormCheckbox";
import FormInput from "../../FormInput";
import Button from "../../UI/Button";
import GoogleAuthBtn from "../GoogleAuthBtn";
import { FORM_VALIDATIONS } from "../Register/constants";
import VKAuthBtn from "../VKAuthBtn";
import styles from "./styles.module.css";
import { emailVerify, verifyGoogle } from "../../../api/auth/register";

const LoginStep1 = ({ 
  setAlertMessage, 
  setLoginData, 
  next, 
  setIsRegister, 
  isRegister,
  setOtp,
  setIsRegisterGoogle
 }) => {
  const { translate } = useTranslate();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const socket = useSocketProvider();
  const userRedux = useSelector((state) => state.user);

  const { control, handleSubmit, setError } = useForm();

  const [loading, setLoading] = useState(false);

  const onSubmit = async ({ email, rememberMe }) => {
    try {
      setLoading(true);

      const response = await logInEmail({ email });
      if (response.status !== 200) {
        setError("email", {
          message: translate("errorMessages.invalidEmail"),
        });
        return;
      } else {
        setLoginData({ email, rememberMe });
        next();
      }
      
    } catch (err) { // register user if it does not exist
      await emailVerify({ email });
      setLoginData({email: email})
      setIsRegister()
      next()
    } finally {
      setLoading(false)
    }
  };

  const onGoogleSubmit = async (token) => {
    try {
      setLoading(true);

      const res = await logInGoogle({
        token,
        country: userRedux.country,
        ip: userRedux.ip,
      });
      const user = res.data.user;
      const userSettings = res.data.userSettings;

      if (user && userSettings) {
        localStorage.setItem("userSettings", JSON.stringify(userSettings));
        localStorage.setItem("user", JSON.stringify(user));

        dispatch(setUser(user));
        dispatch(setUserSettings(userSettings));

        const maxDate = new Date(2147483647 * 1000); // Convert seconds to milliseconds
        Cookies.set("refreshToken", userSettings.refreshToken, {
          expires: maxDate
        });

        const token = userSettings.refreshToken;
        socket.authenticate(token);
      }

      navigate(`/profile/${user.userSlug}`);
      // yandex metrika
      ym(97684982,'reachGoal','open')
    } catch (err) {
      /*
      console.error(err);
      setLoading(false);

      setError("email", { message: "" });

      //if error status 4-02 , then message that user is banner
      if(err.response.status === 402) {
        return setAlertMessage('User is banned');
      }
      if (err.response.status === 401 || err.response.status === 400) {
        return setAlertMessage(translate("errorMessages.invalidEmailPassword"));
      }

      setAlertMessage(translate("errorMessages.unexpectedError")); */
      // register google
      try {
        setLoading(true);
        await verifyGoogle({ token });
  
        setLoginData({ token });
  
        setIsRegisterGoogle()
        next()
      } catch (error) {
        setError("email", { message: error?.response?.data?.email });
        //console.error(error);
      } finally {
        setLoading(false);
      }
    } finally {
      setLoading(false)
    }
  };
  return (
    <Box
      className={styles.form}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormInput
        type="email"
        name="email"
        label={translate("authentication.email")}
        control={control}
        autoComplete="email"
        disabled={loading}
        rules={FORM_VALIDATIONS.email(translate)}
      />
      <Button
        className={styles.form__submit}
        type="submit"
        size="large"
        fullWidth
        loading={loading}
      >
        {translate("authentication.signInToYourAccount")}
      </Button>

      <GoogleAuthBtn onToken={onGoogleSubmit} disabled={loading} />

      {/*
      <VKAuthBtn title="authentication.signInVk" disabled={loading} />
      */}
    </Box>
  );
};

export default LoginStep1;
