import axios from "axios";
import Cookies from "js-cookie";

const baseURL =
  (process.env.NODE_ENV.includes("production") &&
    process.env.REACT_APP_SERVER_URL) ||
  "http://localhost:3030/api/";
const API = axios.create({ baseURL });

const header = () => {
  const token = Cookies.get("refreshToken");
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
};

export const createGroupChat = (data) => API.post("/chats", data, header());
export const getChats = () => API.get("/chats", header());
export const getChatUser = (id) => API.get(`/chats/user/${id}`, header());
export const getChatById = (id) => API.get(`/chats/${id}`, header());
export const markChatAsRead = (chatId) =>
  API.post("/chats/read", { chatId }, header());
