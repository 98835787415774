import { Box, Button, Container, Typography, useTheme } from "@mui/material";

import { useTranslate } from "../../../context/translate/translate.context";
import Footer from "../../Layout/Footer";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";

export const Error404 = () => {
  const { translate } = useTranslate();

  const navigate = useNavigate()

  // If the user has a history, go back to the previous page.
  const handleGoBack = () => {
    navigate('/')
  };

  return (
    <Box className={styles.wrapper}>
      <Container className={styles.container}>
        <Typography className={styles.heading} variant="h1" color="main">
          {translate("error404.somethingWentWrong")}
        </Typography>
        <Typography className={styles.text}>
          {translate("error404.tryToRefresh")}
        </Typography>
        <Button
          className={styles.button}
          variant="contained"
          // color="primary"
          onClick={handleGoBack}
        >
          {translate("error404.goBack")}
        </Button>
      </Container>
      <Footer customFooter />
    </Box>
  );
};
