import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import StepGoogle from "./StepGoogle";
import StepVk from "./StepVK";

export const REGISTER_STEPS = [
  (props) => <Step1 {...props} />,
  (props) => <Step2 {...props} />,
  (props) => <Step3 {...props} />,
];

export const REGISTER_VK_STEPS = [
  (props) => <StepVk {...props} />,
  (props) => <Step3 {...props} />,
];

export const REGISTER_GOOGLE_STEPS = [
  (props) => <StepGoogle {...props} />,
  (props) => <Step3 {...props} />,
];

export const FORM_VALIDATIONS = {
  username: (translate) => ({
    min: {
      value: 4,
      message: translate("authentication.validations.usernameMin"),
    },
    max: {
      value: 24,
      message: translate("authentication.validations.usernameMax"),
    },
    required: {
      value: true,
      message: translate("authentication.validations.usernameRequired"),
    },
  }),
  email: (translate) => ({
    required: {
      value: true,
      message: translate("authentication.validations.emailRequired"),
    },
    pattern: {
      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,6}$/,
      message: translate("authentication.validations.invalidEmail"),
    },
  }),
};
