import { Box, Container, Typography, useTheme } from "@mui/material";
import React from "react";

import { useTranslate } from "../../context/translate/translate.context";
import QuestionAnswer from "./QuestionAnswer";

export const FaqPage = () => {
  const theme = useTheme();

  const { translate } = useTranslate();

  return (
    <Container>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "1.8rem",
            color: `${theme.palette.text.secondary}`,
            // fontFamily: `${theme.typography.fontFamily}`,
          }}
        >
          {translate("faqPage.title")}
        </Typography>
      </Box>

      <QuestionAnswer
        question={`Lorem ipsum dolor sit amet, consectetur adipiscing elit ?`}
        answer={`  Aenean vel lectus et leo mattis eleifend. Nunc sit amet orci
                    nec tellus lacinia vestibulum. Donec nisi magna, ornare
                    elementum sem sit amet, sagittis pellentesque nisl. In
                    pharetra, velit et varius sollicitudin, lacus purus
                    malesuada tellus, at sagittis dolor est non ex.`}
      />
      <QuestionAnswer
        question={`Lorem ipsum dolor sit amet, consectetur adipiscing elit ?`}
        answer={` Aenean vel lectus et leo mattis eleifend. Nunc sit amet orci
                    nec tellus lacinia vestibulum. Donec nisi magna, ornare
                    elementum sem sit amet, sagittis pellentesque nisl. In
                    pharetra, velit et varius sollicitudin, lacus purus
                    malesuada tellus, at sagittis dolor est non ex.`}
      />
      <QuestionAnswer
        question={`Lorem ipsum dolor sit amet, consectetur adipiscing elit ?`}
        answer={`Aenean vel lectus et leo mattis eleifend. Nunc sit amet orci
                    nec tellus lacinia vestibulum. Donec nisi magna, ornare
                    elementum sem sit amet, sagittis pellentesque nisl. In
                    pharetra, velit et varius sollicitudin, lacus purus
                    malesuada tellus, at sagittis dolor est non ex.`}
      />
    </Container>
  );
};
