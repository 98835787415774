import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Collapse from '@mui/material/Collapse';
import { useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function QuestionAnswer(props) {
    const question = props.question;
    const answer = props.answer;

    const [checked, setChecked] = useState(false);

    const handleChange = () => {
        setChecked((prev) => !prev);
    };

    return (
        <Box
            sx={{
                marginTop: '2rem',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                rowGap: '1rem',
            }}
        >
            <Box
                sx={{
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingLeft: '40px',
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 'bold',
                        fontSize: '1.3rem',
                        textAlign: 'center',
                    }}
                >
                    {question ? question : null}
                </Typography>
                <IconButton onClick={() => handleChange()}>
                    {!checked ? (
                        <KeyboardArrowDownIcon />
                    ) : (
                        <KeyboardArrowUpIcon />
                    )}
                </IconButton>
            </Box>
            <Collapse in={checked}>
                {' '}
                <Typography sx={{ fontSize: '1rem', textAlign: 'center' }}>
                    {answer ? answer : null}
                </Typography>
            </Collapse>
        </Box>
    );
}

export default QuestionAnswer;
