import axios from "axios";
import Cookies from "js-cookie";

const API = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL || "http://localhost:3030/api/",
});

const header = () => {
  const token = Cookies.get("refreshToken");
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
};

export const getNotificationsSettings = () => {
  return API.get("/settings/notifications", header());
};

export const getTelegramNotificationsSteps = () => {
  return API.get("/settings/telegram/steps/notifications", header());
};

export const changeTelegramNotifications = (data) => {
  return API.put("/settings", data, header());
};