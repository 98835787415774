import { Box, Chip, Typography } from "@mui/material";
import clsx from "clsx";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { useTranslate } from "../../../context/translate/translate.context";
import styles from "./styles.module.css";

const Category = ({
  size = "small",
  _id,
  name,
  slug,
  primaryProductType,
  productTypes,
  media,
  thumb,
  subcategories,
}) => {
  const { translate } = useTranslate();
  const [imageError, setImageError] = useState(false);

  const imageUrl = useMemo(() => {
    if (media && !imageError) {
      return media;
    }

    return "/images/product_placeholder.png";
  }, [media, imageError]);

  const thumbUrl = useMemo(() => {
    if (thumb && !imageError) {
      return thumb;
    }

    return null;
  }, [thumb, imageError]);

  const chips = useMemo(() => [...subcategories, ...productTypes], []);

  return (
    <Box
      key={_id}
      className={clsx(styles.container, size)}
      component={Link}
      aria-label={translate("ariaLabel.navigateToCategory", {
        name,
      })}
      to={primaryProductType?.slug ? `/games/${slug}` : "#"}
    >
      <Box
        className={styles.imageContainer}
        component="img"
        src={thumbUrl !== null && thumbUrl !== undefined ? thumbUrl : imageUrl}
        onError={() => setImageError(true)}
      />
      <Box>
        <Typography className={styles.name}>{name}</Typography>
        {!!chips.length && (
          <Box className={styles.chipsContainerWrapper}>
            <Box className={styles.chipsContainer}>
              {chips?.map((chip) => (
                <Chip
                  key={chip._id}
                  className={styles.chip}
                  label={translate(chip.name)}
                  component={Link}
                />
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Category;
