import { Container, Divider, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import styles from "./styles.module.css";

const AgencyContract = () => {
  return (
    <Container className={styles.agencyContainer}>
      <Typography variant="h4">Агентский договор</Typography>
      <Divider />
      <Typography variant="body2">
        Настоящий агентский договор (далее — Договор), является публичной
        офертой Индивидуального предпринимателя ИП Горелкин Евгений Андреевич ИНН 346000803002, ОГРН 322344300043709 (далее – MentUp, Агент) на совершение расчетов от имени и
        за счет Покупателя на сайте
        <Link className={styles.agencyLink} to="/">
          {" "}
          https://mentup-store.com{" "}
        </Link>
        (далее – Сайт) на условиях, приведенных ниже.
      </Typography>
      <Typography variant="body2">
        Термины и определения, используемые в настоящем Договоре, раскрыты в
        заключенном Сторонами Лицензионном соглашении, размещенном по адресу:
        <Link className={styles.agencyLink} to="/info/laws/license-agreement">
          {" "}
          https://mentup-store.com/info/laws/license-agreement
        </Link>
      </Typography>
      <Typography variant="h5">1. ПРЕДМЕТ ДОГОВОРА</Typography>
      <Divider />
      <Typography variant="body2">
        1.1. Покупатель в качестве Принципала поручает MentUp в качестве Агента
        совершать от имени Покупателя и за его счет расчеты по исполнению
        Сделок, заключаемых Покупателем с Продавцами на Сайте, и обязуется
        выплачивать Агенту агентское вознаграждение.
      </Typography>
      <Typography variant="body2">
        1.2. Оформление Покупателем Сделки с Продавцом через Сервис Сайта
        означает автоматическое поручение Покупателем Агенту в соответствии с
        Договором.
      </Typography>
      <Typography variant="body2">
        1.3. Агент вправе переуступать либо каким-либо иным способом передавать
        свои права и обязанности, вытекающие из его отношений с Покупателем,
        третьим лицам.
      </Typography>

      <Typography variant="h5">2. ВОЗНАГРАЖДЕНИЕ И РАСХОДЫ АГЕНТА</Typography>
      <Divider />
      <Typography variant="body2">
        2.1. Вознаграждение Агента выплачивается отдельно по каждому поручению и
        состоит из: безусловной части вознаграждения; вознаграждения по
        результатам исполнения поручения.
      </Typography>
      <Typography variant="body2">
        2.2. Вознаграждение Агента, а также каждой его части, зависит от цены
        поручения (суммы, подлежащей перечислению Продавцу по указанию
        Покупателя), а также титула Покупателя в соответствии с программами
        лояльности, предусмотренными на Сайте. Информация о его титуле доступна
        Покупателю в Личном кабинете.
      </Typography>
      <Typography variant="body2">
        2.3. Размер безусловной части вознаграждения Агента по одному поручению
        не может превышать 15% от цены поручения.
      </Typography>
      <Typography variant="body2">
        2.4. Размер вознаграждения Агента по результатам исполнения поручения по
        одному поручению не может превышать 15% от цены поручения.
      </Typography>
      <Typography variant="body2">
        2.5. Общая сумма вознаграждения Агента по одному поручению не может
        превышать 20% от цены поручения.
      </Typography>
      <Typography variant="body2">
        2.6. Расходы, понесенные Агентом для исполнения Агентский договора,
        компенсируются по мере их несения.
      </Typography>

      <Typography variant="h5">3. ПОРЯДОК ВЗАИМОДЕЙСТВИЯ СТОРОН</Typography>
      <Divider />
      <Typography variant="body2">
        3.1. Подтверждение (акцепт) Покупателем через Сайт Сделки с Продавцом
        означает автоматическое поручение Покупателем Агенту.
      </Typography>
      <Typography variant="body2">
        3.2. Покупатель, выдавший поручение Агенту, перечисляет Агенту денежные
        средства для исполнения соответствующего поручения, размер которых
        включает:
        <ul>
          <li>сумму, подлежащую перечислению Продавцу;</li>
          <li>вознаграждение Агента.</li>
        </ul>
        При перечислении Покупателем денежных средств Агенту, Покупатель
        дополнительно оплачивает комиссию платежной системы, которая
        удерживается платежной системой при перечислении.
      </Typography>
      <Typography variant="body2">
        3.3 Aгент удерживает из суммы, полученной от Покупателя, безусловную
        часть вознаграждения Агента и помещает оставшуюся сумму на депозит до
        получения дополнительных указаний Покупателя.
      </Typography>
      <Typography variant="body2">
        3.4. После получения указания Покупателя о перечислении средств
        Продавцу, Агент перечисляет средства депозита за минусом суммы
        вознаграждения по результатам исполнения поручения на Кошелек Продавца и
        предоставляет Покупателю отчет об исполнении поручения.
      </Typography>
      <Typography variant="body2">
        3.5. Моментом исполнения Агентом поручения считается момент зачисления
        суммы, подлежащую перечислению Продавцу, на Кошелек Продавца.
      </Typography>
      <Typography variant="body2">
        3.6. Отчетом Агента по конкретному поручению является информация в
        Личном кабинете Покупателя о произведенных расчетах с Продавцом.
        Доказательства расходов, произведенных Агентом за счет Покупателя, в
        отчете агента не указываются.
      </Typography>
      <Typography variant="body2">
        3.7. Отчет Агента размещается в Личном кабинете Покупателя, как правило,
        в течение часа, но не более чем в течение 24 часов.
      </Typography>
      <Typography variant="body2">
        3.8. Покупатель, имеющий возражения по отчету Агента, должен сообщить о
        них в течение 24 часов. В противном случае отчет Агента считается
        принятым Покупателем.
      </Typography>
      <Typography variant="body2">
        3.9. Покупатель вправе отказаться от поручения в любой момент до выдачи
        указания Агенту о перечислении средств Продавцу.
      </Typography>
      <Typography variant="body2">
        3.10. В случае отказа Покупателя от поручения до перечисления денежных
        средств Агенту для исполнения поручения, вознаграждение Агенту не
        выплачивается.
      </Typography>
      <Typography variant="body2">
        3.11. В случае отказа Покупателя от поручения после размещения Агентом
        средств на депозите и до выдачи указания Агенту о перечислении средств
        Продавцу, вся сумма депозита (включая сумму, подлежащую перечислению
        Продавцу, и сумму вознаграждения по результатам исполнения поручения)
        переводится Агентом на его Кошелек, безусловная часть вознаграждения
        Агента не возвращается.
      </Typography>
      <Typography variant="body2">
        3.12. В случае последующей отмены Сделки и возврата средств Покупателю в
        случаях, предусмотренных Лицензионным соглашением, такой возврат
        производится на условиях, определяемых Лицензионным соглашением, и не
        включает вознаграждение Агента, т.е. возврату подлежит только сумма,
        которая подлежала перечислению Продавцу по указанию Покупателя.
      </Typography>

      <Typography variant="h5">4. ОГРАНИЧЕНИЕ ОТВЕТСТВЕННОСТИ</Typography>
      <Divider />
      <Typography variant="body2">
        4.1. Агент не несет ответственности за нарушение сроков, указанных в
        разделе 3 Договора в случае частичной или полной недоступности Сайта и
        его Сервисов по причине проведения профилактических или иных работ, или
        по любым другим причинам технического характера.
      </Typography>
      <Typography variant="body2">
        4.2. В случае проведения таких работ или наступления таких причин,
        сроки, указанные в разделе 3 Договора, продлеваются на срок выполнения
        соответствующих работ или устранения причин, вызвавших частичную или
        полную недоступность Сайта и/или его Сервисов.
      </Typography>
      <Typography variant="body2">
        4.3. Агент не несет никаких иных дополнительных гарантий в отношении
        работы Сайта и его Сервисов, а также в связи с использованием или
        невозможностью использования Сайта и/или его Сервисов, сверх гарантий,
        установленных Лицензионным соглашением.
      </Typography>
      <Typography variant="body2">
        4.4. Агент не является стороной или заинтересованным лицом по Сделке
        между Покупателем и Продавцом, а потому не несет ответственность за
        последствия этой Сделки. Ответственность Агента ограничивается
        ответственностью, предусмотренной по Договору.
      </Typography>

      <Typography variant="h5">
        5. ПОРЯДОК ЗАКЛЮЧЕНИЯ И ИЗМЕНЕНИЯ ДОГОВОРА, СРОК ЕГО ДЕЙСТВИЯ
      </Typography>
      <Divider />
      <Typography variant="body2">
        5.1. Принимая Договор путем нажатия соответствующей кнопки в конце
        текста Договора или иным способом, предусмотренным интерфейсом Сайта,
        Покупатель дает полное и безоговорочное согласие на условия, изложенные
        в Договоре.
      </Typography>
      <Typography variant="body2">
        5.2. Принимая условия Договора, Покупатель подтверждает, что обладает
        дееспособностью, правами и полномочиями на заключение Договора и
        полностью понимает и осознает значение своих действий.
      </Typography>
      <Typography variant="body2">
        5.3. Договор вступает в силу с момента его заключения (акцепта) и
        действует до исполнения Сторонами своих обязательств по нему или
        наступления следующих событий:
        <ul>
          <li>Расторжения Сторонами Лицензионного соглашения;</li>
          <li>принятия решения Покупателем о расторжении Договора.</li>
        </ul>
      </Typography>
      <Typography variant="body2">
        5.4. Агент вправе в любое время и по своему усмотрению вносить изменения
        в Договор. В случае внесения изменений в Договор обновленная версия
        публикуется на Сайте в разделе
        <Link className={styles.agencyLink} to="/info/laws/agency-contract">
          {" "}
          https://mentup-store.com/info/laws/agency-contract{" "}
        </Link>
        .
      </Typography>
      <Typography variant="body2">
        Обновленная версия Договора вступает в силу с даты публикации.
        Актуальная дата всегда будет указана непосредственно под текстом
        документа.
      </Typography>
      <Typography variant="body2">
        Покупатель получает уведомление об изменении Договора по адресу
        электронной почты, указанной им при регистрации. Продолжение
        использования Покупателем Сервисов Сайта после публикации изменений в
        Договоре означает его согласие с данными изменениями.
      </Typography>
      <Typography variant="body2">
        5.5. Если Покупатель не согласен с каким-либо условием Договора или оно
        ему непонятно, он не должен акцептовать Договор, предусмотренным на
        Сайте способом, до устранения неясностей или несогласия.
      </Typography>

      <Typography variant="h5">6. ИНЫЕ ПОЛОЖЕНИЯ</Typography>
      <Divider />
      <Typography variant="body2">
        6.1. Независимо от места нахождения Покупателя, применимым правом по
        Договору является право Российской Федерации, в соответствии с которым
        разрешаются все споры и разногласия между Сторонами.
      </Typography>
      <Typography variant="body2">
        6.2. Стороны будут стремиться к разрешению споров и разногласий по
        Договору или в связи с ним, путем переговоров.
      </Typography>
      <Typography variant="body2">
        6.3. Если по тем или иным причинам одно или несколько положений Договора
        будут признаны недействительными или не имеющими юридической силы, это
        не оказывает влияния на действительность или применимость остальных
        положений Договора.
      </Typography>
      <Typography variant="body2">
        6.4. Стороны признают юридическую силу за: электронными письмами и
        документами, направленными по электронной почте (cо стороны Агента это
        адрес электронной почты, указанный на Сайте, со стороны Покупателя -
        адрес электронной почты, указанный при регистрации); согласиями и
        гарантиями, полученными от Покупателя с использованием элементов
        интерфейса Сайта.
      </Typography>
    </Container>
  );
};

export default AgencyContract;
