import { getProductFeaturedAll } from "../api/product/getFeaturedProducts";

async function getFeaturedProducts(country) {
  const lastDisplayedId = localStorage.getItem("featured-last");
  const response = await getProductFeaturedAll(lastDisplayedId, country);

  const lastItem = response.data[response.data.length - 1];
  if (lastItem) {
    localStorage.setItem("featured-last", lastItem._id);
  }

  return response.data;
}

export default getFeaturedProducts;
