import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Box, Button, Card, IconButton, InputBase, List, ListItem, Typography } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { TranslationsDate, useTranslate } from "../../../../context/translate/translate.context";
import { useMessages } from "../../../../hooks/messages";
import AlertComponent from "../../../AlertComponent";
import { LogoNew } from "../../../logo/LogoNew";
import ChatOptions from "../../../svg/ChatOptions";
import Send from "../../../svg/Send";
import { TranslateText } from "../../../translate-text";
import MessageUpload from "../MessageUpload";
import { defaultQuestions, questionsByParent } from "../constants";
import styles from "./styles.module.css";
import Lightbox from "../LightBox/LightBox";

export const ChatMessages = ({ otherUser, chat, getChat, unreadCount, markAsRead, showBack, close }) => {
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const messages = useMessages(chat);
  const { language, translate } = useTranslate();
  
  const online = useMemo(() => !!otherUser?.online || chat?.isSupportChat, [otherUser?.online, chat?.isSupportChat]);
  const [faqQuestions, setFaqQuestions] = useState(defaultQuestions);
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(null);
  const [lightboxImage, setLightboxImage] = useState(null); // State for lightbox

  useEffect(() => {
    if (unreadCount || messages?.items?.length) {
      markAsRead();
    }
  }, [unreadCount, messages?.items?.length]);

  useEffect(() => {
    setMessage("");
  }, [otherUser, chat?._id]);

  const trySendMessage = async (chatId, valueToSend) => {
    try {
      await messages.create(chatId, valueToSend);
    } catch (error) {
      //console.error(error);
      setAlert({
        message: error.response?.data?.message || translate("errorMessages.unexpectedError"),
        severity: "error",
      });
      setMessage(valueToSend.trim());
    }
  };

  const handleSend = async (e) => {
    const msgValue = (e?.target?.value || message || "").trim();
    setMessage("");

    if (!msgValue) {
      return;
    }

    let chatId = chat?._id;
    if (!chat) {
      const chat = await getChat(otherUser);
      chatId = chat._id;
    }

    trySendMessage(chatId, msgValue);
  };

  const handleAskSupport = async (question) => {
    let chatId = chat?._id;
    if (!chat) {
      const chat = await getChat(otherUser);
      chatId = chat._id;
    }
    messages.createSupport(chatId, question);
    setFaqQuestions(questionsByParent[question] || faqQuestions);
  };

  const loadMoreItems = async (e) => {
    const shouldLoadMore = e.target.scrollTop * -1 + e.target.clientHeight >= e.target.scrollHeight;
  };

  const handleImageClick = (src) => {
    setLightboxImage(src); // Open lightbox with image
  };

  const handleCloseLightbox = () => {
    setLightboxImage(null); // Close lightbox
  };

  const messageChange = (value) => {
    setMessage(value);
    messages.registerTyping(chat?._id);
  };

  const clearState = () => {
    setMessage("");
  };

  return (
    <Card className={styles.container}>
      <AlertComponent
        message={alert?.message}
        severity={alert?.severity || "error"}
        resetMessageInParent={() => setAlert(null)}
      />

      {(chat?.isSupportChat || !!otherUser) && (
        <Box className={clsx(styles.header, styles.backHeader)}>
          {!!showBack && (
            <IconButton size="medium" onClick={close}>
              <ArrowLeftIcon />
            </IconButton>
          )}

          <Box className={styles.userInfoContainer}>
            {chat?.isSupportChat ? (
              <LogoNew width="40px" height="40px" style={{ minWidth: "40px" }} />
            ) : (
              <img
                src={otherUser?.avatar || "defaultProfile.png"}
                alt="Profile"
                className={styles.userAvatar}
              />
            )}

            <Typography noWrap className={styles.title}>
              {chat?.isSupportChat ? "Mentup Support" : otherUser?.username}
            </Typography>
          </Box>

          <IconButton size="medium">
            <ChatOptions />
          </IconButton>
        </Box>
      )}

      <List onScroll={loadMoreItems} className={styles.list}>
        <ListItem>
          {!!chat?.isSupportChat && (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
              {defaultQuestions[0] !== faqQuestions[0] && (
                <Button variant="contained" onClick={() => setFaqQuestions(defaultQuestions)}>
                  <KeyboardBackspaceIcon fontSize="medium" />
                </Button>
              )}
              {faqQuestions.map((question) => (
                <Button key={question} variant="contained" onClick={() => handleAskSupport(question)}>
                  <Typography>
                    <TranslateText value={`chat.messages.faq.questions.${question}`} />
                  </Typography>
                </Button>
              ))}
            </Box>
          )}
        </ListItem>

        {messages.items.map((msg) => (
          <ListItem
            key={msg._id}
            sx={{
              justifyContent: msg.sender === currentUser._id ? "flex-end" : "flex-start",
            }}
          >
            <Box
              className={clsx(
                styles.messageContainer,
                msg.sender === currentUser._id
                  ? styles.myMessageContainer
                  : styles.notMyMessageContainer
              )}
            >
              {msg.sender !== currentUser._id && (
                <Box className={styles.msgAvatarContainer}>
                  {chat?.isSupportChat ? (
                    <LogoNew width="40px" height="40px" style={{ minWidth: "40px" }} />
                  ) : (
                    <img
                      src={otherUser?.avatar || "defaultProfile.png"}
                      alt="Profile"
                      className={styles.userAvatar}
                    />
                  )}
                  {online && <Box className={styles.online} />}
                </Box>
              )}

              <Box className={styles.message}>
                <Box className={styles.dataContainer}>
                  <Typography noWrap className={styles.msgTitle}>
                    <TranslateText
                      value={
                        msg.sender === currentUser._id
                          ? "chat.messages.you"
                          : chat?.isSupportChat
                          ? "Mentup Support"
                          : otherUser?.username
                      }
                    />
                  </Typography>
                  <Typography noWrap className={styles.msgDate}>
                    {new Date(msg.createdAt).toLocaleTimeString(
                      TranslationsDate[language],
                      {
                        weekday: "long",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      }
                    )}
                  </Typography>
                </Box>
                {msg?.type === "image" ? (
                  <img
                    src={msg.value}
                    className={clsx(
                      msg.sender === currentUser._id
                        ? styles.myMessage
                        : styles.notMyMessage,
                      styles.clickableImage // Add clickableImage class
                    )}

                    onClick={() => handleImageClick(msg.value)} // Add onClick handler
                  />
                ) : msg?.type === "video" ? (
                  <video
                    controls
                    autoPlay={false}
                    src={msg.value}
                    className={clsx(
                      msg.sender === currentUser._id
                        ? styles.myMessage
                        : styles.notMyMessage
                    )}
                  />
                ) : (
                  <Box
                    className={clsx(
                      styles.textContainer,
                      msg.sender === currentUser._id
                        ? styles.myMessage
                        : styles.notMyMessage
                    )}
                  >
                    <Typography>
                      {msg.isSupportQuestion
                        ? translate(`chat.messages.faq.questions.${msg.value}`)
                        : msg.isSupportAnswer
                        ? translate(`chat.messages.faq.answers.${msg.value}`)
                        : msg.value}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </ListItem>
        ))}
      </List>

      {chat || otherUser ? (
        <Box className={styles.inputContainer}>
          <MessageUpload
            chatId={chat?._id}
            percents={messages.percent}
            handleUpload={messages.uploadFile}
          />
          <InputBase
            className={styles.input}
            value={message}
            multiline
            onChange={(e) => messageChange(e.target.value)}
            onKeyUp={(e) => e.key === "Enter" && handleSend(e)}
            placeholder={translate("Message")}
          />
          <IconButton onClick={(e) => handleSend(e)} className={styles.send}>
            <Send />
          </IconButton>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: "bold",
            }}
          >
            <TranslateText value="chat.messages.noSelected" />
          </Typography>
        </Box>
      )}

      {/* Lightbox component */}
      <Lightbox src={lightboxImage} onClose={handleCloseLightbox} />
    </Card>
  );
};
