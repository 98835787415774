import { createSlice } from "@reduxjs/toolkit";

const cartStore = createSlice({
  name: "cartStore",
  initialState: localStorage.getItem("cart")
    ? JSON.parse(localStorage.getItem("cart"))
    : [],
  reducers: {
    addProduct: (state, action) => {
      if (state.length > 0) {
        const item = state.find(
          (item) => item.product._id === action.payload._id
        );
        if (item) {
          if (item.quantity < action.payload.quantity) {
            item.quantity++;
          }
        } else {
          state.push({ product: action.payload, quantity: 1 });
        }
      } else {
        state.push({ product: action.payload, quantity: 1 });
      }
      localStorage.setItem("cart", JSON.stringify(state));
    },
    increaseQuantity: (state, action) => {
      const item = state.find(
        (item) => item.product._id === action.payload._id
      );
      if (item) {
        if (item.quantity < action.payload.quantity) {
          item.quantity++;
        }
      }
      localStorage.setItem("cart", JSON.stringify(state));
    },
    decreaseQuantity: (state, action) => {
      const item = state.find(
        (item) => item.product._id === action.payload._id
      );
      if (item) {
        if (item.quantity >= 2) {
          item.quantity--;
        } else if (item.quantity === 1) {
          localStorage.setItem(
            "cart",
            JSON.stringify(
              state.filter((item) => item.product._id !== action.payload._id)
            )
          );
          return state.filter(
            (item) => item.product._id !== action.payload._id
          );
        }
      }
    },
    removeItems: (state, action) => {
      //console.log(action.payload);

      const newList = state.filter(
        (item) => !action.payload.includes(item.product._id)
      );

      localStorage.setItem("cart", JSON.stringify(newList));

      return newList;
    },
    resetCart: (state) => {
      // reset state to initial state
      localStorage.removeItem("cart");
      return [];
    },
    setQuantityy: (state, action) => {
      const item = state.find(
        (item) => item.product._id === action.payload.product
      );
      if (item) {
        item.quantity = action.payload.quantity;
      }
      localStorage.setItem("cart", JSON.stringify(state));
    },
  },
});

export const {
  addProduct,
  increaseQuantity,
  decreaseQuantity,
  resetCart,
  removeItems,
  setQuantityy,
} = cartStore.actions;

export const cartReducer = cartStore.reducer;
