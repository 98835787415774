import axios from "axios";

const API = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL || "http://localhost:3030/api/",
});

/**
 * @param {string} category
 * @param {string} type
 * @returns
 */
export const getProductListingsFilters = (category, type) => {
  return API.post("/item/listings/filters", { category, type });
};

export const getProductListings = (filters) => {
  return API.post("/item/listings/list", filters);
};
