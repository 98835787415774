import React from 'react';
import { Box, List, ListItem, ListItemText, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

const Privacy = () => {
    const theme = useTheme();
  return (
    <Box sx={{
      width: '100%'
    }}>
      <Typography sx={{ 
        margin: '1rem',
        marginBottom: '2rem',
        fontWeight: 'bold',
        fontSize: '1.5rem',
      }}>
        Privacy Policy
      </Typography>

      <Typography variant="h6" gutterBottom sx={{marginBottom: '1rem'}}>
        Политика конфиденциальности MentUp
      </Typography>

      <Typography variant="h6" gutterBottom>
        1. Общие положения
      </Typography>

      <Typography paragraph>
        1.1. Данный документ определяет условия использования веб-сайта и его сервисов (далее "Сайт"). Пользователь,
        посещающий и использующий Сайт, соглашается с данными условиями.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Политика обработки персональных данных
      </Typography>

      <Typography paragraph>
        1. Общие положения
        Настоящая политика обработки персональных данных (далее - Политика) описывает как происходит сбор, обработка и защита персональных данных граждан РФ во время использования веб-сайта https://mentup-store.com.
        Политика действует в отношении всех персональных данных, которые Администрация веб-сайта mentup-store.com (Далее - Администрация) может получить от Пользователя во время взаимодействия с сайтом и связанными с ним любыми программными обеспечениями.
        Политика составлена Индивидуальным предпринимателем Согоян Армине Михайловна (ИНН 344223391197), выступающим в качестве оператора персональных данных (далее - Оператор), а также разработана в соответствии с требованиями Федерального закона от 27.07.2006. № 152-ФЗ «О персональных данных» (далее — Закон о персональных данных).
      </Typography>

      <Typography paragraph>
        1.1. Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.
      </Typography>

      <Typography paragraph>
        1.3. Предоставляя нам персональные данные, Вы соглашаетесь на их обработку в соответствии с настоящей политикой.
      </Typography>

      <Typography paragraph>
        1.4. Настоящая политика регулируется законодательством Российской Федерации
      </Typography>

      <Typography variant="h6" gutterBottom>
        2. Основные понятия, используемые в Политике
      </Typography>

      <Typography paragraph>
        2.1. Автоматизированная обработка персональных данных — обработка персональных данных с помощью средств вычислительной техники.
      </Typography>

      <Typography paragraph>
        2.2. Блокирование персональных данных — временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных).
      </Typography>

      <Typography paragraph>
        2.3. Веб-сайт — совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу https://mentup-store.com.
      </Typography>

      <Typography paragraph>
        2.4. Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных и обеспечивающих их обработку информационных технологий и технических средств.
      </Typography>

      <Typography paragraph>
        2.5. Обезличивание персональных данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных.
      </Typography>

      <Typography paragraph>
        2.6. Обработка персональных данных — любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
      </Typography>

      <Typography paragraph>
        2.7. Оператор — государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и/или осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.
      </Typography>

      <Typography paragraph>
        2.8. Персональные данные — любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю веб-сайта https://mentup-store.com.
      </Typography>

      <Typography paragraph>
        2.9. Персональные данные, разрешенные субъектом персональных данных для распространения, — персональные данные, доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных путем дачи согласия на обработку персональных данных, разрешенных субъектом персональных данных для распространения в порядке, предусмотренном Законом о персональных данных (далее — персональные данные, разрешенные для распространения).
      </Typography>

      <Typography paragraph>
        2.10. Пользователь  — любой посетитель веб-сайта https://mentup-store.com.
      </Typography>

      <Typography paragraph>
        2.11. Предоставление персональных данных — действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц.
      </Typography>

      <Typography paragraph>
        2.12. Распространение персональных данных — любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом.
      </Typography>

      <Typography paragraph>
        2.13. Трансграничная передача персональных данных — передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу.
      </Typography>

      <Typography paragraph>
        2.14. Уничтожение персональных данных — любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и/или уничтожаются материальные носители персональных данных.
      </Typography>

      <Typography paragraph>
        2.15. Файлы cookie — это файлы, установленные в компьютере, телефоне, планшете или любом другом устройстве пользователя для того, чтобы регистрировать его действия во время просмотра на веб-сайте https://mentup-store.com. С помощью файлов cookie сервер, на котором находится Web, распознает используемый пользователем браузер, предоставляя, например, зарегистрированному пользователю доступ к областям и службам без необходимости регистрации при каждом посещении и запоминая его предпочтения языка, страны и т. д. для будущих посещений. Файлы cookie также используются для вычисления аудитории и параметров трафика, отслеживания прогресса и количества входов.
      </Typography>

      <Typography variant="h6" gutterBottom>
        3. Основные права и обязанности Оператора
      </Typography>

      <Typography paragraph>
        3.1. Оператор имеет право:
      </Typography>

      <List>
        <ListItem>
          <ListItemText>
            получать от субъекта персональных данных достоверные информацию и/или документы, содержащие персональные данные;
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            в случае отзыва субъектом персональных данных согласия на обработку персональных данных, а также, направления обращения с требованием о прекращении обработки персональных данных, Оператор вправе продолжить обработку персональных данных без согласия субъекта персональных данных при наличии оснований, предусмотренных законодательством Российской Федерации;
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            самостоятельно определять состав и перечень мер, необходимых и достаточных для обеспечения выполнения обязанностей, предусмотренных Законом о персональных данных и принятыми в соответствии с ним нормативными правовыми актами;
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            осуществлять иные права, предусмотренные законодательством Российской Федерации.
          </ListItemText>
        </ListItem>
      </List>

      <Typography paragraph>
        3.2. Оператор обязан:
      </Typography>

      <List>
        <ListItem>
          <ListItemText>
            предоставлять субъекту персональных данных по его просьбе информацию, касающуюся обработки его персональных данных;
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            организовывать обработку персональных данных в порядке, установленном действующим законодательством РФ;
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            отвечать на телефонния и запросы субъектов персональных данных и их законных представителей в соответствии с требованиями Закона о персональных данных;
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            сообщать в уполномоченный орган по защите прав субъектов персональных данных по запросу этого органа необходимую информацию в течение 10 дней с даты получения такого запроса;
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            публиковать или иным образом обеспечивать неограниченный доступ к настоящей Политике в отношении обработки персональных данных;
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            принимать необходимые правовые, организационные и технические меры для защиты персональных данных;
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            прекратить передачу (предоставление, доступ) персональных данных, прекратить обработку и уничтожить персональные данные в порядке и случаях, предусмотренных Законом о персональных данных;
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            исполнять иные обязанности, предусмотренные Законом о персональных данных.
          </ListItemText>
        </ListItem>
      </List>

      <Typography variant="h6" gutterBottom>
        4. Основные права и обязанности субъектов персональных данных
      </Typography>

      <Typography paragraph>
        4.1. Пользователь, как субъект персональных данных, имеет право:
      </Typography>

      <List>
        <ListItem>
          <ListItemText>
            получать информацию, касающуюся обработки его персональных данных, посредством направления соответствующего запроса;
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            требовать от оператора уточнения его персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки;
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            выдвигать условие предварительного согласия при обработке персональных данных в целях продвижения на рынке товаров, работ и услуг;
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            на отзыв согласия на обработку персональных данных, а также, на направление требования о прекращении обработки персональных данных;
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            обжаловать в уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке неправомерные действия или бездействие Оператора при обработке его персональных данных;
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            на осуществление иных прав, предусмотренных законодательством РФ.
          </ListItemText>
        </ListItem>
      </List>

      <Typography paragraph>
        4.2. Субъекты персональных данных обязаны:
      </Typography>

      <List>
        <ListItem>
          <ListItemText>
            предоставлять Оператору достоверные данные о себе;
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            сообщать Оператору об уточнении (обновлении, изменении) своих персональных данных.
          </ListItemText>
        </ListItem>
      </List>

      <Typography paragraph>
        4.3. Лица, передавшие Оператору недостоверные сведения о себе, либо сведения о другом субъекте персональных данных без согласия последнего, несут ответственность в соответствии с законодательством РФ.
      </Typography>

      <Typography variant="h6" gutterBottom>
        5. Принципы обработки персональных данных
      </Typography>

      <Typography paragraph>
        5.1. Обработка персональных данных осуществляется на законной и справедливой основе.
      </Typography>

      <Typography paragraph>
        5.2. Обработка персональных данных ограничивается достижением конкретных, заранее определенных и законных целей. Не допускается обработка персональных данных несовместимая с целями сбора персональных данных.
      </Typography>

      <Typography paragraph>
        5.3. Не допускается объединение баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместимых между собой.
      </Typography>

      <Typography paragraph>
        5.4. Обработке подлежат только персональные данные, которые отвечают целям их обработки.
      </Typography>

      <Typography paragraph>
        5.5. Обрабатываемые персональные данные не являются избыточными по отношению к заявленным целям их обработки.
      </Typography>

      <Typography paragraph>
        5.6. При обработке персональных данных обеспечивается точность персональных данных, их достаточность, а в необходимых случаях и актуальность по отношению к целям обработки персональных данных.
      </Typography>

      <Typography paragraph>
        5.7. Хранение персональных данных осуществляется в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных. Обрабатываемые персональные данные уничтожаются либо обезличиваются по достижении целей обработки или в случае утраты необходимости в достижении этих целей.
      </Typography>

      <Typography paragraph>
        5.8. Оператор и иные лица соблюдают требования конфиденциальности персональных данных, установленные ст. 7 Закона о персональных данных.
      </Typography>


      <Typography variant="h6" gutterBottom>
        6. Цели обработки персональных данных и категории персональных данных
      </Typography>

      <Typography paragraph>
        6.1. Обработка персональных данных осуществляется в следующих целях:
      </Typography>

      <List>
        <ListItem>
          <ListItemText primary="предоставления Пользователю возможности пользоваться веб-сайтом: создавать учетную запись, размещать объявления, искать и просматривать объявления и т.п." />
        </ListItem>
        <ListItem>
          <ListItemText primary="обеспечивать стабильную работу и безопасность веб-сайта, улучшать пользовательский опыт, качество услуг и маркетинговых мероприятий." />
        </ListItem>
        <ListItem>
          <ListItemText primary="информирования Пользователей о мероприятиях, организуемых на веб-сайте." />
        </ListItem>
        <ListItem>
          <ListItemText primary="выполнение обязательств и предоставление Пользователю согласованных услуг и/или продуктов." />
        </ListItem>
        <ListItem>
          <ListItemText primary="отправка Пользователю сервисных и/или продуктовых коммуникаций, а также маркетинговых материалов для продвижения продуктов веб-сайта, а также сайтов третьих лиц (включая любые страницы социальных сетей, поддерживаемые или управляемые Администрацией, такие как Facebook, Instagram, Snapchat, Twitter, YouTube)." />
        </ListItem>
        <ListItem>
          <ListItemText primary="уведомление Пользователя о внесенных изменениях в услуги или продукты веб-сайта." />
        </ListItem>
        <ListItem>
          <ListItemText primary="обеспечение контроля качества и обучения персонала." />
        </ListItem>
        <ListItem>
          <ListItemText primary="улучшение продуктов и услуг, включая настройку веб-сайта." />
        </ListItem>
        <ListItem>
          <ListItemText primary="проверка личности или местоположения Пользователя и проведение проверок безопасности в связи с покупкой." />
        </ListItem>
        <ListItem>
          <ListItemText primary="проведение маркетинговых исследований и анализа." />
        </ListItem>
      </List>

      <Typography paragraph>
        6.2. Для выполнения целей, указанных в п. 6.2. собираются следующие персональные данные Пользователя:
      </Typography>

      <List>
        <ListItem>
          <ListItemText primary="фамилия и имя;" />
        </ListItem>
        <ListItem>
          <ListItemText primary="номер телефона;" />
        </ListItem>
        <ListItem>
          <ListItemText primary="адрес электронной почты;" />
        </ListItem>
        <ListItem>
          <ListItemText primary="год, месяц и дата рождения;" />
        </ListItem>
        <ListItem>
          <ListItemText primary="информация о браузере (или иной программе, которая осуществляет доступ к показу рекламы);" />
        </ListItem>
        <ListItem>
          <ListItemText primary="IP-адреса;" />
        </ListItem>
        <ListItem>
          <ListItemText primary="файлы cookie." />
        </ListItem>
      </List>

      <Typography variant="h6" gutterBottom>
        7. Условия обработки персональных данных
      </Typography>

      <Typography paragraph>
        7.1. Оператор осуществляет следующие действия с персональными данными: сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (предоставление, доступ), обезличивание, блокирование, удаление и уничтожение персональных данных.
      </Typography>

      <Typography paragraph>
        7.2. Обработка персональных данных осуществляется с согласия Пользователя.
      </Typography>

      <Typography paragraph>
        7.3. Обработка персональных данных необходима для достижения целей, предусмотренных настоящей Политикой, любым законным способом, в том числе в информационных системах с использованием средств автоматизации или без использования таких средств.
      </Typography>

      <Typography paragraph>
        7.4. Обработка персональных данных необходима для исполнения договора, стороной которого либо выгодоприобретателем или поручителем по которому является субъект персональных данных, а также для заключения договора по инициативе субъекта персональных данных или договора, по которому субъект персональных данных будет являться выгодоприобретателем или поручителем.
      </Typography>

      <Typography paragraph>
        7.5. Обработка персональных данных необходима для осуществления прав и законных интересов оператора или третьих лиц либо для достижения общественно значимых целей при условии, что при этом не нарушаются права и свободы субъекта персональных данных.
      </Typography>

      <Typography paragraph>
        7.6. Осуществляется обработка персональных данных, доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных либо по его просьбе (далее — общедоступные персональные данные).
      </Typography>

      <Typography paragraph>
        7.7. Осуществляется обработка персональных данных, подлежащих опубликованию или обязательному раскрытию в соответствии с федеральным законом.
      </Typography>

      <Typography paragraph>
        7.8. Пользователь при заполнении регистрационной формы подтверждает, что:
      </Typography>

      <List>
        <ListItem>
          <ListItemText primary="7.8.1. указывает достоверную информацию о себе, вся иная информация предоставляется Пользователем по его собственному усмотрению;" />
        </ListItem>
        <ListItem>
          <ListItemText primary="7.8.2. ознакомлен с настоящей Политикой, выражает свое согласие с ней. Ознакомление с условиями настоящей Политики является письменным согласием Пользователя на сбор, хранение, обработку персональных данных, предоставляемых Пользователем." />
        </ListItem>
      </List>

      <Typography paragraph>
        7.9. Веб-сайт не проверяет достоверность получаемой (собираемой) информации о Пользователях, за исключением случаев, когда такая проверка необходима в целях исполнения обязательств перед Пользователем.
      </Typography>

      <Typography variant="h6" gutterBottom>
        8. Порядок сбора, хранения, передачи и других видов обработки персональных данных
      </Typography>

      <Typography paragraph>
        Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты персональных данных.
      </Typography>

      <Typography paragraph>
        8.1. Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.
      </Typography>

      <Typography paragraph>
        8.2. Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с исполнением действующего законодательства либо в случае, если субъектом персональных данных дано согласие Оператору на передачу данных третьему лицу для исполнения обязательств по гражданско-правовому договору.
      </Typography>

      <Typography paragraph>
        8.3. В случае выявления неточностей в персональных данных, Пользователь может актуализировать их самостоятельно, путем направления Оператору уведомления на адрес электронной почты Оператора: admin@mentup-store.com, с пометкой «Актуализация персональных данных».
      </Typography>

      <Typography paragraph>
        8.4. Срок обработки персональных данных определяется достижением целей, для которых были собраны персональные данные. Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес Оператора: admin@mentup-store.com, с пометкой «Отзыв согласия на обработку персональных данных».
      </Typography>

      <Typography paragraph>
        8.5. Вся информация, которая собирается сторонними сервисами, в том числе платежными системами, средствами связи и другими поставщиками услуг, хранится и обрабатывается указанными лицами (Операторами) в соответствии с их Пользовательским соглашением и Политикой конфиденциальности. Оператор не несет ответственность за действия третьих лиц, в том числе указанных в настоящем пункте поставщиков услуг.
      </Typography>

      <Typography paragraph>
        8.6. Установленные субъектом персональных данных запреты на передачу (кроме предоставления доступа), а также на обработку или условия обработки (кроме получения доступа) персональных данных, разрешенных для распространения, не действуют в случаях обработки персональных данных в государственных, общественных и иных публичных интересах, определенных законодательством РФ.
      </Typography>

      <Typography paragraph>
        8.7. Оператор осуществляет хранение персональных данных в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных.
      </Typography>

      <Typography paragraph>
        8.8. Условием прекращения обработки персональных данных может являться достижение целей обработки персональных данных, истечение срока действия согласия субъекта персональных данных, отзыв согласия субъектом персональных данных или требование о прекращении обработки персональных данных, а также выявление неправомерной обработки персональных данных.
      </Typography>

      <Typography variant="h6" gutterBottom>
        9. Трансграничная передача персональных данных
      </Typography>

      <Typography paragraph>
        9.1. Оператор до начала осуществления деятельности по трансграничной передаче персональных данных обязан уведомить уполномоченный орган по защите прав субъектов персональных данных о своем намерении осуществлять трансграничную передачу персональных данных.
      </Typography>

      <Typography paragraph>
        9.2. Оператор до подачи вышеуказанного уведомления, обязан получить от органов власти иностранного государства, иностранных физических лиц, иностранных юридических лиц, которым планируется трансграничная передача персональных данных, соответствующие сведения.
      </Typography>

      <Typography variant="h6" gutterBottom>
        10. Заключительные положения
      </Typography>

      <Typography paragraph>
        10.1. Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору с помощью электронной почты{' '}
        <Typography
          component={Link}
          to="mailto:admin@mentup-store.com"
          sx={{ 
            color: theme.palette.mode === 'dark' ? 'darkgray' : 'inherit', 
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline'
            }
         }}
        >
          admin@mentup-store.com
        </Typography>
        .
      </Typography>

      <Typography paragraph>
        10.2. В настоящей Политике будут отражены любые изменения в обработке персональных данных Оператором. Политика действует бессрочно до замены ее новой версией.
      </Typography>

      <Typography paragraph>
        10.3. Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу{' '}
        <Typography
          component={Link}
          to="https://mentup-store.com/privacy"
          sx={{ 
            color: theme.palette.mode === 'dark' ? 'darkgray' : 'inherit', 
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline'
            }
         }}
        >
          https://mentup-store.com/privacy
        </Typography>
        .
      </Typography>
      
    </Box>
  );
};

export default Privacy;
