import axios from "axios";
import Cookies from "js-cookie";

const API = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL || "http://localhost:3030/api/",
});

const header = () => {
  const token = Cookies.get("refreshToken");
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
};

export const disableProduct = (productId) => {
  return API.patch(`/item/disable/${productId}`, {}, header());
};

export const enableProduct = (productId) => {
  return API.patch(`/item/enable/${productId}`, {}, header());
};
