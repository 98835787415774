import * as React from "react"
const ComputerGames = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#F4B826"
      d="M8.268 3.97c.03-.52-.034-.752.292-1.213a1.67 1.67 0 0 1 1.063-.693c.24-.04.49-.019.73-.02.154-.002.317-.018.469 0 .05.007.095.019.135.05.065.05.105.13.112.21a.161.161 0 0 1-.04.128c-.1.117-.345.09-.486.099-.521-.011-1.034-.096-1.443.297a1.148 1.148 0 0 0-.336.63 1.152 1.152 0 0 0-.018.167c-.03.263-.011.545-.01.811.007.465.007.93 0 1.396l.992-.003c.135 0 .284-.016.418.004a.281.281 0 0 1 .157.067.234.234 0 0 1 .072.178.232.232 0 0 1-.08.172.237.237 0 0 1-.145.052c-.238.014-.481.004-.72.003H8.083l-.833-.001c-.163 0-.337.013-.498-.01a.218.218 0 0 1-.17-.162.225.225 0 0 1 0-.12.238.238 0 0 1 .115-.148.338.338 0 0 1 .138-.036c.163-.01.333 0 .497.001h.93c.007-.62.02-1.241.006-1.86ZM6.182 6.586c1.458-.007 2.917-.006 4.376.003.756.003 1.515-.015 2.271.004.994.025 1.985.484 2.665 1.206.653.692 1.036 1.655 1.004 2.607a3.93 3.93 0 0 1-.075.63 4.362 4.362 0 0 1-.09.352 3.62 3.62 0 0 1-.423.901 3.968 3.968 0 0 1-.328.435 3.85 3.85 0 0 1-.529.495 3.805 3.805 0 0 1-.454.297 3.395 3.395 0 0 1-.494.226 4.111 4.111 0 0 1-.346.108 3.416 3.416 0 0 1-.535.095 3.393 3.393 0 0 1-.543.015c-.928-.04-1.757-.497-2.379-1.17a4.311 4.311 0 0 1-.38-.532c-.92.032-1.844.012-2.764.013a3.257 3.257 0 0 1-.528.65 3.712 3.712 0 0 1-.553.443 3.691 3.691 0 0 1-.712.357 3.132 3.132 0 0 1-.338.107 3.628 3.628 0 0 1-.877.114c-.955.015-1.872-.387-2.551-1.048-.725-.706-1.09-1.64-1.099-2.645a3.695 3.695 0 0 1 1.04-2.55c.564-.57 1.363-.995 2.164-1.087.352-.04.716-.023 1.07-.023l1.408-.003Z"
    />
    <path
      fill="#181445"
      d="M7.798 10.481a.35.35 0 0 1 .124.02.29.29 0 0 1 .167.152.244.244 0 0 1 .001.19c-.035.09-.107.13-.191.167a.383.383 0 0 1-.139-.015.266.266 0 0 1-.16-.148.255.255 0 0 1 .003-.195c.038-.092.107-.134.196-.171ZM9.213 10.473a.35.35 0 0 1 .123.019.29.29 0 0 1 .167.153.244.244 0 0 1 .001.19c-.035.09-.107.13-.191.167a.383.383 0 0 1-.139-.015.266.266 0 0 1-.16-.148.255.255 0 0 1 .003-.196c.038-.092.107-.134.196-.17ZM12.15 9.608c.014.048.025.1.03.15a.831.831 0 0 1-.16.601.738.738 0 0 1-.62.283.699.699 0 0 1-.26-.057.727.727 0 0 1-.255-.176.794.794 0 0 1-.21-.394.788.788 0 0 1-.006-.318.72.72 0 0 1 .06-.186.898.898 0 0 1 .08-.135.78.78 0 0 1 .36-.265.73.73 0 0 1 .22-.042.765.765 0 0 1 .561.222l-.018-.002c-.024-.003-.043-.012-.066-.022-.191-.078-.44-.099-.633-.019a.847.847 0 0 0-.445.438.633.633 0 0 0 .081.618.765.765 0 0 0 .153.155.691.691 0 0 0 .497.133.772.772 0 0 0 .199-.053.732.732 0 0 0 .148-.088.952.952 0 0 0 .076-.07.708.708 0 0 0 .157-.267c.053-.168.006-.373.05-.506Z"
    />
    <path
      fill="url(#a)"
      d="M12.15 9.608c-.045.133.001.338-.051.506a.549.549 0 0 1-.039.096.686.686 0 0 1-.678.385.646.646 0 0 1-.202-.047.659.659 0 0 1-.187-.111.625.625 0 0 1-.124-.132.696.696 0 0 1-.072-.127.633.633 0 0 1-.01-.492.847.847 0 0 1 .445-.437c.193-.08.443-.06.634.018.022.01.042.019.066.022l.018.002a.686.686 0 0 1 .2.317Z"
    />
    <path
      fill="#181445"
      d="M13.764 9.365a.747.747 0 0 1 .939-.144.822.822 0 0 1 .373.472.813.813 0 0 1-.22.754.726.726 0 0 1-.285.17.733.733 0 0 1-.072.02.777.777 0 0 1-.603-.108.784.784 0 0 1-.318-.512.796.796 0 0 1 .115-.57c.02-.029.043-.063.07-.082Z"
    />
    <path
      fill="#291B52"
      d="M14.438 9.251a.592.592 0 0 1 .285.083.602.602 0 0 1 .29.411.4.4 0 0 1-.073-.082c-.122-.197-.36-.279-.385-.316-.009-.013-.01-.03-.014-.046a.169.169 0 0 0-.07-.028l-.025-.003-.008-.019Z"
    />
    <path
      fill="url(#b)"
      d="M14.108 10.578a.664.664 0 0 1-.433-.364.613.613 0 0 1-.053-.286.728.728 0 0 1 .271-.51.72.72 0 0 1 .547-.167l.008.018.024.003a.17.17 0 0 1 .071.029c.005.015.006.033.015.046.025.037.262.119.384.316.02.032.046.056.074.082.022.207 0 .406-.132.577a.825.825 0 0 1-.161.154.76.76 0 0 1-.615.102Z"
    />
    <path
      fill="#181445"
      d="M12.71 7.636c.193-.015.366.003.535.106a.795.795 0 0 1 .288.313.755.755 0 0 1 .078.26.844.844 0 0 1 0 .195.996.996 0 0 1-.024.115.796.796 0 0 1-.096.211.794.794 0 0 1-.506.34.844.844 0 0 1-.592-.144.804.804 0 0 1-.302-.45.77.77 0 0 1 .084-.593c.125-.202.315-.298.536-.353Z"
    />
    <path
      fill="url(#c)"
      d="M12.778 7.755c.165-.01.337.03.476.12a.66.66 0 0 1 .295.43.724.724 0 0 1-.144.55.748.748 0 0 1-.52.276.74.74 0 0 1-.46-.121.738.738 0 0 1-.305-.495.623.623 0 0 1 .101-.483c.136-.178.344-.251.557-.277Z"
    />
    <path
      fill="url(#d)"
      d="M12.518 10.69a.773.773 0 0 1 .61-.013.828.828 0 0 1 .487.673.793.793 0 0 1-.025.262.787.787 0 0 1-.492.527.825.825 0 0 1-.625-.038.748.748 0 0 1-.372-.453.758.758 0 0 1-.033-.19c-.026-.098.006-.241.042-.332a.776.776 0 0 1 .408-.437Z"
    />
    <path
      fill="#181445"
      d="M12.517 10.69a.773.773 0 0 1 .61-.013.827.827 0 0 1 .487.673.793.793 0 0 1-.025.262.787.787 0 0 1-.493.527.825.825 0 0 1-.624-.038.749.749 0 0 1-.372-.453.758.758 0 0 1-.034-.19c.063.094.07.267.156.336a.65.65 0 0 0 .478.318c.161.038.32 0 .469-.063a.796.796 0 0 0 .269-.243.654.654 0 0 0 .11-.487.752.752 0 0 0-.31-.43c-.2-.16-.37-.145-.61-.146v-.01a.673.673 0 0 1 .237-.054l.027-.001-.006-.014c-.033-.015-.05-.026-.088-.022l.007.01.025.01c-.026.014-.015.012-.042.012-.087 0-.19.039-.271.015Z"
    />
    <path
      fill="url(#e)"
      d="M3.423 8.527c.417.003.835.003 1.253.001L4.674 9.55c.357.002.715-.005 1.073-.007l.001 1.343c-.357-.006-.715-.001-1.073-.001l.004.948c-.092.04-1.066.017-1.247.017l.004-.994-1.004.01-.006-1.276c.332.005.664.007.996.006.013-.356.004-.713 0-1.07Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={11.446}
        x2={11.446}
        y1={9.198}
        y2={10.597}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#533C8E" />
        <stop offset={1} stopColor="#3C227D" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={14.322}
        x2={14.322}
        y1={9.248}
        y2={10.602}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#533C8E" />
        <stop offset={1} stopColor="#3C227D" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={12.834}
        x2={12.834}
        y1={7.753}
        y2={9.133}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#533C8E" />
        <stop offset={1} stopColor="#3C227D" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={12.838}
        x2={12.838}
        y1={10.62}
        y2={12.183}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#533C8E" />
        <stop offset={1} stopColor="#3C227D" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={4.087}
        x2={4.087}
        y1={8.527}
        y2={11.858}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#533C8E" />
        <stop offset={1} stopColor="#3C227D" />
      </linearGradient>
    </defs>
  </svg>
)
export default ComputerGames
