import Alert from "../../svg/Alert";
import Chat from "../../svg/Chat";
import Logout from "../../svg/Logout";

export const HEADER_MENU = [
  {
    label: "alerts",
    path: "/alerts",
    icon: <Alert />,
  },
  {
    label: "logout",
    path: "/logout",
    icon: <Logout />,
  },
];

export const DRAWER_MENU = [
  {
    label: "chat.title",
    path: "/chat",
    icon: <Chat />,
  },
];

export const PUBLIC_MENU = [];
