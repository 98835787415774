import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";

export const advantages = [
  {
    icon: <PhoneInTalkIcon />,
    text: "Поддержка 24/7",
  },
  {
    icon: <GppGoodOutlinedIcon />,
    text: "Гарантия безопасной сделки",
  },
  {
    icon: <WorkspacePremiumOutlinedIcon />,
    text: "Огромный ассортимент",
  },
  {
    icon: <StarBorderOutlinedIcon />,
    text: "Уникальные предложения",
  },
  {
    icon: <PaymentsOutlinedIcon />,
    text: "Надежное место для продаж и покупок",
  },
];

export const advantagesEn = [
  {
    icon: <PhoneInTalkIcon />,
    text: "Support 24/7",
  },
  {
    icon: <GppGoodOutlinedIcon />,
    text: "Safe Transaction Guarantee",
  },
  {
    icon: <WorkspacePremiumOutlinedIcon />,
    text: "Huge assortment.",
  },
  {
    icon: <StarBorderOutlinedIcon />,
    text: "Unique offers",
  },
  {
    icon: <PaymentsOutlinedIcon />,
    text: "A trusted place to shop and sell.",
  },
];
