import "swiper/css";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  CircularProgress,
  Container,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useTranslate } from "../../../context/translate/translate.context";
import getBestSellersProducts from "../../../services/bestSellers";
import ProductCard from "../../ProductCard";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";

const Bestsellers = () => {
  const { translate } = useTranslate();

  const userRedux = useSelector((state) => state.user);

  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);

  const [totalProducts, setTotalProducts] = useState(0);

  const [limit, setLimit] = useState(8);
  const [showMoreLoading, setShowMoreLoading] = useState(false);

  const navigate = useNavigate()

  const getFeatured = useCallback(async () => {
    try {
      setShowMoreLoading(true);
      const response = await getBestSellersProducts(
        limit,
        products.length,
        userRedux.country
      );

      const items = response.items;
      const total = response.total;

      setProducts((prev) => [...prev, ...items]);
      setTotalProducts(total);
    } catch (error) {
      navigate('/404')
    } finally {
      setLoading(false);
      setShowMoreLoading(false);
    }
  }, [
    limit,
    products.length,
    userRedux.country,
    setProducts,
    setTotalProducts,
    setLoading,
    setShowMoreLoading,
  ]);

  useEffect(() => {
    getFeatured();
  }, []);

  if (!loading && products.length === 0) {
    return null;
  }

  const handleShowMore = async () => {
    setShowMoreLoading(true);
    getFeatured();
  };

  const isMobile = useMediaQuery("@media (max-width: 900px)");

  return (
    <Container className={styles.bestsellersProductsContainer} disableGutters>
      <Typography
        className={styles.bestsellersProductsContainer__heading}
      >
        {translate("home.bestsellers")}
      </Typography>
      {loading ? (
        <Box
          sx={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100vw",
            height: "100vh",
            background: "rgba(0,0,0,1)",
            zIndex: "100",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              marginTop: "20px",
              marginBottom: "36px",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: "20px",
              "@media (max-width: 900px)": {
                marginBottom: "60px",
              },
            }}
          >
            {/* <ProductSwiper products={products} /> */}

            {products.map((product, index) => (
              <ProductCard
                key={index}
                product={product}
                isMobile={isMobile}
              />
            ))}
          </Box>

          {/* Show more button */}
          {products.length < totalProducts && (
            <Box
              onClick={() => handleShowMore()}
              sx={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "15px",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                }}
              >
                Показать Больше
              </Typography>
              <Box>
                {showMoreLoading ? (
                  <CircularProgress
                    size={24}
                    style={{
                      marginTop: "10px",
                      position: "relative",
                      zIndex: 10,
                    }}
                  />
                ) : (
                  <IconButton aria-label="showMore" size="large">
                    <ExpandMoreIcon fontSize="24px" />
                  </IconButton>
                )}
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Container>
  );
};

export default Bestsellers;
