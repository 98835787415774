import Cookies from "js-cookie";
import { useEffect, useState } from "react";

import {
  createGroupChat,
  getChats,
  markChatAsRead,
} from "../api/chat/ChatRequests";
import { useSocketProvider } from "../context/socket";

export const useChats = () => {
  const [items, setItems] = useState([]);

  const socket = useSocketProvider();

  const sortItems = (i) =>
    i.sort((a, b) =>
      a.isSupportChat
        ? -1
        : new Date(b.lastMessageAt).getTime() -
          new Date(a.lastMessageAt).getTime()
    );

  const create = async (users) => {
    const response = await createGroupChat({ users });
    setItems((prev) => sortItems([...prev, response.data]));

    return response.data;
  };

  const getItems = async () => {
    if (!Cookies.get("refreshToken")) {
      return;
    }

    const response = await getChats();
    setItems(sortItems(response.data || []));
  };

  const markAsRead = async (chatId) => {
    const chat = items.find((i) => i._id === chatId);
    if (chat) {
      setItems((prev) =>
        sortItems(
          prev.map((i) => ({
            ...i,
            unread: i._id === chatId ? 0 : i.unread?.[0]?.count || 0,
          }))
        )
      );
    }
    socket.unreadChats.decrease();

    await markChatAsRead(chatId);
  };

  const initListeners = () => {
    socket.instance.current.on("chats/update", (chat) => {
      setItems((prev) =>
        sortItems(prev.map((i) => (i._id === chat._id ? chat : i)))
      );
    });
    socket.instance.current.on("chats/new", (chat) =>
      setItems((prev) => sortItems([chat, ...prev]))
    );
  };

  useEffect(() => {
    if (socket.inited) {
      initListeners();
    }
  }, [socket.inited]);

  return {
    items,
    create,
    getItems,
    markAsRead,
  };
};
