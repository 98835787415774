import axios from "axios";
import Cookies from "js-cookie";

const API = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL || "http://localhost:3030/api/",
});

const header = () => {
    const token = Cookies.get('refreshToken');
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };
}

export const getProductById = (id) => {
  const token = Cookies.get("refreshToken");
  if (token && id) {
    return API.get(`/item/${id}`, header());
  } else if (!token) {
    throw new Error("No token found");
  }
};

export const getProductByIdToEdit = (id) => {
  const token = Cookies.get("refreshToken");
  if (token && id) {
    return API.get(`/item/edit/${id}`, header());
  } else if (!token) {
    throw new Error("No token found");
  }
};
