import React, { useEffect } from "react";
import { useState } from "react";

// @mui
import { Snackbar, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";

function AlertComponent({
  message,
  severity: severityProp,
  resetMessageInParent,
}) {
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  const severity = (() => {
    if (severityProp) {
      return severityProp;
    }

    switch (message) {
      case "Error occured":
      case "Remove discount or Increase Price":
      case "Please fill all the product codes":
      case "Unexpected Error Occured":
        return "error";

      case "Product deleted successfully":
      case "Продукт удален":
      case "Message was sent successfully":
      case "Product disabled":
      case "Продукт отключен":
      case "Product enabled":
      case "Code edited successfully":
      case "Код успешно изменен":
      case "Product delivered successfully!":
      case "Товар успешно доставлен!":
      case "Order dispute started!":
      case "Спор по заказу начат!":
      case "Product confirmed!":
      case "Продукт подтвержден!":
        return "success";

      default:
        return "error";
    }
  })();

  useEffect(() => {
    if (message) {
      setSuccessMessage(message);
      handleSuccessOpen();
    }
  }, [message]);

  const handleSuccessOpen = () => {
    setSuccess(true);
  };

  const handleSuccessClose = () => {
    setSuccess(false);
    setTimeout(() => {
      setSuccessMessage(null);
      setSuccessMessage(null);
      if (resetMessageInParent) {
        resetMessageInParent();
      }
    }, 1000);
  };

  const navigate = useNavigate()

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={success}
      autoHideDuration={6000}
      onClose={handleSuccessClose}
    >
      {severity !== 'custom' ? (
        <Alert
          variant="filled"
          onClose={handleSuccessClose}
          severity={severity}
          sx={{ 
            width: "100%",
          }}

        >
          {successMessage && successMessage}
        </Alert>
      ) : (
        <Alert
          variant="filled"
          onClose={handleSuccessClose}
          severity={severity}
          sx={{ 
            width: "100%",
            backgroundColor: "primary.main",
            '&:hover':{
              cursor: 'pointer'
            }
          }}
          onClick={() => navigate('/checkout')}
        >
          {successMessage && successMessage}
        </Alert>
      )}
    </Snackbar>
  );
}

export default AlertComponent;
