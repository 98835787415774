import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import clsx from "clsx";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import { useTranslate } from "../../../../../../../../context/translate/translate.context";
import { isRuCountry } from "../../../../../../../../lib/country";
import CheckMarkNewClosed from "../../../../../../../svg/Checkmark/CheckMarkNewClosed";
import CheckMarkNewOpen from "../../../../../../../svg/Checkmark/CheckMarkNewOpen";
import { TranslateText } from "../../../../../../../translate-text";
import styles from "./styles.module.css";

const PromoteProductStep4 = ({
  loading,
  selectedPrice,
  selectedPayment,
  onPaymentSelect,
  next,
}) => {
  const { translate } = useTranslate();

  const userRedux = useSelector((state) => state.user);
  const userSession = JSON.parse(localStorage.getItem("user"));
  const country = useMemo(
    () => userSession?.country || userRedux?.country,
    [userSession, userRedux?.country]
  );
  const paymentMethods = [
    // { _id: "yookassa", name: "yookassa" },
    // { _id: "paybox", name: "paybox" },
    // { _id: "transfer", name: "transfer" },
    // { _id: "lava", name: "lava" },
    // { _id: "stripe", name: "stripe" },
    { _id: "tinkoff", name: "tinkoff" },
    // { _id: "yooMoney", name: "yooMoney" },
  ];
  if (
    (userSession?.wallet?.balance ?? 0) >
    (country && isRuCountry(country)
      ? selectedPrice?.price
      : selectedPrice?.usd)
  ) {
    paymentMethods.push({ _id: "balance", name: "balance" });
  }

  return (
    <>
      <Box className={styles.selectPaymentContainer}>
        {paymentMethods.map((payment) => (
          <FormControlLabel
            key={payment._id}
            sx={{ color: "#f6f6f6" }}
            control={
              <Checkbox
                key={payment._id}
                checked={selectedPayment?._id === payment._id}
                onChange={() => onPaymentSelect(payment)}
                icon={<CheckMarkNewClosed />}
                checkedIcon={<CheckMarkNewOpen />}
              />
            }
            label={translate(
              `promoteProductModal.modal2.paymentMethodInput.${payment.name}`
            )}
          />
        ))}
      </Box>

      <LoadingButton
        loading={loading}
        className={clsx(styles.button, styles.cancelButton)}
        endIcon={<ArrowRightIcon />}
        onClick={next}
      >
        <TranslateText value="products.product.promote.finish" />
      </LoadingButton>
    </>
  );
};

export default PromoteProductStep4;
