import { Box, Tooltip, Typography } from "@mui/material";
import clsx from "clsx";

import { useTranslate } from "../../context/translate/translate.context";
import styles from "./styles.module.css";

const ReviewsStars = ({
  className,
  reviews,
  rating,
  count: countProps,
  disabled,
  size,
  starsOnly,
}) => {
  const { translate } = useTranslate();

  const averageRating = reviews
    ? (
        reviews.reduce((acc, review) => acc + review.rating, 0) /
          reviews.length || 0
      ).toFixed(1)
    : rating || 0;

  const count = reviews ? reviews.length : countProps;

  return (
    <Box
      className={clsx(
        styles.reviews,
        className,
        disabled && styles.opacity50,
        size && styles[`reviews_${size}`]
      )}
    >
      <Tooltip
        title={translate("userProfile.rating.average", {
          rating: averageRating,
        })}
        placement="left"
        arrow
      >
        <Box
          style={{ "--percent": `${(averageRating / 5) * 100}%` }}
          className={styles.reviews__rating}
          min={0}
          max={5}
          value={averageRating}
          component="meter"
        />
      </Tooltip>
      {(count || count == 0) && !starsOnly && (
        <Typography className={styles.reviews__text}>
          {count}{" "}
          {translate(
            `userProfile.rating.count.${count === 1 ? "one" : "other"}`
          )}
        </Typography>
      )}
    </Box>
  );
};

export default ReviewsStars;
