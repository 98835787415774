const SearchIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="#F7F7F7"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.96387 2.21875C4.94356 2.21875 2.49512 4.66719 2.49512 7.6875C2.49512 10.7078 4.94356 13.1562 7.96387 13.1562C10.9842 13.1562 13.4326 10.7078 13.4326 7.6875C13.4326 4.66719 10.9842 2.21875 7.96387 2.21875ZM1.55762 7.6875C1.55762 4.14943 4.42579 1.28125 7.96387 1.28125C11.5019 1.28125 14.3701 4.14943 14.3701 7.6875C14.3701 9.28782 13.7833 10.7511 12.8132 11.8739L14.8578 13.9185C15.0409 14.1016 15.0409 14.3984 14.8578 14.5815C14.6748 14.7645 14.378 14.7645 14.1949 14.5815L12.1502 12.5368C11.0275 13.507 9.56419 14.0937 7.96387 14.0937C4.42579 14.0937 1.55762 11.2256 1.55762 7.6875Z"
    />
  </svg>
);

export default SearchIcon;
