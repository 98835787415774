// Lightbox.js
import React from "react";
import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./styles.module.css"; // Add your styles here

const Lightbox = ({ src, onClose }) => {
  if (!src) return null;

  return (
    <Box className={styles.lightbox} onClick={onClose}>
      <Box className={styles.lightboxContent} onClick={(e) => e.stopPropagation()}>
        <img src={src} alt="Lightbox" className={styles.lightboxImage} />
        <IconButton className={styles.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Lightbox;