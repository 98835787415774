import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { getTelegramNotificationsSteps } from "../../../api/settings/notifications";
import { useTranslate } from "../../../context/translate/translate.context";
import MentupModal from "../../MentupModal/MentupModal";
import MentupStepper from "../../MentupStepper";
import TelegramContactBot from "../TelegramContactBot";
import TelegramLinkAccount from "../TelegramLinkAccount";
import styles from "./styles.module.css";
import TelegramFinishNotifications from "../TelegramFinishNotifications";
import { useNavigate } from "react-router-dom";

const TelegramNotifications = ({ open, close }) => {
  const { translate } = useTranslate();

  const [loadingInit, setLoadingInit] = useState(true);

  const [steps, setSteps] = useState([]);
  const [activeStep, setActiveStep] = useState(null);
  const navigate = useNavigate()

  const getStepsNotifications = async () => {
    try {
      setLoadingInit(true);
      const response = await getTelegramNotificationsSteps();
      setSteps(response.data.steps);
      setActiveStep(response.data.steps[0]);
    } catch (error) {
      navigate('/404')
    } finally {
      setLoadingInit(false);
    }
  };

  useEffect(() => {
    if (open) {
      getStepsNotifications();
    } else {
      setActiveStep(null);
    }
  }, [open]);

  return (
    <MentupModal open={open} close={close}>
      {loadingInit ? (
        <Box className={styles.container}>
          <CircularProgress />
        </Box>
      ) : (
        <Box className={styles.container}>
          <Typography className={styles.title}>
            {translate(
              "vendorDashboard.settings.notifications.telegram.steps.title"
            )}
          </Typography>

          {steps.length > 1 && (
            <MentupStepper
              steps={steps.length}
              getStepTitle={(index) =>
                `${translate(
                  "vendorDashboard.settings.notifications.telegram.steps.stepper"
                )} ${index + 1}`
              }
              current={steps.indexOf(activeStep)}
            />
          )}

          {activeStep === "auth" && (
            <TelegramLinkAccount
              next={() => setActiveStep("contactBot")}
              close={close}
            />
          )}
          {activeStep === "contactBot" && (
            <TelegramContactBot
              next={() => setActiveStep("finish")}
              close={close}
            />
          )}
          {activeStep === "finish" && (
            <TelegramFinishNotifications next={close} />
          )}
        </Box>
      )}
    </MentupModal>
  );
};

export default TelegramNotifications;
