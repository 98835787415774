import { Box, Typography } from "@mui/material";

import { useTranslate } from "../../context/translate/translate.context";
import FOOTER_LINKS from "../Layout/Footer/constants";
import { PRODUCT_INFORMATION } from "../user/products/ProductPage/constants";
import styles from "./styles.module.css";

const PaymentInformation = () => {
  const { translate } = useTranslate();

  return (
    <Box className={styles.container}>
      {PRODUCT_INFORMATION.map(({ icon, label }, index) => (
        <Box key={index} className={styles.container__information}>
          {icon}
          <Typography className={styles.container__text}>
            {translate(label)}
          </Typography>
        </Box>
      ))}
      <Box className={styles.container__information}>
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="#EDF67D"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.9987 2.2085C4.59294 2.2085 1.83203 4.96941 1.83203 8.37516C1.83203 11.7809 4.59294 14.5418 7.9987 14.5418C11.4045 14.5418 14.1654 11.7809 14.1654 8.37516C14.1654 4.96941 11.4045 2.2085 7.9987 2.2085ZM0.832031 8.37516C0.832031 4.41712 4.04066 1.2085 7.9987 1.2085C11.9567 1.2085 15.1654 4.41712 15.1654 8.37516C15.1654 12.3332 11.9567 15.5418 7.9987 15.5418C4.04066 15.5418 0.832031 12.3332 0.832031 8.37516ZM7.4397 4.54181C7.44829 4.54182 7.45685 4.54183 7.46536 4.54183H8.9987C10.1953 4.54183 11.1654 5.51188 11.1654 6.7085C11.1654 7.90511 10.1953 8.87516 8.9987 8.87516H7.16536V9.2085H7.9987C8.27484 9.2085 8.4987 9.43235 8.4987 9.7085C8.4987 9.98464 8.27484 10.2085 7.9987 10.2085H7.16536V11.7085C7.16536 11.9846 6.94151 12.2085 6.66536 12.2085C6.38922 12.2085 6.16536 11.9846 6.16536 11.7085V10.2085H5.9987C5.72256 10.2085 5.4987 9.98464 5.4987 9.7085C5.4987 9.43235 5.72256 9.2085 5.9987 9.2085H6.16536V8.87516H5.9987C5.72256 8.87516 5.4987 8.65131 5.4987 8.37516C5.4987 8.09902 5.72256 7.87516 5.9987 7.87516H6.16536V5.84183C6.16536 5.83331 6.16535 5.82476 6.16534 5.81617C6.16523 5.71915 6.16511 5.61831 6.17973 5.52599C6.25879 5.02678 6.65032 4.63526 7.14952 4.55619C7.24184 4.54157 7.34269 4.54169 7.4397 4.54181ZM7.16536 7.87516H8.9987C9.64303 7.87516 10.1654 7.35283 10.1654 6.7085C10.1654 6.06416 9.64303 5.54183 8.9987 5.54183H7.46536C7.40072 5.54183 7.36224 5.54187 7.33323 5.54267C7.3148 5.54317 7.3068 5.54387 7.30489 5.54405C7.23444 5.55568 7.17921 5.61091 7.16759 5.68136C7.1674 5.68328 7.16671 5.69127 7.1662 5.7097C7.16541 5.73871 7.16536 5.77719 7.16536 5.84183V7.87516Z"
          />
        </svg>
        <Box className={styles.container__payments}>
          {FOOTER_LINKS.payments.map(({ icon }, index) => (
            <Box key={index}>{icon}</Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentInformation;
