import axios from "axios";

const API = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL || "http://localhost:3030/api/",
});

export const getAllCategories = () => {
  return API.get(`/category`);
};

export const getAllCategoriesHome = ({ limit = 10, skip, productType } = {}) =>
  API.get(
    `/category?limit=${limit}${skip ? `&skip=${skip}` : ""}${
      productType ? `&productType=${productType}` : ""
    }`
  );

export const getCategoriesCount = () => API.get(`/category/count`);

export const getAllCategoriesHomePage = () => API.get(`/category/homepage`);

export const getCategoriesBySoft = ({skip, slug, limit}) => API.get(`/category/soft?slug=${slug}&skip=${skip}${limit ? `&limit=${limit}` : ""}`)
