import axios from 'axios';
import Cookies from 'js-cookie';

const API = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL || 'http://localhost:3030/api/',
});

export const updateProduct = (productData, productId) => {
    const token = Cookies.get('refreshToken');
    if (token && productData && productId) {
        return API.patch(`/item/${productId}`, productData, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });
    }
};
