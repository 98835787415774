import { Box, Typography } from "@mui/material";
import Cookies from "js-cookie";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { useTranslate } from "../../../context/translate/translate.context";
import { setUserSettings } from "../../../store/userSettingsStore";
import { setUser } from "../../../store/userStore";
import AlertComponent from "../../AlertComponent";
import MentupStepper from "../../MentupStepper";
import Button from "../../UI/Button";
import Wrapper from "../Wrapper";
import {
  REGISTER_GOOGLE_STEPS,
  REGISTER_STEPS,
  REGISTER_VK_STEPS,
} from "./constants";
import styles from "./styles.module.css";

const Register = () => {
  const { translate } = useTranslate();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { token } = useParams();

  const [vkFinalStep, setVKFinalStep] = useState(false);
  const [googleFinalStep, setGoogleFinalStep] = useState(false);

  const [alertMessage, setAlertMessage] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (token) {
      setActiveStep(2);

      const params = location.search.split("?email=")[1];

      const username = params.split("?user=")[1] || "";
      const email = params.split("?user=")[0] || "";

      Cookies.set("refreshToken", token);

      setData({
        email,
        username,
      });

      return;
    }

    const searchParams = new URLSearchParams(
      window.location.hash.replace("#", "?")
    );

    const accessToken = searchParams.get("access_token");
    const userId = searchParams.get("user_id");

    if (accessToken && userId) {
      setVKFinalStep(true);
    } else {
      setActiveStep(0);
    }
  }, [token, location.search]);

  const logIn = useCallback(({ data: { user, userSettings } }) => {
    setVKFinalStep(false);

    const token = userSettings.refreshToken;

    if (user && userSettings) {
      dispatch(setUser(user));
      dispatch(setUserSettings(userSettings));

      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("userSettings", JSON.stringify(userSettings));

      if (token) {
        setActiveStep(2);
        Cookies.set("refreshToken", token);
        setTimeout(() => {
          ym(97684982,'reachGoal','registraciya')
          navigate("/profile/" + user.userSlug, { replace: true });
        }, 2000);
      }
    }
  }, []);

  const getComponent = () => {
    if (vkFinalStep) {
      return REGISTER_VK_STEPS[activeStep];
    }

    if (googleFinalStep) {
      return REGISTER_GOOGLE_STEPS[activeStep];
    }

    return REGISTER_STEPS[activeStep];
  };

  const component = getComponent();


  const next = (data) => {
    if (data) {
      setData((prev) => ({ ...prev, ...data }));
    }
    setActiveStep((prev) => prev + 1);
  };

  const previous = () => {
    setActiveStep((prev) => prev - 1);
  };

  return (
    <Wrapper>
      <AlertComponent
        message={alertMessage}
        resetMessageInParent={() => setAlertMessage(null)}
      />
      <Box className={styles.buttons}>
        <Button
          className={styles.buttons__faded}
          size="medium"
          color="secondary"
          disabled={loading}
          onClick={() => navigate("/login")}
        >
          {translate("authentication.login")}
        </Button>
        <Button size="medium" color="secondary" disabled={loading}>
          {translate("authentication.register")}
        </Button>
      </Box>
      <Typography className={styles.title} variant="h2">
        {translate("authentication.createAccount")}
      </Typography>
      <Box className={styles.stepperContainer}>
        <MentupStepper
          getStepTitle={(count) => translate("authentication.step", { count })}
          current={activeStep}
          steps={vkFinalStep || googleFinalStep ? 2 : 3}
        />
      </Box>
      {typeof component === 'function' ? component({
        data,
        setData,
        loading,
        setLoading,
        setAlertMessage,
        logIn,
        next,
        previous,
        setGoogleFinalStep,
      }) : null}
    </Wrapper>
  );
};

export default Register;
