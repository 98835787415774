import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";

import { useTranslate } from "../../../context/translate/translate.context";
import FormInput from "../../FormInput";
import Button from "../../UI/Button";
import styles from "./styles.module.css";

import { MentupSwitch } from "../../../lib/theme";

const PromocodeBlock = ({ submit }) => {
  const { translate } = useTranslate();

  const [discountCode, setDiscountCode] = useState("");
  const [isSwitched, setIsSwitched] = useState(false);

  const handleSubmit = async () => {
    if (!discountCode.length) {
      return;
    }

    try {
      await submit(discountCode);
    } catch {

    } finally {
      setDiscountCode("");
      setIsSwitched(false)
    }
  };

  return (
    <Box className={styles.promocodeContainer}>
      <Box sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}>
        <Typography className={styles.promocodeContainer__title}>
          {translate("cartPage.usePromocode")}
        </Typography>
        <MentupSwitch 
          onChange={() => setIsSwitched(!isSwitched)} 
        />
      </Box>
      <div
        className={clsx(
          styles.promocodeContainer__input,
          isSwitched && styles.promocodeContainer__input_active
        )}
      >
        <FormInput
          sx={{
            marginTop: '20px'
          }}
          value={discountCode}
          onChange={(event) => {
            setDiscountCode(event.target.value);
          }}
          label={translate("cartPage.insertPromocode")}
        />
        <Button
          className={clsx(
            styles.promocodeContainer__button,
            discountCode.length && styles.promocodeContainer__button_active
          )}
          type="button"
          fullWidth
          onClick={handleSubmit}
        >
          {translate("cartPage.apply")}
        </Button>
      </div>
    </Box>
  );
};

export default PromocodeBlock;
