import { Box, Button, Typography } from "@mui/material";
import clsx from "clsx";

import { LogoNew } from "../../logo/LogoNew";
import styles from "./styles.module.css";

const Conversation = ({ active, chat, receiver, unreadCount, select }) => {
  const displayTime = (time) => {
    return new Date(time).toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    });
  };

  return (
    <Button
      className={clsx(styles.container, active && styles.active)}
      onClick={select}
    >
      {chat.isSupportChat ? (
        <LogoNew width="32px" height="32px" style={{ minWidth: "32px" }} />
      ) : (
        <img
          src={receiver?.avatar || "defaultProfile.png"}
          alt="Profile"
          className={styles.userAvatar}
        />
      )}

      <Typography noWrap className={styles.title}>
        {chat?.isSupportChat ? "Mentup Support" : receiver?.username}
      </Typography>

      <Box className={styles.details}>
        {!!unreadCount && (
          <Typography className={styles.unread}>{unreadCount}</Typography>
        )}

        <Typography noWrap className={styles.time}>
          {displayTime(chat?.updatedAt)}
        </Typography>
      </Box>
    </Button>
  );
};

export default Conversation;
