import { CircularProgress, Container } from "@mui/material";
import clsx from "clsx";

import styles from "./styles.module.css";

const Loading = ({ className, fullHeight }) => (
  <Container
    className={clsx(
      styles.container,
      fullHeight && styles.container_fullHeight,
      className
    )}
  >
    <CircularProgress />
  </Container>
);

export default Loading;
