import { useEffect } from 'react';
// set dispatch
import { useDispatch } from 'react-redux';
import { setUser } from '../../store/userStore';
import Cookies from 'js-cookie';

// API
import { logout } from '../../api/auth/logout';

const Logout = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        const LogMeOut = async () => {
            const res = await logout()
            if(res) {
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                localStorage.removeItem('userSettings')
                Cookies.remove('refreshToken');
                dispatch(setUser({}));
                window.location.href = '/';
            }
        }
        LogMeOut();
    }, []);
    return <></>;
};

export default Logout;
