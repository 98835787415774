const UncheckedIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" rx="6" fill="#F6F6F6" fillOpacity="0.1" />
    <rect
      x="0.5"
      y="0.5"
      width="19"
      height="19"
      rx="5.5"
      stroke="#F6F6F6"
      strokeOpacity="0.5"
    />
  </svg>
);

export default UncheckedIcon;
