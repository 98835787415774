import React from "react";
import styles from "./styles.module.css";
import { Box, Container, Typography } from "@mui/material";
import { clsx } from "clsx";
import { useTranslate } from '../../../../context/translate/translate.context';


const Warranty = () => {
  const {translate} = useTranslate()

  return (
    <Box className={styles.warranty}>
        <Typography className={styles.warranty__heading}>{translate('sellersPage.warranty.warrantyHeading')}</Typography>
        <Container className={styles.descriptionContainer}>
        <Box className={clsx(styles.description, styles.description__first)}>
            <Typography className={styles.description__title}>
            {translate('sellersPage.warranty.descriptionTitle1')}
            </Typography>
            <Typography className={styles.description__text}>
            {translate('sellersPage.warranty.descriptionText1')}
            </Typography>
        </Box>
        <Box className={clsx(styles.description, styles.description__second)}>
            <Typography className={styles.description__title}>
            {translate('sellersPage.warranty.descriptionTitle2')}
            </Typography>
            <Typography className={styles.description__text}>
            {translate('sellersPage.warranty.descriptionText2')}
            </Typography>
        </Box>
        </Container>
    </Box>
  );
};

export default Warranty;
