import { Box, Container, Typography } from "@mui/material";
import React from "react";

import { useTranslate } from "../../context/translate/translate.context";
import Contact from "./Contact";

function ContactPage() {
  const { translate } = useTranslate();

  return (
    <Container>
      <Contact />
    </Container>
  );
}

export default ContactPage;
