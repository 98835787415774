import { useTheme } from "@emotion/react";
import { Box, Button, MenuItem, Select, Typography } from "@mui/material";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import clsx from "clsx";

import { useTranslate } from "../../../../../../../../context/translate/translate.context";
import styles from "./styles.module.css";

const PromoteProductStep1 = ({
  selectedFeatureType,
  onSelectFeatureType,
  next,
}) => {
  const theme = useTheme();
  const { translate } = useTranslate();

  const inputStyle = {
    WebkitBoxShadow: `0 0 0 1000px ${theme.palette.primary.backgroundPaperDark} inset`,
  };
  const inputStyleDay = {
    WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.paper} inset`,
  };
  const isDarkTheme = theme.palette.mode === "dark";
  const selectedInputStyle = isDarkTheme ? inputStyle : inputStyleDay;

  return (
    <>
      <Box className={styles.selectPriceContainer}>
        <Typography className={styles.sort__text} color="GrayText">
          {translate("products.product.promote.steps.step1.title")}
        </Typography>
        <Select
          required
          value={selectedFeatureType}
          onChange={(e) => onSelectFeatureType(e.target.value)}
          style={selectedInputStyle}
          className={styles.selectPriceInput}
        >
          <MenuItem value="ProductFeatured">
            {translate("products.product.promote.steps.step1.ProductFeatured")}
          </MenuItem>
          <MenuItem value="CatalogFeatured">
            {translate("products.product.promote.steps.step1.CatalogFeatured")}
          </MenuItem>
        </Select>
      </Box>

      <Button
        className={clsx(styles.button, styles.cancelButton)}
        endIcon={<ArrowRightIcon />}
        disabled={!selectedFeatureType}
        onClick={next}
      >
        {translate("products.product.promote.next")}
      </Button>
    </>
  );
};

export default PromoteProductStep1;
