import {
  Box,
  Container,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

import styles from "./styles.module.css";

const Rules = () => (
  <Container className={styles.rulesContainer}>
    <Typography variant="h4">Правила Сайта</Typography>
    <Divider />
    <Typography variant="body2">
      Настоящий документ (далее — Правила Сайта, Правила, Правила Сервиса),
      является частью публичной оферты, размещенной по адресу
      <Link to="/info/laws/license-agreement" className={styles.rulesLink}>
        {" "}
        https://mentup-store.com/info/laws/license-agreement{" "}
      </Link>{" "}
      (далее – Соглашения). Раскрытие все терминов и определений, общих для
      Правил и Соглашения, приведено в Соглашении.
    </Typography>

    <TableContainer component={Paper} sx={{ my: 2 }}>
      <Table sx={{ minWidth: 250 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>
              Правила, соблюдение которых необходимо для работы на площадке
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              Более подробное описание в лицензионном соглашении (если
              встречается)
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              Варианты наказаний, применимые к учётной записи пользователя
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Контент и общение</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              1.1 Запрещается размещать на проекте заведомо ложную информацию о
              товаре или услуге, их наличии, качестве, количестве,
              характеристиках.
            </TableCell>
            <TableCell>п. 5.1. лицензионного соглашения</TableCell>
            <TableCell>
              Предупреждение; Заморозка; Обнуление титула; Блокировка.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              1.2 Запрещается введение в заблуждение и обман пользователей и
              администрации проекта любыми способами
            </TableCell>
            <TableCell>п. 4.6, 5.1. лицензионного соглашения</TableCell>
            <TableCell>
              Предупреждение; Заморозка; Обнуление титула; Блокировка.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              1.3. Запрещено размещение объявлений, отправка личных сообщений,
              отзывов, создание никнеймов, изображений на аватарах
              пользователей, содержащих персональную информацию и создание и
              передача любой другой информации, раскрывающей личные данные
              третьих лиц, если это не относится непосредственно к сделке.
            </TableCell>
            <TableCell>п. 5.2, 5.4. лицензионного соглашения</TableCell>
            <TableCell>
              Предупреждение; Заморозка; Обнуление титула; Блокировка.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              1.4. Запрещается накрутка и получение отзывов любым путем, не
              предусмотренным процессом честной сделки.
            </TableCell>
            <TableCell></TableCell>
            <TableCell>
              Удаление отзыва; Удаление всех отзывов; Заморозка; Обнуление
              титула; Блокировка.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              1.5. Запрещаются ссылки на личные мессенджеры, страницы в
              социальных сетях, форумы с целью проведения сделки и общения за
              пределами сервиса
            </TableCell>
            <TableCell>п. 5.2 лицензионного соглашения</TableCell>
            <TableCell>Заморозка; Обнуление титула; Блокировка</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              1.6. Запрещена продажа товаров, полученных посредством обмана
              других пользователей сервиса или игроков проектов, размещенных на
              MentUp.
            </TableCell>
            <TableCell>п. 4.2 лицензионного соглашения</TableCell>
            <TableCell>Заморозка; Блокировка</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              1.7. Запрещена продажа товаров, нарушающих исключительные права и
              лицензионные соглашения третьих лиц без разрешения правообладателя
            </TableCell>
            <TableCell>п. 5.1. лицензионного соглашения</TableCell>
            <TableCell>
              Заморозка; Блокировка; Удаление материала при обращении
              правообладателя
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              1.8. Запрещается неуважительное общение с Администрацией и
              Пользователями Сервиса, использование обесцененной лексики,
              оскорбления, несвязная речь.
            </TableCell>
            <TableCell></TableCell>
            <TableCell>Предупреждение; Заморозка; Блокировка</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              1.8.1. Запрещается обращение в поддержку Сайта без видимой
              причины, спам, намеренное отвлечение администрации от работы, и
              другие обращения, непосредственно не связанные со спорной
              ситуацией между пользователями или вопросами о Сайте.
            </TableCell>
            <TableCell></TableCell>
            <TableCell>Предупреждение; Заморозка; Блокировка</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

    <TableContainer component={Paper} sx={{ my: 2 }}>
      <Table sx={{ minWidth: 250 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>
              Сделки, объявления
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}></TableCell>
            <TableCell sx={{ fontWeight: "bold" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              2.1. Запрещен текстовый спам, ненормативная лексика в лотах,
              намеренное засорение поисковой выдачи объявлениями, включая, но не
              ограничиваясь: дублирование объявлений, использование спама
              спецсимволами, внесение незначительных изменений в объявления с
              целью полной или частичной монополизации поисковой выдачи.
            </TableCell>
            <TableCell>п. 5.2., 5.8. лицензионного соглашения</TableCell>
            <TableCell>
              Предупреждение; Заморозка; Блокировка; Автоматическая блокировка
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              2.2. Запрещается предоставление финансов и/или товара/услуги
              участнику сделки до момента согласия и подтверждения сделки обеими
              сторонами, на сторонних ресурсах и/или в авансовом порядке. В этом
              случае администрация проекта не сможет вернуть средства и
              аннулировать сделку.
            </TableCell>
            <TableCell>п. 10.8. лицензионного соглашения</TableCell>
            <TableCell>
              Отказ в поддержке и невозможность последующего обращения по сделке
              в поддержку; Заморозка; Блокировка
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              2.3. Запрещен призыв к совершению сделок или общению за пределами
              MentUp в мессенджерах, социальных сетях, форумах, других
              площадках.
            </TableCell>
            <TableCell>п. 10.9. лицензионного соглашения</TableCell>
            <TableCell>
              Отказ в поддержке и невозможность последующего обращения по сделке
              в поддержку; Блокировка; Автоматическая блокировка; Обнуление
              титула
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              2.4. Запрещено размещение не существующих предложений с целью
              демпинга и попытки искусственного изменения цены рынка.
            </TableCell>
            <TableCell></TableCell>
            <TableCell>Заморозка; Обнуление титула; Блокировка</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              2.5. В поле “автовыдача” допускается размещение только той
              информации, получение которой удовлетворит покупателя и будет
              полностью соответствовать описанию лота. Размещение сторонней
              информации в поле автовыдачи запрещено.
            </TableCell>
            <TableCell></TableCell>
            <TableCell>Предупреждение; Заморозка; Блокировка</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              2.6. Запрещается подтверждение сделки до момента получения услуги
              в полном объеме.
            </TableCell>
            <TableCell></TableCell>
            <TableCell>
              Предупреждение; Заморозка; Блокировка; Автоматическая блокировка;
              Отказ в поддержке и невозможность последующего обращения по сделке
              в поддержку
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              2.7. Запрещается требовать подтверждение сделки у второй стороны
              до предоставления услуги в полном объеме
            </TableCell>
            <TableCell></TableCell>
            <TableCell>
              Предупреждение; Заморозка; Блокировка; Автоматическая блокировка;
              Отказ в поддержке и невозможность последующего обращения по сделке
              в поддержку
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

    <TableContainer component={Paper} sx={{ my: 2 }}>
      <Table sx={{ minWidth: 250 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>
              Финансы и ограничения
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}></TableCell>
            <TableCell sx={{ fontWeight: "bold" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              3.1. В случае заморозки аккаунта для проведения внутреннего
              расследования, все денежные операции, ожидающие вывода, отменяются
              и средства возвращаются на внутренний баланс Сервиса в рамках
              аккаунта замороженного пользователя.
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              3.2. Возврат средств осуществляется только при наличии средств на
              кошельке обманувшего, в иных случаях возврат не может быть
              осуществлен.
            </TableCell>
            <TableCell>п. 10.8. лицензионного соглашения</TableCell>
            <TableCell>
              Отказ в поддержке и невозможность последующего обращения по сделке
              в поддержку; Заморозка; Блокировка
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              3.3. Администрация оставляет за собой право обращения в
              правоохранительные органы для преследования нарушителей и
              злоумышленников, включая передачу всей персональной информации
              мошенника.
            </TableCell>
            <TableCell>п. 4.2. лицензионного соглашения</TableCell>
            <TableCell>
              Раскрытие персональных данных; Заморозка; Обнуление титула;
              Блокировка
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              3.4. После выявления несоответствий качества товара при детальной проверке, администрация сайта обнулит сделку и вернет средства на карту или кошелек клиента в полном объеме. 
            </TableCell>
            <TableCell>п. 4.6. лицензионного соглашения</TableCell>
            <TableCell>
              Предупреждение; Заморозка; Блокировка
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

    <TableContainer component={Paper} sx={{ my: 2 }}>
      <Table sx={{ minWidth: 250 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>Блокировки</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}></TableCell>
            <TableCell sx={{ fontWeight: "bold" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              4.1. Обманувший пользователь замораживается на площадке до
              разбирательства. В случае отказа от разбирательства, аккаунт
              блокируется
            </TableCell>
            <TableCell>п. 8.4. лицензионного соглашения</TableCell>
            <TableCell>
              Раскрытие персональных данных; Заморозка; Обнуление титула;
              Блокировка; Возврат средств пострадавшей стороне; Отказ в выплатах
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              4.2. При нарушении правил сервиса администрация вправе изменять
              персональные условия пользования площадкой для нарушителя
            </TableCell>
            <TableCell>п. 4.2. лицензионного соглашения</TableCell>
            <TableCell>
              Раскрытие персональных данных; Заморозка; Обнуление титула;
              Блокировка; Возврат средств пострадавшей стороне; Отказ в
              выплатах; Измение титула
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </Container>
);

export default Rules;
