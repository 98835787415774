import {
    Box,
    Button,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
  } from "@mui/material";
  import { useCallback, useEffect, useState } from "react";
  
  import styles from "./styles.module.css";
  import { getUserReviews } from "../../../../api/personalOffice/requests";
  import { TranslateText } from "../../../translate-text";
  import VendorReview from "../../VendorReview";
  import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";

const UserInfoTabReviews = ({ user }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const navigate = useNavigate()
  
    const [pagination, setPagination] = useState({
      limit: 6,
      page: 0,
    });
    const [totalItems, setTotalItems] = useState(0);
    const [reviews, setReviews] = useState([]);
    const [loading, setLoading] = useState(false)
  
    const loadReviews = useCallback(
      async (newPage) => {
        setLoading(true)
        try {
          const response = await getUserReviews({
            user: user._id,
            limit: pagination.limit,
            page: newPage,
          });
  
          setTotalItems(response.data.totalItems);
          setReviews((prev) => [...prev, ...response.data.items]);
        } catch (error) {
          navigate('/404')
        } finally {
            setLoading(false)
        }
      },
      [pagination.limit, user?._id]
    );
  
    const handleLoadMore = () => {
      const newPage = pagination.page + 1;
      setPagination((prev) => ({ ...prev, page: newPage }));
      loadReviews(newPage);
    };
  
    useEffect(() => {
      if (user?._id) {
        loadReviews(pagination.page);
      }
    }, [user?._id, pagination.page]);
  
    if (!reviews.length) {
      return null;
    }

  return (
    <Box className={styles.containerWrapper}>
        <Box sx={{
            display: 'flex',
            gap: '10px',
            justifyContent: 'center'
        }}>
            <Typography variant="h3" className={styles.title}>
                <TranslateText value="vendorDashboard.reviews.title" />
            </Typography>
            <Typography variant="h3" className={styles.title}>
                {" - " + totalItems}
            </Typography>
        </Box>

      <Grid
        container
        spacing={isMobile ? 2 : 4}
        className={styles.container}
      >
        {reviews.map((review) => (
          <Grid item key={review._id}>
            <VendorReview review={review} />
          </Grid>
        ))}
      </Grid>

      {reviews.length < totalItems && (
        <Box textAlign="center" mt={4}>
            <LoadingButton 
                className={styles.button}
                loading={loading}
                variant="contained"
                type="submit"
                onClick={handleLoadMore}
            >
              Показать ещё
            </LoadingButton>
        </Box>
      )}
    </Box>
  );
};

export default UserInfoTabReviews;
