import axios from "axios";
import Cookies from "js-cookie";

const baseURL =
  (process.env.NODE_ENV.includes("production") &&
    process.env.REACT_APP_SERVER_URL) ||
  "http://localhost:3030/api/";
const API = axios.create({ baseURL });

const header = () => {
  const token = Cookies.get("refreshToken");
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
};

/*

    data = {
        user // User Id
        type // Type of draw ex: brawlStarsBP
    }
*/

export const createDraw = (data) => API.post("/draws", data, header());