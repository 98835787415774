import { useTheme } from "@emotion/react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { ArrowLeftIcon, ArrowRightIcon } from "@mui/x-date-pickers";
import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { getCharsByCategoryAndType } from "../../../../../../api/characteristics/getCharsByCategoryAndType";
import { useTranslate } from "../../../../../../context/translate/translate.context";
import styles from "./styles.module.css";

const AddProductModalStep4 = ({ defaultProduct, back, submit }) => {
  const theme = useTheme();
  const { translate } = useTranslate();

  const [product, setProduct] = useState(defaultProduct);
  const [databaseCharacteristics, setDbCharacteristics] = useState([]);

  const goBack = useCallback(() => back(product), [...Object.values(product)]);

  const inputStyle = {
    WebkitBoxShadow: `0 0 0 1000px ${theme.palette.primary.backgroundPaperDark} inset`,
  };
  const inputStyleDay = {
    WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.paper} inset`,
  };
  const isDarkTheme = theme.palette.mode === "dark";
  const selectedInputStyle = isDarkTheme ? inputStyle : inputStyleDay;

  const getChars = useCallback(async () => {
    if (product?.category?._id && product?.type?._id) {
      const response = await getCharsByCategoryAndType(
        product.category._id,
        product.type
      );
      setDbCharacteristics(response.data?.data?.array || []);
    }
  }, [product?.category?._id, product?.type]);

  const onSubmitForm = useCallback((data) => submit(product), [product]);

  useEffect(() => {
    getChars();
  }, []);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmitForm)} className={styles.form}>
      <Box className={styles.formContainer}>
        <Controller
          name="databaseCharacteristics"
          control={control}
          defaultValue={product.productChars}
          render={({ field: { onChange } }) => (
            <Autocomplete
              multiple
              disablePortal={false}
              id="product-characteristics-selection"
              options={databaseCharacteristics}
              getOptionLabel={(option) => `${option.char}: ${option.value}`}
              sx={{ width: "100%" }}
              value={product.productChars}
              onChange={(_, productChars) => {
                setProduct((prev) => ({ ...prev, productChars }));
                onChange(productChars);
              }}
              renderInput={(params) => (
                <TextField
                  label={translate(
                    "addProductModal.new.step4.inputs.databaseCharacteristics.label"
                  )}
                  error={!!errors.databaseCharacteristics}
                  helperText={errors.databaseCharacteristics?.message || ""}
                  sx={selectedInputStyle}
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: selectedInputStyle,
                  }}
                />
              )}
            />
          )}
        />

        {product.customCharacteristics.map((_, index) => (
          <Box key={index} className={styles.rowInputs}>
            <TextField
              required
              label={translate(
                "addProductModal.new.step4.inputs.customCharacteristic.label"
              )}
              variant="outlined"
              InputProps={{ style: selectedInputStyle }}
              autoComplete="off"
              error={Boolean(errors[index])}
              className={styles.input}
              helperText={errors[index]?.message}
              value={product.customCharacteristics[index]?.char || ""}
              onChange={(e) =>
                setProduct(({ customCharacteristics: v, ...prev }) => ({
                  ...prev,
                  customCharacteristics: v.map((prevItm, i) =>
                    i === index ? { ...prevItm, char: e.target.value } : item
                  ),
                }))
              }
            />
            <TextField
              required
              label={translate(
                "addProductModal.new.step4.inputs.customCharacteristicProperty.label"
              )}
              variant="outlined"
              InputProps={{ style: selectedInputStyle }}
              autoComplete="off"
              error={Boolean(errors[index])}
              className={styles.input}
              helperText={errors[index]?.message}
              value={product.customCharacteristics[index]?.value || ""}
              onChange={(e) =>
                setProduct(({ customCharacteristics: v, ...prev }) => ({
                  ...prev,
                  customCharacteristics: v.map((prevItm, i) =>
                    i === index
                      ? { ...prevItm, value: e.target.value }
                      : prevItm
                  ),
                }))
              }
            />

            <Button
              variant="contained"
              color="error"
              sx={{
                height: "100%",
                minWidth: "fit-content",
                padding: "6px 10px",
              }}
              onClick={() =>
                setProduct(({ customCharacteristics: v, ...prev }) => ({
                  ...prev,
                  customCharacteristics: v.filter((_, i) => i !== index),
                }))
              }
            >
              <DeleteIcon />
            </Button>
          </Box>
        ))}

        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() =>
            setProduct(({ customCharacteristics: v, ...prev }) => ({
              ...prev,
              customCharacteristics: [...v, { char: "", value: "" }],
            }))
          }
          sx={{ width: "100%" }}
        >
          {translate(
            "addProductModal.modal1.productCharacteristics.addNewButtonLabel"
          )}
        </Button>
      </Box>

      <Box className={styles.buttonsContainer}>
        <Button
          onClick={goBack}
          className={clsx(styles.button, styles.cancelButton)}
          startIcon={<ArrowLeftIcon />}
        >
          {translate("addProductModal.new.step2.back")}
        </Button>
        <Button
          type="submit"
          className={clsx(styles.button, styles.submitButton)}
          endIcon={<ArrowRightIcon />}
        >
          {translate("addProductModal.new.step2.next")}
        </Button>
      </Box>
    </form>
  );
};

export default AddProductModalStep4;
