import { Box, Button, Container, Typography, useTheme } from "@mui/material";

import { useTranslate } from "../../context/translate/translate.context";
import Footer from "../Layout/Footer";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const { translate } = useTranslate();

  // If the user has a history, go back to the previous page.
  const handleGoBack = () => {
    window.history.length > 1
      ? window.history.back()
      : (window.location.href = "/");
  };

  return (
    <Box className={styles.wrapper}>
      <Container className={styles.container}>
        <Typography className={styles.heading} variant="h1" color="main">
          {translate("paymentSuccess.succeeded")}
        </Typography>
        <Typography className={styles.text}>
          {translate("paymentSuccess.wannaContinue")}
        </Typography>
        <Button
          className={styles.button}
          variant="contained"
          onClick={() => navigate("/catalog")}
          > {/* ПОМЕНЯТЬ NAVIGATE */}
           
          {translate("paymentSuccess.goCatalog")}
        </Button>
      </Container>
      <Footer customFooter />
    </Box>
  );
}

export default PaymentSuccess;
