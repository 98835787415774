import { Box, Container, Grid, Rating, Typography, useMediaQuery } from "@mui/material";
import styles from "./styles.module.css";
import { LoadingButton } from "@mui/lab";
import { TranslateText } from "../translate-text";
import { getAllReviews } from "../../api/reviews/getAllReviews";
import { useTheme } from "@emotion/react";
import { useCallback, useEffect, useState } from "react";
import VendorReview from "../vendor/VendorReview";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { LogoNew } from "../logo/LogoNew";
import ReviewsStars from "../Reviews";
import Button from "../UI/Button";

const reviewsPage = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const navigate = useNavigate()
  
    const [pagination, setPagination] = useState({
      limit: 6,
      page: 0,
    });
    const [totalItems, setTotalItems] = useState(0);
    const [reviews, setReviews] = useState([]);
    const [loading, setLoading] = useState(false)
  
    const loadReviews = useCallback(
      async (newPage) => {
        setLoading(true)
        try {
          const response = await getAllReviews({
            limit: pagination.limit,
            page: newPage,
          });
  
          setTotalItems(response.data.totalItems);
          setReviews((prev) => [...prev, ...response.data.items]);
        } catch (error) {
            navigate('/404')
        } finally {
            setLoading(false)
        }
      },
      [pagination.limit]
    );
  
    const handleLoadMore = () => {
      const newPage = pagination.page + 1;
      setPagination((prev) => ({ ...prev, page: newPage }));
      loadReviews(newPage);
    };
  
    useEffect(() => {
        loadReviews(pagination.page);
    }, [pagination.page]);
  
    if (!reviews.length) {
      return null;
    }

    const formatReviewsText = (totalReviews) => {
        // Function to format numbers with one decimal and Russian suffixes
        const formatNumber = (number) => {
            if (number >= 1000000) {
                return (number / 1000000).toFixed(1) + ' млн';
            } else if (number >= 1000) {
                return (number / 1000).toFixed(1) + ' тыс';
            } else if (number >= 100) {
                return (number / 100).toFixed(1) + ' сот';
            } else {
                return number;
            }
        }
    
        return formatNumber(totalReviews);
      }

      const handleNavigateBack = () => {
        navigate(-1);
      };

    return (
        <Container className={styles.containerWrapper}>
            <Box sx={{
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
                marginTop: '20px',
                marginLeft: '2%'
            }}>
                <ArrowBackIcon sx={{ 
                    cursor: "pointer", 
                    border: '1px solid black' ,
                    borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
                    borderRadius: '50%',
                    padding: '6px',
                    transition: '1s all easy-in-out',
                }} onClick={handleNavigateBack} />
                <Typography>
                    Отзывы покупателей
                </Typography>
            </Box>
            
            <Box sx={{
                marginTop: '30px',
                display: 'flex',
                gap: '40px',
                alignItems: 'center',
                marginLeft: '2%',
                '@media (max-width: 650px)':{
                    flexDirection: 'column',
                }
            }}>
                <Box sx={{
                    backgroundColor: theme.palette.mode === 'dark' ? 'white' : '#efeaf6',
                    padding: '50px 30px',
                    borderRadius: '5px',
                }}>
                    <LogoNew 
                        width = "106"
                        height = "52"
                    />
                </Box>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                    '@media (max-width: 650px)':{
                        justifyContent: 'center',
                    }
                }}>
                    <Typography sx={{
                        fontSize: '26px',
                        fontWeight: 'bold'
                    }}>
                        Mentup-Store.com
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '15px'
                    }}>
                        <Typography sx={{
                            fontSize: '48px',
                            fontWeight: 'bold'
                        }}>
                            5.0
                        </Typography>
                    
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                            <Typography sx={{
                                opacity: 0.6
                            }}>
                                Рейтинг
                            </Typography>
                            <Box sx={{
                                display: 'flex',
                                gap: '10px'
                            }}>
                                <Rating
                                    sx={{ fontSize: "1.5rem", marginBottom: '0.5rem' }}
                                    value={5}
                                    defaultValue={0}
                                    precision={0.5}
                                    readOnly={true}
                                />
                                <Typography sx={{
                                    opacity: 0.6
                                }}>
                                    {formatReviewsText(totalItems) + ' отзывов'}
                                </Typography>
                            </Box>
                            
                        </Box>
                    </Box>
                    <Button>
                        Оставить отзыв
                    </Button>
                </Box>
            </Box>

        <Grid
            container
            className={styles.container}
        >
            {reviews.map((review, index) => (
            <Grid item key={review._id || index}>
                <VendorReview review={review} />
            </Grid>
            ))}
        </Grid>

        {reviews.length < totalItems && (
            <Box textAlign="center" mt={4}>
                <LoadingButton
                    className={styles.button}
                    loading={loading}
                    variant="contained"
                    type="submit"
                    onClick={handleLoadMore}
                >
                    Показать ещё
                </LoadingButton>
            </Box>
        )}
        </Container>
    )
}

export default reviewsPage