import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { registerGoogle } from "../../../api/auth/register";
import { useTranslate } from "../../../context/translate/translate.context";
import FormInput from "../../FormInput";
import Button from "../../UI/Button";
import { FORM_VALIDATIONS } from "./constants";
import styles from "./styles.module.css";

const StepGoogle = ({ data, loading, setLoading, logIn, next }) => {
  const { translate } = useTranslate();
  const { control, handleSubmit, setError } = useForm();

  const country = useSelector((state) => state.user.country);

  const onSubmit = async (localData) => {
    try {
      setLoading(true);

      const invite = JSON.parse(localStorage.getItem('hasInvite'))
      let dataToSend = {
        ...data,
        ...localData,
        country,
      }
      if(invite){
        dataToSend.invite = invite.fromUser
      }

      const response = await registerGoogle(dataToSend);

      logIn(response);
    } catch (error) {
      setError("email", { message: error?.response?.data?.email });
      //console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      className={styles.form}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormInput
        name="username"
        label={translate("authentication.nickname")}
        control={control}
        autoComplete="username"
        disabled={loading}
        rules={FORM_VALIDATIONS.username(translate)}
      />
      <Button
        className={styles.form__submit}
        type="submit"
        size="large"
        fullWidth
        loading={loading}
      >
        {translate("authentication.continue")}
      </Button>
      <Box className={styles.form__link} component={Link} to="/login">
        {translate("authentication.alreadyHaveAnAccount")}
      </Box>
    </Box>
  );
};

export default StepGoogle;
