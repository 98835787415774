import { getProductsHomePage } from "../api/product/getProductsHome";

async function getBestSellersProducts(productsLimit, skip, country) {
  const response = await getProductsHomePage(
    skip,
    productsLimit,
    country
  );

  return {
    items: response.data.products,
    total: response.data.totalProducts
  }
}

export default getBestSellersProducts;
