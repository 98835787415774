import { useTheme } from "@emotion/react";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Typography } from "@mui/material";
import clsx from "clsx";
import React from "react";

import MentupModal from "../../MentupModal/MentupModal";
import Danger from "../../svg/Danger";
import { TranslateText } from "../../translate-text";
import styles from "./styles.module.css";

function BuyerRejectOrderModal({
  open,
  loading,
  close,
  confirm,
  orderStatus,
  productStatus,
}) {
  const theme = useTheme();

  return (
    <MentupModal open={open} close={close}>
      <Box className={styles.confirmContainer}>
        <Box className={styles.headerContainer}>
          <Danger
            color={theme.palette.mode === "dark" ? "#EDF67D" : "#8142f6"}
          />
          <Typography className={styles.alertTitle}>
            <TranslateText value="orders.orderProduct.confirmRefund" />
          </Typography>
          <Typography className={styles.alertInfo}>
            <TranslateText value="orders.orderProduct.refundDblConfirmation" />
          </Typography>
          <Typography className={styles.alertMessage}>
            <TranslateText value="orders.orderProduct.refundDblMessage" />
          </Typography>
        </Box>

        <Box className={styles.buttonsContainer}>
          <Button
            className={clsx(styles.button, styles.confirmButton)}
            onClick={close}
          >
            <TranslateText value="products.product.delete.confirmation.cancel" />
          </Button>
          <LoadingButton
            className={clsx(styles.button, styles.cancelButton)}
            onClick={confirm}
            loading={loading}
          >
            <TranslateText value="products.product.delete.confirmation.confirm" />
          </LoadingButton>
        </Box>
      </Box>
    </MentupModal>
  );
}

export default BuyerRejectOrderModal;
