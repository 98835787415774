import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

import {
  Box,
  Card,
  CircularProgress,
  Container,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Grid, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { getAllCategoriesHome, getAllCategoriesHomePage, getCategoriesCount } from "../../../api/categories/getAllCategories";
import { getAllProductType } from "../../../api/product/ProductTypes";
import { useTranslate } from "../../../context/translate/translate.context";
import useThrottledResize from "../../../hooks/useThrottledResize";
import Arrow from "../../svg/Arrow";
import styles from "./styles.module.css";
import ComputerGames from "../../svg/ComputerGames";
import Applications from "../../svg/Applications";
import MobileGames from "../../svg/MobileGames";

const Games = () => {
  const { translate } = useTranslate();

  const navigate = useNavigate();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const swiperRef = useRef(null);

  const [loading, setLoading] = useState(true);

  const [games, setGames] = useState([]);
  const [totalGames, setTotalGames] = useState(0)

  const [apps, setApps] = useState([]);
  const [totalApps, setTotalApps] = useState(0)

  const [mobileGames, setMobileGames] = useState([])
  const [totalMobileGames, setTotalMobileGames] = useState(0)


  const [productTypes, setProductTypes] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState("all");

  // const [fetchedGamesCount, setFetchedGamesCount] = useState(0);

  const [disableArrows, setDisableArrows] = useState({
    left: true,
    right: true,
  });

  const setArrowDisability = () => {
    if (swiperRef.current) {
      const { isBeginning, isEnd } = swiperRef.current;

      setDisableArrows({
        left: isBeginning,
        right: isEnd,
      });
    }
  };

  const centerContainerOnMobile = () => {
    if (!swiperRef.current) {
      return;
    }

    const { el } = swiperRef.current;

    if (!isMobile) {
      el.firstChild.style.marginLeft = "0";
      return;
    }

    const { left } = el.getBoundingClientRect();

    el.firstChild.style.marginLeft = left > 20 ? "16px" : "0";
  };

  useThrottledResize(() => {
    setArrowDisability();
    centerContainerOnMobile();
  });

  useEffect(() => {
    const getFeatured = async () => {
      try {
        /*
        const [{ data: games }, { data }] = await Promise.all([
          getAllCategoriesHome(),
          getAllProductType(),
        ]);  */

        const res = await getAllCategoriesHomePage()

        /*
        const {
          data: { total },
        } = await getCategoriesCount();

        setTotalGames(total) 

        // Function to repeat the games array until it has at least 30 items
        const repeatArray = (arr, count) => {
          const repeated = [];
          while (repeated.length < count) {
            repeated.push(...arr);
          }
          return repeated.slice(0, count); // Ensure the length is exactly `count`
        };

        // Repeat the games array to ensure it has at least 30 items
        const repeatedGames = repeatArray(games, 30);
        const apps = repeatArray(games, 12)
        const mobileGames = repeatArray(games, 28)
        
        
        setTotalGames(total);
        setGames(repeatedGames);
        setApps(apps)
        setMobileGames(mobileGames)
        */
        
        
        // Check if the user is on a mobile device and slice arrays accordingly
        const maxItems = isMobile ? 8 : Infinity; // Use Infinity to avoid slicing if not mobile

        // Slicing the arrays to a maximum of 8 items if on mobile
        const limitedGames = res.data.games.list.slice(0, maxItems);
        const limitedApps = res.data.apps.list.slice(0, maxItems);
        const limitedMobileGames = res.data.mobileGames.list.slice(0, maxItems);

        setTotalGames(res.data.games.total);
        setGames(limitedGames);
        setApps(limitedApps);
        setTotalApps(res.data.apps.total);
        setMobileGames(limitedMobileGames);
        setTotalMobileGames(res.data.mobileGames.total);
        

      
        //setProductTypes(data);
      } catch (error) {
        navigate('/404')
      } finally {
        setLoading(false);
      }
    };

    getFeatured();
  }, []);

  const handleNextSlides = () => {
    swiperRef.current.slideNext();
  };

  const handlePrevSlides = () => {
    swiperRef.current.slidePrev();
  };

  if (!loading && games.length === 0) {
    return null;
  }

  const handleSlideChange = () => {
    setArrowDisability();
  };

  const buttons = (
    <Box className={styles.gamesContainer__swipeButtons}>
      <IconButton
        className={styles.leftButton}
        disabled={disableArrows.left}
        onClick={handlePrevSlides}
      >
        <Arrow />
      </IconButton>
      <IconButton
        className={styles.rightButton}
        disabled={disableArrows.right}
        onClick={handleNextSlides}
      >
        <Arrow />
      </IconButton>
    </Box>
  );

  return (
    <Box className={styles.gamesContainerWrapper}>
      <Container className={styles.gamesContainer} disableGutters>
        {/*
        <Box className={styles.gamesContainer__textContainer}>
          <Typography className={styles.gamesContainer__heading} variant="h3">
            {translate("home.games")}
          </Typography>
        </Box> */}
        {loading ? (
          <Box
            sx={{
              position: "fixed",
              top: "0",
              left: "0",
              width: "100vw",
              height: "100vh",
              background: "rgba(0,0,0,1)",
              zIndex: "100",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            {/*
            <Box className={styles.gamesContainer__buttons}>
              <Box width="100%">
                <Tabs
                  value={selectedCategory}
                  onChange={(_, newValue) => setSelectedCategory(newValue)}
                  variant="scrollable"
                  scrollButtons="auto"
                  indicatorColor="secondary"
                  aria-label="categories tabs"
                >
                  <Tab
                    className={styles.tab}
                    value="all"
                    label={translate("home.allCategories")}
                  />
                  {productTypes.map(({ name, _id }) => (
                    <Tab
                      key={_id}
                      className={styles.tab}
                      value={name}
                      label={translate(name)}
                    />
                  ))}
                </Tabs>
              </Box>
              {!isMobile && buttons}
            </Box> */}
            {/*
            <Box
              sx={{
                "@media (max-width: 900px)": {
                  display: "flex",
                },
              }}
            >
              <Swiper
                modules={[Grid, Pagination]}
                spaceBetween={16}
                slidesPerView="auto"
                slidesPerGroupAuto
                pagination={{
                  el: "#games-pagination",
                  dynamicBullets: true,
                }}
                grid={{
                  rows: 2,
                  fill: "row",
                }}
                breakpoints={{
                  900: {
                    grid: {
                      rows: 1,
                    },
                  },
                }}
                onSlideChange={handleSlideChange}
                onTransitionEnd={setArrowDisability}
                onSwiper={(swiper) => {
                  swiperRef.current = swiper;

                  setArrowDisability();
                  centerContainerOnMobile();
                }}
              >
                {(selectedCategory === "all"
                  ? games
                  : games.filter(({ productTypes }) =>
                      productTypes.some(({ name }) => name === selectedCategory)
                    )
                ).map(
                  (
                    { name, media,thumb,  description, slug, primaryProductType },
                    index
                  ) => (
                    <SwiperSlide key={name + index}>
                      <Box
                        className={styles.cardWrapper}
                        onClick={() => navigate(`/games/${slug}`)}
                      >
                        <Card className={styles.card}>
                          <Box
                            className={styles.image}
                            component="img"
                            src={thumb ? thumb : media}
                            loading="lazy"
                          />
                          <Typography className={styles.title} variant="h4">
                            {name}
                          </Typography>
                          {description && (
                            <Typography className={styles.productDescription}>
                              {description}
                            </Typography>
                          )}
                        </Card>
                      </Box>
                    </SwiperSlide>
                  )
                )}
              </Swiper>
            </Box>
            <Box className={styles.pagination}>
              <Box id={"games-pagination"} />
            </Box>
            {isMobile && buttons}
            */}
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '30px',
              '@media (max-width: 650px)': {
                flexDirection: 'column-reverse'
              }
            }}>

              <Box sx={{
                display: 'flex',
                gap: '1.4rem',
                '@media (max-width: 650px)': {
                  flexDirection: 'column'
                },
              }}>
                <Box sx={{ // 30 items
                  minWidth: '70%',
                  maxWidth: '70%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  '@media (max-width: 650px)': {
                    minWidth: '100%',
                    maxWidth: '100%',
                  }
                }}>
                  {/* header */}
                  <Box sx={{
                    display: 'flex',
                    padding: '10px 16px 10px 12px',
                    background: theme.palette.mode === 'dark' ? '#3D3D42' : '#EFEAF6',
                    borderRadius: '8px',
                    justifyContent: 'space-between'
                  }}>
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px'
                    }}>
                      <ComputerGames />
                      <Typography sx={{
                        fontWeight: '500',
                        textTransform: 'uppercase'
                      }}>
                        Игры
                      </Typography>
                    </Box>
                    <Box 
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        transition: 'all 0.5s ease-in-out',
                        opacity: 1,
                        ':hover': {
                          cursor: 'pointer',
                          opacity: 0.8
                        }
                      }}
                      onClick={() => navigate('/games?s=igri')}
                    >
                      <Typography>{totalGames}</Typography>
                      <Arrow color={theme.palette.mode === 'dark' ? 'white' : 'black'}/>
                    </Box>
                  </Box>
                  {/* list */}
                  <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '10px',
                    marginTop: '10px',
                    rowGap: '20px',
                    justifyContent: 'center'
                  }}>
                    {games && games.map(({ name, media, thumb, description, slug, primaryProductType }, index) => (
                        <Box 
                          key={index} 
                          className={styles.card2}
                          to={`/games/${slug}`}
                          component={Link}
                        >
                          <Box
                            className={styles.image2}
                            component="img"
                            src={thumb ? thumb : media}
                            loading="lazy"
                          />
                          <Typography className={styles.title} variant="h4">
                            {name}
                          </Typography>
                        </Box>
                      ))
                    }
                  </Box>
                </Box>
                <Box sx={{ //  12 items
                  minWidth: '28%',
                  maxWidth: '28%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  '@media (max-width: 650px)': {
                    minWidth: '100%',
                    maxWidth: '100%',
                  }
                }}>
                  {/* header */}
                  <Box sx={{
                    display: 'flex',
                    padding: '10px 16px 10px 12px',
                    background: theme.palette.mode === 'dark' ? '#3D3D42' : '#EFEAF6',
                    borderRadius: '8px',
                    justifyContent: 'space-between'
                  }}>
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px'
                    }}>
                      <Applications />
                      <Typography sx={{
                        fontWeight: '500',
                        textTransform: 'uppercase'
                      }}>
                        Приложения
                      </Typography>
                    </Box>
                    <Box 
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        transition: 'all 0.5s ease-in-out',
                        opacity: 1,
                        ':hover': {
                          cursor: 'pointer',
                          opacity: 0.8
                        }
                      }}
                      onClick={() => navigate('/games?s=prilozheniia')}
                    >
                      <Typography>{totalApps}</Typography>
                      <Arrow color={theme.palette.mode === 'dark' ? 'white' : 'black'}/>
                    </Box>
                  </Box>
                  {/* list */}
                  <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '10px',
                    marginTop: '10px',
                    rowGap: '20px',
                    justifyContent: 'center'
                  }}>
                    {apps && apps.map(({ name, media, thumb, description, slug, primaryProductType }, index) => (
                        <Box 
                          key={index} 
                          className={styles.card2}
                          to={`/games/${slug}`}
                          component={Link}
                        >
                          <Box
                            className={styles.image2}
                            component="img"
                            src={thumb ? thumb : media}
                            loading="lazy"
                          />
                          <Typography className={styles.title} variant="h4">
                            {name}
                          </Typography>
                        </Box>
                      ))
                    }
                  </Box>
                </Box>
              </Box>
                
              <Box sx={{ // 28 items
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                minWidth: '100%',
                maxWidth: '100%'
              }}>
                {/* header */}
                <Box sx={{
                    display: 'flex',
                    padding: '10px 16px 10px 12px',
                    background: theme.palette.mode === 'dark' ? '#3D3D42' : '#EFEAF6',
                    borderRadius: '8px',
                    justifyContent: 'space-between'
                  }}>
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px'
                    }}>
                      <MobileGames />
                      <Typography sx={{
                        fontWeight: '500',
                        textTransform: 'uppercase'
                      }}>
                        Мобильные игры
                      </Typography>
                    </Box>
                    <Box 
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        transition: 'all 0.5s ease-in-out',
                        opacity: 1,
                        ':hover': {
                          cursor: 'pointer',
                          opacity: 0.8
                        }
                      }}
                      onClick={() => navigate('/games?s=mobilьniee-igrie')}
                    >
                      <Typography>{totalMobileGames}</Typography>
                      <Arrow color={theme.palette.mode === 'dark' ? 'white' : 'black'}/>
                    </Box>
                  </Box>
                {/* list */}
                
                <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '9px',
                    marginTop: '10px',
                    rowGap: '20px',
                    justifyContent: 'left',
                    '@media (max-width: 650px)': {
                      justifyContent: 'center'
                    }
                  }}>
                    {mobileGames && mobileGames.map(({ name, media, thumb, description, slug, primaryProductType }, index) => (
                        <Box 
                          key={index} 
                          className={styles.card2}
                          to={`/games/${slug}`}
                          component={Link}
                        >
                          <Box
                            className={styles.image2}
                            component="img"
                            src={thumb ? thumb : media}
                            loading="lazy"
                          />
                          <Typography className={styles.title} variant="h4">
                            {name}
                          </Typography>
                        </Box>
                      ))
                    }
                  </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default Games;
