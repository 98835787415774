import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";

import { Box, IconButton, useMediaQuery, useTheme } from "@mui/material";
import clsx from "clsx";
import { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

import useThrottledResize from "../../hooks/useThrottledResize";
import ProductCard from "../ProductCard";
import Arrow from "../svg/Arrow";
import styles from "./styles.module.css";

const ProductSwiper = ({ products, cardVariant, alwaysMobile, play }) => {

  const theme = useTheme();

  const isMobile = alwaysMobile || useMediaQuery(theme.breakpoints.down("md"));

  const swiperRef = useRef(null);

  const [areArrowsVisible, setAreArrowsVisible] = useState(alwaysMobile);
  const [disableArrows, setDisableArrows] = useState({
    left: true,
    right: true,
  });

  const setArrowsVisibility = () => {
    const { slides, el } = swiperRef.current;

    const { right } = el.getBoundingClientRect();

    const isOverflow = slides.some((slide) => {
      const { right: elementRight, left: elementLeft } =
        slide.getBoundingClientRect();

      return elementLeft < 0 || Math.floor(elementRight) > right;
    });

    setAreArrowsVisible(isOverflow);
  };

  const setArrowDisability = () => {
    if (swiperRef.current) {
      const { isBeginning, isEnd } = swiperRef.current;

      setDisableArrows({
        left: isBeginning,
        right: isEnd,
      });
    }
  };

  const setArrowsState = () => {
    if (!alwaysMobile) {
      setArrowsVisibility();
    }

    setTimeout(() => {
      setArrowDisability();
    }, 0);
  };

  useThrottledResize(setArrowsState);

  const handleNextSlides = () => {
    swiperRef.current.slideNext();
  };

  const handlePrevSlides = () => {
    swiperRef.current.slidePrev();
  };

  const leftButton = (
    <IconButton
      sx={{ display: areArrowsVisible ? "flex" : "none" }}
      className={styles.leftButton}
      disabled={disableArrows.left}
      onClick={handlePrevSlides}
    >
      <Arrow />
    </IconButton>
  );

  const rightButton = (
    <IconButton
      sx={{ display: areArrowsVisible ? "flex" : "none" }}
      className={styles.rightButton}
      disabled={disableArrows.right}
      onClick={handleNextSlides}
    >
      <Arrow />
    </IconButton>
  );

  return (
    <Box
      className={clsx(styles.container, alwaysMobile && styles.container_wrap)}
    >
      {!isMobile && leftButton}
      <Swiper
        modules={[Autoplay]}
        autoplay={play ? {
          delay: 3000,
          disableOnInteraction: false,
        } : false}
        style={{
          width: !disableArrows.left || !disableArrows.right ? "100%" : "auto",
        }}
        spaceBetween={16}
        slidesPerView="auto"
        slidesPerGroupAuto
        centerInsufficientSlides
        onSlideChange={setArrowDisability}
        onTransitionEnd={setArrowDisability}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
          setArrowsState();
        }}
        
      >
        {products.map((product, index) => (
          <SwiperSlide key={product?.name + index}>
            {product !== null && product !== undefined &&
              <ProductCard variant={cardVariant} product={product} />
            }
          </SwiperSlide>
        ))}
      </Swiper>
      {!isMobile && rightButton}
      {isMobile && (
        <Box
          sx={{
            display: "flex",
            gap: "14px",
            width: "100%",
          }}
        >
          {leftButton}
          {rightButton}
        </Box>
      )}
    </Box>
  );
};

export default ProductSwiper;
