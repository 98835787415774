import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  List,
  ListItem,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";

import { getFeaturePrices } from "../../../../../../../../api/feature-prices/get";
import { useTranslate } from "../../../../../../../../context/translate/translate.context";
import { TranslateText } from "../../../../../../../translate-text";
import styles from "./styles.module.css";

const PromoteProductStep2 = ({
  productId,
  selectedPrice,
  selectedFeatureType,
  next,
  onPriceSelect,
}) => {
  const theme = useTheme();
  const { translate } = useTranslate();

  const [prices, setPrices] = useState([]);

  const inputStyle = {
    WebkitBoxShadow: `0 0 0 1000px ${theme.palette.primary.backgroundPaperDark} inset`,
  };
  const inputStyleDay = {
    WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.paper} inset`,
  };
  const isDarkTheme = theme.palette.mode === "dark";
  const selectedInputStyle = isDarkTheme ? inputStyle : inputStyleDay;

  const getPrices = useCallback(async (id, featureType) => {
    setPrices([]);

    if (!id) {
      return;
    }

    const response = await getFeaturePrices(featureType, 100, 0, "days", "asc");
    if (response?.data?.items?.length) {
      setPrices(response.data.items);
      if (!selectedPrice) {
        onPriceSelect(response.data.items[0]);
      }
    }
  }, []);

  useEffect(() => {
    getPrices(productId, selectedFeatureType);
  }, [productId, selectedFeatureType]);

  return (
    <>
      <Box className={styles.selectPriceContainer}>
        <Select
          required
          value={selectedPrice?.days || ""}
          onChange={(e) =>
            onPriceSelect(prices.find((price) => price.days === e.target.value))
          }
          style={selectedInputStyle}
          className={styles.selectPriceInput}
        >
          {prices.map((price) => (
            <MenuItem key={price._id} value={price.days}>
              {`${price.price} ₽ - ${price.days} ${translate(
                price.days === 1
                  ? "promoteProductModal.modal1.confirmationInput.day"
                  : "promoteProductModal.modal1.confirmationInput.days"
              )}`}
            </MenuItem>
          ))}
        </Select>

        <Box className={styles.priceIncludedContainer}>
          <Typography className={styles.priceIncludedTitle}>
            <TranslateText value="products.product.promote.steps.step2.priceIncludedTitle" />
          </Typography>

          <List sx={{ listStyleType: "disc" }}>
            <ListItem sx={{ display: "list-item" }}>
              <Typography>Lorem ipsum dolor sit ametconsectetur</Typography>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <Typography>adipiscing elit, sed do eiusmod tempor i</Typography>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <Typography>
                ncididunt ut labore et dolore magna aliqua
              </Typography>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <Typography>
                Ut enim ad minim veniam, quis nostrud exercitation
              </Typography>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <Typography>
                ullamco laboris nisi ut aliquip ex ea commodo consequat
              </Typography>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <Typography>
                Duis aute irure dolor in reprehenderit in voluptate velit{" "}
              </Typography>
            </ListItem>
          </List>
        </Box>
      </Box>

      <Button
        className={clsx(styles.button, styles.cancelButton)}
        endIcon={<ArrowRightIcon />}
        disabled={!selectedPrice}
        onClick={next}
      >
        {translate("products.product.promote.next")}
      </Button>
    </>
  );
};

export default PromoteProductStep2;
