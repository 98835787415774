import { Box } from "@mui/material";

import { LogoNew } from "../../../../logo/LogoNew";
import styles from "./styles.module.css";

const CoverImage = ({ image }) => {
  if (image) {
    return (
      <Box
        sx={{ backgroundImage: `url(${image})` }}
        className={styles.coverImage}
      />
    );
  }

  return (
    <div className={styles.logoBgImageContainer}>
      <div className={styles.logoContainer}>
        <LogoNew width="100%" height="auto" />
      </div>
    </div>
  );
};

export default CoverImage;
