import React from "react";
import styles from './styles.module.css'
import { Box, Container, Typography, Button } from "@mui/material";
import { useTranslate } from '../../../../context/translate/translate.context'
import { useNavigate } from "react-router-dom";

const Fee = () => {
  const { translate } = useTranslate()
  const navigate = useNavigate()
  return (
    <Box className={styles.fee}>
      <Container>
        <Typography className={styles.fee__heading}>{translate('sellersPage.fee.feeHeading')}</Typography>
        <Typography className={styles.fee__text}><Typography variant="span" className={styles.fee__textSpan}>{translate('sellersPage.fee.only5%')}</Typography>{translate('sellersPage.fee.serviceDevelopment')}</Typography>
        <Button 
          onClick={() => navigate('/login')}
          className={styles.fee__button} sx={{borderRadius: '70px', fontSize: '20px'}} variant='contained'>{translate('sellersPage.fee.button')}</Button>
      </Container>
    </Box>
  );
};

export default Fee;
