export const priceDiscount = (price, discount) => {
    try {
        let numberPrice = Number(price);
        let numberDiscount = Number(discount);
        if (!isNaN(numberPrice) && !isNaN(numberDiscount)) {
            let discountPercentage = Number(numberDiscount / 100);
            if (
                !isNaN(discountPercentage) &&
                (discountPercentage || discountPercentage === 0)
            ) {
                discountPercentage = Number(discountPercentage).toFixed(4);

                const finalPrice = Number(
                    Number(numberPrice) * (1 - discountPercentage)
                ).toFixed(2);

                if (!isNaN(finalPrice)) {
                    return finalPrice;
                }
            }
        }
    } catch (err) {
        //console.log(err);
    }
};
