import React from "react";
import styles from "./styles.module.css";
import { Box, Typography, Button, Container, useTheme } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useTranslate } from "../../../../context/translate/translate.context";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const { translate } = useTranslate();
  const navigate = useNavigate()
  const steps = [
    "sellersPage.register.fillForm",
    "sellersPage.register.acceptEmail",
    "sellersPage.register.createPassword",
  ];
  const stepConfig = {
    "& .MuiStepLabel-root .Mui-active": {
      color: "#EDF67D", // circle color (ACTIVE)
    },
    "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
      width: "100%",
    },
    "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
      fill: "black", // circle's number (ACTIVE)
      fontSize: '16px'
    },
    "& .MuiStepConnector-line": {
      border: "1px solid #EDF67D",
    },
  };
  return (
    <Box className={styles.register}>
      <Container className={styles.registerContainer}>
        <Typography
          className={styles.register__heading}
          sx={{
            fontSize: "52px",
            lineHeight: "46.8px",
            fontWeight: "500",
            mb: "16px",
          }}
        >
          {translate("sellersPage.register.registerHeading")}
        </Typography>
        <Typography
          className={styles.register__text}
          sx={{
            fontSize: "23px",
            lineHeight: "23px",
            fontWeight: "500",
            mb: "70px",
          }}
        >
          {translate("sellersPage.register.registerText")}
        </Typography>
        <Stepper
          alternativeLabel
          sx={{
            width: "600px",
            mb: "70px",
            "@media (max-width: 600px)": {
              width: "100%",
            },
          }}
        >
          {steps.map((label) => (
            <Step sx={stepConfig} key={label} active>
              <StepLabel>
                <Box className={styles.labelWrapper}>
                  <Typography className={styles.stepper__label}>
                    {translate(label)}
                  </Typography>
                </Box>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <Button
          className={styles.register__button}
          variant="outline"
          sx={{ borderRadius: "70px", fontSize: "20px" }}
          onClick={() => navigate('/login')}
        >
          {translate("sellersPage.register.button")}
        </Button>
      </Container>
    </Box>
  );
};

export default Register;
