import { useCallback, useEffect, useState } from "react";

export const useNotificationPermission = () => {
  if (typeof window !== "object") {
    return { permission: null, requestPermission: () => Promise.resolve(null) };
  }

  const [asked, setAsked] = useState(false);
  const [permission, setPermission] = useState(
    () => window?.["Notification"]?.permission
  );

  const isSupported = () =>
    "Notification" in window &&
    "serviceWorker" in navigator &&
    "PushManager" in window;

  const requestPermission = useCallback(async () => {
    if (asked) {
      return;
    }
    setAsked(true);

    if (!isSupported()) {
      return null;
    }

    const permission = await window?.["Notification"]?.requestPermission();
    setPermission(permission);
    return permission;
  }, [asked]);

  useEffect(() => {
    document.addEventListener("click", () => {
      requestPermission();
    });
  }, []);

  return { permission, requestPermission };
};
