import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import clsx from "clsx";
import React from "react";

import { TranslateText } from "../../../../../../../translate-text";
import styles from "./styles.module.css";

function TwoFAQR({ loading, url, close, confirm }) {
  return (
    <Box className={styles.confirmContainer}>
      <Box className={styles.headerContainer}>
        <Typography className={styles.alertTitle}>
          <TranslateText value="vendorDashboard.settings.2fa.qr.title" />
        </Typography>

        {loading ? (
          <CircularProgress size={100} />
        ) : (
          <img src={url} alt="Authenticator QR code" className={styles.qr} />
        )}

        <Typography className={styles.alertMessage}>
          <TranslateText value="vendorDashboard.settings.2fa.qr.step1" />
        </Typography>
        <Typography className={styles.alertMessage}>
          <TranslateText value="vendorDashboard.settings.2fa.qr.step2" />
        </Typography>
      </Box>

      <Box className={styles.buttonsContainer}>
        <Button
          className={clsx(styles.button, styles.confirmButton)}
          onClick={close}
        >
          <TranslateText value="vendorDashboard.settings.2fa.qr.cancel" />
        </Button>
        <LoadingButton
          className={clsx(styles.button, styles.cancelButton)}
          onClick={confirm}
          loading={loading}
        >
          <TranslateText value="vendorDashboard.settings.2fa.qr.next" />
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default TwoFAQR;
