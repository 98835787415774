import React from 'react'
import { Box, Container, Typography, Button } from "@mui/material";
import trustpilot from '../../../../images/sellerPage/trustpilot.png'
import styles from './styles.module.css'
import StarIcon from '../../../../images/sellerPage/StarIcon'
import { useTranslate } from '../../../../context/translate/translate.context'
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { useNavigate } from 'react-router-dom';


const WelcomeSeller = () => {
  const selectedLanguage = useSelector((state) => state.language);
  const { translate } = useTranslate()
  const navigate = useNavigate()
  return (
    <Box className={styles.seller}>
      <Container>
        <img src={trustpilot} alt="" className={styles.seller__trustpilot}/>
        {selectedLanguage === 'ru' ?
        <Typography className={styles.seller__heading}>Добро пожаловать на страницу продавцов <Typography variant='p' className={styles.seller__headingSpan}>MENTUP!</Typography></Typography>
        : 
        <Typography className={styles.seller__heading}>WELCOME TO THE <Typography variant='p' className={styles.seller__headingSpan}>MENTUP</Typography> VENDORS PAGE!</Typography>
        }
        <Typography className={styles.seller__text}>{translate('sellersPage.welcomeSeller.welcomeText')}</Typography>
        <Box className={styles.sellerButtons}>
          <Button
            onClick={() => navigate('/about-us')}
            className={styles.sellerButtons__button} variant='contained' startIcon={<StarIcon />} sx={{borderRadius: '70px', fontSize: '18px'}}>{translate('sellersPage.welcomeSeller.aboutUs')}</Button>
          <Button 
            onClick={() => navigate('/login')}
            className={styles.sellerButtons__button} variant='outlined' sx={{borderRadius: '70px', fontSize: '18px'}}>{translate('sellersPage.welcomeSeller.startSelling')}</Button>
        </Box>
      </Container>
    </Box>
  )
}

export default WelcomeSeller