import { useTheme } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, TextField } from "@mui/material";
import { ArrowLeftIcon, ArrowRightIcon } from "@mui/x-date-pickers";
import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FixedSizeList } from "react-window";
import * as yup from "yup";

import { useTranslate } from "../../../../../../context/translate/translate.context";
import styles from "./styles.module.css";

const AddProductModalStep3 = ({ defaultProduct: product, back, submit }) => {
  const theme = useTheme();
  const { translate } = useTranslate();

  const [formSchema, setFormSchema] = useState({});

  useEffect(() => {
    let tempObject = {};

    for (let i = 0; i < product.quantity; i++) {
      tempObject[i] = yup
        .string(translate("addProductModal.productCodeValidationErrors.string"))
        .min(5, translate("addProductModal.productCodeValidationErrors.min"))
        .max(100, translate("addProductModal.productCodeValidationErrors.max"))
        .required(
          translate("addProductModal.productCodeValidationErrors.required")
        )
        .test(
          translate("addProductModal.productCodeValidationErrors.validValue"),
          translate("addProductModal.productCodeValidationErrors.format"),
          (value) => value.match(/^[a-zA-Z0-9]+$/)
        );
    }
    setFormSchema(tempObject);
  }, [product.quantity]);

  const inputStyle = {
    WebkitBoxShadow: `0 0 0 1000px ${theme.palette.primary.backgroundPaperDark} inset`,
  };
  const inputStyleDay = {
    WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.paper} inset`,
  };
  const isDarkTheme = theme.palette.mode === "dark";
  const selectedInputStyle = isDarkTheme ? inputStyle : inputStyleDay;

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        ...(Object.keys(formSchema).length === product.quantity && formSchema),
      })
    ),
  });

  const renderRow = useCallback(
    (props) => {
      const { index, style } = props;

      return (
        <TextField
          style={style}
          key={`addProductModal-new-step3-inputs-productCode-${index}`}
          required
          label={`${translate(
            "addProductModal.new.step3.inputs.productCode.label"
          )} ${index + 1}`}
          placeholder={translate(
            "addProductModal.new.step3.inputs.productCode.placeholder"
          )}
          variant="outlined"
          error={!!errors[index]}
          inputProps={{ style: selectedInputStyle }}
          helperText={errors[index]?.message}
          {...register(index.toString())}
          defaultValue={product.codes?.[index] || ""}
        />
      );
    },
    [errors, product.codes]
  );

  return (
    <form onSubmit={handleSubmit(submit)} className={styles.form}>
      <Box className={styles.formContainer}>
        <FixedSizeList
          height={400}
          width={"100%"}
          itemSize={62}
          itemCount={product.quantity}
          overscanCount={10}
          style={{ paddingTop: "20px" }}
        >
          {renderRow}
        </FixedSizeList>
      </Box>

      <Box className={styles.buttonsContainer}>
        <Button
          onClick={back}
          className={clsx(styles.button, styles.cancelButton)}
          startIcon={<ArrowLeftIcon />}
        >
          {translate("addProductModal.new.step3.back")}
        </Button>
        <Button
          type="submit"
          className={clsx(styles.button, styles.submitButton)}
          endIcon={<ArrowRightIcon />}
        >
          {translate("addProductModal.new.step3.next")}
        </Button>
      </Box>
    </form>
  );
};

export default AddProductModalStep3;
