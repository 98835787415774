import { Box, Button, Typography } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";

import { TranslateText } from "../../../translate-text";
import Change2FA from "./components/Change2FA";
import ChangeNotifications from "./components/ChangeNotifications";
import ConnectedAccounts from "./components/ConnectedAccounts";
import { SETTINGS_TABS, SETTINGS_TABS_DISABLED } from "./constants";
import styles from "./styles.module.css";

const UserInfoTabSettings = () => {
  const [selectedTab, setSelectedTab] = useState(SETTINGS_TABS[0]);

  return (
    <Box className={styles.container}>
      <Box className={styles.tabs}>
        {SETTINGS_TABS.map((tab) => (
          <Button
            key={tab}
            disabled={SETTINGS_TABS_DISABLED.includes(tab)}
            className={clsx(
              styles.tab,
              selectedTab === tab && styles.selectedTab
            )}
            onClick={() => setSelectedTab(tab)}
          >
            <Typography className={styles.tabText}>
              <TranslateText value={`vendorDashboard.settings.tabs.${tab}`} />
            </Typography>
          </Button>
        ))}
      </Box>

      {selectedTab === "accounts" && <ConnectedAccounts />}
      {selectedTab === "2fa" && <Change2FA />}
      {selectedTab === "notifications" && <ChangeNotifications />}
    </Box>
  );
};

export default UserInfoTabSettings;
