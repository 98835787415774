import { useTheme } from "@emotion/react";
import { Box, Card, Chip, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getAllBlogPostsByCategory } from "../../../../api/blog/getBlogPosts";
import { getCategoryByNameAndType } from "../../../../api/categories/getCategoryByName";
import { useTranslate } from "../../../../context/translate/translate.context";
import CategorySeo from "../../../../lib/seo/CategorySeo";
import GameNews from "../../../blog/GameNews";
import BreadcrumbsNavigation from "../../../BreadcrumbsNavigation";
import CupIcon from "../../../svg/Cup";
import styles from "./styles.module.css";

const CategoryPage = () => {
  const { slug } = useParams();

  const theme = useTheme();
  const navigate = useNavigate();
  const { translate } = useTranslate();

  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState(null);

  // const [activeType, setActiveType] = useState(null);

  // get all types of a category

  const getCategory = async (slug) => {
    setLoading(true);
    try {
      const res = await getCategoryByNameAndType(slug);
      if (res.status === 200) {
        setCategory(res.data);

        // Navigate to first procuct type
        if (res.data?.productTypes?.length > 0) {
          navigate(`/games/${slug}/${res.data.productTypes[0].slug}?attr=all`);
        }
        //setActiveType(category?.productTypes?)
      }
    } catch (error) {
      navigate('/404')
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (slug) {
      getCategory(slug);
    }
  }, [slug]);

  const [activeType, setActiveType] = useState(null);

  const [latest5Blog, setLatest5Blog] = useState([]);

  const getLatest5Blog = async () => {
    try {
      const response = await getAllBlogPostsByCategory(1, 0, 4, 0);

      if (response.status === 200) {
        setLatest5Blog(response.data.data);
      }
    } catch (error) {
      navigate('/404')
    }
  };

  useEffect(() => {
    if (category?._id) {
      getLatest5Blog();
    }
  }, [category?._id]);

  const renderInfoContainer = ({ title, description }, index) => (
    <Box key={index} className={styles.infoItem}>
      <Typography variant="h2" className={styles.infoItemTitle}>
        {title}
      </Typography>

      <Typography variant="body1" className={styles.infoItemDescription}>
        {description}
      </Typography>
    </Box>
  );

  return (
    <Container className={styles.container}>
      <BreadcrumbsNavigation
        breadcrumbs={[
          { label: "navigation.home", path: "/" },
          { label: "navigation.games", path: "/games" },
          { label: category?.name },
        ]}
      />

      {/* SEO */}
      {category && activeType && (
        <CategorySeo category={category} activeType={activeType} />
      )}

      {/* Banner */}
      <Card
        className={styles.banner}
        sx={{
          backgroundImage: `url(${
            category?.banner || ""
          })`,
        }}
      />

      <Box className={styles.descriptionContainer}>
        {!!category?.productTypes?.length && (
          <Box className={styles.productTypes}>
            {category.productTypes.map((type) => (
              <Chip
                key={type._id}
                className={styles.chip}
                label={type.name}
                onClick={() => navigate(`/games/${category.slug}/${type.slug}`)}
              />
            ))}
          </Box>
        )}

        <Box className={styles.infoContainer}>
          <Typography variant="h1" className={styles.title}>
            {category?.name}
          </Typography>

          <Typography variant="body1" className={styles.description}>
            {category?.description}
          </Typography>
        </Box>
      </Box>

      <Box className={styles.divider} />

      {!!latest5Blog.length && (
        <GameNews
          title={translate("games.posts.title", { title: category.name })}
          posts={latest5Blog}
        />
      )}

      <Box className={styles.divider} />

      <Box className={styles.appInfo}>
        <Box className={styles.platformInfoTitleContainer}>
          <CupIcon
            color={theme.palette.mode === "dark" ? "#EDF67D" : "#8142f6"}
          />
          {category?.name && (
          <Typography className={styles.infoTitle}>
            {translate("games.info.title", { title: category?.name })}{" "}
            <span>Mentup</span>
          </Typography>
          )}
        </Box>

        {Array.from({ length: 6 }, () => ({
          title: "",
          description:
            ""})).map(renderInfoContainer)}
      </Box>
    </Container>
  );
};

export default CategoryPage;
