import React from 'react'
import styles from './styles.module.css'
import { Box, Container, Typography } from "@mui/material";
import { advantages, advantagesEn } from '../../constants/constants'
import Advantage from '../Advantage/Advantage';
import chest from '../../../../images/sellerPage/chest.png'
import { useTranslate } from '../../../../context/translate/translate.context'
import { useSelector } from 'react-redux/es/hooks/useSelector';



const Advantages= () => {
  const { translate } = useTranslate()
  const selectedLanguage = useSelector((state) => state.language);

  return (
    <Box className={styles.earnings}>
      <Container>
        <Typography className={styles.advantages__heading}>
          {translate('sellersPage.advantages.advantagesHeading')}
        </Typography>
        <Box className={styles.advantages}>
          {selectedLanguage === 'ru' ? 
          advantages.map((obj, index) => {
            return <Advantage icon={obj.icon} text={obj.text} key={index} />;
          })
          :
          advantagesEn.map((obj, index) => {
            return <Advantage icon={obj.icon} text={obj.text} key={index} />;
          })
          }
          
        </Box>
      </Container>
      <img src={chest} alt={translate('sellersPage.advantages.chestImgAlt')} className={styles.earnings__chest}/>
    </Box>
  )
}

export default Advantages