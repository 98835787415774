import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { sendStripeInvoice } from "../../../api/payments/telegram";
import { useTranslate } from "../../../context/translate/translate.context";
import { resetCart } from "../../../store/cartStore";
import styles from "./styles.module.css";

const TelegramSendInvoice = ({ clearCart, next, cart, setAlert }) => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();

  const sendInvoice = async () => {
    try {
      await Promise.all([
        await sendStripeInvoice(cart),
        new Promise((res) => setTimeout(res, 3000)),
      ]);

      if (clearCart) {
        dispatch(resetCart());
        localStorage.removeItem("cart");
      }

      next();
    } catch (error) {
      //console.log("error", error);
      setAlert({
        message:
          error.response?.data?.message ||
          translate("error404.somethingWentWrong"),
        severity: "error",
      });    }
  };

  useEffect(() => {
    sendInvoice();
  }, []);

  return (
    <Box className={styles.container}>
      <CircularProgress />

      <Typography variant="h5" className={styles.title}>
        {translate("checkout.stripe.steps.step3.title")}
      </Typography>
    </Box>
  );
};

export default TelegramSendInvoice;
