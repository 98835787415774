import axios from "axios";
import Cookies from "js-cookie";

const API = axios.create({
  baseURL: process.env.NODE_ENV.includes("production")
    ? process.env.REACT_APP_SERVER_URL
      ? process.env.REACT_APP_SERVER_URL
      : ""
    : "http://localhost:3030/api/",
});

export const deleteProductSeller = (productId, codes) => {
  const token = Cookies.get("refreshToken");

  if (!token) {
    return; // Early return if there's no valid token
  }

  if (productId) {
    return API.delete(`/item/seller/${productId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  }

  // if (codes && codes.length > 0) {
  //   const body = { imageNames: codes };
  //   return API.delete(`/image/deleteArray`, {
  //     data: body,
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${token}`,
  //     },
  //   });
  // }
};