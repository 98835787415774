import { throttle } from "lodash";
import { useCallback, useEffect } from "react";

const useThrottledResize = (
  callback,
  {
    target = typeof window !== "undefined" ? window : undefined,
    wait = 350,
    init = false,
    dependencies = [],
  } = {}
) => {
  const onResize = useCallback(throttle(callback, wait), dependencies);

  const removeEvent = useCallback(
    () => target.removeEventListener("resize", onResize),
    dependencies
  );

  useEffect(() => {
    if (init) {
      callback(null);
    }
  }, []);

  useEffect(() => {
    removeEvent();
    target.addEventListener("resize", onResize);

    return removeEvent;
  }, dependencies);

  return { removeEvent };
};

export default useThrottledResize;
