import * as React from "react"
const MobileGames = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="url(#a)"
      d="M5.438.003c.347-.01.7.007 1.047.007.974-.005 1.948-.004 2.922.003h1.476c.309 0 .64-.02.945.026a1.47 1.47 0 0 1 .984.615 1.315 1.315 0 0 1 .17.369 1.226 1.226 0 0 1 .04.263c.015.359.006.72.006 1.08l-.002 2.173.002 5.58-.002 3.324.003.907c.001.198.016.405-.014.6-.036.24-.202.487-.39.66-.293.272-.635.362-1.053.374l-4.402.007-1.147.005c-.276.001-.561.016-.834-.022a1.664 1.664 0 0 1-.368-.1 1.496 1.496 0 0 1-.431-.274 1.536 1.536 0 0 1-.183-.207 1.255 1.255 0 0 1-.196-.42 1.133 1.133 0 0 1-.03-.195c-.025-.314-.007-.637-.006-.952v-1.693l.002-5.456-.002-3.685-.003-1.127c0-.213-.017-.438.011-.65a1.33 1.33 0 0 1 .39-.748A1.63 1.63 0 0 1 5.438.003Z"
    />
    <path
      fill="#583B94"
      d="M9.218.828c.122 0 .22-.012.315.069.033.028.054.063.052.104-.002.04-.031.073-.067.097a.246.246 0 0 1-.111.044c-.067-.006-.131-.005-.198-.004.017-.102.01-.207.009-.31Z"
    />
    <path
      fill="url(#b)"
      d="M9.115 14.544c.106.15.16.3.108.475a.637.637 0 0 1-.337.375.773.773 0 0 1-.541.051.606.606 0 0 1-.179-.077.964.964 0 0 0 .492.015 1.032 1.032 0 0 0 .138-.051.737.737 0 0 0 .29-.25c.113-.178.088-.35.03-.538Z"
    />
    <path
      fill="#9C897A"
      d="M8.806.827c.138 0 .276-.003.414.001 0 .103.008.209-.009.31-.137.01-.278 0-.415-.006-.003-.101.005-.203.01-.305Z"
    />
    <path
      fill="#B0957A"
      d="m9.408 1.142-.028.003c-.239.024-.497 0-.738.001-.318.001-.666.033-.98.004a.434.434 0 0 1-.208-.063c-.032-.023-.057-.059-.053-.097.004-.053.049-.108.09-.146C7.637.79 8.568.826 8.806.827c-.005.101-.013.203-.01.305.137.005.279.016.415.006.067-.001.132-.002.198.004Z"
    />
    <path
      fill="url(#c)"
      d="M8.165 15.368a.732.732 0 0 1-.245-.205.528.528 0 0 1-.12-.455.59.59 0 0 1 .327-.375.711.711 0 0 1 .143-.054.927.927 0 0 1 .23-.031.842.842 0 0 1 .193.021c.18.04.325.139.421.275.06.187.084.36-.03.538a.643.643 0 0 1-.195.196.807.807 0 0 1-.268.114.964.964 0 0 1-.456-.024Z"
    />
    <path
      fill="url(#d)"
      d="M4.524 13.545c.083-.048.146-.13.202-.202.425-.546.815-1.118 1.209-1.682l1.098-1.545 3.911-5.526 1.092-1.566c.12-.174.284-.493.441-.627l.01 7.789v2.357c0 .395.012.792-.001 1.187l-7.965.001.003-.186Z"
    />
    <path
      fill="#6F5497"
      d="m4.53 2.213 7.951.005-.005.18c-.157.132-.32.452-.44.626L10.943 4.59l-3.912 5.526-1.098 1.545c-.394.563-.783 1.136-1.21 1.681-.055.072-.118.154-.201.203-.012-.39 0-.78.001-1.17l.002-2.137.005-8.025Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={13.9}
        x2={3.16}
        y1={8.589}
        y2={7.031}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDBA1A" />
        <stop offset={1} stopColor="#FFE115" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={8.847}
        x2={8.984}
        y1={15.155}
        y2={15.289}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#281E45" />
        <stop offset={1} stopColor="#3C344E" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={8.476}
        x2={8.476}
        y1={14.248}
        y2={15.41}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#533C8E" />
        <stop offset={1} stopColor="#3C227D" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={8.507}
        x2={8.507}
        y1={2.397}
        y2={13.731}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#533C8E" />
        <stop offset={1} stopColor="#3C227D" />
      </linearGradient>
    </defs>
  </svg>
)
export default MobileGames
