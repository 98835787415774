import { useTheme } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Container,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";

import {
  getAllProductCodesByProduct,
  updateProductCode,
} from "../../../../api/product/ProductCodes";
import { useTranslate } from "../../../../context/translate/translate.context";
import { isoToReadableDate } from "../../../../lib/isoToReadableDate";
import Button from "../../../UI/Button";
import ArrowLeft from "../../../svg/ArrowLeft";
import ConversationMessage from "../../../svg/ConversationMessage";
import { TranslateText } from "../../../translate-text";
import styles from "./styles.module.css";

export default function EditProductCodes() {
  const theme = useTheme();

  const { id } = useParams();
  const { translate } = useTranslate();

  const [pagination, setPagination] = useState({
    page: 0,
    limit: 7,
    sort: { updatedAt: -1 },
  });
  const [loading, setLoading] = useState(true);
  const [displayNoCodes, setDisplayNoCodes] = useState(false);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [focusedCode, setFocusedCode] = useState();

  const navigate = useNavigate()

  const formRef = useRef();

  const handleGoBack = () => {
    window.history.length > 1
      ? window.history.back()
      : (window.location.href = "/");
  };

  const getRequestedProduct = useCallback(async () => {
    setLoading(true);

    try {
      const response = await getAllProductCodesByProduct(id, pagination);
      setDisplayNoCodes(!response.data?.productCodes?.length);
      setItems(response.data?.productCodes || []);
      setTotalPages(response.data?.totalPages || 0);
    } catch (e) {
      //console.error(e);
      navigate('/404')
    } finally {
      setLoading(false);
    }
  }, [...Object.values(pagination)]);

  const updateProduct = useCallback(
    async (data) => {
      setFocusedCode(null);

      try {
        const response = await updateProductCode(focusedCode._id, data);
        if (response.data?._id) {
          setItems((prev) =>
            prev.map((item) =>
              item._id === response.data._id ? response.data : item
            )
          );
        }
      } catch (e) {
        navigate('/404')
      }
    },
    [focusedCode]
  );

  useEffect(() => {
    if (id) {
      getRequestedProduct();
    }
  }, [id, ...Object.values(pagination)]);

  const validationSchema = yup.object().shape({
    code: yup
      .string()
      .min(
        5,
        translate("products.productCodes.editModal.validationErrors.minimum")
      )
      .max(
        100,
        translate("products.productCodes.editModal.validationErrors.maximum")
      )
      .matches(
        /^[a-zA-Z0-9]+$/,
        translate(
          "products.productCodes.editModal.validationErrors.lettersNumbers"
        )
      )
      .required(
        translate("products.productCodes.editModal.validationErrors.required")
      ),
  });

  const {
    register: register,
    handleSubmit: handleSubmit,
    formState: { errors: errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  return (
    <Container sx={{ paddingTop: "20px", paddingBottom: "20px" }}>
      <Button
        color="white"
        startIcon={
          <ArrowLeft
            color={theme.palette.mode === "dark" ? "#F6F6F6" : "#1f1d22"}
          />
        }
        onClick={handleGoBack}
        aria-label={translate("products.productCodes.codesTable.back")}
      >
        <TranslateText value="products.productCodes.codesTable.back" />
      </Button>

      <Box className={styles.container}>
        <Typography className={styles.title}>
          <TranslateText value="products.productCodes.codesTable.title" />
        </Typography>

        <Box
          className={clsx(
            styles.noCodesContainer,
            !displayNoCodes && styles.hidden
          )}
        >
          <Typography className={styles.noCodesTitle}>
            <TranslateText value="products.productCodes.noCodesMessage" />
          </Typography>
        </Box>

        <TableContainer
          component={Paper}
          className={clsx(
            styles.table,
            loading && styles.tableLoading,
            (!items.length || displayNoCodes) && styles.hidden
          )}
        >
          <Table sx={{ minWidth: 700 }} aria-label="edit product codes table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  style={{ width: "64px", padding: "0" }}
                ></TableCell>
                <TableCell align="left">Код активации товара</TableCell>
                <TableCell align="left">Имя товара</TableCell>
                <TableCell align="left">Последнее обновление</TableCell>
                <TableCell align="left">Заявлено</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {items.map((item) => (
                <TableRow key={item._id}>
                  <TableCell align="center">
                    <ConversationMessage />
                  </TableCell>
                  <TableCell align="left">
                    {focusedCode?._id === item._id ? (
                      <form onSubmit={handleSubmit(updateProduct)}>
                        <TextField
                          id={`${focusedCode._id}-input`}
                          fullWidth
                          autoFocus
                          defaultValue={focusedCode.code}
                          margin="normal"
                          className={styles.codeInput}
                          error={errors.code && true}
                          helperText={errors.code?.message}
                          {...register("code")}
                          onBlur={() => formRef.current?.click?.()}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              formRef.current?.click?.();
                            }
                          }}
                        />
                        <Button
                          ref={formRef}
                          type="submit"
                          className={styles.submitButton}
                        />
                      </form>
                    ) : (
                      <Box
                        className={styles.codeText}
                        onDoubleClick={() => setFocusedCode(item)}
                      >
                        {item.code}
                      </Box>
                    )}
                  </TableCell>
                  <TableCell align="left">{item.product.name}</TableCell>
                  <TableCell align="left">
                    {isoToReadableDate(item.updatedAt).replaceAll("/", ".")}
                  </TableCell>
                  <TableCell align="left">
                    <TranslateText value={item.isClaimed ? "yes" : "no"} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {!!totalPages && (
          <Box className={styles.paginationContainer}>
            <Pagination
              count={totalPages}
              color="primary"
              onChange={(_, page) =>
                setPagination((prev) => ({ ...prev, page: page - 1 }))
              }
            />
          </Box>
        )}
      </Box>
    </Container>
  );
}
