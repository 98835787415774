import { useCallback, useEffect, useState } from "react";

import { createMessage, getMessages } from "../api/chat/MessageRequests";
import { uploadImage } from "../api/images/uploadImage";
import { useSocketProvider } from "../context/socket";

export const useMessages = (chat) => {
  const socket = useSocketProvider();

  const [items, setItems] = useState([]);
  const [chatId, setChatId] = useState();
  const [percent, setPercent] = useState({});
  const [loading, setLoading] = useState(false);
  const [typing, setTyping] = useState([]);

  const onNewMessage = useCallback(
    (message) => {
      if (message.chat._id === chatId) {
        setItems((prev) => [message, ...prev]);
      }
    },
    [chatId]
  );

  const initListeners = () => {
    socket.instance.current.on("messages/new", onNewMessage.bind(this));
    socket.instance.current.on(
      "messages/typing",
      ({ user, typing, chatId: typingChat }) => {
        if (typing && typingChat === chatId) {
          setTyping((prev) => [
            ...prev.filter(({ _id }) => _id !== user._id),
            user,
          ]);
        } else {
          setTyping((prev) => prev.filter((i) => i._id !== user._id));
        }
      }
    );
  };

  const registerTyping = (chatId) => {
    socket.instance.current.emit("messages/typing", chatId);
  };

  const init = async () => {
    setChatId(chat._id);
    setItems([]);

    setLoading(true);
    const response = await getMessages(chat._id);
    setItems(response.data);
    setLoading(false);
  };

  useEffect(() => {
    if (chat?._id) {
      init();
    }
  }, [chat?._id]);

  useEffect(() => {
    if (socket.inited) {
      initListeners();
    }
  }, [socket.inited]);

  const clear = () => {
    setItems([]);
    setLoading(false);
  };

  const create = useCallback(
    async (chatId, value, options = {}) => {
      const response = await createMessage(chatId, {
        value,
        ...options,
      });
      setItems((prev) => [response.data, ...prev]);
    },
    [chatId]
  );

  const createSupport = useCallback(
    async (chatId, value) => {
      await create(chatId, value, {
        isSupportQuestion: true,
      });
    },
    [chatId]
  );

  const uploadFile = async (file) => {
    if (!chatId || !file) {
      return;
    }
    const formData = new FormData();
    formData.append("image", file);

    const res = await uploadImage(formData);
    await create(chatId, "", {
      file: res.data.thumb,
      type: file.type.split("/")[0],
    });
  };

  return {
    typing,
    loading,
    items,
    percent,
    init,
    clear,
    create,
    uploadFile,
    createSupport,
    registerTyping,
  };
};
