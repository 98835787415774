import { useTheme } from "@emotion/react";
import { Box, Modal, IconButton, useMediaQuery } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import React from "react";

import styles from "./styles.module.css";

const MentupModal = ({ open, close, children, isBuyNow, sx = {} }) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Modal
      open={open}
      onClose={close}
      sx={{ transition: "height 2s ease-in-out", background: "#6334BB80" }}
      data-mui-color-scheme={theme.palette.mode}
      onClick={(e) =>  {
        if(isBuyNow) {
          e.stopPropagation()
        } else {
          close()
        }
      }}
    >
      <Box
        className={
          (isBuyNow && isMobile)
            ? styles.modalContainerBuyNow
            : styles.modalContainer
        }
        onClick={(e) => {
          if(isBuyNow){
            e.stopPropagation()
          }
        }}
      >
        <Box
          className={
            (isBuyNow && isMobile)
              ? styles.modalContentBuyNow
              : styles.modalContent
          }
          sx={{
            position: "relative",
            backgroundColor:
              theme.palette.mode === "dark"
                ? theme.palette.primary.fifth
                : theme.palette.primary.four,
            padding: isBuyNow ? "56px 32px 32px 32px" : "32px",
            ...sx,
             
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {/* Close Button */}
          {isBuyNow && (
          <IconButton
            onClick={close}
            className={styles.closeButton}
            sx={{
              color: theme.palette.text.primary,
              opacity: 0.8
            }}
          >
            <CloseIcon />
          </IconButton>
          )}

          {children}
        </Box>
      </Box>
    </Modal>
  );
};

export default MentupModal;
