import { useEffect, useRef } from "react";

import { createTelegramScript } from "../../../../../../../../hooks/useTelegramScript";

function initTelegramAuthLogin(options) {
  window.TelegramAuthLogin = options;
}

function TelegramLoginButton(props) {
  const hiddenDivRef = useRef(null);
  const scriptRef = useRef(null);

  useEffect(() => {
    // destroy the existing script element
    scriptRef.current?.remove();

    // init the global variable
    initTelegramAuthLogin({ onAuthCallback: props.onAuthCallback });

    // create a new script element and save it
    scriptRef.current = createTelegramScript(props);

    // add the script element to the DOM
    hiddenDivRef.current?.after(scriptRef.current);
  }, [props]);

  return <div ref={hiddenDivRef} hidden />;
}

export default TelegramLoginButton;
