const Telegram = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#8142F6"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.77998 18.65L10.06 14.42L17.74 7.49997C18.08 7.18997 17.67 7.03997 17.22 7.30997L7.73998 13.3L3.63998 12C2.75998 11.75 2.74998 11.14 3.83998 10.7L19.81 4.53997C20.54 4.20997 21.24 4.71997 20.96 5.83997L18.24 18.65C18.05 19.56 17.5 19.78 16.74 19.36L12.6 16.3L10.61 18.23C10.38 18.46 10.19 18.65 9.77998 18.65Z" />
  </svg>
);

export default Telegram;
