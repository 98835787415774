import axios from "axios";
import Cookies from "js-cookie";

const API = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL || "http://localhost:3030/api/",
});

const header = () => {
  const token = Cookies.get("refreshToken");
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
};

export const deliverProductApi = (orderInfo) => {
  if (
    orderInfo &&
    orderInfo.sellerOrderId &&
    orderInfo.productID &&
    orderInfo.message &&
    orderInfo.productStatus &&
    orderInfo.orderStatus
  ) {
    return API.post(
      "/order/itemSold/confirmOrderDelivered",
      orderInfo,
      header()
    );
  } else {
    throw new Error("Missing order information");
  }
};
