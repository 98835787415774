import { useCallback } from "react";

export * from "./alerts";

export function assetsPath(path) {
  const prefix =
    (process.env.BASE_PATH ?? "") + (path.at(0) === "/" ? "" : "/");
  return `${prefix}${path}`;
}

export const useServiceWorker = () => {
  if (typeof window !== "object") {
    return { getServiceWorker: () => Promise.resolve(null) };
  }

  const getWorker = useCallback(async (worker) => {
    const path = assetsPath(worker);
    const registration = await navigator.serviceWorker.getRegistration(path);
    if (registration) {
      return registration;
    }

    const newRegistration = await navigator.serviceWorker.register(path);
    return newRegistration;
  }, []);

  const getServiceWorker = useCallback(
    async (worker) => {
      const registration = await getWorker(worker);

      await registration.update();

      return registration;
    },
    [getWorker]
  );

  return { getServiceWorker };
};
