import { useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, IconButton, Typography } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useEffect, useState } from "react";

import {
  addTelegram,
  deleteTelegramInfo,
  getTelegramInfo,
} from "../../../../../../api/settings/telegram";
import { useTranslate } from "../../../../../../context/translate/translate.context";
import AlertComponent from "../../../../../AlertComponent";
import { TranslateText } from "../../../../../translate-text";
import DeleteConfirmContent from "./components/DeleteConfirmContent";
import TelegramLoginButton from "./components/TelegramBtn";
import styles from "./styles.module.css";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ConnectedAccounts = () => {
  const theme = useTheme();
  const { translate } = useTranslate();

  const [alertMessage, setAlertMessage] = useState("");
  const [snackBar, setSnackBar] = useState({
    open: false,
    vertical: "bottom",
    horizontal: "right",
    message: "",
  });
  const [loading, setLoading] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [telegramInfo, setTelegramInfo] = useState(null);

  const getTelegram = async () => {
    try {
      setLoading(true);
      const response = await getTelegramInfo();
      if (response.data.user) {
        setTelegramInfo(response.data.user);
      }
    } catch (error) {
      //console.log("error", error);
      setAlertMessage("Error while fetching telegram info");
    } finally {
      setLoading(false);
    }
  };

  const removeTelegram = async () => {
    try {
      setLoading(true);
      setConfirmDelete(false);
      await deleteTelegramInfo();
      setTelegramInfo(null);
    } catch (error) {
      //console.log("error", error);
      setAlertMessage("Error while deleting telegram info");
    } finally {
      setLoading(false);
    }
  };

  const onTelegramAuthCallback = async (data) => {
    try {
      if (data.error) {
        setAlertMessage(data.error);
      } else {
        await addTelegram(data);
        setSnackBar({
          open: true,
          vertical: "bottom",
          horizontal: "right",
          message: "Telegram account connected successfully",
        });
        window.location.reload();
      }
    } catch (error) {
      //console.log("error", error);
      setAlertMessage(data.error);
    }
  };

  useEffect(() => {
    getTelegram();
  }, []);

  return (
    <Box className={styles.container}>
      <Snackbar
        anchorOrigin={{
          vertical: snackBar.vertical,
          horizontal: snackBar.horizontal,
        }}
        open={snackBar.open}
        autoHideDuration={4000}
        onClose={() => setSnackBar({ ...snackBar, open: false })}
        key={snackBar.vertical + snackBar.horizontal}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          {snackBar.message}
        </Alert>
      </Snackbar>

      <AlertComponent
        message={alertMessage}
        resetMessageInParent={() => setAlertMessage("")}
      />

      <Box className={styles.passwordsContainer}>
        <Typography className={styles.changePasswordTitle}>
          <TranslateText value="vendorDashboard.settings.accounts.title" />
        </Typography>

        {!loading && (
          <Box
            sx={{
              display: "flex",
              gap: "12px",
              alignItems: "center",
              overflowY: "auto",
              padding: "24px 0px",
            }}
          >
            <Typography className={styles.changePasswordTitle}>
              Telegram:
            </Typography>

            {!!telegramInfo ? (
              <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
                <Button variant="contained" className={styles.connectedButton}>
                  {translate("vendorDashboard.settings.accounts.connectedAs", {
                    name: [telegramInfo.firstName, telegramInfo.lastName].join(
                      " "
                    ),
                  })}

                  {!!telegramInfo?.photoUrl && (
                    <img
                      src={telegramInfo?.photoUrl}
                      alt="Telegram profile picture"
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        marginLeft: "10px",
                      }}
                    />
                  )}
                </Button>

                <IconButton
                  sx={{
                    backgroundColor: `${theme.palette.action.disabled}`,
                  }}
                  onClick={() => setConfirmDelete(true)}
                  size="small"
                >
                  <CloseIcon sx={{ fontSize: "1.1rem" }} />
                </IconButton>

                <DeleteConfirmContent
                  open={confirmDelete}
                  close={() => setConfirmDelete(false)}
                  confirm={removeTelegram}
                />
              </Box>
            ) : (
              <TelegramLoginButton
                onAuthCallback={onTelegramAuthCallback}
                requestAccess="write"
                botUsername={process.env.REACT_APP_TELEGRAM_BOT_NAME}
                buttonSize="large" // "large" | "medium" | "small"
                cornerRadius={5} // 0 - 20
                showAvatar // true | false
                lang="en"
                widgetVersion={22}
              />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ConnectedAccounts;
