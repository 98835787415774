const ShareIcon = ({ color = "#F6F6F6" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill={color}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.7018 12.9558C22.715 12.9667 22.7282 12.9775 22.7415 12.9884L26.8661 16.3715C27.6357 17.0028 28.2737 17.526 28.7118 18.0011C29.1722 18.5003 29.5 19.0378 29.5 19.7029C29.5 20.3681 29.1722 20.9056 28.7118 21.4048C28.2737 21.8798 27.6357 22.4031 26.8661 23.0343L22.7415 26.4175C22.7282 26.4283 22.715 26.4392 22.7018 26.45C22.3684 26.7236 22.0572 26.9789 21.7884 27.1426C21.5245 27.3035 21.0445 27.5371 20.5067 27.3142C19.969 27.0914 19.8393 26.6052 19.7942 26.3163C19.7483 26.0221 19.7484 25.6379 19.7484 25.2262C19.7484 25.2099 19.7484 25.1936 19.7484 25.1772V23.6899C18.2569 23.7847 16.7454 24.1503 15.4233 24.7447C13.8686 25.4438 12.6269 26.435 11.9531 27.6193C11.7883 27.909 11.4305 28.0576 11.0862 27.9793C10.7419 27.9009 10.5 27.616 10.5 27.2888C10.5 22.8122 11.9195 19.8974 13.9492 18.1054C15.7183 16.5434 17.8745 15.9025 19.7484 15.7684V14.2287C19.7484 14.2123 19.7484 14.1959 19.7484 14.1796C19.7484 13.768 19.7483 13.3838 19.7942 13.0896C19.8393 12.8007 19.969 12.3144 20.5067 12.0916C21.0445 11.8688 21.5245 12.1024 21.7884 12.2632C22.0572 12.427 22.3684 12.6823 22.7018 12.9558ZM21.293 13.7072C21.4092 13.7991 21.5479 13.9124 21.7174 14.0515L25.7994 17.3996C26.6226 18.0748 27.178 18.5326 27.5379 18.9228C27.8834 19.2976 27.9586 19.5164 27.9586 19.7029C27.9586 19.8894 27.8834 20.1083 27.5379 20.483C27.178 20.8732 26.6226 21.331 25.7994 22.0063L21.7174 25.3544C21.5479 25.4934 21.4092 25.6067 21.293 25.6986C21.2902 25.5574 21.2898 25.3865 21.2898 25.1772V22.954C21.2898 22.5612 20.9448 22.2428 20.5191 22.2428C18.5538 22.2428 16.5114 22.6733 14.7481 23.4661C13.8039 23.8906 12.9249 24.4261 12.179 25.0669C12.558 22.1776 13.6778 20.3135 15.0148 19.1331C16.6795 17.6633 18.7829 17.163 20.5191 17.163C20.9448 17.163 21.2898 16.8446 21.2898 16.4519V14.2287C21.2898 14.0194 21.2902 13.8484 21.293 13.7072Z"
    />
  </svg>
);

export default ShareIcon;
