import {
    Container,
    Divider,
    Typography,
    List,
    ListItem,
  } from "@mui/material";
  
  const ProhibitedProducts = () => {
    return (
      <Container>
        <Typography variant="h4">Запрещенные товары</Typography>
        <Divider />
        <Typography variant="body2">
          На нашем маркетплейсе строго запрещены следующие категории товаров:
        </Typography>
        <List>
          <ListItem>1. Оружие, боеприпасы и их компоненты.</ListItem>
          <ListItem>2. Взрывчатые вещества, пиротехника.</ListItem>
          <ListItem>3. Наркотические вещества, прекурсоры, психотропные вещества.</ListItem>
          <ListItem>4. Средства самообороны (газовые баллончики, электрошокеры и др.).</ListItem>
          <ListItem>5. Оборудование для негласного получения информации.</ListItem>
          <ListItem>6. Лекарственные средства, медицинские изделия.</ListItem>
          <ListItem>7. Алкогольная продукция, табачные изделия, БАДы.</ListItem>
          <ListItem>8. Продукты питания, не соответствующие санитарным нормам.</ListItem>
          <ListItem>9. Животные и растения, запрещенные к обороту.</ListItem>
          <ListItem>10. Предметы, нарушающие авторские и смежные права.</ListItem>
          <ListItem>11. Документы, содержащие персональные данные.</ListItem>
          <ListItem>12. Товары, пропагандирующие насилие, дискриминацию, экстремизм.</ListItem>
          <ListItem>13. Любые иные товары, запрещенные к продаже законодательством РФ.</ListItem>
        </List>
      </Container>
    );
  };
  
  export default ProhibitedProducts;
  