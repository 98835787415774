import { useTheme } from "@emotion/react";
import { Box, Button, ClickAwayListener, Menu } from "@mui/material";
import { useState } from "react";

import ArrowDownSVG from "../../../../lib/svg/arrowDown";
import { TranslateText } from "../../../translate-text";
import styles from "./styles.module.css";

const FilterDropdown = ({ title, children }) => {
  const theme = useTheme();

  const [anchorSettings, setAnchorSettings] = useState(null);

  const handleOpenMenu = (event) => {
    setAnchorSettings(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorSettings(null);
  };

  return (
    <ClickAwayListener onClickAway={handleCloseMenu}>
      <Box>
        <Button
          color="secondary"
          className={styles.button}
          onClick={handleOpenMenu}
        >
          <TranslateText value={title} />
          <ArrowDownSVG
            color={theme.palette.mode === "dark" ? "#f6f6f6" : "#1f1d22"}
          />
        </Button>

        <Menu
          data-mui-color-scheme={theme.palette.mode}
          className={styles.userMenu}
          classes={{
            paper: styles.userMenu__paper,
            list: styles.userMenu__list,
          }}
          anchorEl={anchorSettings}
          open={Boolean(anchorSettings)}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          {children}
        </Menu>
      </Box>
    </ClickAwayListener>
  );
};

export default FilterDropdown;
