import { Box, Typography } from '@mui/material'
import React from 'react'
import styles from '../../styles.module.css'

const Advantage = ({icon, text}) => {
  return (
    <Box className={styles.advantage}>
        {icon}
        <Typography>{text}</Typography>
    </Box>
  )
}

export default Advantage
