import { useState } from "react";
import styles from "./styles.module.css";
import {
  Tabs,
  Tab,
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormLabel,
  FormHelperText,
} from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import clsx from "clsx";

const BlogTabs = ({ tabsArray, tabsOnChange, currentTab }) => {
  const [select, setSelect] = useState(1);

  const handleChange = (event) => {
    setSelect(event.target.value);
  };

  return (
    <Box className={styles.tabsBox}>
      <Box className={styles.tabsBox__categories}>
        <Typography className={styles.tabsSpan} color="GrayText">
          Категории:
        </Typography>
        <Tabs
          className={styles.tabs}
          onChange={tabsOnChange}
          value={currentTab}
          // orientation="vertical"
          variant='scrollable'
          scrollButtons='auto'
        >
          {tabsArray.map((category, idx) => {
            return (
              <Tab
                className={clsx(
                  styles.tabs__tab,
                  (currentTab = idx ? styles.tab_active : "")
                )}
                label={category.attributes.title}
                key={idx}
                sx={{ color: "white" }}
              />
            );
          })}
        </Tabs>
      </Box>
      <Box className={styles.tabsBox__sort}>
        <Typography className={styles.sort__text} color="GrayText">
          <SortIcon className={styles.sort__icon} /> Сортировать:
        </Typography>
        <FormControl>
          <Select
            sx={{
              boxShadow: "none",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
            }}
            disableUnderline
            className={styles.sortSelect}
            label="Сортировать"
            variant="standard"
            size="small"
            value={select}
            onChange={handleChange}
          >
            <MenuItem value={1}>По популярности</MenuItem>
            <MenuItem value={2}>По тегу</MenuItem>
            <MenuItem value={3}>По чему-то ещё</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default BlogTabs;
