import { Box, Chip, Typography } from "@mui/material";
import clsx from "clsx";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { CURRENCY_SIGN_BY_CURRENCY } from "../../../constants";
import { useTranslate } from "../../../context/translate/translate.context";
import styles from "./styles.module.css";

const Product = ({
  size = "small",
  _id,
  name,
  slug,
  user,
  media,
  thumb,
  price,
  discountedPrice,
  currency,
  productChars,
  promocode,
}) => {
  const { translate } = useTranslate();

  const [imageError, setImageError] = useState(false);

  const imageUrl = useMemo(() => {
    if (media && !imageError) {
      return media;
    }

    return "/images/product_placeholder.png";
  }, [media, imageError]);

  const thumbUrl = useMemo(() => {
    if (thumb && !imageError) {
      return thumb;
    }

    return null;
  }, [thumb, imageError]);

  return (
    <Box
      key={_id}
      className={clsx(styles.container, size)}
      component={Link}
      aria-label={translate("ariaLabel.navigateToCategory", {
        name,
      })}
      to={`/profile/${user.userSlug}/${slug}`}
    >
      <Box
        className={styles.imageContainer}
        component="img"
        src={thumbUrl !== null && thumbUrl !== undefined ? thumbUrl : imageUrl}
        onError={() => setImageError(true)}
      />
      <Box>
        <Typography className={styles.name}>{name}</Typography>
        <Box className={styles.chipsContainer}>
          {productChars.map((chip) => (
            <Chip key={chip} className={styles.chip} label={translate(chip)} />
          ))}
          {promocode && (
            <Chip
              className={clsx(styles.chip, styles.chip_background)}
              label={`-${promocode.discount}%`}
            />
          )}
        </Box>
        <Typography className={styles.price}>
          {discountedPrice ? discountedPrice : price}
          {CURRENCY_SIGN_BY_CURRENCY[currency || "USD"]}
        </Typography>
      </Box>
    </Box>
  );
};

export default Product;
