import * as React from "react";

const ArrowDownSVG = ({ color = "#F6F6F6", isRotated = false }) => (
  
  
  <svg 
    width="16" 
    height="16" 
    viewBox="0 0 16 16" 
    fill="none"
    style={{
      transform: isRotated ? "rotate(180deg)" : "rotate(0deg)",
      transition: "transform 0.6s ease", // Optional: Add a smooth transition
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.95437 5.67461C3.13408 5.46495 3.44973 5.44067 3.65939 5.62038L8.00066 9.34147L12.3419 5.62038C12.5516 5.44067 12.8672 5.46495 13.047 5.67461C13.2267 5.88428 13.2024 6.19993 12.9927 6.37964L8.32606 10.3796C8.13881 10.5401 7.86251 10.5401 7.67526 10.3796L3.0086 6.37964C2.79893 6.19993 2.77465 5.88428 2.95437 5.67461Z"
      fill={color}
    />
  </svg>
);
export default ArrowDownSVG;
