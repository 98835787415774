import { Avatar, Box, Typography } from "@mui/material";
import { useState } from "react";

import { useTranslate } from "../../../../../context/translate/translate.context";
import { formatISOTime } from "../../../../../utils/strings/time";
import ReviewsStars from "../../../../Reviews";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";

const Review = ({ createdAt, rating, text, user, title }) => {
  const { translate } = useTranslate();
  const [showFullText, setShowFullText] = useState(text.length < 200);

  const navigate = useNavigate();

  return (
    <Box className={styles.reviewContainer}>
      <Box className={styles.reviewContainer__header}>
        <Avatar 
          className={styles.reviewContainer__avatar} 
          alt="User avatar"
          src={user.avatar}  
          onClick={() => navigate(`/profile/${user.userSlug}`)}
        />
        <Box>
          <Typography className={styles.reviewContainer__username}>
            {user.username}
          </Typography>
          <ReviewsStars size="small" rating={rating} />
        </Box>
        <Typography className={styles.reviewContainer__time}>
          {formatISOTime(createdAt)}
        </Typography>
      </Box>
      {title && (
        <Typography className={styles.reviewContainer__title}>
          {title}
        </Typography>
      )}
      <Typography className={styles.reviewContainer__text}>
        {showFullText ? text : `${text.slice(0, 200)}...`}
      </Typography>
      {!showFullText && (
        <Typography
          className={styles.reviewContainer__readFull}
          component="a"
          aria-label="Show full text"
          onClick={() => setShowFullText(true)}
        >
          {translate("products.productPage.reviews.readFull")}
        </Typography>
      )}
    </Box>
  );
};

const ProductReviews = ({ reviews }) => {
  const { translate } = useTranslate();
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <Box className={styles.container}>
      <Typography className={styles.container__title} variant="h3">
        {translate("products.productPage.reviews.title")}
        <Typography className={styles.container__reviewsCount} component="span">
          {reviews.length}
        </Typography>
      </Typography>

      {reviews.slice(currentIndex, currentIndex + 6).map((review) => (
        <Review key={review._id} {...review} />
      ))}
      <Box className={styles.container__buttonsContainer}>
        {currentIndex > 0 && (
          <Typography
            className={styles.container__button}
            component="a"
            aria-label={translate(
              "products.productPage.reviews.showPreviousReviews"
            )}
            onClick={() => setCurrentIndex((prev) => prev - 6)}
          >
            {translate("products.productPage.reviews.showPreviousReviews")}
          </Typography>
        )}
        {reviews.length > currentIndex + 6 && (
          <Typography
            className={styles.container__button}
            component="a"
            aria-label={translate(
              "products.productPage.reviews.showNextReviews"
            )}
            onClick={() => setCurrentIndex((prev) => prev + 6)}
          >
            {translate("products.productPage.reviews.showNextReviews")}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ProductReviews;
