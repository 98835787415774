import { Box, IconButton, Typography } from "@mui/material";
import { useTranslate } from "../../../../context/translate/translate.context";

import styles from "./styles.module.css";
import Telegram from "../../../svg/socials/Telegram";
import YouTube from "../../../svg/socials/YouTube";
import Instagram from "../../../svg/socials/Instagram";
import Facebook from "../../../svg/socials/Facebook";
import FOOTER_LINKS from "../constants";
import { Link, useNavigate } from "react-router-dom";
import clsx from "clsx";

const Socials = ({ className , from}) => {
  const { translate } = useTranslate();

  const navigate = useNavigate();

  return (
    <Box className={clsx(styles.container, className)}>
      {!from && (
      <Typography className={styles.text}>
        {translate("footer.findOutMore")}:
      </Typography>
      )}
      <Box className={styles.icons}>
        {FOOTER_LINKS.socials.map(({ path, icon }, index) => (
          <IconButton
            key={index}
            className={styles.button}
            component={Link}
            to={path}
            target="_blank"
          >
            {icon}
          </IconButton>
        ))}
      </Box>
    </Box>
  );
};

export default Socials;
