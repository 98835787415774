import React from "react";
import styles from "./styles.module.css";

import { Box, Container, Typography } from "@mui/material";
import { useSelector } from "react-redux/es/hooks/useSelector";
import trustpilot from "../../../../images/sellerPage/trustpilot.png";
import { useTranslate } from "../../../../context/translate/translate.context";

const GamerShop = () => {
  const selectedLanguage = useSelector((state) => state.language);
  const { translate } = useTranslate();

  return (
    <Box className={styles.gamerShop}>
      <Container>
        <img src={trustpilot} alt="" className={styles.gamerShop__trustpilot} />
        {selectedLanguage === "ru" ? (
          <Typography className={styles.gamerShop__heading}>
            Магазин для геймеров -{" "}
            <Typography variant="p" className={styles.gamerShop__headingSpan}>
              MENTUP
            </Typography>
          </Typography>
        ) : (
          <Typography className={styles.gamerShop__heading}>
            Store for gamers -{" "}
            <Typography variant="p" className={styles.gamerShop__headingSpan}>
              MENTUP
            </Typography>
          </Typography>
        )}
        <Typography className={styles.gamerShop__subheading}>
          {translate("aboutUsPage.GamerShop.gamerShopSubheading")}
        </Typography>
        <Typography className={styles.gamerShop__text}>
          {translate("aboutUsPage.GamerShop.gamerShopText")}
        </Typography>
      </Container>
    </Box>
  );
};

export default GamerShop;
