import {
  Box,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import styles from "./styles.module.css";
import { useTranslate } from "../../../context/translate/translate.context";
import { LogoNew } from "../../logo/LogoNew";
import { Link } from "react-router-dom";
import { UiThemeSwitchContainer } from "../../../lib/theme";
import LanguageSelector from "../../langPage/LanguageSelector";
import { useDispatch, useSelector } from "react-redux";
import { setTheme } from "../../../store/userStore";
import FOOTER_LINKS from "./constants";
import Socials from "./Socials";
import Payments from "./Payments";
import clsx from "clsx";

const Footer = ({ customFooter }) => {
  const { translate } = useTranslate();

  const theme = useTheme();

  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const currentUser = user._id
    ? user
    : JSON.parse(localStorage.getItem("user"));

  const { main } = FOOTER_LINKS;

  return (
    <Box
      data-mui-color-scheme={customFooter ? "dark" : theme.palette.mode}
      className={clsx(styles.footer, customFooter && styles.footer_custom)}
      component="footer"
    >
      <Container className={styles.footerContainer}>
        <Box
          className={styles.logoBlock}
          component={Link}
          to={"/"}
          aria-label="Homepage"
        >
          <LogoNew />
          <Box>
            <Typography variant="h1" className={styles.heading}>
              {translate("title")}
            </Typography>
            <Typography className={styles.subheading}>
              {translate("header.subTitle")}
            </Typography>
          </Box>
        </Box>
        <Box className={styles.linksBlock}>
          {main.map(({ label, path }) => (
            <Box
              key={label}
              className={styles.link}
              component={Link}
              to={path}
              aria-label={translate(label)}
            >
              {translate(label)}
            </Box>
          ))}
        </Box>
        {!customFooter && (
          <Box className={styles.moreBlock}>
            <Socials />
            <Payments />
          </Box>
        )}
        <Box className={styles.actionsBlock}>
          <UiThemeSwitchContainer
            userData={currentUser}
            dispatchNewUserTheme={(theme) => {
              dispatch(setTheme(theme));
            }}
            theme={theme?.palette?.mode}
          />
          <LanguageSelector />
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
