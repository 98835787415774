import axios from 'axios';

const API = axios.create({
    baseURL: process.env.NODE_ENV.includes('production')
        ? process.env.REACT_APP_SERVER_URL
            ? process.env.REACT_APP_SERVER_URL
            : ''
        : 'http://localhost:3030/api/',
});

export const emailVerify = (data) => {
    return API.post('/auth/emailVerify', data);
};
export const registerComplete = (data) => {
    return API.post('/auth/register/complete', data);
};

export const vkVerify = (data) => {
    return API.post('/auth/vk-verify', data);
};

export const verifyGoogle = (data) => {
    return API.post('/auth/google-verify', data);
};

export const registerGoogle = (data) => {
    return API.post('/auth/google-register', data);
};

export const registerVK = (data) => {
    return API.post('/auth/vk-verify', data);
};
