import { Box } from "@mui/material";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import styles from "./styles.module.css";

const ProductCarousel = ({ images = [] }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <Box className={styles.container}>
      {images.length > 1 && (
        <Swiper
          direction="horizontal"
          slidesPerView="5"
          spaceBetween={36}
          centerInsufficientSlides
          breakpoints={{
            900: {
              direction: "vertical",
              slidesPerView: 4,
              spaceBetween: 20,
              centerInsufficientSlides: false,
            },
          }}
        >
          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <Box className={`${styles.imageWrapper} ${index === activeIndex ? styles.activeImage : ''}`}>
                <Box
                  component="img"
                  src={image}
                  onMouseEnter={() => setActiveIndex(index)}
                  className={styles.image}
                  onClick={() => setActiveIndex(index)}
                />
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      <Box className={styles.previewWrapper}>
        <Box
          className={styles.preview}
          component="img"
          src={
            !images.length
              ? "/images/product_placeholder.png"
              : images[activeIndex]
          }
        />
      </Box>
    </Box>
  );
};

export default ProductCarousel;
