import { Box, Typography } from "@mui/material";

import { TranslateText } from "../../../../../translate-text";
import styles from "./styles.module.css";
import ProductCard from "../../../../../ProductCard";

const AddProductModalSuccess = ({ product }) => {
  return (
    <Box className={styles.form}>
      <Typography
        variant="addProductModalHeading"
        sx={{ fontWeight: "bold", fontSize: "1.6rem" }}
      >
        <TranslateText value="addProductModal.modal5.title" />
      </Typography>

      <ProductCard
        key={product._id}
        variant="small"
        product={product}
        hideOptions
      />
    </Box>
  );
};

export default AddProductModalSuccess;
