const CIS_COUNTRIES = ['AM', 'AZ', 'BY', 'EE', 'GE', 'KZ', 'KG', 'LV', 'LT', 'MD', 'RU', 'TJ', 'TM', 'UA', 'UZ'];

const CURRENCY_SIGN_BY_COUNTRY = CIS_COUNTRIES.reduce((acc, country) => {
  acc[country] = '₽';  // Assigning Ruble symbol to each CIS country
  return acc;
}, { "US": "$" }); // Adding US with the Dollar sign separately

const CURRENCY_SIGN_BY_CURRENCY = {
  'RUB': '₽',
  'USD': '$',
}

const WorkerName = {
  Alerts: '/scripts/alerts.js',
}


module.exports = {
  WorkerName,
  CIS_COUNTRIES,
  CURRENCY_SIGN_BY_COUNTRY,
  CURRENCY_SIGN_BY_CURRENCY
}