import { Box, Typography } from "@mui/material";
import Cookies from "js-cookie";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { logInOtp } from "../../../api/auth/login";
import { useSocketProvider } from "../../../context/socket";
import { useTranslate } from "../../../context/translate/translate.context";
import { setUserSettings } from "../../../store/userSettingsStore";
import { setUser } from "../../../store/userStore";
import AlertComponent from "../../AlertComponent";
import Button from "../../UI/Button";
import Wrapper from "../Wrapper";
import LoginStep1 from "./Step1";
import LoginStep2 from "./Step2";
import styles from "./styles.module.css";

import { NavLink } from "react-router-dom";

const Login = () => {
  const { translate } = useTranslate();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const socket = useSocketProvider();
  const userRedux = useSelector((state) => state.user);

  // loading and alerts
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);

  // Login data
  const [loginData, setLoginData] = useState();

  // Step
  const [step, setStep] = useState(0);

  const [isRegister, setIsRegister] = useState(false)

  const [isRegisterGoogle, setIsRegisterGoogle] = useState(false)

  // On Submit Login
  const onSubmit = async (otp) => {
    try {
      setLoading(true);

      const loginRes = await logInOtp({ ...loginData, otp });
      if (loginRes.status !== 200) {
        setAlertMessage(
          translate(
            loginRes.data.invalidOtp
              ? "errorMessages.invalidOtp"
              : loginRes.data.otpExpired
              ? "errorMessages.otpExpired"
              : "errorMessages.unexpectedError"
          )
        );
        return;
      }
      const user = loginRes.data.user;
      const userSettings = loginRes.data.userSettings;

      if (user && userSettings) {
        localStorage.setItem("userSettings", JSON.stringify(userSettings));
        localStorage.setItem("user", JSON.stringify(user));

        dispatch(setUser(user));
        dispatch(setUserSettings(userSettings));
        const maxDate = new Date(2147483647 * 1000); // Convert seconds to milliseconds
        Cookies.set("refreshToken", userSettings.refreshToken, {
          expires: maxDate
        });
        const token = userSettings.refreshToken;
        socket.authenticate(token);
      } 
      if (location.href.includes("from=cart")) {
        navigate("/checkout");
        return;
      }

      navigate(`/profile/${user.userSlug}`);
      // yandex metrika
      ym(97684982,'reachGoal','open')
    } catch (err) {
      setAlertMessage(translate("errorMessages.unexpectedError"));
    } finally {
      setLoading(false);
    }
  };

  const logInFromRegister = useCallback(({ data: { user, userSettings } }) => {

    const token = userSettings.refreshToken;

    if (user && userSettings) {
      dispatch(setUser(user));
      dispatch(setUserSettings(userSettings));

      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("userSettings", JSON.stringify(userSettings));

      if (token) {
        const maxDate = new Date(2147483647 * 1000); // Convert seconds to milliseconds
        Cookies.set("refreshToken", token, {
          expires: maxDate
        });
        setTimeout(() => {
          ym(97684982,'reachGoal','registraciya')
          navigate("/profile/" + user.userSlug, { replace: true });
        }, 2000);
      }
    }
  }, []);

  const next = () => {
    setStep((prev) => prev + 1);
  };

  const previous = () => {
    setStep((prev) => prev - 1);
  };


  return (
    <Wrapper>
      <AlertComponent
        message={alertMessage}
        resetMessageInParent={() => setAlertMessage(null)}
      />
      <Box className={styles.buttons}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 'semibold'
          }}
        >
          {translate("authentication.login") + ' / ' + translate("authentication.register")}
        </Typography>
        {/*
        <Button
          size="medium"
          className={styles.buttons__faded}
          color="secondary"
          disabled={loading}
          onClick={() => navigate("/register")}
        >
          {translate("authentication.register")}
        </Button>
        */}
      </Box>

      {step === 0 && (
        <LoginStep1
          setLoginData={setLoginData}
          setAlertMessage={setAlertMessage}
          next={next}
          setIsRegister={() => setIsRegister(true)}
          setIsRegisterGoogle={() => setIsRegisterGoogle(true)}
        />
      )}
      {step === 1 && (
        <LoginStep2
          loginData={{
            ...loginData,
            ip: userRedux.ip,
            country: userRedux.country,
          }}
          setAlertMessage={setAlertMessage}
          setLoginData={setLoginData}
          next={next}
          logIn={onSubmit}
          logInFromRegister={logInFromRegister}
          isRegister={isRegister}
          isRegisterGoogle={isRegisterGoogle}
          country={userRedux.country}
        />
      )}
      <Typography className={styles.form__confirmation}>
        {translate("authentication.byRegisteringYouAgree")}{" "}
        <Box component={NavLink} to="/info/laws/license-agreement">
          {translate("authentication.licenseAgreement")}
        </Box>
        ,{" "}
        <Box component={NavLink} to="/info/laws/privacy-policy">
          {translate("authentication.privacyPolicy")}
        </Box>{" "}
        {translate("authentication.and")}{" "}
        <Box component={NavLink} to="/info/laws/rules">
          {translate("authentication.rules")}
        </Box>
      </Typography>
    </Wrapper>
  );
};

export default Login;
