import Edit from "../../../svg/Alert";
import Delete from "../../../svg/Delete";
import Disable from "../../../svg/Disable";
import EditCodes from "../../../svg/EditCodes";
import Statistics from "../../../svg/Statistics";

export const ProductMenuOptions = [
  {
    label: "edit",
    icon: <Edit />,
    lightIcon: <Edit color="#1f1d22" />,
  },
  {
    label: "editCodes",
    icon: <EditCodes />,
    lightIcon: <EditCodes color="#1f1d22" />,
  },
  {
    label: "delete",
    icon: <Delete />,
    lightIcon: <Delete color="#1f1d22" />,
  },
  {
    label: "disable",
    icon: <Disable />,
    lightIcon: <Disable color="#1f1d22" />,
  },
  {
    label: "promote",
    icon: <Statistics />,
    lightIcon: <Statistics color="#1f1d22" />,
  },
];
