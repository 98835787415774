const Arrow = ({ color = "#f6f6f6" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill={color}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4697 6.08149C13.7626 5.78859 14.2374 5.78859 14.5303 6.08149L20.5303 12.0815C20.8232 12.3744 20.8232 12.8493 20.5303 13.1421L14.5303 19.1421C14.2374 19.435 13.7626 19.435 13.4697 19.1421C13.1768 18.8493 13.1768 18.3744 13.4697 18.0815L18.1893 13.3618H4C3.58579 13.3618 3.25 13.026 3.25 12.6118C3.25 12.1976 3.58579 11.8618 4 11.8618H18.1893L13.4697 7.14215C13.1768 6.84925 13.1768 6.37438 13.4697 6.08149Z"
    />
  </svg>
);

export default Arrow;
