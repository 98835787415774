import "swiper/css";

import { Box, Container, Typography } from "@mui/material";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";

import { useTranslate } from "../../../context/translate/translate.context";
import Bestsellers from "./Bestsellers";
import Blog from "./Blog";
import Contacts from "./Contacts";
import FeaturedProducts from "./FeaturedProducts";
import Games from "./Games";
import Hero from "./Hero";
import styles from "./styles.module.css";

const HomePage = () => {

  return (
    <>
      <Box className={styles.section} component="section">
        <Hero />
      </Box>
      
      <Box className={styles.section} component="section">
        {/*
        <Container className={styles.descriptionContainer}>
          <Box className={clsx(styles.description, styles.description__first)}>
            <Typography className={styles.description__title}>
              {translate("home.description.first.title")}
            </Typography>
            <Typography className={styles.description__text}>
              {translate("home.description.first.text")}
            </Typography>
          </Box>
          <Box className={clsx(styles.description, styles.description__second)}>
            <Typography className={styles.description__title}>
              {translate("home.description.second.title")}
            </Typography>
            <Typography className={styles.description__text}>
              {translate("home.description.second.text")}
            </Typography>
          </Box>
          <Box className={clsx(styles.description, styles.description__third)}>
            <Typography className={styles.description__title}>
              {translate("home.description.third.title")}
            </Typography>
            <Typography className={styles.description__text}>
              {translate("home.description.third.text")}
            </Typography>
          </Box>
        </Container>
        */}

        <Games />
        <FeaturedProducts />
        <Bestsellers />
          {/*
        <Blog />
        
        <Contacts />
        */}
      </Box>
    </>
  );
};

export default HomePage;
