import axios from "axios";

const API = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL || "http://localhost:3030/api/",
});

export const getAllTypeAttrsByTypeId = (typeId, categoryId) => 
  API.get(
    `/typeAttributes/productType/${typeId}/${categoryId}`
);

export const getAllTypeAttrs = () =>
  API.get(
    `/typeAttributes/all`,
  );

