import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { firebaseAuth } from "../context/socket";

export const useGoogle = () => {
  const provider = new GoogleAuthProvider();

  const auth = async () => {
    try {
      const result = await signInWithPopup(firebaseAuth, provider);
      const idToken = await result.user.getIdToken();

      return idToken;
    } catch (err) {
      //console.error(err);
    }
  };

  return { auth };
};
