import { Box, Typography, useTheme } from "@mui/material"
// import link from react router dom
import { Link } from 'react-router-dom';

const Terms = () => {
    const theme = useTheme();
  return (
    <Box>
        <Typography variant="h6" gutterBottom>
        Пользовательское соглашение
        </Typography>

        <Typography variant="h6" gutterBottom>
        Термины и понятия
        </Typography>

        <Typography paragraph>
        Для целей настоящего Соглашения перечисленные ниже термины имеют следующие значения:
        </Typography>

        <Typography>
        <strong>Администрация сайта (далее - MentUp)</strong> - уполномоченные сотрудники на управление Сайтом, действующие от имени Индивидуального предпринимателя Согоян Армине Михайловна (ИНН 344223391197).
        </Typography>

        <Typography>
        <strong>Акцепт</strong> - полное и безоговорочное принятие Пользователем условий доступа к сервису на сайте. Надлежащим акцептом настоящего Соглашения и подтверждением согласия Пользователя с его условиями считается использование Сервиса любым Пользователем, включая просмотр сайта Сервиса либо скачивание мобильных приложений Сервиса, размещение какой-либо информации в рамках Сервиса, передача ссылки на Сервис любым третьим лицам, но не ограничиваясь указанным.
        </Typography>

        <Typography>
        <strong>Модератор</strong> - сотрудник MentUp, обрабатывающий сообщения пользователей, а также обеспечивающий соблюдение Пользователями условий настоящего Соглашения.
        </Typography>

        <Typography>
        <strong>Объявление</strong> - предложение о покупке или продаже товара (услуги), размещенное Пользователем на Сайте https://mentup-store.com в соответствии с настоящим Договором.
        </Typography>

        <Typography>
        <strong>Пользователь</strong> - лицо, имеющее доступ к Сайту, посредством сети интернет и использующее Сайт.
        </Typography>

        <Typography>
        <strong>Сайт</strong> - это интернет-ресурс, находящийся по адресу https://mentup-store.com (включая все уровни домена), представляющий собой совокупность содержащихся в информационной системе объектов интеллектуальной собственности (в том числе, база данных, графическое оформление интерфейса) и информации (Пользовательского контента). Ресурс представляет собой электронный каталог Объявлений о товарах и услугах, которые пользователи могут предлагать, просматривать и искать на Сайте с целью заключения сделок.
        </Typography>

        <Typography>
        <strong>Сделка</strong> - действия Пользователей направленные на продажу, приобретение товаров в соответствии с условиями настоящего соглашения.
        </Typography>

        <Typography>
        <strong>Товар</strong> - любой цифровой, нематериальный товар или услуга, в отношении которых Пользователь размещает Объявление через Сервис.
        </Typography>

        <Typography>
        <strong>Сервис</strong> - комплекс услуг, предоставляемых пользователю с использованием сайта, программные инструменты, обеспечивающие для Пользователей возможность размещения, просмотра и поиска Товаров, заключения сделок между Пользователями и их исполнение.
        </Typography>

        <Typography>
        <strong>Соглашение</strong> – настоящее Пользовательское соглашение об использовании Сайта, заключаемое между MentUp и Пользователем.
        </Typography>

        <Typography variant="h6" gutterBottom>
        1. Общие положения
        </Typography>

        <Typography paragraph>
        1.1. Настоящее соглашение определяет условия использования Пользователями маркетплейса игровых товаров и услуг – MentUp-store.
        </Typography>

        <Typography paragraph>
        1.2. Сайт представляет собой площадку для самостоятельного размещения Пользователями предложений о продаже Товаров и совершения сделок купли-продажи дистанционным способом в отношении данных Товаров.
        </Typography>

        <Typography>
        1.3. Доступ к Сайту предоставляется на бесплатной основе.
        </Typography>

        <Typography>
        1.4. MentUp не осуществляет реализацию товаров в рамках настоящего Соглашения.
        </Typography>

        <Typography>
        1.5. Настоящее Соглашение является публичной офертой в соответствии со ст. 437 Гражданского кодекса Российской Федерации и представляет собой предложение, содержащее все существенные условия договора, из которого усматривается воля MentUp, делающего предложение Пользователю заключить договор в отношении использования Сайта на предложенных MentUp условиях в соответствии с настоящим Соглашением.
        </Typography>

        <Typography>
        1.6. Использование сервисов Сайта регулируется настоящим Соглашением, а также Политикой обработки персональных данных (размещенная по адресу{' '}
        <Typography
            component={Link}
            to="https://mentup-store.com/privacy"
            sx={{ color: theme.palette.mode === 'dark' ? 'darkgray' : 'inherit', textDecoration: 'none' }}
        >
            https://mentup-store.com/privacy
        </Typography>
        ), которая является неотъемлемой частью настоящего Соглашения.
        </Typography>

        <Typography>
        1.7. Осуществляя доступ к Сайту и заключая таким образом настоящее Соглашение, Пользователь гарантирует, что обладает всеми правами и полномочиями, необходимыми для заключения и исполнения настоящего Соглашения.
        </Typography>

        <Typography>
        1.8. Пользователь принимает положение о том, что информация на Сайта может сопровождаться рекламой. Пользователь согласен с тем, что MentUp не имеет каких-либо обязательств и не несет какой-либо ответственности в связи с такой рекламой.
        </Typography>

        <Typography>
        1.9. Любые составляющие части Сайта, включая текстовый, видеоконтент и изображения могут быть свободно просмотрены, не могут быть скопированы и распространены каким-либо способом.
        </Typography>

        <Typography variant="h6" gutterBottom>
        2. Предмет соглашения
      </Typography>

      <Typography paragraph>
        2.1. Предметом настоящего соглашения является регулирование отношений между MentUp и Пользователями, предоставление Пользователю доступа к содержащимся на Сайте товарам и предоставление пользователю функциональных возможностей приобретать Товары.
      </Typography>

      <Typography>
        2.2. Данное соглашение распространяется на все виды Товаров и Сервисов, представленные на сайте.
      </Typography>

      <Typography paragraph>
        2.3. MentUp не несет ответственности за товары, реализуемые пользователями, а также за их соответствие размещенному в объявлениях Продавцов описанию. Пользователь настоящим соглашается, что любые претензии относительно товара подлежат направлению тому Продавцу, чей Товар был приобретен на Сайте.
      </Typography>

      <Typography variant="h6" gutterBottom>
        3. Регистрация на сайте
      </Typography>

      <Typography paragraph>
        3.1. Для полноценного использования функций и сервисов Сайта Пользователь обязан создать учетную запись (аккаунт) путем регистрации на Сайте.
      </Typography>

      <Typography>
        3.2. При регистрации Пользователь обязуется предоставить достоверные и полные личные данные, а также дает свое согласие на обработку персональных данных в соответствии с политикой MentUp (
        <Link
          href="https://mentup-store.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ color: theme.palette.mode === 'dark' ? 'darkgray' : 'inherit', textDecoration: 'none' }}
        >
          https://mentup-store.com/privacy
        </Link>
        ).
      </Typography>

      <Typography paragraph>
        3.3. Пользователь несет ответственность за безопасность своей учетной записи и пароля, а также за все действия, совершенные с использованием его учетных данных. Настоятельно рекомендуем не сообщать пароль от вашего аккаунта посторонним и не использовать его на других сайтах.
      </Typography>

      <Typography>
        3.4. Любое действие, совершенное из Личного кабинета Пользователя с использованием регистрационных данных, считается действием, совершенным самим Пользователем или уполномоченным им лицом и устанавливает обязанности и ответственность для Пользователя в отношении таких действий, включая ответственность за нарушение настоящего Соглашения.
      </Typography>

      <Typography paragraph>
        3.5. Пользователь обязан незамедлительно уведомить MentUp в случае несанкционированного доступа к его учетной записи или иного нарушения безопасности.
      </Typography>

      <Typography>
        3.6. MentUp оставляет за собой право отказать в регистрации или заблокировать учетную запись Пользователя по своему усмотрению без объяснения причин.
      </Typography>

      <Typography paragraph>
        3.7. Пользователь вправе в любой момент удалить свою учетную запись через обращение в техническую поддержку MentUp (support@mentup-store.com).
      </Typography>

      <Typography variant="h6" gutterBottom>
        4. Порядок работы Сайта
      </Typography>

      <Typography>
        4.1. Стоимость Товара на Сайте указывается в российских рублях.
      </Typography>

      <Typography paragraph>
        4.2. Пользователь может провести оплату по безналичному расчету.
      </Typography>

      <Typography>
        4.3. При осуществлении платежа Пользователь обязуется следовать платежным инструкциям по порядку и способам оплаты, в том числе и по правилам ввода сообщения и номера коротких текстовых сообщений (SMS), включая порядок ввода заглавных и строчных букв, цифр и языка ввода.
      </Typography>

      <Typography paragraph>
        4.4. MentUp не производит разъяснений Пользователям вопросов, связанных с правилами и порядком использования платежных систем, а также не производит компенсацию денежных средств Пользователю, который осуществил операции по оплате Товара посредством платежных систем.
      </Typography>

      <Typography>
        4.5. Оплатить Товар можно банковскими картами Visa, Master Card, «Мир» или через платежные системы QIWI Кошелек, ЮMoney. Для оплаты Товара, Пользователь будет перенаправлен на сервер платежной системы для ввода необходимых данных.
      </Typography>

      <Typography paragraph>
        4.6. Денежные средства Пользователя учитываются на его балансе в Личном кабинете. Ваши конфиденциальные данные, необходимые для оплаты (реквизиты карты, регистрационные данные и др.), не поступают на Сайт — их обработка производится на стороне процессингового центра платежной системы.
      </Typography>

      <Typography>
        4.6. Обязанность по оплате Товара между Пользователями считается исполненной Покупателем в момент зачисления денежных средств на баланс Продавца на Сайте.
      </Typography>

      <Typography paragraph>
        4.7. Расчеты между Пользователями по Сделке производятся исключительно с помощью Сервиса на Сайте. Расчеты за пределами Сайта запрещены.
      </Typography>

      <Typography>
        4.8. Плата за использование Сервиса для Продавцов рассчитывается в виде процента от суммы денежных средств, которые Покупатель оплачивает за Товар. Процент устанавливается автоматически.
      </Typography>

      <Typography paragraph>
        4.9. После выполнения Продавцом условий по передаче Товара, а также предоставления подтверждений о легальности реализуемого Товара, денежные средства списываются с баланса Покупателя и зачисляются на баланс Продавца.
      </Typography>

      <Typography>
        4.10. Если Покупатель не получил Товар или полученный Товар не соответствует описанию в Объявлении, то обеспечительный платеж возвращается на баланс Покупателя на Сайте.
      </Typography>

      <Typography paragraph>
        4.11. Запрос о возмещении денежных средств возможен в случае ошибки или дефекта в работе Сайта, например: сбой программного обеспечения, который повлек за собой неполучение цифрового товара.
      </Typography>

      <Typography variant="h6" gutterBottom>
        5. Обязанности и права Пользователя
      </Typography>

      <Typography>
        5.1. Пользователь обязуется:
      </Typography>

      <Typography>
        5.1.1. ознакомиться с настоящим Соглашение перед использованием Сайта и согласиться с ними;
      </Typography>

      <Typography>
        5.1.2. нести ответственность за точность и достоверность содержимого, которое он размещает на Сайте, а также за любые взаимодействия, осуществленные с использованием Сайта;
      </Typography>

      <Typography>
        5.1.3. не использовать информацию и материалы Сайта без согласия MentUp;
      </Typography>

      <Typography>
        5.1.4. действовать исключительно в соответствии с действующим законодательством Российской Федерации, настоящим Соглашением.
      </Typography>

      <Typography paragraph>
        5.1.5. не препятствовать каким-либо образом работе Сайта и его Сервисов.
      </Typography>

      <Typography>
        5.1.6. предоставлять по запросу MentUp дополнительную информацию.
      </Typography>

      <Typography>
        5.1.7. соблюдать имущественные и неимущественные права авторов и иных правообладателей при использовании MentUp.
      </Typography>

      <Typography>
        5.1.8. соблюдать правила конфиденциальности и не разглашать полученные им на Сайте личные данные других пользователей без их согласия;
      </Typography>

      <Typography>
        5.1.9. не использовать Сайт для распространения информации рекламного характера, без согласия MentUp.
      </Typography>

      <Typography>
        - не распространять через сайт контент следующего характера:
      </Typography>

      <Typography>
        - контент, который является незаконным, нарушает любые права третьих лиц; пропагандирует насилие, жестокость, ненависть и (или) дискриминацию по расовому, национальному, половому, религиозному, социальному признакам; содержит недостоверные сведения и (или) оскорбления в адрес конкретных лиц, организаций, органов власти.
      </Typography>

      <Typography>
        - побуждения к совершению противоправных действий, а также содействия лицам, действия которых направлены на нарушение ограничений и запретов, действующих на территории Российской Федерации.
      </Typography>

      <Typography paragraph>
        - нарушения прав несовершеннолетних лиц и/или причинение им вреда в любой форме.
      </Typography>

      <Typography>
        - введения в заблуждение относительно свойств и характеристик какого-либо товара.
      </Typography>

      <Typography>
        5.2. Пользователь вправе:
      </Typography>

      <Typography>
        5.2.1. получить доступ к использованию сайта;
      </Typography>

      <Typography paragraph>
        5.2.2. пользоваться всеми имеющимися на сайте услугами, а также приобретать любые товары, предлагаемые на сайте и доступные для заказа;
      </Typography>
      <Typography variant="h6" gutterBottom>
        6. Конфиденциальность и защита персональных данных
      </Typography>

      <Typography>
        6.1. Персональные данные, предоставляемые Пользователем Сайту при регистрации, защищаются, используются и обрабатываются в соответствии с Федеральным законом от 27 июля 2006 года № 152-ФЗ "О персональных данных".
      </Typography>

      <Typography paragraph>
        6.2. Персональная информация Пользователя, содержащаяся в Личном кабинете Пользователя, хранится и обрабатывается MentUp в соответствии с условиями политики MentUp <Link color={theme.palette.mode === 'dark' ? 'darkgray' : 'inherit'} href="https://mentup-store.com/privacy">https://mentup-store.com/privacy</Link>.
      </Typography>

      <Typography variant="h6" gutterBottom>
        7. Ответственность
      </Typography>

      <Typography>
        7.1. Пользователь использует Сервис на свой собственный риск. MentUp не принимает на себя никакой ответственности, в том числе за соответствие Сервиса целям и задачам Пользователя.
      </Typography>

      <Typography>
        7.2. MentUp не возмещает убытки, которые Пользователь может понести в случаях:
      </Typography>

      <Typography>
        7.2.1. умышленного или неосторожного нарушения любого положения настоящего соглашения;
      </Typography>

      <Typography>
        7.2.2. по причине и/или в следствии неразумного и/или неосторожного использования средств связи, использования запрещенных или небезопасных подключений к сети Интернет и сайту, а также при наличии вирусов и прочих вредоносных данных на персональном устройстве Пользователя;
      </Typography>

      <Typography paragraph>
        7.2.3. любой ущерб, включая упущенную выгоду, или вред, вызванные в связи с использованием Сайта, размещенного контента или иных материалов, к которым пользователь или иные лица получили доступ при помощи Сайта, даже если MentUp предупреждал или указывал на возможность причинения такого ущерба или вреда.
      </Typography>
      <Typography variant="h6" gutterBottom>
        8. Политика в отношении файлов cookie
      </Typography>

      <Typography>
        8.1. Файл cookie - это небольшой файл, который запрашивает разрешение на размещение на жестком диске компьютера Пользователя. После предоставления согласия файл добавляется, и cookie помогает анализировать трафик на веб-страницах или уведомляет, когда Пользователь посещает определенный сайт. Файлы cookie позволяют веб-приложениям реагировать на субъект как на отдельного пользователя. Веб-приложение может настраивать свои операции в соответствии с потребностями, предпочтениями и интересами Пользователя, собирая и запоминая информацию о его предпочтениях.
      </Typography>

      <Typography>
        8.2 MentUp использует файлы cookie для отслеживания трафика на страницах нашего сайта. Это помогает анализировать данные о посещаемости страниц и улучшать сайт, чтобы сделать его более удобным для потребностей наших клиентов. MentUp использует эту информацию исключительно для статистического анализа, после чего данные удаляются из системы.
      </Typography>

      <Typography paragraph>
        8.3. Файлы cookie помогают предоставлять более качественный сайт, позволяя отслеживать, какие страницы Пользователь находит полезными, а какие - нет. Файл cookie никоим образом не предоставляет доступ к компьютеру Пользователя или какой-либо информации о Пользователе, кроме данных, которые он сами выбираете предоставить. Пользователь может выбрать принять или отклонить файлы cookie. Следует отметить, что отказ от файлов cookie может помешать в полной мере использовать сайт и связанные с ним услуги.
      </Typography>

      <Typography variant="h6" gutterBottom>
        9. Заключительные положения
      </Typography>

      <Typography>
        9.1. Пользовательское соглашение заключается на неопределенный срок.
      </Typography>

      <Typography>
        9.2. С момента акцепта Пользователем Соглашение считается заключенным в письменной форме, подписанной обеими Сторонами.
      </Typography>

      <Typography>
        9.3. Ничто в Пользовательском соглашении не может пониматься как установление между Пользователем и MentUp агентских или иных отношений, прямо не предусмотренных Пользовательским соглашением.
      </Typography>

      <Typography>
        9.4. Настоящее Соглашение может быть изменено MentUp в одностороннем порядке без уведомления Пользователя. Пользователь несет персональную ответственность за проверку настоящего Соглашения на наличие изменений в нем.
      </Typography>

      <Typography>
        9.5. Новая редакция Соглашения вступает в силу с момента ее размещения в сети Интернет по указанному в настоящем пункте адресу. Действующая редакция Условий всегда находится на странице по адресу: <Link color={theme.palette.mode === 'dark' ? 'darkgray' : 'inherit'} href="https://">https://mentup-store.com/terms</Link>.
      </Typography>

      <Typography paragraph>
        9.6. Настоящее Пользовательское соглашение регулируется в соответствии с действующим законодательством Российской Федерации.
      </Typography>
    </Box>
  )
}

export default Terms