import { AccordionDetails, Box, Typography } from "@mui/material";

import { isoToReadableDate } from "../../../../../../lib/isoToReadableDate";
import { TranslateText } from "../../../../../translate-text";
import styles from "./styles.module.css";

const TransactionsDetails = ({ item }) => {
  const currencySign = (currency) => {
    if (currency === "USD") {
      return "$";
    }

    return "₽";
  };

  return (
    <AccordionDetails className={styles.container}>
      <Typography className={styles.title}>
        <TranslateText value="transactions.items.details.title" />
      </Typography>

      <Box className={styles.detailsContainer}>
        <table border={1} frame="void" rules="rows">
          <tbody>
            <tr>
              <td>
                <Typography>
                  <TranslateText value="transactions.items.details.common" />
                </Typography>
              </td>
              <td>
                <Typography>
                  {item.total} {currencySign(item.currency)}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography>
                  <TranslateText value="transactions.items.details.subTotal" />
                </Typography>
              </td>
              <td>
                <Typography>
                  {item.subTotal} {currencySign(item.currency)}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography>
                  <TranslateText value="transactions.items.details.currency" />
                </Typography>
              </td>
              <td>
                <Typography>
                  <TranslateText
                    value={`transactions.items.details.${item.currency.toLowerCase()}`}
                  />
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>

        <table border={1} frame="void" rules="rows">
          <tbody>
            <tr>
              <td>
                <Typography>
                  <TranslateText value="transactions.items.details.status" />
                </Typography>
              </td>
              <td>
                <Box
                  className={styles.statusTag}
                  sx={{
                    backgroundColor:
                      item.status === "succeeded" ? "#12B76A1A" : "#F1FF5217",
                  }}
                >
                  <Box
                    className={styles.dot}
                    sx={{
                      backgroundColor:
                        item.status === "succeeded" ? "#1db870" : "#FF5252",
                    }}
                  />
                  <Typography
                    sx={{
                      color:
                        item.status === "succeeded"
                          ? "#1db870 !important"
                          : "#FF5252 !important",
                    }}
                  >
                    <TranslateText
                      value={`vendorDashboard.filters.status.${item.status}`}
                    />
                  </Typography>
                </Box>
              </td>
            </tr>
            <tr>
              <td>
                <Typography>
                  <TranslateText value="transactions.items.details.paidWith" />
                </Typography>
              </td>
              <td>
                <Typography>{item.paidWith}</Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography>
                  <TranslateText value="transactions.items.details.balanceUsed" />
                </Typography>
              </td>
              <td>
                <Typography>{item.balanceUsed}</Typography>
              </td>
            </tr>
          </tbody>
        </table>

        <table border={1} frame="void" rules="rows">
          <tbody>
            <tr>
              <td>
                <Typography>
                  <TranslateText value="transactions.items.details.conversionRate" />
                </Typography>
              </td>
              <td>
                <Typography>{item.conversionRate}</Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography>
                  <TranslateText value="transactions.items.details.marketplaceRevenueRate" />
                </Typography>
              </td>
              <td>
                <Typography>{item.marketplaceRevenueRate || 0}%</Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography>
                  <TranslateText value="transactions.items.details.discountCode" />
                </Typography>
              </td>
              <td>
                <Typography>{item.discountCode || "–"}</Typography>
              </td>
            </tr>
          </tbody>
        </table>

        <Box className={styles.createdAtContainer}>
          <Typography>
            <TranslateText value="transactions.items.details.createdAt" />
          </Typography>
          <Typography>
            {isoToReadableDate(item.createdAt).replaceAll("/", ".")}
          </Typography>
        </Box>
      </Box>
    </AccordionDetails>
  );
};

export default TransactionsDetails;
