import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { ArrowLeftIcon, ArrowRightIcon } from "@mui/x-date-pickers";
import { nanoid } from "@reduxjs/toolkit";
import clsx from "clsx";
import { useCallback, useState } from "react";

import GalleryIcon from "../../../../../svg/Gallery";
import RemoveProductImage from "../../../../../svg/RemoveProductImage";
import { TranslateText } from "../../../../../translate-text";
import styles from "./styles.module.css";

const AddProductModalStep5 = ({ loading, defaultImages, back, submit }) => {
  
  const [images, setImages] = useState(
    Array.from({ length: 6 }, (_, index) => defaultImages[index] || null)
  );

  const goBack = useCallback(() => back(images.filter(Boolean)), [images]);

  const renderNoImage = (index) => {
    return (
      <Box
        key={`product-image-upload-input-${index}`}
        className={styles.noImageContainer}
      >
        <input
          id={`product-image-upload-input-${index}`}
          multiple
          hidden
          accept="image/*"
          type="file"
          onChange={handleProductPictureUpload}
          onClick={(e) => (e.target.value = null)}
        />
        <label htmlFor={`product-image-upload-input-${index}`}>
          <Button component="span" className={styles.imageButton}>
            <GalleryIcon
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                translate: "-50% -50%",
              }}
            />
          </Button>
        </label>
      </Box>
    );
  };

  const renderImage = (image, index) => {
    if (!image) {
      return renderNoImage(index);
    }

    return (
      <Box
        key={`product-image-upload-input-${index}`}
        className={styles.imageContainer}
        sx={{ backgroundImage: `url('${image.pictureUrl}')` }}
      >
        <IconButton
          size="medium"
          className={styles.removeButton}
          onClick={() =>
            setImages((prev) => [...prev.filter((_, i) => i !== index), null])
          }
        >
          <RemoveProductImage />
        </IconButton>
      </Box>
    );
  };

  const handleProductPictureUpload = (e) =>
    setImages((prev) =>
      [
        ...prev.filter(Boolean),
        ...Array.from({ length: 6 }, (_, index) =>
          e.target.files?.[index]
            ? {
                pictureFile: e.target.files[index],
                pictureUrl: URL.createObjectURL(e.target.files[index]),
                pictureId: nanoid(),
                type: "localPicture",
              }
            : null
        ),
      ].splice(0, 6)
    );

  return (
    <Box className={styles.form}>
      <Box className={styles.titleContainer}>
        <Typography className={styles.title}>
          <TranslateText value="addProductModal.new.step5.title" />{" "}
          <span className={styles.subtitle}>
            <TranslateText value="addProductModal.new.step5.subtitle" />
          </span>
        </Typography>
      </Box>

      <Box className={styles.imagesContainer}>{images.map(renderImage)}</Box>

      <Box className={styles.buttonsContainer}>
        <Button
          disabled={loading}
          onClick={goBack}
          className={clsx(styles.button, styles.cancelButton)}
          startIcon={<ArrowLeftIcon />}
        >
          <TranslateText value="addProductModal.new.step5.back" />
        </Button>
        <LoadingButton
          loading={loading}
          onClick={() => submit(images.filter(Boolean))}
          className={clsx(styles.button, styles.submitButton)}
          endIcon={<ArrowRightIcon />}
        >
          <TranslateText value="addProductModal.new.step5.next" />
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default AddProductModalStep5;
