import { useTheme } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ArrowLeftIcon, ArrowRightIcon } from "@mui/x-date-pickers";
import clsx from "clsx";
import { useCallback, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { useTranslate } from "../../../../../../context/translate/translate.context";
import { regions } from "../../../../../../lib/regions";
import { MentupSwitch } from "../../../../../../lib/theme";
import { TranslateText } from "../../../../../translate-text";
import styles from "./styles.module.css";

const AddProductModalStep2 = ({
  defaultProduct,
  maxQuantity,
  addProductCodes,
  addCharacteristics,
  setMaxQuantity,
  setAddProductCodes,
  setAddCharacteristics,
  back,
  submit,
}) => {
  const theme = useTheme();
  const { translate } = useTranslate();

  const [product, setProduct] = useState(defaultProduct);

  const goBack = useCallback(() => back(product), [...Object.values(product)]);

  const inputStyle = {
    WebkitBoxShadow: `0 0 0 1000px ${theme.palette.primary.backgroundPaperDark} inset`,
  };
  const inputStyleDay = {
    WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.paper} inset`,
  };
  const isDarkTheme = theme.palette.mode === "dark";
  const selectedInputStyle = isDarkTheme ? inputStyle : inputStyleDay;

  const userSetting = JSON.parse(localStorage.getItem("userSettings"));
  const commission = userSetting?.marketRate || 15;

  const totalPrice = useMemo(() => product.price, [product.price]);
  const amountWillReceive = useMemo(
    () =>
      product.commissionPaidBySeller
        ? totalPrice - (totalPrice * commission) / 100
        : totalPrice,
    [product.commissionPaidBySeller, totalPrice]
  );

  const formSchema = yup.object().shape({
    price: yup
      .number()
      .min(0.1)
      .max(500000)
      .required()
      .test(
        translate("addProductModal.validationErrors.schema2.validActualPrice"),
        translate("addProductModal.validationErrors.schema2.priceFormatUsd"),
        (value) =>
          value.toString().match(/^\d+\.\d{1,2}$/) ||
          value.toString().match(/^\d+$/)
      ),
    ...(maxQuantity
      ? {}
      : { quantity: yup.number().integer().min(1).max(1000).required() }),
    promocode: yup.number().integer().min(0).max(95).required(),
    region: yup
      .object()
      .required()
      .test(
        translate("addProductModal.validationErrors.schema2.validRegionValue"),
        translate("addProductModal.validationErrors.schema2.region"),
        (v) => regions.some(({ name }) => name === v.name)
      ),
    commissionPaidBySeller: yup.boolean().required(),
  });

  const {
    control,
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <form onSubmit={handleSubmit(submit)} className={styles.form}>
      <Box className={styles.formContainer}>
        <Box className={styles.rowInputs}>
          <TextField
            required
            label={translate("addProductModal.new.step2.inputs.price.label")}
            placeholder={translate(
              "addProductModal.new.step2.inputs.price.placeholder"
            )}
            variant="outlined"
            InputProps={{ style: selectedInputStyle }}
            autoComplete="off"
            error={Boolean(errors.price)}
            className={styles.input}
            helperText={errors.price?.message}
            {...register("price")}
            value={product.price}
            onChange={(e) =>
              setProduct((prev) => ({ ...prev, price: e.target.value }))
            }
          />
          {!maxQuantity && (
            <TextField
              required
              label={translate(
                "addProductModal.new.step2.inputs.quantity.label"
              )}
              placeholder={translate(
                "addProductModal.new.step2.inputs.quantity.placeholder"
              )}
              variant="outlined"
              InputProps={{ style: selectedInputStyle }}
              autoComplete="off"
              error={Boolean(errors.quantity)}
              className={styles.input}
              helperText={errors.quantity?.message}
              {...register("quantity")}
              value={product.quantity}
              onChange={(e) =>
                setProduct((prev) => ({ ...prev, quantity: e.target.value }))
              }
            />
          )}
        </Box>

        <Box className={styles.rowInputs}>
          <TextField
            required
            label={translate(
              "addProductModal.new.step2.inputs.promocode.label"
            )}
            variant="outlined"
            InputProps={{ style: selectedInputStyle }}
            autoComplete="off"
            error={Boolean(errors.promocode)}
            className={styles.input}
            helperText={errors.promocode?.message}
            {...register("promocode")}
            value={product.promocode}
            onChange={(e) =>
              setProduct((prev) => ({ ...prev, promocode: e.target.value }))
            }
          />
          <Controller
            defaultValue={product.region}
            render={({ field: { onChange } }) => (
              <Autocomplete
                isOptionEqualToValue={(o, v) => o?._id === v?._id}
                disablePortal={false}
                options={regions}
                getOptionLabel={(o) => o?.name || ""}
                sx={{
                  width: "100%",
                  "@media (max-width: 500px)": { width: "100%" },
                }}
                value={product.region}
                onChange={(e, region) => {
                  setProduct((prev) => ({ ...prev, region }));
                  onChange(region);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={translate(
                      "addProductModal.new.step2.inputs.region.label"
                    )}
                    error={errors.region && true}
                    helperText={errors.region?.message}
                    sx={selectedInputStyle}
                    inputProps={{
                      ...params.inputProps,
                      style: selectedInputStyle,
                      required: true,
                    }}
                    required={true}
                  />
                )}
              />
            )}
            name="region"
            control={control}
            rules={{ required: true }}
          />
        </Box>

        <FormControl sx={{ width: "100%" }} required>
          <InputLabel id="product-whopayscommission-selection-label">
            <TranslateText value="addProductModal.new.step2.inputs.commission.label" />
          </InputLabel>
          <Select
            required
            labelId="product-whopayscommission-selection-label"
            label={translate(
              "addProductModal.new.step2.inputs.commission.label"
            )}
            error={!!errors.commissionPaidBySeller}
            {...register("commissionPaidBySeller")}
            value={product.commissionPaidBySeller}
            onChange={(e) =>
              setProduct((prev) => ({
                ...prev,
                commissionPaidBySeller: e.target.value,
              }))
            }
            style={selectedInputStyle}
          >
            <MenuItem value={true}>
              <TranslateText value="addProductModal.new.step2.inputs.commission.seller" />
            </MenuItem>
            <MenuItem value={false}>
              <TranslateText value="addProductModal.new.step2.inputs.commission.buyer" />
            </MenuItem>
          </Select>
          {errors.commissionPaidBySeller?.message ? (
            <FormHelperText sx={{ color: theme.palette.error.main }}>
              {errors.commissionPaidBySeller.message}
            </FormHelperText>
          ) : null}
        </FormControl>

        <Box className={styles.switchContainer}>
          <FormControlLabel
            control={
              <MentupSwitch
                checked={maxQuantity || false}
                onChange={() => setMaxQuantity((prev) => !prev)}
              />
            }
            label={translate(
              "addProductModal.new.step2.inputs.maxQuantity.label"
            )}
          />
        </Box>

        {!product?._id && (
          <Box className={styles.switchContainer}>
            <FormControlLabel
              control={
                <MentupSwitch
                  checked={addProductCodes || false}
                  onChange={() => setAddProductCodes((prev) => !prev)}
                />
              }
              label={translate(
                "addProductModal.new.step2.inputs.productCodes.label"
              )}
            />
          </Box>
        )}

        {!product?._id && (
          <Box className={styles.switchContainer}>
            <FormControlLabel
              control={
                <MentupSwitch
                  checked={addCharacteristics || false}
                  onChange={() => setAddCharacteristics((prev) => !prev)}
                />
              }
              label={translate(
                "addProductModal.new.step2.inputs.characteristics.label"
              )}
            />
          </Box>
        )}

        <Box>
          <Box className={styles.finalContainer}>
            <Typography>
              <TranslateText value="addProductModal.new.step2.totalPrice" />
            </Typography>
            <Typography>{totalPrice}</Typography>
          </Box>
          <Box className={styles.divider} />
          <Box className={styles.finalContainer}>
            <Typography>
              <TranslateText value="addProductModal.new.step2.commission" />
            </Typography>
            <Typography>
              -{product.commissionPaidBySeller ? commission : 0}%
            </Typography>
          </Box>
          <Box className={styles.divider} />
          <Box className={styles.finalContainer}>
            <Typography>
              <TranslateText value="addProductModal.new.step2.amountWillReceive" />
            </Typography>
            <Typography>{amountWillReceive}</Typography>
          </Box>
        </Box>
      </Box>

      <Box className={styles.buttonsContainer}>
        <Button
          onClick={goBack}
          className={clsx(styles.button, styles.cancelButton)}
          startIcon={<ArrowLeftIcon />}
        >
          {translate("addProductModal.new.step2.back")}
        </Button>
        <Button
          type="submit"
          className={clsx(styles.button, styles.submitButton)}
          endIcon={<ArrowRightIcon />}
        >
          {translate("addProductModal.new.step2.next")}
        </Button>
      </Box>
    </form>
  );
};

export default AddProductModalStep2;
