import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputBase,
  Pagination,
  Slider,
  Typography,
} from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import clsx from "clsx";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

import {
  getTransactions,
  getTransactionsFilters,
  getUserWallet,
} from "../../../../api/personalOffice/requests";
import { useTranslate } from "../../../../context/translate/translate.context";
import FiltersSVG from "../../../../lib/svg/filters";
import ExportIcon from "../../../svg/Export";
import SearchIcon from "../../../svg/SearchIcon";
import { TranslateText } from "../../../translate-text";
import FilterDropdown from "../FilterDropdown";
import WithdrawModal from "../WithdrawModal";
import TopUpBalanceContent from "./TopUpBalanceContent";
import TransactionsList from "./components/TransactionSummary";
import styles from "./styles.module.css";
import { CURRENCY_SIGN_BY_COUNTRY } from "../../../../constants";
import { useNavigate } from "react-router-dom";

const UserInfoTabWallet = () => {
  const theme = useTheme();
  const { translate } = useTranslate();

  const userRedux = useSelector((state) => state.user);
  const walletRedux = useSelector((state) => state.wallet);

  const navigate = useNavigate()

  const [loading, setLoading] = useState(true);
  const [defaultSelected, setDefaultSelected] = useState("");
  const [topUpOpen, setTopUpOpen] = useState(false);
  const [pages, setTotalPages] = useState(0);
  const [wallet, setWallet] = useState(null);
  const [items, setItems] = useState([]);
  const [allFilters, setAllFilters] = useState();
  const [priceRadius, setPriceRadius] = useState([0, 0]);
  const [filters, setFilters] = useState({
    country: userRedux.country,
    text: "",
    limit: 5,
    page: 0,
    sort: { createdAt: -1 },
    price: { min: 0, max: 0 },
    statuses: [],
  });

  const ref = useRef(null);

  const handlePriceChange = (_, newValue) => {
    setFilters((prev) => ({
      ...prev,
      price: { min: newValue[0], max: newValue[1] },
      page: 0,
    }));
  };

  const handleSearch = useCallback(
    async (f) => {
      setLoading(true);
      const { data } = await getTransactions(f);
      setItems(data.items);
      setTotalPages(data.totalPages);
      setLoading(false);
    },
    [filters, filters.text]
  );

  const debouncedSearchText = useMemo(() => debounce(handleSearch, 300), []);

  const checkStatus = useCallback(
    async (value) => {
      const statuses = filters.statuses.includes(value)
        ? filters.statuses.filter((s) => s !== value)
        : filters.statuses.concat(value);

      setFilters((prev) => ({ ...prev, statuses, page: 0 }));
    },
    [filters.statuses]
  );

  const checkSort = useCallback(
    (sort) => setFilters((prev) => ({ ...prev, sort, page: 0 })),
    []
  );

  const getWallet = async () => {
    try {
      const { data } = await getUserWallet();
      setWallet(data);
    } catch (error) {
      navigate('/404')
    }
  };

  const getFilters = useCallback(async (openTransactionId) => {
    try {
      const { data } = await getTransactionsFilters(openTransactionId);
      setAllFilters(data.filters);
      setPriceRadius([data.filters.price.min, data.filters.price.max]);
      setFilters((prev) => ({
        ...prev,
        ...data.filters,
      }));
      if (data.openTransactionId) {
        setDefaultSelected(data.openTransactionId);
      }
    } catch (error) {
      navigate('/404')
    }
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(
      window.location.href.split("?")[1] || ""
    );
    const transactionId = searchParams.get("id");
    getFilters(transactionId);
    getWallet();
  }, []);

  useEffect(() => {
    if (allFilters) {
      debouncedSearchText(filters);
    }
    return () => debouncedSearchText.cancel();
  }, [
    allFilters,
    filters.text,
    filters.limit,
    filters.page,
    filters.sort.createdAt,
    filters.price.min,
    filters.price.max,
    filters.user,
    filters.statuses,
  ]);

  const currencySign = '₽';
  const shownBalance = `${
    walletRedux.wallet?.balance
      ?.toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,") || 0.0
  } ${currencySign}`;
  const shownPendingBalance = `${
    walletRedux.wallet?.pendingBalance
      ?.toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,") || 0.0
  } ${currencySign}`;
  const shownPendingWithdrawals = `${
    walletRedux.wallet?.pendingWithdrawals
      ?.toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,") || 0.0
  } ${currencySign}`;

  return (
    <Box className={styles.container}>
      <Card className={styles.balanceContainer}>
        <Box className={clsx(styles.rowBetween, styles.wAvailable)}>
          <Typography className={styles.balanceTitle}>
            <TranslateText value="vendorDashboard.wallet.balance" />
          </Typography>
          <Typography className={styles.balanceTotal}>
            {shownBalance}
          </Typography>
        </Box>

        <Box className={clsx(styles.wAvailable, styles.pendingContainer)}>
          <Box className={clsx(styles.rowBetween, styles.wAvailable)}>
            <Typography className={styles.pending}>
              <TranslateText value="vendorDashboard.wallet.pendingBalance" />
            </Typography>
            <Typography className={styles.pending}>
              {shownPendingBalance}
            </Typography>
          </Box>
          <Box className={clsx(styles.rowBetween, styles.wAvailable)}>
            <Typography className={styles.pending}>
              <TranslateText value="vendorDashboard.wallet.pendingWithdrawals" />
            </Typography>
            <Typography className={styles.pending}>
              {shownPendingWithdrawals}
            </Typography>
          </Box>
        </Box>

        <Box className={clsx(styles.rowBetween, styles.wAvailable)}>
          <Button
            variant="contained"
            startIcon={<ExportIcon />}
            className={clsx(styles.add_new_button)}
            onClick={() => setTopUpOpen(true)}
          >
            <TranslateText value="vendorDashboard.wallet.topUpBalance" />
          </Button>
          <WithdrawModal newWithdrawal={getWallet} />
        </Box>
      </Card>

      <Box className={styles.filtersContainer}>
        <Box className={styles.nowrap}>
          <FiltersSVG
            color={theme.palette.mode === "dark" ? "#f6f6f6" : "#1f1d22"}
          />

          <Box className={styles.filters}>
            <FilterDropdown title="vendorDashboard.filters.price.title">
              <Box className={styles.priceFilter}>
                <Typography
                  color="primary"
                  id="range-slider"
                  sx={{ width: "50px" }}
                >
                  {filters.price.min}
                </Typography>

                <Slider
                  min={priceRadius[0]}
                  max={priceRadius[1]}
                  getAriaLabel={() => "Price range"}
                  value={[filters.price.min, filters.price.max]}
                  onChange={handlePriceChange}
                  valueLabelDisplay="auto"
                  getAriaValueText={() => "Price range"}
                />

                <Typography
                  color="primary"
                  id="range-slider"
                  w
                  sx={{ width: "50px" }}
                >
                  {filters.price.max}
                </Typography>
              </Box>
            </FilterDropdown>
            <FilterDropdown title="vendorDashboard.filters.status.title">
              {allFilters?.statuses.map((status) => (
                <Box key={status} className={styles.categoryItem}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={status}
                        color="primary"
                        onChange={({ target }) => checkStatus(target.value)}
                        checked={filters.statuses.includes(status)}
                      />
                    }
                    label={translate(
                      `vendorDashboard.filters.status.${status}`
                    )}
                  />
                </Box>
              ))}
            </FilterDropdown>
            <FilterDropdown title="vendorDashboard.filters.createdAt.title">
              <Box className={styles.categoryItem}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={-1}
                      color="primary"
                      onChange={() => checkSort({ createdAt: -1 })}
                      checked={filters.sort.createdAt === -1}
                    />
                  }
                  label={translate("vendorDashboard.filters.createdAt.asc")}
                />
              </Box>
              <Box className={styles.categoryItem}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={1}
                      color="primary"
                      onChange={() => checkSort({ createdAt: 1 })}
                      checked={filters.sort.createdAt === 1}
                    />
                  }
                  label={translate("vendorDashboard.filters.createdAt.desc")}
                />
              </Box>
            </FilterDropdown>
          </Box>
        </Box>

        <Box className={styles.inputContainer}>
          <InputBase
            ref={ref}
            className={styles.input}
            value={filters.text}
            onChange={({ target }) =>
              setFilters((prev) => ({ ...prev, text: target.value, page: 0 }))
            }
            placeholder={translate("header.search")}
          />
          <IconButton
            className={styles.iconContainer}
            aria-label="clear search input"
            onClick={() => setFilters((prev) => ({ ...prev, text: "" }))}
            disabled={!filters.text}
          >
            {filters.text ? <ClearIcon /> : <SearchIcon />}
          </IconButton>
        </Box>
      </Box>

      {!!items.length && (
        <TransactionsList
          defaultSelected={defaultSelected}
          items={items}
          loading={loading}
        />
      )}

      {!!pages && (
        <Box className={styles.paginationContainer}>
          <Pagination
            page={filters.page + 1}
            count={pages}
            color="primary"
            onChange={(_, page) =>
              setFilters((prev) => ({ ...prev, page: page - 1 }))
            }
          />
        </Box>
      )}

      <TopUpBalanceContent open={topUpOpen} close={() => setTopUpOpen(false)} />
    </Box>
  );
};

export default UserInfoTabWallet;
