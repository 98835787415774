import { createSlice } from "@reduxjs/toolkit";

const currentTabSlice = createSlice({
  name: "currentTab",
  initialState: 0, // default currentTab 0
  reducers: {
    setCurrentTab: (state, action) => {
      return action.payload;
    },
  },
});

export const { setCurrentTab } = currentTabSlice.actions;
export default currentTabSlice.reducer;
