import { Box, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { Link, NavLink } from "react-router-dom";

import { emailVerify, verifyGoogle } from "../../../api/auth/register";
import { useTranslate } from "../../../context/translate/translate.context";
import FormInput from "../../FormInput";
import Button from "../../UI/Button";
import GoogleAuthBtn from "../GoogleAuthBtn";
import VKAuthBtn from "../VKAuthBtn";
import { FORM_VALIDATIONS } from "./constants";
import styles from "./styles.module.css";

const Step1 = ({
  data,
  setData,
  loading,
  setLoading,
  setGoogleFinalStep,
  next,
}) => {
  const { translate } = useTranslate();

  const { control, handleSubmit, setError } = useForm();

  const onGoogleSubmit = async (token) => {
    try {
      setLoading(true);
      await verifyGoogle({ token });

      setData({ ...data, token });

      setGoogleFinalStep(true);
    } catch (error) {
      setError("email", { message: error?.response?.data?.email });
      //console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (localData) => {
    try {
      await emailVerify(localData);
      next(localData);
    } catch (error) {
      setError("email", {
        message: error.response?.data?.message || "Email already in use",
      });
    } finally {
      setLoading(false);
    }
  };


  return (
    <Box
      className={styles.form}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormInput
        name="username"
        label={translate("authentication.nickname")}
        control={control}
        autoComplete="username"
        disabled={loading}
        rules={FORM_VALIDATIONS.username(translate)}
      />
      <FormInput
        type="email"
        name="email"
        label={translate("authentication.email")}
        control={control}
        autoComplete="email"
        disabled={loading}
        rules={FORM_VALIDATIONS.email(translate)}
      />
      <Typography className={styles.form__confirmation}>
        {translate("authentication.byRegisteringYouAgree")}{" "}
        <Box component={NavLink} to="/info/laws/license-agreement">
          {translate("authentication.licenseAgreement")}
        </Box>
        ,{" "}
        <Box component={NavLink} to="/info/laws/privacy-policy">
          {translate("authentication.privacyPolicy")}
        </Box>{" "}
        {translate("authentication.and")}{" "}
        <Box component={NavLink} to="/info/laws/rules">
          {translate("authentication.rules")}
        </Box>
      </Typography>
      <Button
        className={styles.form__submit}
        type="submit"
        size="large"
        fullWidth
        loading={loading}
      >
        {translate("authentication.continue")}
      </Button>
      <GoogleAuthBtn
        title="registration.steps.1.continueGoogle"
        onToken={onGoogleSubmit}
      />
      <VKAuthBtn title="registration.steps.1.continueVK" />
      <Box className={styles.form__link} component={Link} to="/login">
        {translate("authentication.alreadyHaveAnAccount")}
      </Box>
    </Box>
  );
};

export default Step1;
