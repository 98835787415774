import {
  Box,
  Button,
  Card,
  Rating,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { isoToReadableDate } from "../../../lib/isoToReadableDate";
import { priceLabel } from "../../../utils/strings/price";
import GlobalIcon from "../../svg/Global";
import { TranslateText } from "../../translate-text";
import styles from "./styles.module.css";

function VendorReview({ review }) {
  const theme = useTheme();
  const description = useRef(null);
  const navigate = useNavigate();

  const [displayViewMore, setDisplayViewMore] = useState();
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    if (description.current && typeof displayViewMore !== "boolean") {
      setDisplayViewMore(
        description.current.scrollHeight > description.current.clientHeight
      );
    }
  }, [description.current, displayViewMore]);

  let readableDate = null;
  if (review && review.updatedAt) {
    readableDate = isoToReadableDate(review.updatedAt).replaceAll("/", ".");
  }

  const handleRedirect = (href) => (event) => {
    event.stopPropagation();

    if (href) {
      navigate(href);
    }
  };

  const handleShowMore = (event) => {
    event.stopPropagation();
    setShowMore((prev) => !prev);
    setDisplayViewMore(false);
  };

  return (
    <Box className={styles.containerWrapper}>
      <Card
        className={styles.container}
        onClick={handleRedirect(
          review?.product?.slug &&
            review?.product?.user?.userSlug &&
            `/profile/${review.product.user.userSlug}/${review.product.slug}`
        )}
      >
        <Box className={styles.headerWrapper}>
          <Box className={styles.userContainer}>
            <Card
              onClick={handleRedirect(
                review?.user?.userSlug && `/profile/${review.user.userSlug}`
              )}
              className={styles.avatar}
              sx={{ backgroundImage: `url(${review.user?.avatar})` }}
            />

            <Box className={styles.usernameContainer}>
              <Button
                type="text"
                className={styles.username}
                sx={{
                  color: `${theme.palette.text.primary}`,
                  "&:hover": {
                    color: `${theme.palette.primary.main}`,
                  },
                }}
                onClick={handleRedirect(
                  review?.user?.userSlug && `/profile/${review.user.userSlug}`
                )}
              >
                {review?.user?.username || ""}
              </Button>
            </Box>
          </Box>

          <Typography
            className={styles.createDate}
            sx={{ color: theme.palette.text.secondary }}
          >
            {readableDate}
          </Typography>
        </Box>

        <Box className={styles.productContainer}>
          <img
            src={
              review?.product?.thumb && review?.product?.thumb?.length > 0 ?
                review?.product?.thumb?.[0]
              :
                review?.product?.media?.[0] || "defaultProfile.png"}
            alt="Product image"
            className={styles.productImage}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "6px",
              justifyContent: "center",
              maxWidth: '90%',
            }}
          >
            
            <Typography
              sx={{
                color: theme.palette.text.primary,
              }}
              className={styles.productName}
            >
              {review.product.name}
            </Typography>
            <Typography
              sx={{ color: theme.palette.mode === 'dark' ? 'primary.yellow' : 'primary.main' }}
              className={styles.productPrice}
              
            >
              {review.product.promocode ? (
                <>
                  <Box className={styles.price__strike} component="span">
                    {priceLabel(review.product.price, {
                      currency: review.product?.currency,
                    })}
                  </Box>
                  {priceLabel(
                    review.product.price -
                      (review.product.price *
                        review.product.promocode.discount) /
                        100,
                    {
                      currency: review.product?.currency,
                    }
                  )}
                </>
              ) : (
                priceLabel(review.product.price, {
                  currency: review.product?.currency,
                })
              )}
            </Typography>
            {/*
            <Tooltip title={`Region: ${review.product.region}`} placement="top">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <GlobalIcon
                  color={theme.palette.mode === "dark" ? "#f6f6f6" : "#1f1d22"}
                />
              </Box>
            </Tooltip> */}
          </Box>
        </Box>

        <Box>
          <Rating
            sx={{ fontSize: "1.5rem", marginBottom: '0.5rem' }}
            value={review.rating}
            defaultValue={0}
            precision={0.5}
            readOnly={true}
          />
          {review.text && (
            <Typography
              ref={description}
              className={clsx(styles.review, !showMore && styles.limitLines)}
            >
              {review.text}
            </Typography>
          )}

          {displayViewMore && (
            <Typography
              ref={description}
              className={styles.viewMore}
              onClick={handleShowMore}
            >
              <TranslateText value="vendorDashboard.reviews.viewMore" />
            </Typography>
          )}
        </Box>
      </Card>
    </Box>
  );
}

export default VendorReview;
