import * as React from "react";

const FiltersSVG = ({ color = "#F6F6F6" }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g opacity="0.6">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.70898 5.8335C2.70898 5.48832 2.98881 5.2085 3.33398 5.2085H16.6673C17.0125 5.2085 17.2923 5.48832 17.2923 5.8335C17.2923 6.17867 17.0125 6.4585 16.6673 6.4585H3.33398C2.98881 6.4585 2.70898 6.17867 2.70898 5.8335ZM2.70898 10.0002C2.70898 9.65498 2.98881 9.37516 3.33398 9.37516H12.5007C12.8458 9.37516 13.1257 9.65498 13.1257 10.0002C13.1257 10.3453 12.8458 10.6252 12.5007 10.6252H3.33398C2.98881 10.6252 2.70898 10.3453 2.70898 10.0002ZM2.70898 14.1668C2.70898 13.8217 2.98881 13.5418 3.33398 13.5418H7.50065C7.84583 13.5418 8.12565 13.8217 8.12565 14.1668C8.12565 14.512 7.84583 14.7918 7.50065 14.7918H3.33398C2.98881 14.7918 2.70898 14.512 2.70898 14.1668Z"
        fill={color}
      />
    </g>
  </svg>
);
export default FiltersSVG;
