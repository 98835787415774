import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { useTranslate } from "../../../context/translate/translate.context";
import styles from "./styles.module.css";

const Account = ({
  size = "small",
  _id,
  username,
  userSlug,
  rating,
  avatar,
}) => {
  const { translate } = useTranslate();

  const [imageError, setImageError] = useState(false);

  const imageUrl = useMemo(() => {
    if (avatar && !imageError) {
      return avatar;
    }

    return "/images/product_placeholder.png";
  }, [avatar, imageError]);

  const renderRating = () => {
    if (!rating) {
      return null;
    }
    return (
      <Box className={styles.ratingContainer}>
        <Box
          className={styles.average_rating}
          style={{ "--percent": `${(rating / 5) * 100}%` }}
          min="0"
          max="5"
          value={rating}
          title={`${rating} out of 5 stars`}
          component="meter"
        >
          {rating} out of 5
        </Box>
      </Box>
    );
  };

  return (
    <Box
      key={_id}
      className={clsx(styles.container, size)}
      component={Link}
      aria-label={translate("ariaLabel.navigateToCategory", {
        username,
      })}
      to={`/profile/${userSlug}`}
    >
      <Box
        className={styles.imageContainer}
        component="img"
        src={imageUrl}
        onError={() => setImageError(true)}
      />
      <Box className={styles.nameContainer}>
        <Typography className={styles.name}>{username}</Typography>
        {renderRating()}
      </Box>
    </Box>
  );
};

export default Account;
