import { Container, Box, Typography } from "@mui/material";
import styles from "./styles.module.css";
import BlogTabs from "./BlogTabs/BlogTabs";
import { useEffect, useState } from "react";
import { getBlogCategories } from "../../../api/blog/getCategories";
import Latest5Blog from "../Latest5Blog";
import { getBlogPosts } from "../../../api/blog/getPosts";
import { useSelector } from "react-redux";
import getBestSellersProducts from "../../../services/bestSellers";
import ProductSwiper from "../../ProductsSwiper";
import Post from "./Post/Post";
import SEOHelmet from "../../../lib/SEOHelmet";
import { useNavigate } from "react-router-dom";

const BlogHome = () => {
  
  const [blogCategories, setBlogCategories] = useState([]);
  const [currentCategoryIdx, setCurrentCategoryIdx] = useState(null);
  const [posts, setPosts] = useState([]);
  const [otherPosts, setOtherPosts] = useState([]);
  const handleCategoryChange = async (event, categoryIdx) => {
    setCurrentCategoryIdx(categoryIdx);
    // Получаем id категории
  };

  const navigate = useNavigate()

  // Получаем все категории
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getBlogCategories();
        setBlogCategories(response.data.data);
        setCurrentCategoryIdx(0)
      } catch (error) {
        navigate('/404')
      }
    };

    fetchCategories();
  }, []);

  useEffect( () => {
    if (blogCategories.length > 0) {
      const fetchPosts = async () => {
        const categoryId =  blogCategories[currentCategoryIdx].id;
        //console.log(categoryId)
        try {
          // Выполняем запрос на получение постов
          const response = await getBlogPosts(categoryId, 1, 9, 0);
          // if there are no posts related to a category, then remove the category
          if (response.data.data.length === 0) {
            if(currentCategoryIdx === blogCategories.length - 1) {
              setCurrentCategoryIdx(0);
            } else {
              setCurrentCategoryIdx(currentCategoryIdx+1);
            }
            return;
          } else {
            setPosts(response.data); // Обработка полученных данных
          }
        } catch (error) {
          navigate('/404')
        }
      }
      fetchPosts()
    }
  }, [currentCategoryIdx])

  useEffect(() => {
    setPosts(posts);
  }, [posts]);

  

  const userRedux = useSelector((state) => state.user);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const getFeatured = async () => {
      try {
        const res = await getBestSellersProducts(
          8,
          products.length,
          userRedux.country
        );

        setProducts(res.items);
      } catch (error) {
        navigate('/404')
      } finally {
      }
    };

    getFeatured();
  }, []);
  
  useEffect(()=>{
      setOtherPosts(posts.data && posts.data.slice(1,5))
  }, [posts])

  return (
    <Container>
      <SEOHelmet 
        title="Mentup - Блог"
        description="Блог"
        keywords={blogCategories.map((category) => category?.attributes?.name).join(', ')}
      />
      <BlogTabs
        tabsArray={blogCategories}
        tabsOnChange={handleCategoryChange}
        currentTab={currentCategoryIdx}
      />
      {posts.data && <Latest5Blog posts={posts.data} />}
      <Box className={styles.swiperWrapper}>
        {products?.length > 0 && <ProductSwiper products={products} />}
      </Box>
      <Box className={styles.posts}>
        {otherPosts && otherPosts.map((post, idx) => <Post onClick={()=>{

        }} postData={post} key={idx}/>)}
      </Box>
    </Container>
  );
};

export default BlogHome;
