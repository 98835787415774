import "swiper/css";
import "swiper/css/navigation";

import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { getUserReviews } from "../../api/personalOffice/requests";
import useThrottledResize from "../../hooks/useThrottledResize";
import Arrow from "../svg/Arrow";
import { TranslateText } from "../translate-text";
import VendorReview from "../vendor/VendorReview";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";

const ReviewsSwiper = ({ user }) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const swiperRef = useRef(null);

  const navigate = useNavigate()

  const [pagination, setPagination] = useState({
    limit: 3,
    page: 0,
  });
  const [totalItems, setTotalItems] = useState(0);
  const [disableArrows, setDisableArrows] = useState({
    left: true,
    right: true,
  });
  const [itemsPerPage, setItemsPerPage] = useState({ 0: [] });

  const displayItems = useMemo(() => {
    return Object.values(itemsPerPage).flat();
  }, [pagination.page, itemsPerPage]);

  const setArrowDisability = useCallback(() => {
    if (swiperRef.current) {
      const { isBeginning, isEnd, slides } = swiperRef.current;

      setDisableArrows({
        left: isBeginning,
        right: isEnd && displayItems.length === totalItems,
      });
    }
  }, [displayItems.length, totalItems]);

  useThrottledResize(() => {
    setArrowDisability();
  });

  useEffect(() => {
    setArrowDisability();
  }, [displayItems.length]);

  const handleNextSlides = useCallback(async () => {
    const nextPage = pagination.page + 1;
    if (itemsPerPage[nextPage]?.length) {
      swiperRef.current.slideNext();
      return;
    }

    const newPagination = {
      ...pagination,
      page: pagination.page + 1,
    };
    setPagination(newPagination);
    await getReviews(user._id, newPagination);
    setTimeout(() => {
      swiperRef.current.slideNext();
    }, 500);
  }, [user?._id, ...Object.values(pagination), itemsPerPage]);

  const handlePrevSlides = useCallback(() => {
    setPagination((prev) => ({
      ...prev,
      page: Math.max(0, prev.page - 1),
    }));

    swiperRef.current.slidePrev();
  }, []);

  const getReviews = useCallback(
    async (userId, body = pagination) => {
      try {
        const response = await getUserReviews({ user: userId, ...body });
        setTotalItems(response.data.totalItems);
        setItemsPerPage((prev) => ({
          ...prev,
          [body.page]: response.data.items,
        }));
      } catch (error) {
        navigate('/404')
      }
    },
    [pagination]
  );

  useEffect(() => {
    if (user?._id) {
      getReviews(user._id);
    }
  }, [user?._id]);

  const leftButton = (
    <IconButton
      sx={{ display: "flex" }}
      className={styles.leftButton}
      disabled={disableArrows.left}
      onClick={handlePrevSlides}
    >
      <Arrow />
    </IconButton>
  );

  const rightButton = (
    <IconButton
      sx={{ display: "flex" }}
      className={styles.rightButton}
      disabled={disableArrows.right}
      onClick={handleNextSlides}
    >
      <Arrow />
    </IconButton>
  );

  if (!displayItems.length) {
    return null;
  }

  return (
    <Box className={styles.containerWrapper}>
      <Typography variant="h3" className={styles.title}>
        <TranslateText value="vendorDashboard.reviews.title" />
      </Typography>

      <Box id="vendorReviews" className={styles.container}>
        {!isMobile && leftButton}
        <Swiper
          style={{ width: "100%" }}
          spaceBetween={16}
          slidesPerView="auto"
          slidesPerGroupAuto
          onSlideChange={setArrowDisability}
          onTransitionEnd={setArrowDisability}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
            setArrowDisability();
          }}
        >
          {displayItems.map((review) => (
            <SwiperSlide key={review._id}>
              <VendorReview review={review} />
            </SwiperSlide>
          ))}
        </Swiper>
        {!isMobile && rightButton}
        {isMobile && (
          <Box
            sx={{
              display: "flex",
              gap: "14px",
              width: "100%",
            }}
          >
            {leftButton}
            {rightButton}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ReviewsSwiper;
