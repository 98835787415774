import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import getItemsOfCategory from "../../../api/categories/getItemsOfCategory";
import { useTranslate } from "../../../context/translate/translate.context";
import ProductSwiper from "../../ProductsSwiper";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";

const CartPageRecommended = () => {
  const { translate } = useTranslate();

  const cartItems = useSelector((state) => state.cart);
  const { country } = useSelector((state) => state.user);

  const [similarProducts, setSimilarProducts] = useState([]);

  const navigate = useNavigate()

  useEffect(() => {
    (async () => {
      try {
        if (!cartItems?.length) {
          return;
        }

        const response = await getItemsOfCategory(
          cartItems[0].product.category?.name,
          5,
          country,
          cartItems[0].product.type._id
        );

        setSimilarProducts(
          response.data.filter(
            (item) =>
              !cartItems.some(({ product: { _id } }) => item._id === _id)
          )
        );
      } catch (error) {
        navigate('/404')
        //console.error(error);
      }
    })();
  }, []);

  return (
    !!similarProducts?.length && (
      <Box className={styles.container}>
        <Accordion className={styles.accordionContainer}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="Recommended products"
          >
            <Typography>
              {translate("cartPage.recommendedSectionTitle")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ProductSwiper products={similarProducts} />
          </AccordionDetails>
        </Accordion>
      </Box>
    )
  );
};

export default CartPageRecommended;
