import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useEffect, useRef } from "react";

import { getTelegramChatCreated } from "../../../api/settings/telegram";
import { useTranslate } from "../../../context/translate/translate.context";
import { TranslateText } from "../../translate-text";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";

const TelegramContactBot = ({ next, close }) => {
  const { translate } = useTranslate();

  const statusCheck = useRef(null);

  const navigate = useNavigate()

  const onTelegramAuthCallback = async () => {
    try {
      const response = await getTelegramChatCreated();
      if (response.data.chat) {
        setTimeout(() => next(), 100);
      }
    } catch (error) {
      navigate('/404')
    } finally {
      statusCheck.current = setTimeout(() => onTelegramAuthCallback(), 3000);
    }
  };

  useEffect(() => {
    statusCheck.current = setTimeout(() => onTelegramAuthCallback(), 3000);

    return () => {
      clearTimeout(statusCheck.current);
    };
  }, []);

  return (
    <Box className={styles.container}>
      <Typography variant="h5" className={styles.title}>
        {translate("checkout.stripe.steps.step2.title")}
      </Typography>
      <Typography
        variant="h5"
        className={styles.description}
        dangerouslySetInnerHTML={{
          __html: translate("checkout.stripe.steps.step2.description", {
            link: `<a href="https://t.me/${process.env.REACT_APP_TELEGRAM_BOT_NAME}">t.me/${process.env.REACT_APP_TELEGRAM_BOT_NAME}</a>`,
            message: '"/start"',
          }),
        }}
      />

      <CircularProgress />

      <Button className={styles.cancelButton} onClick={close}>
        <TranslateText value="checkout.stripe.steps.step1.cancel" />
      </Button>
    </Box>
  );
};

export default TelegramContactBot;
