const User = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="#F6F6F6"
  >
    <path d="M7.99944 0.833496C6.25054 0.833496 4.83277 2.25126 4.83277 4.00016C4.83277 5.74906 6.25054 7.16683 7.99944 7.16683C9.74834 7.16683 11.1661 5.74906 11.1661 4.00016C11.1661 2.25126 9.74834 0.833496 7.99944 0.833496ZM5.83277 4.00016C5.83277 2.80355 6.80282 1.8335 7.99944 1.8335C9.19605 1.8335 10.1661 2.80355 10.1661 4.00016C10.1661 5.19678 9.19605 6.16683 7.99944 6.16683C6.80282 6.16683 5.83277 5.19678 5.83277 4.00016Z" />
    <path d="M7.99944 8.16683C6.45707 8.16683 5.03605 8.51742 3.98307 9.10972C2.94576 9.69321 2.16611 10.5775 2.16611 11.6668L2.16606 11.7348C2.16531 12.5094 2.16436 13.4815 3.01705 14.1759C3.43669 14.5176 4.02376 14.7606 4.81691 14.9211C5.61228 15.0821 6.64892 15.1668 7.99944 15.1668C9.34995 15.1668 10.3866 15.0821 11.182 14.9211C11.9751 14.7606 12.5622 14.5176 12.9818 14.1759C13.8345 13.4815 13.8336 12.5094 13.8328 11.7348L13.8328 11.6668C13.8328 10.5775 13.0531 9.69321 12.0158 9.10972C10.9628 8.51742 9.54181 8.16683 7.99944 8.16683ZM3.16611 11.6668C3.16611 11.0993 3.58036 10.4836 4.47333 9.9813C5.35064 9.48781 6.59629 9.16683 7.99944 9.16683C9.40259 9.16683 10.6482 9.48781 11.5255 9.9813C12.4185 10.4836 12.8328 11.0993 12.8328 11.6668C12.8328 12.5387 12.8059 13.0295 12.3504 13.4004C12.1034 13.6016 11.6904 13.7979 10.9836 13.941C10.2789 14.0836 9.31559 14.1668 7.99944 14.1668C6.68329 14.1668 5.71993 14.0836 5.0153 13.941C4.30845 13.7979 3.89552 13.6016 3.6485 13.4004C3.19298 13.0295 3.16611 12.5387 3.16611 11.6668Z" />
  </svg>
);

export default User;
