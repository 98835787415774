import {
  Container,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";

import styles from "./styles.module.css";
import { useTheme } from "@emotion/react";

const PrivacyPolicy = () => {

  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Container className={styles.privacyContainer}>
      <Typography variant="h4">Политика конфиденциальности</Typography>
      <Divider />
      <Typography variant="body2">
        Настоящая политика конфиденциальности (далее — Политика) действует в
        отношении персональных данных, которые могут быть получены
        Индивидуального предпринимателя ИП Горелкин Евгений Андреевич ИНН 346000803002, ОГРН 322344300043709 (далее – MentUp) при использовании вами:
        <ul>
          <li>
            веб-сайта{" "}
            <Link className={styles.privacyLink} to="/">
              {" "}
              https://mentup-store.com{" "}
            </Link>
            , принадлежащего MentUp, и его поддоменов (далее вместе — Сайт);
          </li>
          <li>
            любых программ, продуктов, услуг и/или сервисов, предоставляемых на
            Сайте (далее вместе — Сервисы);
          </li>
        </ul>
        а также в ходе исполнения ИНН (346000803002) любых соглашений и
        договоров, заключённых с вами в связи с использованием Сервисов на
        Сайте.
      </Typography>
      <Typography variant="body2">
        <strong>Политика содержит информацию о том:</strong>
        <ul>
          <li>какие персональные данные MentUp получает от вас;</li>
          <li>
            как MentUp использует и обрабатывает полученные персональные данные;
          </li>
          <li>
            когда MentUp может передавать ваши персональные данных третьим
            лицам;
          </li>
          <li>
            как обеспечивается конфиденциальность и безопасность персональных
            данных.
          </li>
        </ul>
      </Typography>

      <Typography variant="h5">1. ОБЩИЕ ПОЛОЖЕНИЯ</Typography>
      <Divider />
      <Typography variant="body2">
        1.1. Персональные данные – это любая информация о человеке (субъекте
        персональных данных), по которой можно его определить.
      </Typography>
      <Typography variant="body2">
        1.2. Обработка персональных данных — это любые действия (операции) с
        персональными данными, включая их сбор, накопление, хранение, изменение,
        дополнение, использование, передачу (распространение, предоставление,
        доступ) третьим лицам, блокирование, удаление и уничтожение.
      </Typography>
      <Typography variant="body2">
        При этом конкретные действия (операции), которые MentUp совершает с
        вашими персональными данными, зависят от используемого Сервиса и могут
        доводиться до вашего сведения до начала обработки при помощи
        соответствующих юридических документов (оферт, условий использования и
        т.д.) или элементов пользовательского интерфейса (форма для заполнения
        внутри Сервиса означает, что MentUp осуществляет сбор ваших персональных
        данных).
      </Typography>
      <Typography variant="body2">
        1.3. MentUp обрабатывает только те данные и только теми способами,
        которые перечислены в Политике и которые характеризуют вас как
        пользователя Сайта и/или Сервисов.
      </Typography>
      <Typography variant="body2">
        1.4. Ваше обращение к Сайту означает ваше согласие с Политикой и ее
        условиями обработки персональных данных. Вы также можете дать согласие
        на обработку ваших персональных данных при заполнении формы регистрации
        на Сайте и иными способами, предусмотренными Политикой.
      </Typography>
      <Typography variant="body2">
        В случае несогласия с Политикой вы должны воздержаться от использования
        Сайта MentUp.
      </Typography>
      <Typography variant="body2">
        1.5. MentUp не может проверить и потому доверяет вам в том, что вы:
        <ul>
          <li>обладаете полной дееспособностью;</li>
          <li>предоставили собственные достоверные персональные данные.</li>
        </ul>
      </Typography>
      <Typography variant="body2">
        1.6. MentUp вправе вносить изменения в Политику, в т.ч. в связи с
        изменениями законодательства о защите персональных данных, без
        специального уведомления.
      </Typography>
      <Typography variant="body2">
        В случае внесения изменений обновленная версия Политики публикуется на
        Сайте в разделе
        <Link className={styles.privacyLink} to="/info/laws/privacy-policy">
          {" "}
          https://mentup-stre.com/info/laws/privacy-policy{" "}
        </Link>{" "}
        и вступает в силу с даты публикации. Актуальная дата всегда будет
        указана непосредственно под текстом Политики.
      </Typography>
      <Typography variant="body2">
        MentUp рекомендует время от времени просматривать эту страницу, чтобы
        отслеживать возможные изменения. Продолжение использования вами Сайта
        после публикации изменений в Политике означает ваше согласие с данными
        изменениями.
      </Typography>
      <Typography variant="body2">
        1.7. При внесении существенных изменений в Политику мы сообщим вам об
        этом. MentUp также может попросить ваше согласие на продолжение
        обработки персональных данных, даже если вы давали подобное согласие до
        этого.
      </Typography>
      <Typography variant="body2">
        1.8. Вы можете обратиться к MentUp по любым вопросам, связанным с
        исполнением или изменением Политики, а также обработкой персональных
        данных:
        <ul>
          <li>
            по адресу электронной почты:{" "}
            <Link
              className={styles.privacyLink}
              to="mailto:admin@mentup-store.com"
            >
              {" "}
              admin@mentup-store.com{" "}
            </Link>
            ;
          </li>
          <li>
            воспользовавшись формой обратной связи
            <Link className={styles.privacyLink} to="/contact">
              {" "}
              https://mentup-store.com/contact
            </Link>
          </li>
        </ul>
        При обращении укажите тему «Обработка персональных данных».
      </Typography>
      <Typography variant="body2">
        MentUp ответит на ваше обращение не позднее 10 рабочих дней с момента
        его получения.
      </Typography>

      <Typography variant="h5">2. ВАШИ ПРАВА</Typography>
      <Divider />
      <Typography variant="body2">
        2.1. В любой момент, когда у MentUp есть ваши персональные данные, вы
        можете воспользоваться следующими правами:
        <ul>
          <li>
            Право на доступ – у вас есть право запросить копию ваших
            персональных данных, которые есть у MentUp;
          </li>
          <li>
            Право на исправление – у вас есть право запросить MentUp исправить
            ваши неточные и/или неполные персональные данные;
          </li>
          <li>
            Право на отзыв – вы вправе в любой момент времени отозвать свое
            согласие на обработку персональных данных;
          </li>
          <li>
            Право на забвение – вы вправе запросить удаление персональных
            данных, которые есть в отношении вас у MentUp, за исключением
            случаев, когда MentUp обязан хранить такие данные по закону.
          </li>
          <li>
            Если вы хотите, чтобы какие-либо ваши персональные были удалены, вы
            можете обратиться к нам с соответствующим запросом в соответствии с
            п.Вы можете обратиться к MentUp по любым вопросам, связанным с
            исполнением или изменением Политики, а также обработкой персональных
            данных: Политики.
          </li>
        </ul>
      </Typography>
      <Typography variant="body2">
        2.2. Вы также вправе обратиться к MentUp, если захотите уточнить порядок
        реализации иных прав, предусмотренных Федеральным законом «О
        персональных данных», в соответствии с п.Вы можете обратиться к MentUp
        по любым вопросам, связанным с исполнением или изменением Политики, а
        также обработкой персональных данных: Политики.
      </Typography>

      <Typography variant="h5">3. ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ</Typography>
      <Divider />
      <Typography variant="body2">
        3.1. MentUp обрабатывает персональные данные в цифровой форме
        (автоматизированно), ограничиваясь следующими действиями:
        <ul>
          <li>сбор;</li>
          <li>хранение;</li>
          <li>систематизация;</li>
          <li>накопление;</li>
          <li>предоставление третьим лицам;</li>
          <li>уточнение (изменение, обновление);</li>
          <li>уничтожение персональных данных.</li>
        </ul>
      </Typography>
      <Typography variant="body2">
        3.2. Обработка персональных данных производится MentUp с использованием
        баз данных, находящихся на территории Российской Федерации.
      </Typography>
      <Typography variant="body2">
        3.3. Цели обработки персональных данных:
        <TableContainer component={Paper} sx={{ 
            width: isMobile ? 350 : 550, 
            my: 2 
          }}>
          <Table sx={{ 
              minWidth: 250 
            }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Цель обработки</TableCell>
                <TableCell>Персональные данные</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableCell>Обеспечение работы Сайта</TableCell>
              <TableCell></TableCell>
            </TableBody>
          </Table>
        </TableContainer>
        <ul>
          <li>IP-адрес</li>
          <li>данные о местоположении</li>
          <li>данные о браузере</li>
          <li>User-Agent (данные об устройстве)</li>
          <li>cokkie-файлы</li>
        </ul>
        | | Доступ к Сервисам | - адрес электронной почты -| | Проведение сделки
        на Сайте | - адрес электронной почты - номер банковской карты для выплат
        (продавцам) - номер банковской карты для оплат (покупателям) | |
        Улучшение пользовательского опыта и повышение качества использования
        Сервисов | - адрес электронной почты - дата рождения - пол - место
        проживания - история объявлений на Сайте - история сделок на Сайте |
      </Typography>
      <Typography variant="body2">
        3.4. Все персональные данные, перечисленные в п.Обработка персональных
        данных производится MentUp с использованием баз данных, находящихся на
        территории Российской Федерации., обрабатываются MentUp в течение срока,
        необходимого для достижения указанной цели или отзыва согласия на их
        обработку.
      </Typography>
      <Typography variant="body2">
        3.5. По истечении срока обработки персональных данных они удаляются из
        базы данных MentUp.
      </Typography>

      <Typography variant="h5">
        4. КОНФИДЕНЦИАЛЬНОСТЬ ПЕРСОНАЛЬНЫХ ДАННЫХ
      </Typography>
      <Divider />
      <Typography variant="body2">
        4.1. MentUp обязуется обеспечивать конфиденциальность ваших персональных
        данных, т.е. не распространять и не раскрывать третьим лицам ваши
        персональные данные за исключением следующих случаев:
        <ul>
          <li>
            в рамках заключения и исполнения с вами различных договоров, в т.ч.
            условий использования отдельных Сервисов;
          </li>
          <li>
            для исполнения положений законодательства по раскрытию персональных
            данных в определенном обмене (например, по требованию налоговых
            органов или по запросу суда);
          </li>
          <li>с вашего согласия;</li>
          <li>
            при наличии иных правовых оснований в соответствии с
            законодательством.
          </li>
        </ul>
      </Typography>
      <Typography variant="body2">
        4.2. К числу третьих лиц, которым MentUp может передавать ваши
        персональные данные, относятся банки и операторы платежных систем при
        проведении расчетов на Сайте, государственные органы, запрашивающие
        персональные данные в пределах своей компетенции. Такие лица
        обрабатывают ваши персональные данные не от имени MentUp или в его
        интересах, поскольку имеют собственные цель и правовые основания такой
        обработки.
      </Typography>
      <Typography variant="body2">
        4.3. Вы также соглашаетесь, что MentUp вправе поручить обработку ваших
        персональных данных третьим лицам на основании заключаемых с ними
        договоров. Это означает, что MentUp может делегировать часть своих
        функций по обработке персональных данных определенному лицу, которое при
        этом будет действовать от имени или в интересах MentUp. Например, в
        случае необходимости в дополнительных вычислительных мощностях, MentUp
        может арендовать выделенные серверы у третьего лица в отсутствие
        собственных серверов.
      </Typography>
      <Typography variant="body2">
        4.4. Когда это необходимо для защиты и/или реализации собственных прав и
        законных интересов, MentUp может использовать сторонние сервисы и
        привлекать третьих лиц для проверки информации о вас, передавая им такую
        информацию. Таким образом MentUp, например, может выявлять и устранять
        финансовые и юридические риски, бороться с мошенническим или иным
        недобросовестным поведением пользователей Сервисов и предотвращать
        угрозы информационной безопасности, которые могут возникать в отношении
        Сервисов.
      </Typography>

      <Typography variant="h5">5. ФАЙЛЫ COOKIE</Typography>
      <Divider />
      <Typography variant="body2">
        5.1. Файлы cookie – это небольшие фрагменты данных с определённой (часто
        технической) информацией, которые Сайт передает вашему браузеру, а ваш
        браузер хранит и передает обратно Сайту с каждым вашим запросом к нему.
      </Typography>
      <Typography variant="body2">
        Использование файлов cookie является обычной практикой.
      </Typography>
      <Typography variant="body2">
        5.2. С помощью файлов cookie MentUp собирает такую информацию, как
        время, проведенное на Сайте, области посещения Сайта, веб-сайты со
        ссылками на наш Сайт и иную дополнительную информацию.
      </Typography>
      <Typography variant="body2">
        Обращаем ваше внимание, что файлы cookie не используются для сбора
        личной информации о вас.
      </Typography>
      <Typography variant="body2">
        5.3. Структура файла cookie, его содержание и технические параметры
        определяются Сайтом и могут быть изменены без направления вам
        предварительного уведомления.
      </Typography>
      <Typography variant="body2">
        5.4. MentUp применяет файлы cookie для:
        <ul>
          <li>
            запоминания пользователей Сайта и сохранения их персональных
            предпочтений и настроек;
          </li>
          <li>предоставления им персонализированных Сервисов;</li>
          <li>таргетирования рекламы;</li>
          <li>статистических и исследовательских целей;</li>
          <li>
            усовершенствования Сайта и Сервисов на основе пользовательских
            предпочтений.
          </li>
        </ul>
      </Typography>
      <Typography variant="body2">
        5.5. Обращаем Ваше внимание, что оборудование и программное обеспечение,
        используемые вами для посещения веб-сайтов, могут обладать функцией
        запрета операций с файлами cookie для любых или для определенных
        веб-сайтов, а также удаления ранее полученных файлов cookie.
      </Typography>
      <Typography variant="body2">
        В любое время вы можете самостоятельно удалить сохраненные на вашем
        компьютере файлы cookie, изменить параметры в настройках вашего браузера
        таким образом, чтобы браузер перестал сохранять все файлы cookie и
        оповещал их об отправке.
      </Typography>
      <Typography variant="body2">
        5.6. Отказ или ограничение использования файлов cookie не является
        препятствием для использования Сайта, однако в этом случае некоторые
        функции Сайта и Сервисы могут быть недоступны или работать с
        ограничениями. MentUp оставляет за собой право установить, что
        предоставление определенного Сервиса возможно лишь при условии, что
        прием и получение файлов cookie разрешены пользователем.
      </Typography>

      <Typography variant="h5">6. СЧЕТЧИКИ Яндекс.Метрика</Typography>
      <Divider />
      <Typography variant="body2">
        6.1. На Сайте MentUp размещены счетчики “Яндекс.Метрика” для сбора
        сведений об использовании Сайта (частота посещения Сайта, посещенные
        страницы и сайты, на которых вы были до перехода на Сайт и др.).
        Обращаем внимание, что Яндекс.Метрика собирает только IP-адреса,
        назначенные вам в день посещения Сайта, но не имя или другие
        идентификационные сведения.
      </Typography>
      <Typography variant="body2">
        6.2. Яндекс.Метрика размещает постоянный cookie-файл в вашем
        веб-браузере для идентификации вас в качестве уникального пользователя
        при следующем вашем посещении Сайта. Этот cookie-файл не может
        использоваться никем, кроме Яндекс, которому передаются сведения,
        собранные с помощью этого cookie-файла.
      </Typography>
      <Typography variant="body2">
        6.3. MentUp используем сведения, полученные через Яндекс.Метрику, только
        для совершенствования Сайта и его Сервисов. MentUp не объединяет
        сведения, полученные через Яндекс,Метрику, с персональными сведениями.
      </Typography>
      <Typography variant="body2">
        6.4. Возможности Яндекс по использованию и передаче третьим лицам
        сведений, собранных средством Яндекс.Метрики о ваших посещениях Сайта,
        ограничиваются Политикой конфиденциальности Яндекс. Вы можете запретить
        Яндекс.Метрике узнавать вас при повторных посещениях Сайта, отключив
        cookie-файлы Яндекс.Метрики в своем браузере.
      </Typography>

      <Typography variant="h5">7. БЕЗОПАСНОСТЬ ПЕРСОНАЛЬНЫХ ДАННЫХ</Typography>
      <Divider />
      <Typography variant="body2">
        7.1. MentUp защищает хранимые персональные данные от разглашения, полной
        или частичной утраты, а также несанкционированного доступа третьих лиц.
      </Typography>
      <Typography variant="body2">
        7.2. MentUp использует все необходимые технические и организационные
        меры по обеспечению безопасности и конфиденциальности хранимых
        персональных данных и постоянно актуализирует их с учетом последних
        технических разработок.
      </Typography>
      <Typography variant="body2">
        7.3. Если происходит утечка персональных данных, MentUp:
        <ul>
          <li>в течение 24 часов уведомляет об этом Роскомнадзор;</li>
          <li>
            в течение 72 часов проводит собственное расследование и уведомляет о
            его результатах Роскомнедзор.
          </li>
        </ul>
      </Typography>

      <Typography variant="h5">8. ОГРАНИЧЕНИЕ ПОЛИТИКИ</Typography>
      <Divider />
      <Typography variant="body2">
        8.1. Политика применима только к Сайту MentUp и не распространяется на
        какие-либо другие сторонние веб-сайты, в т.ч. в случаях когда:
        <ul>
          <li>сторонний веб-сайт содержит ссылку на Сайт MentUp;</li>
          <li>Сайт MentUp содержат ссылку на сторонний веб-сайт.</li>
        </ul>
      </Typography>
      <Typography variant="body2">
        8.2. Порядок обработки персональных данных на сторонних веб-сайтах
        производится в соответствии с собственными правилами конфиденциальности,
        которые могут отличаться от данной Политики. В связи с этим рекомендуем
        вам ознакомиться с этими правилами стороннего веб-сайта при переходе на
        него.
      </Typography>
    </Container>
  );
};

export default PrivacyPolicy;
