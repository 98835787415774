import { Box } from "@mui/material";
import { useState } from "react";
import OtpInput from "react-otp-input";

import { checkCaptcha, logInOtp } from "../../../api/auth/login";
import { useTranslate } from "../../../context/translate/translate.context";
import Button from "../../UI/Button";
import styles from "./styles.module.css";
import FormInput from "../../FormInput";
import { registerComplete, registerGoogle } from "../../../api/auth/register";

const LoginStep2 = ({
  setAlertMessage,
  loginData,
  setLoginData,
  logIn,
  isRegister,
  logInFromRegister,
  isRegisterGoogle,
  country
}) => {
  const { translate } = useTranslate();

  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");

  const [userName, setUserName] = useState("")

  const onSubmit = async () => {
    try {
      setLoading(true);

      if(!isRegister) {
        setLoginData({ ...loginData, otp });

        /*
        const captchaRes = await checkCaptcha({ email: loginData.email });
        if (captchaRes.data.captcha) {
          setAlertMessage(translate("errorMessages.captchaRequired"));
          showCaptcha();
          return;
        } */

        await logIn(otp);
        
      } else {
        /*
        const response = await registerComplete({
          ...loginData,
          otp,
        });

        logIn(response);
        */
        // check otp
        const invite = JSON.parse(localStorage.getItem('hasInvite'))
        let dataToSend = {
          ...loginData,
          otp,
          username: userName
        }
        if(invite){
          dataToSend.invite = invite.fromUser
        }
        setLoginData(dataToSend);
        const response = await registerComplete(dataToSend);
        logInFromRegister(response)
      }
    } catch (err) {
      //console.error(err);
      setAlertMessage(translate("errorMessages.unexpectedError"));
    } finally {
      setLoading(false);
    }
  };

  const onSubmitGoogle = async (localData) => {
    try {
      setLoading(true);
      const invite = JSON.parse(localStorage.getItem('hasInvite'))
      let dataToSend = {
        ...loginData,
        country: country,
        username: userName
      }
      if(invite){
        dataToSend.invite = invite.fromUser
      }
      const response = await registerGoogle(dataToSend);

      logInFromRegister(response);
    } catch (error) {
      //console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className={styles.form}>
      {(isRegister || isRegisterGoogle) && (
        <FormInput
          name="username"
          label={translate("authentication.nickname")}
          autoComplete="username"
          disabled={loading}
          onChange={(e) => setUserName(e.target.value)}
        />
      )}
      {!isRegisterGoogle && 
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          inputStyle={{
            background: "#f6f6f60d",
            border: "1px solid #f6f6f680",
            borderRadius: "3px",
            color: "#f6f6f6",
            fontSize: "24px",
            height: "38px",
            padding: "8px 12px",
            textAlign: "center",
            transition: "all 200ms cubic-bezier(0, 0, 0.2, 1)",
            width: "22px",
          }}
          shouldAutoFocus={true}
          skipDefaultStyles
          renderSeparator={<div style={{ width: "8px" }} />}
          renderInput={(props) => <input {...props} />}
        />
      }
      <Button
        type="button"
        size="large"
        fullWidth
        loading={loading}
        style={{ marginTop: "16px" }}
        onClick={() => !isRegisterGoogle ?  onSubmit() : onSubmitGoogle()}
      >
        {translate("authentication.signInToYourAccount")}
      </Button>
    </Box>
  );
};

export default LoginStep2;
