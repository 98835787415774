import { useTheme } from "@emotion/react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputBase,
  Pagination,
  Slider,
  Typography,
} from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import clsx from "clsx";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

import {
  getPurchases,
  getPurchasesFilters,
} from "../../../../api/personalOffice/requests";
import { useTranslate } from "../../../../context/translate/translate.context";
import FiltersSVG from "../../../../lib/svg/filters";
import PurchaseCard from "../../../PurchaseCard";
import SearchIcon from "../../../svg/SearchIcon";
import FilterDropdown from "../FilterDropdown";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";

const UserInfoTabPurchases = ({ openTransactions }) => {
  const theme = useTheme();
  const { translate } = useTranslate();

  const userRedux = useSelector((state) => state.user);

  const [from, setFrom] = useState(null)

  const navigate = useNavigate()

  const [loading, setLoading] = useState(true);
  const [pages, setTotalPages] = useState(0);
  const [items, setItems] = useState([]);
  const [allFilters, setAllFilters] = useState();
  const [priceRadius, setPriceRadius] = useState([0, 0]);
  const [filters, setFilters] = useState({
    country: userRedux.country,
    text: "",
    limit: 5,
    page: 0,
    sort: { updatedAt: -1 },
    price: { min: 0, max: 0 },
    categories: [],
    confirmed: true,
    revoked: true,

    statuses: [],
    delivered: [true, false],
    paymentMethods: [],
  });

  const ref = useRef(null);

  const handlePriceChange = (_, newValue) => {
    setFilters((prev) => ({
      ...prev,
      price: { min: newValue[0], max: newValue[1] },
      page: 0,
    }));
  };

  // Effect to get the `from` parameter from the URL
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const fromParam = params.get('from');
    setFrom(fromParam);
  }, []); // Empty dependency array means this effect runs only once after the initial render

  // Effect to handle navigation when `from` and `items` are updated
  useEffect(() => {
    if (from && items.length > 0) {
      navigate(`/orderb/${items[0]._id}`);
    }
  }, [from, items]); // Dependency array ensures this effect runs when `from` or `items` changes

  const handleSearch = useCallback(
    async (f) => {
      setLoading(true);
      const { data } = await getPurchases(f);
      setItems(data.items);
      setTotalPages(data.totalPages);
      setLoading(false);
    },
    [filters, filters.text]
  );

  const debouncedSearchText = useMemo(() => debounce(handleSearch, 300), []);

  const checkCategory = useCallback(
    async (id) => {
      const categories = filters.categories.includes(id)
        ? filters.categories.filter((categoryId) => categoryId !== id)
        : filters.categories.concat(id);

      setFilters((prev) => ({ ...prev, categories, page: 0 }));
    },
    [filters.categories]
  );

  const checkStatus = useCallback(
    async (value) => {
      const statuses = filters.statuses.includes(value)
        ? filters.statuses.filter((categoryId) => categoryId !== value)
        : filters.statuses.concat(value);

      setFilters((prev) => ({ ...prev, statuses, page: 0 }));
    },
    [filters.statuses]
  );

  const checkPaymentMethod = useCallback(
    async (value) => {
      const paymentMethods = filters.paymentMethods.includes(value)
        ? filters.paymentMethods.filter((v) => v !== value)
        : filters.paymentMethods.concat(value);

      setFilters((prev) => ({ ...prev, paymentMethods, page: 0 }));
    },
    [filters.paymentMethods]
  );

  const checkSort = useCallback(
    (sort) => setFilters((prev) => ({ ...prev, sort, page: 0 })),
    []
  );

  const checkDelivered = useCallback(
    async (v) => {
      const delivered = filters.delivered.includes(v)
        ? filters.delivered.filter((deliveredV) => deliveredV !== v)
        : filters.delivered.concat(v);

      setFilters((prev) => ({ ...prev, delivered, page: 0 }));
    },
    [filters.delivered]
  );

  const getFilters = useCallback(async () => {
    try {
      const { data } = await getPurchasesFilters();
      setAllFilters(data);
      setPriceRadius([data.price.min, data.price.max]);
      setFilters((prev) => ({
        ...prev,
        categories: data.categories.map(({ _id }) => _id),
        paymentMethods: data.paymentMethods,
        statuses: data.statuses,
        price: data.price,
      }));
    } catch (error) {
      navigate('/404')
    }
  }, []);

  useEffect(() => {
    getFilters();
  }, []);

  useEffect(() => {
    if (allFilters) {
      debouncedSearchText(filters);
    }
    return () => debouncedSearchText.cancel();
  }, [
    allFilters,
    filters.text,
    filters.limit,
    filters.page,
    filters.sort.updatedAt,
    filters.price.min,
    filters.price.max,
    filters.user,
    filters.categories,
    filters.statuses,
    filters.delivered,
    filters.paymentMethods,
  ]);

  return (
    <Box className={styles.container}>
      <Box className={styles.filtersContainer}>
        <Box className={styles.nowrap}>
          <FiltersSVG
            color={theme.palette.mode === "dark" ? "#f6f6f6" : "#1f1d22"}
          />

          <Box className={styles.filters}>
            <FilterDropdown title="vendorDashboard.filters.category">
              {allFilters?.categories.map(({ _id, name }) => (
                <Box key={_id} className={styles.categoryItem}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={_id}
                        color="primary"
                        onChange={({ target }) => checkCategory(target.value)}
                        checked={filters.categories.includes(_id)}
                      />
                    }
                    label={name}
                  />
                </Box>
              ))}
            </FilterDropdown>
            <FilterDropdown title="vendorDashboard.filters.price.title">
              <Box className={styles.priceFilter}>
                <Typography
                  color="primary"
                  id="range-slider"
                  sx={{ width: "50px" }}
                >
                  {filters.price.min}
                </Typography>

                <Slider
                  min={priceRadius[0]}
                  max={priceRadius[1]}
                  getAriaLabel={() => "Price range"}
                  value={[filters.price.min, filters.price.max]}
                  onChange={handlePriceChange}
                  valueLabelDisplay="auto"
                  getAriaValueText={() => "Price range"}
                />

                <Typography
                  color="primary"
                  id="range-slider"
                  w
                  sx={{ width: "50px" }}
                >
                  {filters.price.max}
                </Typography>
              </Box>
            </FilterDropdown>
            <FilterDropdown title="vendorDashboard.filters.status.title">
              {allFilters?.statuses.map((status) => (
                <Box key={status} className={styles.categoryItem}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={status}
                        color="primary"
                        onChange={({ target }) => checkStatus(target.value)}
                        checked={filters.statuses.includes(status)}
                      />
                    }
                    label={translate(
                      `vendorDashboard.filters.status.${status}`
                    )}
                  />
                </Box>
              ))}
            </FilterDropdown>
            <FilterDropdown title="vendorDashboard.filters.delivered.title">
              <Box className={styles.categoryItem}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={true}
                      color="primary"
                      onChange={() => checkDelivered(true)}
                      checked={filters.delivered.includes(true)}
                    />
                  }
                  label={translate("vendorDashboard.filters.delivered.yes")}
                />
              </Box>
              <Box className={styles.categoryItem}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={false}
                      color="primary"
                      onChange={() => checkDelivered(false)}
                      checked={filters.delivered.includes(false)}
                    />
                  }
                  label={translate("vendorDashboard.filters.delivered.no")}
                />
              </Box>
            </FilterDropdown>
            {/*
            <FilterDropdown title="vendorDashboard.filters.updatedAt.title">
              <Box className={styles.categoryItem}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={-1}
                      color="primary"
                      onChange={() => checkSort({ updatedAt: -1 })}
                      checked={filters.sort.updatedAt === -1}
                    />
                  }
                  label={translate("vendorDashboard.filters.updatedAt.asc")}
                />
              </Box>
              <Box className={styles.categoryItem}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={1}
                      color="primary"
                      onChange={() => checkSort({ updatedAt: 1 })}
                      checked={filters.sort.updatedAt === 1}
                    />
                  }
                  label={translate("vendorDashboard.filters.updatedAt.desc")}
                />
              </Box>
            </FilterDropdown>
            <FilterDropdown title="vendorDashboard.filters.paymentMethod.title">
              {allFilters?.paymentMethods.map((paymentMethod) => (
                <Box key={paymentMethod} className={styles.categoryItem}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={paymentMethod}
                        color="primary"
                        onChange={({ target }) =>
                          checkPaymentMethod(target.value)
                        }
                        checked={filters.paymentMethods.includes(paymentMethod)}
                      />
                    }
                    label={translate(
                      `vendorDashboard.filters.paymentMethod.${paymentMethod}`
                    )}
                  />
                </Box>
              ))}
            </FilterDropdown>
            */}
          </Box>
        </Box>

        <Box className={styles.inputContainer}>
          <InputBase
            ref={ref}
            className={styles.input}
            value={filters.text}
            onChange={({ target }) =>
              setFilters((prev) => ({ ...prev, text: target.value, page: 0 }))
            }
            placeholder={translate("header.search")}
          />
          <IconButton
            className={styles.iconContainer}
            aria-label="clear search input"
            onClick={() => setFilters((prev) => ({ ...prev, text: "" }))}
            disabled={!filters.text}
          >
            {filters.text ? <ClearIcon /> : <SearchIcon />}
          </IconButton>
        </Box>
      </Box>

      <Box
        className={clsx(styles.itemsContainer, loading && styles.itemsLoading)}
      >
        {items.map((item) => (
          <PurchaseCard
            key={item._id}
            order={item}
            openTransactions={openTransactions}
          />
        ))}
      </Box>

      {!!pages && (
        <Box className={styles.paginationContainer}>
          <Pagination
            count={pages}
            color="primary"
            onChange={(_, page) =>
              setFilters((prev) => ({ ...prev, page: page - 1 }))
            }
          />
        </Box>
      )}
    </Box>
  );
};

export default UserInfoTabPurchases;
