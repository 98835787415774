import { Box, Button, Card, Collapse, Container, Divider, IconButton, List, ListItemButton, Snackbar, Typography } from "@mui/material";
import React, { useEffect } from 'react'
import MuiAlert from "@mui/material/Alert";
import styles from "./styles.module.css";
import { useState } from "react";
import StarIcon from '@mui/icons-material/Star';
import Person2Icon from '@mui/icons-material/Person2';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ReplyIcon from '@mui/icons-material/Reply';
import CloseIcon from '@mui/icons-material/Close';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Socials from "../Layout/Footer/Socials";
import { Link, useNavigate } from 'react-router-dom';
import { MentupSwitch } from "../../lib/theme";
import { LoadingButton } from "@mui/lab";
import MentupModal from "../MentupModal/MentupModal";

import { useTheme } from "@emotion/react";

// Api
import { checkPurchase, checkReferal, checkTicket, registerTicket, getTickets } from "../../api/comps/CompTicketsRequests";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const GiveAway = () => {

    const navigate = useNavigate();

    const theme = useTheme()

    const prizes = [
        {
            quantImage: "first.png",
            image: "prize1.png",
            title: "100.000 ₽"
        }
    ]

    // Refferal checl
    const [hasRefferal, setHasRefferal] = useState(false);
    const [hasRefferalLoading, setHasRefferalLoading] = useState(false)

    useEffect(() => {
        const checkIfUserHasRefferal = async () => {
            setHasRefferalLoading(true)
            try {
                await checkReferal(isAuth._id)
                .then((res) =>{
                    if(res && res.data) {
                        setHasRefferal(res.data.hasRefferal)
                    }
                })
                .finally(() =>{
                    setHasRefferalLoading(false)
                })
            } catch (err) {
                setHasRefferalLoading(false)
                //console.log(err)
            }
        }
        if(isAuth) {
            checkIfUserHasRefferal()
        }
    }, [])

    // Register
    const [loadingRegister, setLoadingRegister] = useState(false);
    const [isRegistered, setIsRegistered] = useState(false)

    useEffect(() => {
        const checkIfUserIsRegistered = async () => {
            setLoadingRegister(true)
            try {
                await checkTicket(isAuth._id)
                .then((res) =>{
                    if(res && res.data) {
                        setIsRegistered(res.data.isRegistered)
                    }
                })
                .catch((err) => {
                    setLoadingRegister(false)
                })
                .finally(() =>{
                    setLoadingRegister(false)
                })
            } catch (err) {
                setLoadingRegister(false)
                //console.log(err)
            }
        }
        if(isAuth) {
            checkIfUserIsRegistered()
        }
    }, [])

    // Participants
    const [participants, setParticipants] = useState(0);
    useEffect(() => {
        const getTotalTicketsCampaign = async () => {
            setLoadingRegister(true)
            try {
                const competitionId = '66704c1037b959aab3f46b55'
                await getTickets(competitionId)
                .then((res) =>{
                    if(res && res.data) {
                        setParticipants(parseInt(res.data.total))
                    }
                })
                .catch((err) => {
                    setLoadingRegister(false)
                })
                .finally(() =>{
                    setLoadingRegister(false)
                })
            } catch (err) {
                setLoadingRegister(false)
                //console.log(err)
            }
        }
        getTotalTicketsCampaign()
    }, [])

    // Modals
    const [openRegisterModal, setOpenRegisterModal] = useState(false);

    const isAuth = JSON.parse(localStorage.getItem("user"));

    const userSettings = JSON.parse(localStorage.getItem("userSettings"));

    const [snackBar, setSnackBar] = useState({
        open: false,
        vertical: "bottom",
        horizontal: "right",
        message: "",
      });

    // Helper function 
    const registerUserInDB = async (userId) => {
        try{
            const data = {
                user: userId
            }
            await registerTicket(data)
            .then((res) => {
                // Snackbar success
                setIsRegistered(true)
                setSnackBar({
                    ...snackBar,
                    open: true,
                    message: "Вы успешно зарегестрировались!"
                })
                
            })
            .catch((error) => {
                // SnackBar error
                console.log(error);
            })
            .finally(() => {    
                setLoadingRegister(false);
            }
            );
        } catch (error) {
            // Snackbar error
            setLoadingRegister(false);
            //console.log(error);
        }
    }

    const handleRegisterButtonClick = async () => {
        if(!isAuth) {
            navigate("/login");
        } else {
            setLoadingRegister(true);
            // Make Api call to register user
            await registerUserInDB(isAuth._id);
        }
    }

    // FAQ

    const faq = [
        {
            id: 1,
            title: "Как принять участие в розыгрыше?",
            description: "За выполнение условий даются билеты для участия. Всего можно получить 5 билетов. Выполните хотя бы 1 условие, чтобы принять участие в розыгрыше."
        },
        {
            id: 2,
            title: "Как выполнить условия?",
            description: `1. Перейдите на 4 соц. сети и подпишитесь. 

2. Пользователь должен зарегистрироваться по вашей реферальной ссылке.

3. Оплатите товар с платежной системы или пополните баланс одним платежом на сумму 500 ₽ или более.`
        },
        {
            id: 3,
            title: "Как получить приз?",
            description: `Выберем 10 победителей и вручим призы с общим призовым фондом 150 000 ₽. Если вы займете 1 место, то мы свяжемся с вами и доставим приз в любую точку мира. Если 2-5 место, то 5 000 ₽ будут начислены на ваш баланс на сайте.`
        },
        {
            id: 4,
            title: "Как узнать о результатах?",
            description: `Остерегайтесь фейков! Мы проведем итоги 23 июня в 18:00 по московскому времени на прямой трансляции на YouTube-канале Mentup. Мы оповестим вас о результатах розыгрыша в официальном чате Mentup на сайте, а также в наших соц. сетях. Включите уведомления, чтобы не пропустить итоги.`
        },
        {
            id: 5,
            title: "Что запрещено?",
            description: "Запрещено регистрировать дополнительные профили на сайте, чтобы выполнить условия розыгрыша или принять участие с нескольких аккаунтов. 1 человек = 1 участник. Бот проверит и в случае нарушения заблокирует все профили на сайте за нарушение условий участия."
        },
        {
            id: 6,
            title: "Как увеличить шансы?",
            description: "Чем больше условий выполните, тем больше билетов получите. Если у вас 1 билет, а у друга 7 билетов – у него шансы на победу в 7 раз больше. Расскажите другу о розыгрыше, чтобы повысить ваши шансы на победу."
        }
    ]

    const [open, setOpen] = useState(null);

    const handleClickFAQ = (id) => {
        setOpen(open === id ? null : id);
    };

    // Text Support Button

    const handleTextSupportClick = () => {
        if(!isAuth) {
            navigate("/login");
        } else {
            navigate("/chat");
        }
    }

    // Share button 

    const [shareModalOpen, setShareModalOpen] = useState(false)

    const handleShareButton = () => {
        if(!isAuth) {
            navigate('/login')
        } else {
            setShareModalOpen(true)
        }
    }

    const handleShareModalClose = () => {
        setShareModalOpen(false)
    }

    const handleCopyLinkButton = () => {
        if(!isAuth) {
            navigate('/login')
        } else {
            let link = 'https://mentup-store.com/login?invite='+isAuth.userSlug
            navigator.clipboard.writeText(link)
            setSnackBar({
                ...snackBar,
                open: true,
                message: "Ссылка успешно скопирована!"
            })
            handleShareModalClose()
        }
    }

    // Make Purchase

    const [hasOnePurchase, setHasOnePurchase] = useState(false)
    const [hasOnePurchaseLoading, setHasOnePurchaseLoading] = useState(false)

    useEffect(() => {
        const checkIfUserHasPurchase = async () => {
            setHasOnePurchaseLoading(true)
            try {
                await checkPurchase(isAuth._id)
                .then((res) =>{
                    if(res && res.data) {
                        setHasOnePurchase(res.data.hasPurchase)
                    }
                })
                .finally(() =>{
                    setHasOnePurchaseLoading(false)
                })
            } catch (err) {
                setHasOnePurchaseLoading(false)
                //console.log(err)
            }
        }
        if(isAuth) {
            checkIfUserHasPurchase()
        }
    }, [])

    const handleMakePurchaseButton = () =>{
        if(!isAuth) {
            navigate("/login")
        } else {
            if(!hasOnePurchase)
                navigate('/')
        }
    }

    // Subscribe button

    const handleSubscribeButton = () =>{
        if(!isAuth) {
            navigate('/login')
        } else {
            if(userSettings && !userSettings.notifications)
                navigate('/profile'+isAuth.userSlug+'#settings')
        }
    }

    return (
        <Container
            className={styles.containerComponent}
        >
            {/* Misc SnackBar */}
            <Snackbar
                anchorOrigin={{
                vertical: snackBar.vertical,
                horizontal: snackBar.horizontal,
                }}
                open={snackBar.open}
                autoHideDuration={4000}
                onClose={() => setSnackBar({ ...snackBar, open: false })}
                key={snackBar.vertical + snackBar.horizontal}
            >
                <Alert severity="success" sx={{ width: "100%" }}>
                    {snackBar.message}
                </Alert>
            </Snackbar>
            {/* Banner */}
            <Box className={styles.bannerContaier}>
                <Card
                    className={styles.banner}
                    sx={{
                        backgroundImage: `url("/images/giveaway.png")`,
                    }}
                />
            </Box>

            {/* Prize + Conditions */}
            <Box className={styles.rowContainer} >
                {/* Prizes Container 
                <Box className={styles.prizeContainer}>
                    <Box className={styles.prizeTitleContainer}>
                        <Typography className={styles.prizesTitle}>
                            Призы
                        </Typography>
                    </Box>
                    {prizes && prizes.map((prize, index) => (
                        <Box key={index} className={styles.prizeCard}>
                            <Card 
                                className={styles.prizeQuantImage}
                                sx={{
                                    backgroundImage: `url("/images/giveaway/${prize.quantImage}")`,
                                }}
                            />
                            <Card 
                                className={styles.prizeCardImage}
                                sx={{
                                    backgroundImage: `url("/images/giveaway/${prize.image}")`,
                                }}    
                            />
                            <Typography className={styles.prizeCardTitle}>
                                {prize.title}
                            </Typography>
                        </Box>
                    ))}
                    <Box className={styles.prizeInfoCard}>
                        <Card 
                            className={styles.prizeCardImage}
                            sx={{
                                backgroundImage: `url("/images/giveaway/restQuant.png}")`,
                            }}
                        />
                        <Card 
                            className={styles.prizeCardImage}
                            sx={{
                                backgroundImage: `url("/images/giveaway/restPrize.png")`,
                            }}    
                        />
                        <Typography className={styles.prizeCardTitle}>
                            5000 ₽
                        </Typography>
                    </Box>
                </Box> */}

                {/* Conditions Container */}
                <Box className={styles.conditionContainer}>

                    <Box className={styles.prizeTitleContainer}>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px"
                        }}>
                            <Card className={styles.prizeCardImage} sx={{backgroundImage: `url("/images/giveaway/alert.png")`}} />
                            <Typography className={styles.prizesTitle}>
                                Обязательные условия
                            </Typography>
                        </Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",

                            gap: "5px"
                        }}>
                            <Typography className={styles.prizesTitleDescription}>
                                Вы можете получить 6
                            </Typography>
                            <StarIcon sx={{ 
                                    color: "primary.main", 
                                    fontSize: "18px",
                                    marginTop: '2.5px'
                                }} />
                            <Typography className={styles.prizesTitleDescription}>
                                для участия
                            </Typography>
                        </Box>
                        
                    </Box> 

                    {/* Condition Cards */}
                    <Box className={styles.conditionCardsContainer}>

                        {/* Social Media Follow */}
                        <Card className={styles.conditionCard} >
                            <Box className={styles.conditionCardLeft} sx={{
                                opacity: userSettings && userSettings.notifications ? 0.5 : 1
                            }}>
                                <Typography className={styles.conditionCardTitle}>
                                    Подписка на соц. сети
                                </Typography>
                                <Box className={styles.socialMediaList}>
                                    <Socials from="giveaway" />
                                </Box>
                            </Box>
                            <Box className={styles.conditionCardRight}>
                                {userSettings && userSettings.notifications ? (
                                    <>
                                        <TaskAltIcon sx={{
                                            color: "primary.main",
                                            fontSize: "32px",
                                        }} />
                                    </>
                                ) : (
                                    <>
                                        <Typography className={styles.conditionCardDescription}>
                                            +1
                                        </Typography>
                                        <StarIcon sx={{ 
                                            color: "primary.main", 
                                            fontSize: "24px",
                                        }} />
                                    </>
                                )}
                            </Box>
                        </Card>

                        {/* Referall used */}
                        <Card className={styles.conditionCard} >
                            <Box className={styles.conditionCardLeft} sx={{
                                opacity: hasRefferal ? 0.5 : 1
                            }}>
                                <Typography className={styles.conditionCardTitle}>
                                    Пригласить 1 друга
                                </Typography>
                                <Box className={styles.socialMediaList}>
                                    <Person2Icon sx={{
                                        color: "primary.main",
                                        fontSize: "24px"
                                    }} />
                                    <Typography className={styles.conditionCardDescription}>
                                        { hasRefferal ? "1/1" : "0/1" }
                                    </Typography>
                                    <IconButton 
                                        sx={{
                                            color: "primary.main",
                                            marginLeft: "-10px",
                                            transform: "scaleX(-1)",
                                            marginBottom: "3px",
                                            '&:hover': {
                                                backgroundColor: "transparent"
                                            }
                                        }}
                                        onClick={() => handleShareButton()}
                                    >
                                        <ReplyIcon sx={{
                                            fontSize: "24px"
                                        }}  />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Box className={styles.conditionCardRight}>
                                {!hasRefferal ? (
                                    <>
                                        <Typography className={styles.conditionCardDescription}>
                                            +2
                                        </Typography>
                                        <StarIcon sx={{ 
                                            color: "primary.main", 
                                            fontSize: "24px",
                                        }} />
                                    </> 
                                ) : (
                                    <>
                                        <TaskAltIcon sx={{
                                            color: "primary.main",
                                            fontSize: "32px",
                                        }} />
                                    </>
                                )}
                            </Box>
                        </Card>

                        {/* Purchase of >500p */}
                        <Card className={styles.conditionCard} >
                            <Box className={styles.conditionCardLeft} sx={{
                                opacity: hasOnePurchase ? 0.5 : 1
                            }}>
                                <Typography className={styles.conditionCardTitle}>
                                    Совершить платеж от 500 ₽
                                </Typography>
                                <Box className={styles.socialMediaList}>
                                    <AccountBalanceWalletIcon sx={{
                                        color: "primary.main",
                                        fontSize: "24px"
                                    }} />
                                    <Typography className={styles.conditionCardDescription}>
                                        500 ₽
                                    </Typography>
                                    <IconButton 
                                        sx={{
                                            color: "primary.main",
                                            marginLeft: "-10px",
                                            fontSize: "24px",
                                            '&:hover': {
                                                backgroundColor: "transparent"
                                            }

                                        }}
                                        onClick={() => handleMakePurchaseButton()}
                                    >
                                        <AddCircleIcon  />
                                    </IconButton>
                                    {/* Make Purchase Modal (if not logged in modal to login) */}
                                </Box>
                            </Box>
                            <Box className={styles.conditionCardRight}>
                                {!hasOnePurchase ? (
                                    <>
                                        <Typography className={styles.conditionCardDescription}>
                                            +3
                                        </Typography>
                                        <StarIcon sx={{ 
                                            color: "primary.main", 
                                            fontSize: "24px",
                                        }} />
                                    </>
                                ) : (
                                    <>
                                        <TaskAltIcon sx={{
                                            color: "primary.main",
                                            fontSize: "32px",
                                        }} />
                                    </>
                                )}
                            </Box>
                        </Card>

                    {/* Register Button */}
                    <Box className={styles.registerButtonContainer}>
                        <LoadingButton
                            className={styles.button}
                            variant="contained"
                            loading={loadingRegister}
                            onClick={handleRegisterButtonClick}
                            disabled={isRegistered}
                        >
                            <Typography className={styles.registerButtonText}>{isRegistered ? 'Вы участвуите' : 'Участвовать'}</Typography>
                        </LoadingButton>
                    </Box>
                    {/* Nr of Participants */}
                    <Box className={styles.participantsContainer}>
                        <Typography className={styles.participantsText}>{participants}</Typography>
                        <StarIcon sx={{ 
                            color: "primary.main", 
                            fontSize: "20px",
                        }} />
                        <Typography className={styles.participantsText}>Участников</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
            <Divider className={styles.divider} />
            {/* Results Info */}
            <Box className={styles.resultsContainer}>
                <Box className={styles.prizeTitleContainer}>
                    <Box className={styles.prizeTitleInnerContainer}>
                        <Card className={styles.prizeCardImage} sx={{backgroundImage: `url("/images/giveaway/alert2.png")`}} />
                        <Typography className={styles.prizesTitle}>
                            Результаты
                        </Typography>
                    </Box>
                </Box>
                <Box className={styles.rowContainerResults}>
                    <Box className={styles.resultsInfoContainer}>
                        <Typography className={styles.resultsInfoTitle}>
                            Розыгрыш состоится 30.07.2024 в 20:00
                        </Typography>
                        <Typography className={styles.resultsInfoText}>
                            Выберем 5 победителей случайным образом в прямом эфире на Youtube-канале Mentup
                        </Typography>
                    </Box>

                    <Box className={styles.subToNotificationsContainer}>
                        <Card className={styles.prizeCardImage} sx={{backgroundImage: `url("/images/giveaway/notifAlert.png")`}} />
                        <Box className={styles.subToNotifications}>
                            <Box className={styles.subToNotificationsText}>
                                <Typography className={styles.subToNotificationsTitle}>
                                    Подпишитесь на уведомления чтобы не пропустить розыгрыш
                                </Typography>
                                <MentupSwitch
                                    checked={userSettings ? userSettings.notifications : false}
                                    onChange={() => handleSubscribeButton()}
                                />
                            </Box>
                            <Box className={styles.registerButtonContainer}>
                                <Button
                                    variant="contained"
                                    className={styles.button}
                                    sx={{
                                        marginTop: '10px',
                                    }}
                                    disabled={userSettings ? userSettings.notifications : false}
                                    onClick={() => handleSubscribeButton()}
                                >
                                    {userSettings ? userSettings.notifications ? 'Вы Уже подписаны' : 'Подписаться' : 'Подписаться'}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            
            {/* FAQ */}
            <Box className={styles.faqContainer}>
                <Box className={styles.faqContainerList}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px"
                    }}>
                        <Card className={styles.prizeCardImage} sx={{backgroundImage: `url("/images/giveaway/alert2.png")`}} />
                        <Typography className={styles.prizesTitle}>
                            Остались вопросы?
                        </Typography>
                    </Box>
                </Box>

                <Box className={styles.faqList}>
                    <List className={styles.faqListInner}>
                        {faq && faq.map((faq, index) => (
                            <Box key={index} className={styles.faqCard}>
                                <ListItemButton className={styles.faqButton} key={index} onClick={() => handleClickFAQ(faq.id)}>
                                    <Typography className={styles.faqCardTitle}>
                                        {faq.title}
                                    </Typography>
                                </ListItemButton>
                                <Collapse in={open === faq.id} timeout="auto" unmountOnExit> 
                                    <Divider />
                                    <List component="div" disablePadding> 
                                        <Typography className={styles.faqCardDescription}>
                                            {faq.description}
                                        </Typography>
                                    </List> 
                                </Collapse> 
                            </Box>
                        ))}
                    </List>
                    <Box className={styles.textSupportContainer} >
                        <Button
                            variant="contained"
                            className={styles.button}
                            onClick={() => handleTextSupportClick()}
                        >
                            Написать в поддержку
                        </Button>
                    </Box>
                </Box>

                <Box className={styles.faqFooter}>
                    <Typography className={styles.faqFooterText}>
                        Участвуя в розыгрыше, вы принимаете <Typography component={Link} to="/giveaway-terms" sx={{
                            color: "primary.main",
                            cursor: "pointer",
                            textDecoration: "none",
                            '&:hover': {
                                textDecoration: "underline",
                            }
                        }}>условия конкурса</Typography>. Желаем удачи!
                    </Typography>
                </Box>
            </Box>

            {/* Share Button Modal */}
            <MentupModal open={shareModalOpen} close={handleShareModalClose}>
                <Box className={styles.modalHeader}>
                    <Typography className={styles.modalHeaderTitle}>
                        Поделиться
                    </Typography>
                    <IconButton
                        onClick={() => setShareModalOpen(false)}
                    >
                        <CloseIcon sx={{
                            fontSize: '26px',
                            color: 'gray',
                        }} />
                    </IconButton>
                </Box>
                <Box className={styles.confirmContainer}>
                    <Box className={styles.headerContainer}>
                        
                        <Box className={styles.alertTitleContainer}>
                            <Typography className={styles.alertTitle}>+10%</Typography>
                            <Typography>вы получите от первой оплаты</Typography>
                        </Box>
                    </Box>
                </Box>
                <LoadingButton
                    className={styles.button}
                    onClick={() => handleCopyLinkButton()}
                >
                    <Typography>Скопировать ссылку</Typography>
                </LoadingButton>
            </MentupModal>
        </Container>
    );
}
    
export default GiveAway;