import { createContext, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";

import { getWallet } from "../../api/wallet";
import { setWallet } from "../../store/walletStore";
import { useNavigate } from "react-router-dom";

const Context = createContext(null);
export const GlobalServiceProvider = ({ children }) => {
  const dispatch = useDispatch();


  const resetBalance = async () => {
    try {
      const response = await getWallet();

      const currentUser = localStorage.getItem("user");

      if (currentUser && response.data?.wallet) {
        dispatch(setWallet(response.data?.wallet));

        const parsedUser = JSON.parse(currentUser);
        parsedUser.wallet = response.data.wallet;
        localStorage.setItem("user", JSON.stringify(parsedUser));
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    resetBalance();
  }, []);

  return (
    <Context.Provider value={{ resetBalance }}>{children}</Context.Provider>
  );
};

export const useGlobalServiceProvider = () => useContext(Context);
