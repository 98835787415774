import axios from "axios";
import Cookies from "js-cookie";

const API = axios.create({
  baseURL: process.env.NODE_ENV.includes("production")
    ? process.env.REACT_APP_SERVER_URL
      ? process.env.REACT_APP_SERVER_URL
      : ""
    : "http://localhost:3030/api/",
});

const header = () => {
  const token = Cookies.get("refreshToken");
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
};

export const confirmRejectProductBuyerApi = (data) => {
  //console.log('from api')
  //console.log(data.orderStaus)
  if (
    data.productId &&
    data.order &&
    data.orderStatus &&
    data.productStatus &&
    (data.status || data.status === false)
  ) {
    return API.patch("/order/purchase/updateFulfiled", data, header());
  } else {
    throw new Error("Missing data");
  }
};
