import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";

import styles from "./styles.module.css";

const MentupStepper = ({ steps = 0, current = 0, getStepTitle }) => {
  const [left, setLeft] = useState(0);
  const [right, setRight] = useState(0);

  return (
    <Box className={styles.container}>
      <Box
        className={styles.midLine}
        sx={{
          left,
          right,
          backgroundImage: `linear-gradient(
            90deg,
            #9879ec ${(current * 100) / (steps - 1)}%,
            rgba(152, 121, 236, 0.656568) 0.9%,
            rgba(152, 121, 236, 0) 100%
          )`,
        }}
      />

      {Array.from({ length: steps }).map((_, index) => (
        <Box key={`step-dot-${index}`} className={styles.step}>
          <Typography
            className={clsx(
              styles.stepNumber,
              index <= current && styles.stepNumberPrevious
            )}
          >
            {getStepTitle(index)}
          </Typography>

          <Box
            ref={(el) =>
              index === 0
                ? setLeft(el?.parentElement.offsetWidth / 2)
                : index === steps - 1
                ? setRight(el?.parentElement.offsetWidth / 2)
                : null
            }
            className={clsx(
              styles.stepDot,
              index < current && styles.stepPrevious,
              index === current && styles.stepDotInner
            )}
          />
        </Box>
      ))}
    </Box>
  );
};

export default MentupStepper;
