import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";

import { useTranslate } from "../../../context/translate/translate.context";
import FormInput from "../../FormInput";
import Button from "../../UI/Button";
import styles from "./styles.module.css";

const PromocodeBlock = ({ submit }) => {
  const { translate } = useTranslate();

  const [discountCode, setDiscountCode] = useState("");

  const handleSubmit = async () => {
    if (!discountCode.length) {
      return;
    }

    try {
      await submit(discountCode);
    } catch {
    } finally {
      setDiscountCode("");
    }
  };

  return (
    <Box className={styles.promocodeContainer}>
      <Typography className={styles.promocodeContainer__title}>
        {translate("cartPage.usePromocode")}
      </Typography>
      <FormInput
        className={styles.promocodeContainer__input}
        value={discountCode}
        onChange={(event) => {
          setDiscountCode(event.target.value);
        }}
        label={translate("cartPage.insertPromocode")}
      />
      <Button
        className={clsx(
          styles.promocodeContainer__button,
          discountCode.length && styles.promocodeContainer__button_active
        )}
        type="button"
        fullWidth
        onClick={handleSubmit}
      >
        {translate("cartPage.apply")}
      </Button>
    </Box>
  );
};

export default PromocodeBlock;
