const ArrowLeft = ({ color = "#F6F6F6", width = 25, height = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9991 5.46967C11.292 5.76256 11.292 6.23744 10.9991 6.53033L6.27941 11.25H20.4688C20.883 11.25 21.2188 11.5858 21.2188 12C21.2188 12.4142 20.883 12.75 20.4688 12.75H6.27941L10.9991 17.4697C11.292 17.7626 11.292 18.2374 10.9991 18.5303C10.7062 18.8232 10.2313 18.8232 9.93842 18.5303L3.93842 12.5303C3.64553 12.2374 3.64553 11.7626 3.93842 11.4697L9.93842 5.46967C10.2313 5.17678 10.7062 5.17678 10.9991 5.46967Z"
      fill={color}
    />
  </svg>
);

export default ArrowLeft;
