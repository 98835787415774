import { Container, Divider, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import styles from "./styles.module.css";

const LicenseAgreement = () => {
  return (
    <Container className={styles.licenseContainer}>
      <Typography variant="h4">Лицензионное соглашение</Typography>
      <Divider />
      <Typography variant="body2">
        Настоящее лицензионное соглашение (далее — Соглашение), является
        публичной офертой Индивидуального предпринимателя ИП Горелкин Евгений Андреевич ИНН 346000803002, ОГРН 322344300043709 (далее – MentUp) на предоставление доступа
        к:
        <ul>
          <li>
            сайту{" "}
            <Link className={styles.licenseLink} to="/">
              https://mentup-store.com
            </Link>{" "}
            (далее – Сайт);
          </li>
          <li>
            программам, продуктам, услугам и сервисам, предоставляемым на Сайте
            (далее вместе — Сервисы);
          </li>
        </ul>
        на условиях, приведенных ниже. В случае несогласия с условиями
        Соглашения вы должны воздержаться от использования Сайта MentUp.
      </Typography>

      <Typography variant="h5">1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</Typography>
      <Divider />
      <Typography variant="body2">
        <strong>1.1. Сайт</strong> – совокупность:
        <ul>
          <li>
            программы для ЭВМ, находящейся в сети интернет по адресу{" "}
            <Link className={styles.licenseLink} to="/">
              {" "}
              mentup-store.com{" "}
            </Link>{" "}
            и реализующей в том числе функциональность интернет-площадки, на
            которой одни Пользователи (далее также – Продавцы) размещают в
            публичный доступ свои предложения о продаже их Товара (далее –
            Объявление), а другие Пользователи (далее также – Покупатели)
            приобретают данный Товар и/или Услугу.
          </li>
          <li>баз данных MentUp;</li>
          <li>
            иных объектов интеллектуальной собственности MentUp, включая
            графическое оформление (дизайн) интерфейса и товарные знаки;
          </li>
          <li>
            данных (контента), размещаемого MentUp и Пользователями
            (пользовательский контент).
          </li>
        </ul>
      </Typography>
      <Typography variant="body2">
        <strong>1.2. Сервисы</strong> – автоматизированные средства Сайта,
        включающие программные инструменты, функционирующие в автоматическом
        режиме и предоставляющие авторизованным на Сайте Пользователям
        технические возможности для создания разделов Сайта, размещения
        Объявлений, заключения и исполнения Cделок между Пользователями с
        использованием инструментов Сайта.
      </Typography>
      <Typography variant="body2">
        <strong>1.3. Правила Сайта</strong> – правила использования Сайта и его
        Сервисов, размещенные по адресу:
        <Link className={styles.licenseLink} to="/info/laws/rules">
          {" "}
          https://mentup-store.com/info/laws/rules{" "}
        </Link>
        .
      </Typography>
      <Typography variant="body2">
        <strong>1.4. Стороны</strong> – лица, заключившие между собой настоящее
        Соглашение.
      </Typography>
      <Typography variant="body2">
        <strong>1.5. Пользователь (любой Пользователь)</strong> – физическое
        лицо, обратившееся к Сайту.
      </Typography>
      <Typography variant="body2">
        <strong>1.6. Авторизованный Пользователь</strong> – Пользователь,
        прошедший регистрацию на Сайте.
      </Typography>
      <Typography variant="body2">
        <strong>1.7. Пользователь и Авторизованный Пользователь</strong>{" "}
        являются лицензиатами по настоящему Соглашению.
      </Typography>
      <Typography variant="body2">
        <strong>1.8. Продавец</strong> - Авторизованный Пользователь,
        размещающий Объявление с предложением заключить Сделку в отношении
        Товара, действующий в собственных интересах или в интересах иного лица.
      </Typography>
      <Typography variant="body2">
        <strong>1.9. Покупатель</strong> — Авторизованный Пользователь,
        осуществляющий на Сайте взаимодействие с Продавцом в отношении Товара и
        заключение Сделки с ним.
      </Typography>
      <Typography variant="body2">
        <strong>1.10. Товар</strong> — любое имущество, имущественные права,
        товар или услуга, в отношении которых Продавец размещает Объявление.
      </Typography>
      <Typography variant="body2">
        <strong>1.11. Объявление (также Лот)</strong> — информационное сообщение
        с предложением о Товаре (включая любую сопутствующую информацию),
        размещаемое Продавцом с использованием Сервисов Сайта, адресованное
        неопределенному кругу лиц.
      </Typography>
      <Typography variant="body2">
        <strong>1.12. Сделка</strong> - договор в отношении Товара на условиях,
        указанных в Объявлении, заключаемый между Продавцом и Покупателем с
        использованием Сервисов Сайта и в соответствии с условиями настоящего
        Соглашения.
      </Typography>
      <Typography variant="body2">
        <strong>1.13. Личный кабинет</strong> (далее также – аккаунт) –
        интерфейс взаимодействия Авторизованного Пользователя с Сайтом,
        позволяющий ему размещать и/или изменять Объявления, изменять
        информацию, указанную о себе при регистрации (в т.ч., но не
        ограничиваясь: никнейм (имя), аватар, адрес электронной почты),
        использовать чат Сайта, просматривать историю своих Сделок на Сайте.
      </Typography>
      <Typography variant="body2">
        <strong>1.14. Регистрационные данные</strong> — идентификационные данные
        Авторизованного Пользователя, предназначенные для его доступа к Личному
        кабинету. Регистрационные данные состоят из: логина (адрес электронной
        почты) и пароля.
      </Typography>
      <Typography variant="body2">
        <strong>1.15. Кошелек</strong> – лицевой счет Авторизованного
        Пользователя в Личном кабинете с информацией о доступном балансе для
        совершения Сделок на Сайте или вывода на банковскую карту
        Авторизованного Пользователя.
      </Typography>
      <Typography variant="body2">
        <strong>1.16. Спор</strong> – обращение одного Авторизованного
        Пользователя в отношении другого Авторизованного Пользователя в MentUp в
        связи с любым нарушением или подозрением на нарушение положений
        Соглашения и/или подозрением на факт мошенничества со стороны
        Авторизованного Пользователя, в отношении которого такое обращение
        направлено.
      </Typography>
      <Typography variant="body2">
        <strong>1.17. Агент</strong> – MentUp, уполномоченный Авторизованным
        Пользователем Сервиса за вознаграждение совершать от имени Покупателя и
        за его счет расчеты по исполнению Сделок, заключаемых через Сайт
        Покупателем с Продавцами. Агент действует в соответствии с условиями
        настоящего Соглашения и агентского договора, размещенного по адресу:
        <Link className={styles.licenseLink} to="/info/laws/agency-contract">
          {" "}
          https://mentup-store.com/info/laws/agency-contract{" "}
        </Link>{" "}
        Покупатель является для Агента Принципалом.
      </Typography>

      <Typography variant="h5">2. ПРЕДМЕТ СОГЛАШЕНИЯ</Typography>
      <Divider />
      <Typography variant="body2">
        2.1. MentUp предоставляет Пользователю право использования Сайта (за
        исключением Сервисов Сайта) в соответствии с его функциональным
        назначением на условиях неисключительной, действующей на территории
        всего мира, не подлежащей переуступке лицензии, иных условиях и в
        объеме, определенных в Соглашении.
      </Typography>
      <Typography variant="body2">
        2.2. Право использования Сайта предоставляется Пользователю с даты его
        обращения к Сайту. Обращение Пользователя к Сайту означает принятие им
        условий Соглашения.
      </Typography>
      <Typography variant="body2">
        2.3. MentUp предоставляет Авторизованному Пользователю право
        использования Сервисов Сайта в соответствии с их функциональным
        назначением на условиях неисключительной, действующей на территории
        всего мира, не подлежащей переуступке лицензии, иных условиях и в
        объеме, определенных в Соглашении.
      </Typography>
      <Typography variant="body2">
        2.4. Право использования Сервисов Сайта предоставляется Авторизованному
        Пользователю с даты его регистрации на Сайте и явно выраженного им
        принятия (с использованием элементов интерфейса Сайта) Соглашения и
        Правил Сайта.
      </Typography>
      <Typography variant="body2">
        2.5. Право использования Сайта и его Сервисов предоставляется MentUp
        безвозмездно. При этом по усмотрению MentUp, условием предоставления
        доступа к определенным Сервисам Сайта может быть соблюдение
        Пользователем иных дополнительных требований, предусмотренных
        Соглашением.
      </Typography>
      <Typography variant="body2">
        2.6. Пользователь и Авторизованный Пользователь вправе обратиться к
        MentUp по любым вопросам, связанным с настоящим Соглашением:
        <ul>
          <li>по электронной почте: admin@mentup-store.com</li>
          <li>
            воспользовавшись формой обратной связи
            <Link className={styles.licenseLink} to="/contact">
              {" "}
              https://mentup-store.com/contact{" "}
            </Link>
          </li>
        </ul>
        При обращении необходимо указать тему «Лицензионное соглашение».
      </Typography>

      <Typography variant="h5">
        3. РЕГИСТРАЦИЯ И АВТОРИЗАЦИЯ НА САЙТЕ
      </Typography>
      <Divider />
      <Typography variant="body2">
        3.1. Для доступа к Сервисам Сайта Пользователю необходимо пройти
        процедуру регистрации (стать Авторизованным Пользователем).
      </Typography>
      <Typography variant="body2">
        3.2. Регистрация на Сайте осуществляется Пользователем в автоматическом
        (без участия MentUp) порядке с использованием технических средств Сайта.
        Регистрация может осуществляться как путем заполнения Пользователем
        регистрационных данных (регистрационная форма:
        <Link className={styles.licenseLink} to="/login">
          {" "}
          https://mentup-store.com/login{" "}
        </Link>{" "}
        ), так и путем авторизации Пользователя через сайты (сервисы) третьих
        лиц (например, ВКонтакте, Яндекс.Дзен, Telegram и другие), если таковая
        предлагается на Сайте. В последнем случае минимальная информация о
        Пользователе, необходимая для его авторизации, передается Сайту
        автоматически через сайт третьего лица.
      </Typography>
      <Typography variant="body2">
        3.3. Пользователь обязуется предоставить в процессе регистрации
        достоверную и полную информацию о себе, а также в дальнейшем
        поддерживать эту информацию в актуальном состоянии.
      </Typography>
      <Typography variant="body2">
        3.4. Регистрация на Сайте без согласия Пользователя с Соглашением и
        Правилами Сайта невозможна. Регистрацией Пользователь выражает полное и
        безоговорочное согласие с Соглашением и Правилами Сайта.
      </Typography>
      <Typography variant="body2">
        3.5. В результате регистрации Пользователь приобретает статус
        Авторизованного Пользователя и ему создается Личный кабинет на Сайте.
        Доступ к Личному кабинету, а также доступ к Сервисам Сайта
        Авторизованный Пользователь осуществляет путем авторизации по логину и
        паролю (либо к Сайту, либо к сайту третьего лица, через который он
        авторизовался на Сайте).
      </Typography>
      <Typography variant="body2">
        3.6. Авторизованный Пользователь обязуется сохранять конфиденциальность
        своего пароля. Все действия, совершаемые с использованием авторизации
        (логина и пароля Пользователя) на Сайте, считаются совершенными самим
        Авторизованным Пользователем.
      </Typography>
      <Typography variant="body2">
        3.7. При утере пароля Авторизованный Пользователь имеет возможность
        получить напоминание пароля на адрес электронной почты, указанный им при
        регистрации.
      </Typography>
      <Typography variant="body2">
        3.8. Регистрация на Сайте означает автоматическое согласие
        Авторизованного Пользователя получать на указанный им при регистрации
        адрес электронный почты сообщения информационного и рекламного характера
        от MentUp и его партнеров. Пользователь может в любое время отозвать это
        согласие, обратившись в MentUp:
      </Typography>
      <Typography variant="body2">
        <ul>
          <li>по электронной почте: admin@mentup-store.com</li>
          <li>
            воспользовавшись формой обратной связи
            <Link className={styles.licenseLink} to="/contact">
              {" "}
              https://mentup-store.com/contact{" "}
            </Link>
          </li>
        </ul>
        При обращении необходимо указать тему «Прочее».
      </Typography>
      <Typography variant="body2">
        3.9. При условии отсутствия незавершенных Сделок, споров и нулевом
        балансе Кошелька, Авторизованный Пользователь вправе удалить свой Личный
        кабинет, воспользовавшись функцией «Удалить аккаунт» в нем.
      </Typography>
      <Typography variant="body2">
        3.10. Удаление аккаунта является необратимой операцией. После удаления
        аккаунта Авторизованный Пользователь теряет свой статус, Сервисы Сайта
        становятся недоступны.
      </Typography>

      <Typography variant="h5">4. ПРАВА И ОБЯЗАННОСТИ СТОРОН</Typography>
      <Divider />
      <Typography variant="body2">
        <strong>4.1. MentUp обязуется:</strong>
        <ul>
          <li>
            предоставить Пользователю и Авторизованному Пользователю возможность
            отозвать его согласие на обработку персональных данных полностью и
            частично, а также запросить удаление своих персональных данных в
            любой момент по своему усмотрению;
          </li>
          <li>
            рассматривать мотивированные претензии Пользователей и
            Авторизованных Пользователю по работе Сайта и его Сервисов;
          </li>
          <li>
            предоставлять Авторизованному Пользователю возможность управлять
            настройками Личного кабинета, Сервисов и Объявлений с учетом
            технических и функциональных возможностей Сайта и Сервисов;
          </li>
          <li>
            предоставить Авторизованному Пользователю возможность удалить
            Объявление и/или свои регистрационные данные в любой момент по
            своему усмотрению;
          </li>
          <li>
            информировать Авторизованного Пользователя об изменениях Соглашения
            и/или Правил Сайта по адресу электронной почты, указанному им при
            регистрации;
          </li>
          <li>
            консультировать Авторизованного Пользователя по вопросам
            функционирования Сервисов и технических аспектах использования
            Сайта;
          </li>
          <li>
            информировать Авторизованных Пользователей обо всех комиссиях,
            взимаемых при проведении расчетов на Сайте;
          </li>
          <li>
            проводить проверку обращений Авторизованных Пользователей о
            потенциальных фактах мошенничества и/или нарушения положений
            Соглашения и/или Правил Сайта.
          </li>
          <li>соблюдать иные свои обязанности, предусмотренные Соглашением.</li>
        </ul>
      </Typography>
      <Typography variant="body2">
        <strong>4.2. MentUp вправе:</strong>
        <ul>
          <li>
            в любое время изменять оформление (дизайн) Сайта, его программное
            обеспечение, контент, перечень и состав Сервисов, осуществлять иные
            действия с целью улучшения качества и удобства использования Сайта и
            его Сервисов с предварительным уведомлением Пользователей и/или
            Авторизованных Пользователей или без такового;
          </li>
          <li>
            в любое время и в любой форме запрашивать у Пользователя и
            Авторизованного Пользователя согласие на обработку его персональных
            данных на Сайте;
          </li>
          <li>
            изменять условия регистрации или прекращать ее действие (временно
            или окончательно) с предварительным уведомлением или без такового;
          </li>
          <li>
            изменять Правила Сайта с предварительным уведомлением или без
            такового;
          </li>
          <li>
            предлагать Авторизованному Пользователю предоставление
            дополнительных услуг на Сайте. Предоставление таких дополнительных
            может регулироваться отдельными документами (условиями, правилами,
            офертами Агента и т.д.). Документы, регулирующие отдельные вопросы,
            связанные с предоставлением MentUp дополнительных услуг на Сайте,
            действуют в части не противоречащей Соглашению;
          </li>
          <li>
            в любое время и по своему усмотрению вводить и отменять программы
            лояльности для Авторизованных Пользователей («титулы»; бонусы,
            купоны и пр.);
          </li>
          <li>
            использовать данные переписки Авторизованных Пользователей в чате
            Сайта для контроля исполнения Сделки, по которой MentUp является
            Агентом, а также в рамках расследования фактов потенциального
            мошенничества и/или нарушения положений Соглашения и Правил Сайта по
            обращению других Авторизованных Пользователей;
          </li>
          <li>
            при обнаружении прямых или косвенных фактов мошенничества или
            нарушения положений Соглашения заморозить, заблокировать или удалить
            аккаунт Авторизованного Пользователя в одностороннем порядке,
            независимо от его баланса;
          </li>
          <li>
            при проведении расследований правоохранительными органами,
            предоставлять доступные персональные данные Пользователей и
            Авторизованных пользователей, текущую локацию, историю браузера,
            fingerprint браузера, историю взаимодействия с Сайтом и/или его
            Сервисами и другим данным, благодаря которым можно получить полную
            или частичную информацию о местоположении и действиях
            злоумышленника;
          </li>
          <li>
            проводить плановые технические работы на Сайте (устранение
            неисправностей, исправление ошибок, повышение безопасности работы,
            обновление и т.п.), в результате которых определенные Сервисы Сайта
            или Сайт полностью могут быть временно недоступны;
          </li>
          <li>
            принять решение о закрытии Сайта с предварительным уведомлением об
            этом Авторизованного пользователя по адресу электронной почты,
            указанному им при регистрации, или иным способом, не менее, чем за
            один месяц до предполагаемой даты закрытия Сайта;
          </li>
          <li>осуществлять иные действия, предусмотренные Соглашением.</li>
        </ul>
      </Typography>
      <Typography variant="body2">
        4.3. Любой Пользователь, акцептовавший данное Соглашение вправе:
        <ul>
          <li>
            зарегистрироваться на Сайте в порядке, определенном Соглашением, для
            доступа к его Сервисам;
          </li>
          <li>
            в любое время обратиться к MentUp по любому вопросу функционирования
            Сайта и Сервисов, а также для разъяснения положений настоящего
            Соглашения и/или Политики конфиденциальности Сайта:
          </li>
          <li>по электронной почте: admin@mentup-store.com</li>
          <li>
            воспользовавшись формой обратной связи
            <Link className={styles.licenseLink} to="/contact">
              {" "}
              https://mentup-store.com/contact{" "}
            </Link>
          </li>
          <li>
            в любое время отказаться от акцепта настоящего Соглашения и/или
            Правил Сайта, прекратив использование Сайта.
          </li>
        </ul>
      </Typography>
      <Typography variant="body2">
        4.4. Авторизованный Пользователь дополнительно к правам, указанным в
        п.Любой Пользователь, акцептовавший данное Соглашение вправе:
        <ul>
          <li>
            размещать на Сайте пользовательский контент, в т.ч. Объявления о
            продаже Товара на Сайте, с учетом правил и гарантий, предусмотренных
            Соглашением;
          </li>
          <li>
            принимать на свое усмотрение и под свою ответственность решение о
            заключении Сделки на Сайте;
          </li>
          <li>
            в любой момент и по своему усмотрению вывести средства Кошелька на
            свой банковский счет;
          </li>
          <li>
            принимать (или не принимать) предложение MentUp о предоставлении
            дополнительных услуг на Сайте на основании отдельных соглашений
            (условий, правил, оферт Агента и т.д.);
          </li>
          <li>
            осуществлять иные действия, предусмотренные Соглашением с учетом
            установленных Соглашением правил и ограничений.
          </li>
        </ul>
      </Typography>
      <Typography variant="body2">
        4.5. Любой Пользователь, акцептовавший данное Соглашение обязан:
        <ul>
          <li>
            неукоснительно соблюдать положения настоящего Соглашения и Правила
            Сайта, в т.ч. установленные данными документами правила и
            ограничения;
          </li>
          <li>
            самостоятельно и не реже чем раз в две недели проверять наличие
            обновлений Соглашения и Правил Сайта;
          </li>
          <li>
            не использовать и не предпринимать попыток использовать Сайт
            способами, которые могут помешать нормальному функционированию Сайта
            и его Сервисов;
          </li>
          <li>
            не осуществлять действия, направленные на дестабилизацию
            функционирования Сайта, осуществлять попытки несанкционированного
            доступа к управлению Сайтом или его Сервисам, а также осуществлять
            любые иные аналогичные действия;
          </li>
          <li>
            воздерживаться от использования возможных технических уязвимостей
            Сайта (багов, "дыр" и т.п.), а при их обнаружении уведомить MentUp в
            кратчайшие сроки:
          </li>
          <li>по электронной почте: admin@mentup-store.com</li>
          <li>
            воспользовавшись формой обратной связи
            <Link className={styles.licenseLink} to="/contact">
              {" "}
              https://mentup-store.com/contact{" "}
            </Link>
          </li>
          <li>
            не использовать автоматизированные скрипты (программы) для сбора
            информации и/или взаимодействия с Сайтом;
          </li>
          <li>
            не предпринимать попыток получения несанкционированного доступа к
            Личным кабинетам Авторизованных Пользователей;
          </li>
          <li>
            прекратить использование Сайта в случае принятия решения об отзыве
            своего акцепта настоящего Соглашения.
          </li>
        </ul>
      </Typography>
      <Typography variant="body2">
        4.6. Авторизованный Пользователь дополнительно к обязанностям, указанным
        в п.Любой Пользователь, акцептовавший данное Соглашение обязан:
        <ul>
          <li>
            строго следовать правилам размещения Объявлений, установленным
            Соглашением;
          </li>
          <li>
            неукоснительно соблюдать правила и запреты, установленные Правилами
            Сайта;
          </li>
          <li>следовать правилам общения в чате, установленным Соглашением;</li>
          <li>
            соблюдать осмотрительность при выборе Продавца/Покупателя по Сделке;
          </li>
          <li>
            проявлять добросовестность и не предпринимать попыток обмана или
            введения в заблуждение MentUp или других Пользователей;
          </li>
          <li>
            самостоятельно удостовериться, что предложение, содержащееся в
            Объявлении на Сайте, является действительным и законным;
          </li>
          <li>
            проводить Сделки только через Сайт, в соответствии с Соглашением и в
            рамках того технического функционала и тех технических ограничений,
            которые есть у Сайта. Нарушение любого из перечисленных условий
            является нарушением Соглашения;
          </li>
          <li>
            не использовать дополнительные услуги, предлагаемые MentUp на Сайте
            в противоправных целях или в целях, которые могут каким-либо образом
            нанести ущерб Исполнителю и/или третьим лицам;
          </li>
          <li>
            не разглашать конфиденциальную информацию, которая стала ему
            известна в результате взаимоотношений с MentUp;
          </li>
          <li>
            сообщать о фактах мошенничества и/или нарушении Соглашения и/или
            Правил Сайта другими Пользователями и/или Авторизованными
            Пользователями, о которых ему стало известно, или подозрении на них
            в службу поддержки Сайта по адресу:
            <Link className={styles.licenseLink} to="/contact">
              {" "}
              https://mentup-store.com/contact{" "}
            </Link>{" "}
            ;
          </li>
          <li>
            оказывать содействие MentUp при рассмотрении споров, в котором
            Авторизованный пользователь является стороной спора;
          </li>
          <li>
            в случае предъявления к MentUp претензий третьими лицами
            относительно размещенных Пользователем Объявлений/и или Товара,
            указанного в соответствующем Объявлении, самостоятельно и за свой
            счет урегулировать такие претензии, а в случае возникновения в связи
            с вышеуказанными претензиями у MentUp убытков, возместить их в
            полном объеме;
          </li>
          <li>
            соблюдать иные свои обязанности, предусмотренные Соглашением и
            Правилами Сайта.
          </li>
        </ul>
      </Typography>

      <Typography variant="h5">5. ПРАВИЛА РАЗМЕЩЕНИЯ ОБЪЯВЛЕНИЙ</Typography>
      <Divider />
      <Typography variant="body2">
        5.1. Запрещено размещать на Сайте Объявления, содержание которых:
        <ul>
          <li>
            нарушает законодательство Российской Федерации (содержат пропаганду
            насилия, расовой ненависти, порнографию и пр.);
          </li>
          <li>нарушает общепринятые нормы морали и нравственности;</li>
          <li>
            является оскорбительным по отношению к другим Пользователям и
            третьим лицам, нарушающий их честь и достоинство;
          </li>
          <li>
            является предложением Товара, для реализации которого требуется
            получение специальных разрешений, при отсутствии таких разрешений;
          </li>
          <li>
            является предложением Товара, являющегося объектом исключительных
            прав MentUp или третьих лиц, при отсутствии необходимых разрешений
            от правообладателя;
          </li>
          <li>
            является предложением Товара, оборот которого ограничен или запрещен
            действующим российский законодательством и/или международными
            соглашениями;
          </li>
          <li>
            является предложением Товара, добытого неправомерным путем, в т.ч.
            путём кардинга, хакинга, брута, фишинга и т.п.
          </li>
          <li>
            является заведомо ложным или способным ввести в заблуждение
            (например, указание ложных характеристик, неполной цены и т.д.);
          </li>
          <li>
            составляет информация или инструкции по совершению незаконных
            действий, описание или пропаганду преступной деятельности, а также
            инструкции или руководства по совершению преступных действий;
          </li>
          <li>
            включает файлы, содержащие вирусы или иные вредоносные программы.
          </li>
        </ul>
      </Typography>
      <Typography variant="body2">
        5.2. Заголовок размещаемого Объявления должен соответствовать его
        содержанию (тексту) и не должен содержать контактную или персональную
        информацию о Пользователе (телефон, электронные адрес, адрес интернет-
        ресурса и пр.) или третьих лицах, а также спецсимволов или нечитаемых
        символов, не имеющих смысловой нагрузки.
      </Typography>
      <Typography variant="body2">
        5.3. Объявление на Сайте размещается в соответствии с тематикой разделов
        Сервиса. Запрещено размещение Объявления в разделе Сервиса, не
        соответствующем содержанию Объявления. Если Авторизованный пользователь
        не может найти или определить нужный раздел Сервиса, он вправе
        предложить новый раздел и/или обратиться в техподдержку Сайта за
        помощью.
      </Typography>
      <Typography variant="body2">
        5.4. Размещая Объявление на Сайте, Авторизованный Пользователь понимает,
        что материалы Объявления публикуются им в открытом доступе, то есть
        доступны для ознакомления любому посетителю Сайта (неограниченному кругу
        лиц). Авторизованный Пользователь понимает и принимает на себя все
        риски, связанные с таким размещением материалов, в т.ч. с включением в
        них своих персональных данных, в нарушение правил, установленных
        Соглашением. К таким рискам можно отнести, включая, но не ограничиваясь:
        риск попадания адреса электронной почты в списки для рассылки
        спам-сообщений, риск попадания адреса электронной почты к различного
        рода мошенникам, риск попадания телефонного номера к SMS-спамерам и/или
        SMS-мошенникам и иные риски, вытекающие из такого размещения материалов.
      </Typography>
      <Typography variant="body2">
        5.5. MentUp предоставляет Авторизованному Пользователю техническую
        возможность для создания Объявлений, но при этом не инициирует
        размещение материала в интересах или от имени Авторизованного
        Пользователя. Размещение материала Авторизованного Пользователя
        осуществляется исключительно по его инициативе и в результате его
        действий с использованием автоматизированных технических средств Сайта.
      </Typography>
      <Typography variant="body2">
        5.6. MentUp не контролирует конкретное размещение Объявления, не
        выбирает, не утверждает и не ограничивает конечных получателей
        информационных материалов, содержащихся в Объявлении.
      </Typography>
      <Typography variant="body2">
        5.7. MentUp вправе устанавливать и изменять требования к содержанию и
        условиям размещения Объявлений. При этом MentUp не контролирует (не
        отслеживает, не согласовывает и не утверждает) создание Объявления
        Авторизованным Пользователем, а также его содержание. Авторизованный
        Пользователь самостоятельно определяет содержание Объявления в рамках
        технического функционала, предоставляемого Сайтом, и правил,
        определенных в Соглашении.
      </Typography>
      <Typography variant="body2">
        5.8. MentUp ни при каких условиях и ни в каком случае не изменяет
        содержание размещаемого Авторизованного Пользователем Объявления, за
        исключением автоматизированных средств удаления спецсимволов для
        улучшения отображения Объявления. Авторизованный Пользователь понимает,
        что дизайн Сайта, отображение введенной им информации может меняться, но
        не сами данные Объявления Авторизованного Пользователя.
      </Typography>
      <Typography variant="body2">
        5.9. При размещении Объявления Авторизованный Пользователь, используя
        элементы интерфейса Сайта, явно подтверждает MentUp наличие у него права
        разместить Объявление в отношении соответствующего Товара или наличие
        соответствующего разрешения правообладателя Товара, если это применимо.
      </Typography>
      <Typography variant="body2">
        Размещение Объявления без предоставления Авторизованным Пользователем
        гарантий, предусмотренных настоящим пунктом Соглашения, невозможна.
      </Typography>
      <Typography variant="body2">
        Авторизованный Пользователь самостоятельно несет ответственность за
        нарушение им указанных в настоящем пункте гарантий.
      </Typography>
      <Typography variant="body2">
        5.10. Авторизованный Пользователь несет полную ответственность за
        размещаемые им в Объявлении информационные материалы как перед лицом,
        чьи права могут быть затронуты, так и перед MentUp за нарушение им
        условий Соглашения.
      </Typography>
      <Typography variant="body2">
        5.11. По обращению правообладателя или третьего лица, считающего что
        размещение Авторизованным Пользователем Объявления на Сайте нарушают или
        способствует нарушению его прав, MentUp вправе обратиться к
        Авторизованному Пользователю для устранения последним оснований,
        повлекшим соответствующее обращение. Авторизованный Пользователь сам
        производит оценку оснований обращения и принимает решение об их
        устранении.
      </Typography>
      <Typography variant="body2">
        5.12. MentUp вправе в одностороннем порядке удалить, скрыть или
        блокировать (делать недоступным третьим лицам) любой пользовательский
        контент, приостановить или ограничить доступ Авторизованного
        Пользователя к его Личному кабинету, а также отказаться в одностороннем
        порядке от Соглашения и удалить аккаунт Авторизованного Пользователя,
        если MentUp станет известно, что Авторизованный Пользователь при
        использовании Сервисов Сайта нарушает или способствует нарушению прав
        MentUp, прав третьих лиц (в том числе правообладателей и/или иных
        Пользователей Сайта), законодательства, условий Соглашения.
      </Typography>
      <Typography variant="body2">
        MentUp вправе совершить действия, предусмотренные настоящим пунктом, без
        предварительного уведомления Авторизованного Пользователя, если сочтет
        необходимым незамедлительное реагирование на нарушение или
        предполагаемое нарушение в соответствии с действующим законодательством.
      </Typography>
      <Typography variant="body2">
        Авторизованный Пользователь обязуется принять указанные действия MentUp,
        совершенные на основании настоящего пункта, вне зависимости от их оценки
        и оценки вызвавших их оснований самим Авторизованным Пользователем.
      </Typography>

      <Typography variant="h5">6. ПРАВИЛА ОБЩЕНИЯ В ЧАТЕ</Typography>
      <Divider />
      <Typography variant="body2">
        6.1. При размещении сообщений в чате Авторизованному Пользователю не
        рекомендуется:
        <ul>
          <li>
            использовать при написании сообщения любые языки кроме русского;
          </li>
          <li>создавать сообщения, относящиеся к оффтопу, флейму, флуду;</li>
          <li>создавать неинформативные или бессодержательные сообщения;</li>
          <li>злоупотреблять функциональной клавишей CAPS LOCK.</li>
        </ul>
      </Typography>
      <Typography variant="body2">
        6.2. При размещении сообщений в чате Авторизованному Пользователю
        запрещено:
        <ul>
          <li>
            использовать высказывания вульгарного, непристойного характера,
            обсценную лексику;
          </li>
          <li>
            оскорблять и дискриминировать других Авторизованных Пользователей
            или третьих лиц по любому признаку (национальному, половому,
            религиозному, расовому, возрастному, профессиональному или по любым
            другим признакам);
          </li>
          <li>размещать персональные данные третьих лиц;</li>
          <li>
            создавать сообщения, носящие характер призыва или пропаганды, любого
            рода, противозаконных или противоправных действий;
          </li>
          <li>
            использовать высказывания, содержащие проявления национальной,
            расовой, религиозной или любой другой нетерпимости, пропаганду
            экстремизма, фашизма и т.п.;
          </li>
          <li>
            использовать ссылки на сторонние интернет-ресурсы и/или файлы, в
            т.ч. содержащие в себе информацию рекламного характера;
          </li>
          <li>
            провоцировать других Авторизованных Пользователей на нарушение
            положений настоящего Соглашения или Правил Сайта;
          </li>
          <li>
            призывать других Авторизованных Пользователей нарушать или
            способствовать нарушению прав MentUp и/или третьих лиц, положений
            настоящего Соглашения или Правил Сайта;
          </li>
          <li>
            размещать какие-либо материалы с нарушением исключительных прав их
            правообладателей;
          </li>
          <li>
            размещать файлы, содержащие вирусы или иные вредоносные программы;
          </li>
          <li>
            размещать файлы, содержание которых нарушает правила для сообщений в
            чате, установленных настоящим пунктом Соглашения.
          </li>
        </ul>
      </Typography>

      <Typography variant="h5">7. СДЕЛКА И РАСЧЕТЫ НА САЙТЕ</Typography>
      <Divider />
      <Typography variant="body2">
        7.1. Покупатель, принявший решение о заключении Сделки, подтверждает
        свое намерение, используя элементы интерфейса Сервиса Сайта.
      </Typography>
      <Typography variant="body2">
        7.2. Для проведения расчетов по Сделке в Сервисе на Сайте Покупатель
        заключает с MentUp агентский договор. С подробными условиями агентского
        договора можно ознакомиться по адресу:
        <Link className={styles.licenseLink} to="/info/laws/agency-contract">
          {" "}
          https://mentup-store.com/info/laws/agency-contract{" "}
        </Link>{" "}
        .
      </Typography>
      <Typography variant="body2">
        7.3. Сумма к оплате по Сделке автоматически рассчитывается Сервисом,
        исходя из:
        <ul>
          <li>текущего баланса Кошелька Покупателя;</li>
          <li>цены и количества Товара, являющегося предметом Сделки;</li>
          <li>суммы вознаграждения Агента;</li>
          <li>
            комиссии платежной системы (удерживается платежной системой при
            перечислении средств).
          </li>
          <li>
            Интерфейс Сервиса предоставляет Покупателю возможность ознакомиться,
            из чего складывается Сумма к оплате по Сделке.
          </li>
        </ul>
      </Typography>
      <Typography variant="body2">
        7.4. Если Покупатель согласен с суммой к оплате по Сделке, он производит
        оплату способами, предусмотренными на Сайте.
      </Typography>
      <Typography variant="body2">
        7.5. После проведения Покупателем оплаты по Сделке, оплаченная им сумма
        за минусом безусловной части вознаграждения Агента и комиссии платежной
        системы, резервируется MentUp, а Продавец получает уведомление о
        произведенной Покупателем оплате.
      </Typography>
      <Typography variant="body2">
        7.6. Продавцу предоставляется шесть часов с момента проведения
        Покупателем оплаты по Сделке, чтобы оформить передачу Товара.
      </Typography>
      <Typography variant="body2">
        7.7. Если Продавец не подтвердил оформление передачи Товара в
        предоставленное ему для этого время, Покупатель вправе отказаться от
        сделки.
      </Typography>
      <Typography variant="body2">
        В случае отказа Покупателем от сделки на основании настоящего пункта
        Соглашения, зарезервированная MentUp сумма (сумма, уплаченная им по
        Сделке за минусом безусловной части вознаграждения Агента и удержанной
        платежной системой комиссии), переводится MentUp на его Кошелек.
      </Typography>
      <Typography variant="body2">
        7.8. Оформление передачи Товара осуществляется от Продавца к Покупателю
        при помощи Сервиса на Сайте. Используя интерфейс Сервиса Сайта Продавец
        передает Покупателю всю необходимую для получения Товара информацию, а
        Покупатель после получения Товара в этом подтверждает факт его
        получения.
      </Typography>
      <Typography variant="body2">
        Без оформления передачи Товара и подтверждения факта его получения в
        Сервисе Сайта Сделка считается неисполненной.
      </Typography>
      <Typography variant="body2">
        7.9. После подтверждения покупателем факта получения Товара в Сервисе
        Сайта Сделка считается завершенной. Зарезервированные MentUp средства по
        Сделке переводятся Продавцу за минусом вознаграждения Агента по
        результатам исполнения поручения и отображаются в Кошельке Продавца.
      </Typography>

      <Typography variant="h5">8. РАССМОТРЕНИЕ СПОРОВ</Typography>
      <Divider />
      <Typography variant="body2">
        8.1. Любой Авторизованный пользователь вправе обратиться к MentUp для
        разрешения спора, возникшего на Сайте, в т.ч. связанного с потенциальным
        мошенничеством и/или подозрением на нарушение со стороны другого
        Авторизованного пользователя Соглашения и/или Правил Сайта.
      </Typography>
      <Typography variant="body2">
        8.2. MentUp в течение срока, необходимого на выяснение обстоятельств
        спора, но не более чем 48 часов, проводит выяснение обстоятельств спора,
        анализ доказательств сторон спора и выносит решение по спору. В ходе
        выяснения обстоятельств спора MentUp вправе использовать как данные чата
        и иные сохраняемые Сайтом данные (в т.ч. логи), так и данные
        предоставленные сторонами спора.
      </Typography>
      <Typography variant="body2">
        8.3. Каждая из сторон спора должна оказывать содействие в выяснении его
        обстоятельств и его разрешении.
      </Typography>
      <Typography variant="body2">
        8.4. Если одна из сторон спора не оказывает содействия в выяснении его
        обстоятельств, или препятствует такому выяснению, или уклоняется от
        него, MentUp вправе применить к такой стороне предусмотренные
        Соглашением и Правилами Сайта меры, в т.ч. блокировку Кошелька.
      </Typography>
      <Typography variant="body2">
        8.5. В случае, если у MentUp есть основания считать, что в ходе Сделки
        были нарушены положения Соглашения и/или Правила Сайта, MentUp вправе
        аннулировать соответствующую Сделку, вернуть средства по Сделке
        Покупателю, а также вправе реализовать иные свои права и меры в
        отношении виновных Авторизованных пользователей, предусмотренные
        Соглашением. Возможность возврата Покупателю средств по Сделке
        ограничивается суммой средств Кошелька Продавца.
      </Typography>
      <Typography variant="body2">
        8.6. Решение MentUp по спору считается окончательным и не может быть
        оспорено. Авторизованный Пользователь обязуется принять указанное
        решение, вне зависимости от этого решения и оценки обстоятельств спора
        самим Авторизованным Пользователем.
      </Typography>

      <Typography variant="h5">
        9. ПРАВА ИНТЕЛЛЕКТАЛЬНОЙ СОБСТВЕННОСТИ
      </Typography>
      <Divider />
      <Typography variant="body2">
        9.1. Исключительные права на программы для ЭВМ и базы данных, входящие в
        состав Сайта, а также дизайн Сайта и размещенные на Сайте товарные знаки
        MentUp, принадлежат MentUp.
      </Typography>
      <Typography variant="body2">
        9.2. Исключительные права на тексты статей, размещенные MentUp на Сайте,
        принадлежат их законным правообладателям и используются MentUp с их
        разрешения.
      </Typography>
      <Typography variant="body2">
        9.3. Исключительные права на изображения, наименования объектов
        авторского права и/или их персонажей, иные объекты исключительных прав
        третьих лиц, размещенные MentUp на Сайте, принадлежат их законным
        правообладателям и используются MentUp исключительно в информационных
        целях ознакомления Пользователя об ассортименте и внешнем виде игр.
      </Typography>
      <Typography variant="body2">
        9.4. Пользователь и Авторизованный Пользователь гарантирует, что будет
        воздерживаться от любого использования Сайта за пределами прав,
        предоставленных по Соглашению, в т.ч. от нарушения исключительных прав
        третьих лиц, в связи с использованием Сайта, и в случае нарушения
        настоящей гарантии возместит MentUp и/или соответствующему третьему лицу
        причиненные в связи с этим нарушением и/или их устранением убытки в
        полном объеме.
      </Typography>
      <Typography variant="body2">
        9.5. Исключительное право на пользовательский контент, размещенный
        Авторизованным Пользователем на Сайте, принадлежит Авторизованному
        Пользователю или его законному правообладателю.
      </Typography>
      <Typography variant="body2">
        9.6. При размещении пользовательского контента на Сайте, Авторизованный
        Пользователь предоставляет MentUp право на его использование на условиях
        неисключительной, безвозмездной, действующей не территории всего мира и
        в течение всего срока действия исключительных прав, лицензии, включая
        права на воспроизведение, распространение, публичный показ и доведение
        (сообщение) до всеобщего сведения.
      </Typography>
      <Typography variant="body2">
        9.7. Учитывая полное и безоговорочное принятие Авторизованным
        Пользователем настоящего Соглашения, Правил Сайта, а также
        дополнительные гарантии предоставляемые Авторизованным Пользователем при
        размещении пользовательского контента MentUp, MentUp исходит из того,
        что у Авторизованного Пользователя есть все права (в т.ч. при
        необходимости соответствующие разрешения правообладателей) на размещение
        данного контента и предоставление прав его использования в соответствии
        с п.При размещении пользовательского контента на Сайте, Авторизованный
        Пользователь предоставляет MentUp право на его использование на условиях
        неисключительной, безвозмездной, действующей не территории всего мира и
        в течение всего срока действия исключительных прав, лицензии, включая
        права на воспроизведение, распространение, публичный показ и доведение
        (сообщение) до всеобщего сведения. Соглашения.
      </Typography>
      <Typography variant="body2">
        Авторизованный Пользователь в своею очередь гарантирует, что обладает
        необходимыми правами и разрешениями для предоставления указанной в п.При
        размещении пользовательского контента на Сайте, Авторизованный
        Пользователь предоставляет MentUp право на его использование на условиях
        неисключительной, безвозмездной, действующей не территории всего мира и
        в течение всего срока действия исключительных прав, лицензии, включая
        права на воспроизведение, распространение, публичный показ и доведение
        (сообщение) до всеобщего сведения. лицензии, и в случае нарушения
        настоящей гарантии возместит MentUp и/или соответствующему третьему лицу
        причиненные, в связи с этим нарушением и/или их устранением убытки в
        полном объеме.
      </Typography>
      <Typography variant="body2">
        9.8. Использование материалов Сайта допускается только с письменного
        разрешения их правообладателей за исключением случаев, прямо
        предусмотренных законом, или, когда на соответствующей странице Сайта
        есть явное указание о возможности свободного использования ее
        материалов.
      </Typography>

      <Typography variant="h5">10. ОГРАНИЧЕНИЕ ОТВЕТСТВЕННОСТИ</Typography>
      <Divider />
      <Typography variant="body2">
        10.1. Пользование Сайтом осуществляется Пользователем и Авторизованным
        Пользователем самостоятельно, без непосредственного участия MentUp, на
        основе общепринятого в международной практике принципа "как есть" ("as
        is"). Это означает, что за проблемы, возникающие в процессе
        использования Сайта и его Сервисов (включая, но не ограничиваясь:
        проблемы совместимости с оборудованием Пользователя, типами и версиями
        используемых операционных систем и браузеров; несоответствие результатов
        использования Сайта и его Сервисов ожиданиям Пользователя и т.п.) MentUp
        ответственности не несет.
      </Typography>
      <Typography variant="body2">
        10.2. MentUp не дает каких-либо гарантий по совместимости Сайта, по его
        функциональности, отсутствию дефектов программного обеспечения Сайта и
        его Сервисов и не несет какой-либо ответственности в связи с этим.
      </Typography>
      <Typography variant="body2">
        10.3. MentUp ни при каких обстоятельствах не несет ответственности за
        ущерб, убытки или расходы, возникшие в связи с использованием или
        невозможностью использования Сайта и/или его Сервисов.
      </Typography>
      <Typography variant="body2">
        10.4. MentUp не обязано, но вправе по своему усмотрению осуществлять
        техническую поддержку и обновление Сайта. Обновленный Сайт может
        содержать исправление дефектов программного обеспечения Сайта и его
        Сервисов, а также новые и/или измененные функции.
      </Typography>
      <Typography variant="body2">
        10.5. Авторизованный Пользователь осознает и согласен с тем, что он
        самостоятельно оценивает все риски, связанные с размещением,
        распространением Объявлений, а также принятием решения о Сделке, в т.ч.
        должен самостоятельно удостовериться, что предложение, содержащееся в
        Объявлении, является действительным и законным.
      </Typography>
      <Typography variant="body2">
        10.6. MentUp не выбирает, не утверждает и не ограничивает конечных
        получателей Объявлений, а также в связи с этим не гарантирует, что
        опубликованное Авторизованным Пользователем Объявление будет просмотрено
        определенным количеством лиц или хотя бы одним.
      </Typography>
      <Typography variant="body2">
        10.7. MentUp не является стороной или заинтересованным лицом по Сделке
        между Покупателем и Продавцом, а потому не несет ответственность за
        последствия этой Сделки. Ответственность MentUp как Агента, если между
        Покупателем и MentUp был заключен агентский договор, ограничивается
        ответственностью, предусмотренной по такому договору.
      </Typography>
      <Typography variant="body2">
        10.8. Гарантии Mentup при рассмотрении споров на Сайте и возврату
        средств по Сделкам, совершенным с нарушением Соглашения и Правил Сайта,
        ограничиваются размером средств на Кошельке Авторизованного
        пользователя, совершившего соответствующее нарушение.
      </Typography>
      <Typography variant="body2">
        10.9. MentUp ни при каких условиях не несет ответственность за действия
        Пользователей и Авторизованных Пользователей, произведенные за пределами
        Сайта и его Сервисов.
      </Typography>

      <Typography variant="h5">
        11. ПОРЯДОК ЗАКЛЮЧЕНИЯ И ИЗМЕНЕНИЯ СОГЛАШЕНИЯ, СРОК ЕГО ДЕЙСТВИЯ
      </Typography>
      <Divider />
      <Typography variant="body2">
        11.1. Обращение Пользователя к Сайту означает его полный и
        безоговорочный акцепт всех условий Соглашения.
      </Typography>
      <Typography variant="body2">
        11.2. Пройдя процедуру регистрации на Cайте и приняв Соглашение путем
        нажатия соответствующей кнопки в конце текста Соглашения или иным
        способом, предусмотренным интерфейсом Сайта, Пользователь приобретает
        все права и обязанности Авторизованного Пользователя и считается
        полностью принявшим условия Соглашения без каких-либо оговорок и
        ограничений.
      </Typography>
      <Typography variant="body2">
        11.3. Принимая условия Соглашения, Пользователь или Авторизованный
        Пользователь подтверждает, что обладает дееспособностью, правами и
        полномочиями на заключение Соглашения и полностью понимает и осознает
        значение своих действий.
      </Typography>
      <Typography variant="body2">
        11.4. Соглашение вступает в силу с момента его заключения (акцепта) и
        действует до исполнения Сторонами своих обязательств по нему или
        наступления следующих событий:
        <ul>
          <li>принятия MentUp решения о закрытии Сайта;</li>
          <li>
            принятия MentUp решения о расторжении Соглашения с Авторизованным
            Пользователем в одностороннем порядке на основаниях, предусмотренных
            Соглашением, в части положений Соглашения, касающихся использования
            Сервисов Сайта;
          </li>
          <li>
            принятия решения Пользователем или Авторизованным Пользователем о
            расторжении Соглашения.
          </li>
        </ul>
      </Typography>
      <Typography variant="body2">
        11.5. В случае прекращения действия Соглашения (полностью или в части),
        Пользователь и Авторизованный Пользователь обязан незамедлительно
        прекратить использование Сайта и/или его Сервисов в части тех положений
        Соглашения, которые прекратили свое действие.
      </Typography>
      <Typography variant="body2">
        11.6. MentUp вправе в любое время и по своему усмотрению вносить
        изменения в Соглашение. В случае внесения изменений в Соглашение
        обновленная версия публикуется на Сайте в разделе
        <Link className={styles.licenseLink} to="/info/laws/license-agreement">
          {" "}
          https://mentup-store.com/info/laws/license-agreement{" "}
        </Link>
        .
      </Typography>
      <Typography variant="body2">
        Обновленная версия Соглашения вступает в силу с даты публикации.
        Актуальная дата всегда будет указана непосредственно под текстом
        документа.
      </Typography>
      <Typography variant="body2">
        MentUp рекомендует Пользователю время от времени просматривать эти
        страницы, чтобы отслеживать возможные изменения. Продолжение
        использования Пользователем Сайта после публикации изменений в
        Соглашении означает его согласие с данными изменениями. Авторизованный
        Пользователь получает уведомление об изменении Соглашения по адресу
        электронной почты, указанной им при регистрации, или иным способом с
        учетом технических возможностей Сайта.
      </Typography>
      <Typography variant="body2">
        11.7. Если Пользователь или Авторизованный Пользователь не согласен с
        каким-либо условием Соглашения или оно ему непонятно, он не должен
        акцептовать Соглашение предусмотренным для него способом до устранения
        неясностей или несогласия. Пользователь и Авторизованный Пользователь
        может обратиться к MentUp по любым вопросам, связанным с Соглашением и
        его условиями:
        <ul>
          <li>по электронной почте: admin@mentup-store.com</li>
          <li>
            воспользовавшись формой обратной связи
            <Link className={styles.licenseLink} to="/contact">
              {" "}
              https://mentup-store.com/contact{" "}
            </Link>
          </li>
        </ul>
        При необходимо указать тему «Лицензионное соглашение».
      </Typography>
      <Typography variant="body2">
        MentUp ответит на обращение не позднее 10 рабочих дней с момента его
        получения.
      </Typography>

      <Typography variant="h5">12. ИНЫЕ ПОЛОЖЕНИЯ</Typography>
      <Divider />
      <Typography variant="body2">
        12.1. Независимо от места нахождения Пользователя или Авторизованного
        Пользователя, применимым правом по Соглашению является право Российской
        Федерации, в соответствии с которым разрешаются все споры и разногласия
        между Сторонами.
      </Typography>
      <Typography variant="body2">
        12.2. Стороны будут стремиться к разрешению споров и разногласий по
        Соглашению или в связи с ним, путем переговоров.
      </Typography>
      <Typography variant="body2">
        12.3. Если по тем или иным причинам одно или несколько положений
        Соглашения будут признаны недействительными или не имеющими юридической
        силы, это не оказывает влияния на действительность или применимость
        остальных положений Соглашения.
      </Typography>
      <Typography variant="body2">
        12.4. Стороны признают юридическую силу за:
        <ul>
          <li>
            электронными письмами и документами, направленными по электронной
            почте (cо стороны MentUp это адрес электронной почты, указанный на
            Сайте, со стороны Авторизованного Пользователя - адрес электронной
            почты, указанный при регистрации);
          </li>
          <li>
            согласиями и гарантиями, полученными от Пользователя и
            Авторизованного Пользователя с использованием элементов интерфейса
            Сайта.
          </li>
        </ul>
      </Typography>
    </Container>
  );
};

export default LicenseAgreement;
