import { Box, Typography } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useCallback, useEffect, useState } from "react";

import {
  changeTelegramNotifications,
  getNotificationsSettings,
} from "../../../../../../api/settings/notifications";
import { MentupSwitch } from "../../../../../../lib/theme";
import AlertComponent from "../../../../../AlertComponent";
import TelegramNotifications from "../../../../../TelegramComponents/Notifications/TelegramNotifications";
import { TranslateText } from "../../../../../translate-text";
import styles from "./styles.module.css";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ChangeNotifications = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [snackBar, setSnackBar] = useState({
    open: false,
    vertical: "bottom",
    horizontal: "right",
    message: "",
  });

  const [settings, setSettings] = useState({
    telegram: false,
  });

  const changeTelegramValue = useCallback(async (telegram) => {
    try {
      setLoading(true);
      const res = await changeTelegramNotifications({ telegram });
      if (res.data.confirmTelegram) {
        setModalOpen(true);
      }
    } catch (error) {
      //console.error(error);
      setAlertMessage(error.message);
    } finally {
      await getSettings();
    }
  }, []);

  const getSettings = async () => {
    try {
      setLoading(true);
      const response = await getNotificationsSettings();
      setSettings(response.data);
      //console.log(response.data);
    } catch (error) {
      //console.error(error);
      setAlertMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <Box className={styles.container}>
      <Snackbar
        anchorOrigin={{
          vertical: snackBar.vertical,
          horizontal: snackBar.horizontal,
        }}
        open={snackBar.open}
        autoHideDuration={4000}
        onClose={() => setSnackBar({ ...snackBar, open: false })}
        key={snackBar.vertical + snackBar.horizontal}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          {snackBar.message}
        </Alert>
      </Snackbar>

      <AlertComponent
        message={alertMessage}
        resetMessageInParent={() => setAlertMessage("")}
      />

      <Box className={styles.itemsContainer}>
        <Box className={styles.titleContainer}>
          <Typography className={styles.title}>
            <TranslateText value="vendorDashboard.settings.notifications.title" />
          </Typography>
        </Box>

        <Box className={styles.switchContainer}>
          <MentupSwitch
            disabled={loading}
            defaultValue={settings.telegram}
            checked={settings.telegram}
            value={settings.telegram}
            onChange={(_, value) => changeTelegramValue(value)}
          />

          <Box>
            <Typography className={styles.subtitle}>
              <TranslateText value="vendorDashboard.settings.notifications.telegram.label" />
            </Typography>
            <Typography className={styles.message}>
              <TranslateText value="vendorDashboard.settings.notifications.telegram.description" />
            </Typography>
          </Box>
        </Box>
      </Box>

      <TelegramNotifications
        open={modalOpen}
        close={() => {
          setModalOpen(false);
          setLoading(false);
          getSettings();
        }}
      />
    </Box>
  );
};

export default ChangeNotifications;
