import { Box, Button, Typography } from "@mui/material";
import clsx from "clsx";
import React from "react";

import MentupModal from "../../../../../../../MentupModal/MentupModal";
import { TranslateText } from "../../../../../../../translate-text";
import styles from "./styles.module.css";

function Unset2FAModal({ open, close, confirm }) {
  return (
    <MentupModal open={open} close={close}>
      <Box className={styles.confirmContainer}>
        <Box className={styles.headerContainer}>
          <Typography className={styles.alertTitle}>
            <TranslateText value="vendorDashboard.settings.2fa.disable.title" />
          </Typography>

          <Typography className={styles.alertMessage}>
            <TranslateText value="vendorDashboard.settings.2fa.disable.description" />
          </Typography>
        </Box>

        <Box className={styles.buttonsContainer}>
          <Button
            className={clsx(styles.button, styles.confirmButton)}
            onClick={close}
          >
            <TranslateText value="vendorDashboard.settings.2fa.qr.cancel" />
          </Button>
          <Button
            className={clsx(styles.button, styles.cancelButton)}
            onClick={confirm}
          >
            <TranslateText value="vendorDashboard.settings.2fa.qr.next" />
          </Button>
        </Box>
      </Box>
    </MentupModal>
  );
}

export default Unset2FAModal;
