const GalleryIcon = ({ color = "#9879EC", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    {...props}
  >
    <path
      d="M2.5 12C2.5 7.28595 2.5 4.92893 3.96447 3.46447C5.42893 2 7.78595 2 12.5 2C17.214 2 19.5711 2 21.0355 3.46447C22.5 4.92893 22.5 7.28595 22.5 12C22.5 16.714 22.5 19.0711 21.0355 20.5355C19.5711 22 17.214 22 12.5 22C7.78595 22 5.42893 22 3.96447 20.5355C2.5 19.0711 2.5 16.714 2.5 12Z"
      stroke={color}
    />
    <circle cx="16.5" cy="8" r="2" stroke={color} />
    <path
      d="M2.5 12.5001L4.25159 10.9675C5.16286 10.1702 6.53628 10.2159 7.39249 11.0721L11.6822 15.3618C12.3694 16.0491 13.4512 16.1428 14.2464 15.5839L14.5446 15.3744C15.6888 14.5702 17.2369 14.6634 18.2765 15.599L21.5 18.5001"
      stroke={color}
      strokeLinecap="round"
    />
  </svg>
);

export default GalleryIcon;
