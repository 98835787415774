import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import { io } from "socket.io-client";

import { useUnreadChats } from "../../hooks/unreadChats";
import { firebaseConfig } from "./config";

export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);

const Context = createContext(null);
export const SocketProvider = ({ children }) => {
  const instance = useRef();
  const [inited, setInited] = useState(false);

  const unreadChats = useUnreadChats(instance);

  const authenticate = useCallback(
    async (token) => {
      if (inited) {
        return;
      }

      instance.current = io(process.env.REACT_APP_SOCKET_URL, {
        autoConnect: false,
        auth: { token },
      }).connect();
      setInited(true);

      unreadChats.init();
    },
    [inited, unreadChats]
  );

  return (
    <Context.Provider value={{ inited, instance, unreadChats, authenticate }}>
      {children}
    </Context.Provider>
  );
};

export const useSocketProvider = () => useContext(Context);
