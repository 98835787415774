import axios from "axios";
import Cookies from "js-cookie";

const API = axios.create({
  baseURL: process.env.NODE_ENV.includes("production")
    ? process.env.REACT_APP_SERVER_URL
      ? process.env.REACT_APP_SERVER_URL
      : ""
    : "http://localhost:3030/api/",
});

const header = () => {
  const token = Cookies.get("refreshToken");
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
};

export const getNotificationsCount = () => {
  const token = Cookies.get("refreshToken");
  if (!token) {
    return {};
  }
  return API.get(`/notification/count`, header());
};
export const getNotifications = (limit, page) =>
  API.get(`/notification?limit=${limit}&page=${page}`, header());
export const readAllNotifications = () =>
  API.get("/notification/read/all", header());
