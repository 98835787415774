import { createSlice } from "@reduxjs/toolkit";

const searchStore = createSlice({
  name: "searchStore",
  initialState: {
    isSearchScreen: false,
    searchValue: "",
  },
  reducers: {
    setIsSearchScreen: (state, action) => {
      return { ...state, isSearchScreen: action.payload };
    },
    setSearchValue: (state, action) => {
      return { ...state, searchValue: action.payload };
    },
  },
});

export const { setIsSearchScreen, setSearchValue } = searchStore.actions;
export const searchReducer = searchStore.reducer;
