import { Box, Container, useMediaQuery, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";

import Footer from "../../Layout/Footer";
import { PAGES_WITH_LOGIN_BACKGROUND } from "./constants";
import styles from "./styles.module.css";

const Wrapper = ({ children }) => {
  const theme = useTheme();
  const location = useLocation();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const desktopBackground = PAGES_WITH_LOGIN_BACKGROUND.some((path) =>
    location.pathname.includes(path)
  )
    ? "login-background"
    : "register-background";

  return (
    <Box
      data-mui-color-scheme="dark"
      sx={{
        background: `url(/${
          isMobile ? "login-background-mobile" : desktopBackground
        }.png)`,
      }}
      className={styles.wrapper}
    >
      <Container className={styles.container}>{children}</Container>
      <Footer customFooter />
    </Box>
  );
};

export default Wrapper;
