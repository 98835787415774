import { Container } from "@mui/material";
import { Navigate } from "react-router-dom";

const Profile = () => {
  return (
    <Container>
      {/* <VendorProfile /> */}
      <Navigate to="/" replace />
    </Container>
  );
};

export default Profile;
