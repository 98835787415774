import axios from "axios";
import Cookies from "js-cookie";

const API = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL || "http://localhost:3030/api/",
});

const header = () => {
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get("refreshToken")}`,
    },
  };
};

export const getListingsFilters = ({ user }) => {
  return API.post(
    "/personal-office/listings/filters",
    { user: user._id },
    header()
  );
};

export const getListings = (filters) => {
  return API.post("/personal-office/listings/list", filters, header());
};

export const getUserReviews = (body) => {
  return API.post("/personal-office/reviews/list", body, header());
};

export const getPurchasesFilters = () => {
  return API.get("/personal-office/purchases/filters", header());
};

export const getPurchases = (filters) => {
  return API.post("/personal-office/purchases/list", filters, header());
};

export const getSalesFilters = () => {
  return API.get("/personal-office/sales/filters", header());
};

export const getSales = (filters) => {
  return API.post("/personal-office/sales/list", filters, header());
};

export const getTransactionsFilters = (openTransactionId) => {
  return API.post(
    "/personal-office/transactions/filters",
    { openTransactionId },
    header()
  );
};

export const getTransactions = (filters) => {
  return API.post("/personal-office/transactions/list", filters, header());
};

export const getUserWallet = () => {
  return API.get("/personal-office/wallet/get", header());
};
