import axios from 'axios';

const API = axios.create({
    baseURL: process.env.NODE_ENV.includes('production')
        ? process.env.REACT_APP_SERVER_URL
            ? process.env.REACT_APP_SERVER_URL
            : ''
        : 'http://localhost:3030/api/',
});

export const getBlogPosts = (id, page, limit, skip, typeSlug) => {
    let queryString = `?page=${page}&limit=${limit}&skip=${skip}`;
    if (typeSlug) {
        queryString += `&typeSlug=${typeSlug}`;
    }
    return API.get(`blog/articles/category/`+id+queryString);
}


