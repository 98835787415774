import { useTheme } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, TextField, Typography } from "@mui/material";
import clsx from "clsx";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { disputeOrderApi } from "../../../api/order/DisputeOrder";
import { disputeOrderBuyerApi } from "../../../api/order/DisputeOrderBuyer";
import { useTranslate } from "../../../context/translate/translate.context";
import AlertComponent from "../../AlertComponent";
import MentupModal from "../../MentupModal/MentupModal";
import Danger from "../../svg/Danger";
import { TranslateText } from "../../translate-text";
import styles from "./styles.module.css";

function DisputeOrderModal({
  open,
  close,
  orderId,
  productId,
  setNewStatus,
  type,
  orderStatus,
  productStatus,
}) {
  const theme = useTheme();
  const { translate } = useTranslate();

  const [alertMessage, setAlertMessage] = useState("");

  const schema = yup.object().shape({
    reason: yup.string().max(500).required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const submitOrderDispute = async (data) => {
    if (!data?.reason) {
      return;
    }

    try {
      const res =
        type == "buyerPurchase"
          ? await disputeOrderBuyerApi({
              orderId,
              productId,
              reason: data.reason,
              orderStatus,
              productStatus,
            })
          : await disputeOrderApi({
              orderId,
              productId,
              reason: data.reason,
              orderStatus,
              productStatus,
            });

      if (!res.data) {
        setAlertMessage(translate("errorMessages.unexpectedError"));
        return;
      }

      if (res.data?.statusChanged) {
        setAlertMessage(translate("orders.deliverOrderModal.statusChanged"));
        return;
      }
      
      if (res.data?.orderSellerUpdate?.productsInfo?.length > 0) {
        const updatedProduct = res.data.orderSellerUpdate.productsInfo.find(
          (product) => product.product === productId
        );
        if (updatedProduct?.status === "dispute") {
          reset();
          close();
          setAlertMessage(translate("orders.disputeOrderModal.startedMessage"));
          if (setNewStatus) {
            setNewStatus("dispute");
          }
        } else {
          setAlertMessage(translate("errorMessages.unexpectedError"));
        }
      }
    } catch (err) {
      setAlertMessage(
        err?.response?.data?.message ||
          translate("errorMessages.unexpectedError")
      );
      close();
    }
  };

  const resetAlertMessage = () => {
    setAlertMessage("");
  };

  return (
    <>
      <AlertComponent
        message={alertMessage}
        resetMessageInParent={resetAlertMessage}
      />

      <MentupModal open={open} close={close}>
        <Box className={styles.confirmContainer}>
          <Box className={styles.headerContainer}>
            <Danger
              color={theme.palette.mode === "dark" ? "#EDF67D" : "#8142f6"}
            />
            <Typography className={styles.alertTitle}>
              <TranslateText value="orders.disputeOrderModal.title" />
            </Typography>
            <Typography className={styles.alertMessage}>
              <TranslateText value="orders.disputeOrderModal.subtitle" />
            </Typography>
          </Box>

          <form
            style={{ width: "100%" }}
            onSubmit={handleSubmit(submitOrderDispute)}
          >
            <TextField
              required
              label={translate("orders.disputeOrderModal.reasonInput.label")}
              variant="outlined"
              sx={{
                backgroundColor:
                  theme.palette.mode === "dark" ? "#F6F6F60D" : "#F6F6F60D",
              }}
              className={styles.input}
              autoComplete="off"
              multiline
              rows={5}
              error={!!errors.reason}
              helperText={
                errors.reason?.message
                  ? translate(
                      "orders.disputeOrderModal.reasonInput.errorMessage"
                    )
                  : null
              }
              {...register("reason")}
            />

            <Box className={styles.buttonsContainer}>
              <Button
                className={clsx(styles.button, styles.confirmButton)}
                onClick={close}
              >
                <TranslateText value="orders.disputeOrderModal.cancelButtonLabel" />
              </Button>
              <Button
                className={clsx(styles.button, styles.cancelButton)}
                type="submit"
              >
                <TranslateText value="orders.disputeOrderModal.submitButtonLabel" />
              </Button>
            </Box>
          </form>
        </Box>
      </MentupModal>
    </>
  );
}

export default DisputeOrderModal;
