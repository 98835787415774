import { TextField } from "@mui/material";
import clsx from "clsx";
import { forwardRef } from "react";
import { Controller } from "react-hook-form";

import styles from "./styles.module.css";

const FormInput = forwardRef(
  (
    {
      className,
      type = "text",
      name,
      control,
      fullWidth = true,
      rules,
      InputProps,
      ...props
    },
    ref
  ) => {
    if (!control) {
      return (
        <TextField
          ref={ref}
          type={type}
          className={clsx(styles.input, styles.input_labelColor, className)}
          fullWidth={fullWidth}
          variant="filled"
          InputProps={{
            disableUnderline: true,
            ...InputProps,
          }}
          {...props}
        />
      );
    }

    return (
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            ref={ref}
            type={type}
            className={clsx(styles.input, styles.input_labelColor, className)}
            value={value || ""}
            onChange={onChange}
            helperText={error ? error.message : null}
            error={!!error}
            fullWidth={fullWidth}
            variant="filled"
            InputProps={{
              disableUnderline: true,
              ...InputProps,
            }}
            {...props}
          />
        )}
      />
    );
  }
);

export default FormInput;
