import { useState, useEffect } from "react";
import { Box, CircularProgress, Container, Typography } from "@mui/material";
import styles from "./styles.module.css";
import { useTranslate } from "../../../../context/translate/translate.context";
import { useSelector } from "react-redux";
import ProductSwiper from "../../../ProductsSwiper";
import getBestSellersProducts from "../../../../services/bestSellers";
import { useNavigate } from "react-router-dom";

const RealGamers = () => {
  const { translate } = useTranslate();
  const userRedux = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);

  const navigate = useNavigate()

  useEffect(() => {
    const getFeatured = async () => {
      try {
        const res = await getBestSellersProducts(
          8,
          products.length,
          userRedux.country
        );

        setProducts(res.items);
      } catch (error) {
        navigate('/404')
      } finally {
        setLoading(false);
      }
    };

    getFeatured();
  }, []);

  // if (!loading && products.length === 0) {
  //   return null;
  // }

  return (
    <Box className={styles.realGamers}>
      <Container>
        <Typography className={styles.realGamers__heading}>
          {translate("aboutUsPage.RealGamers.RealGamersHeading")}
        </Typography>
        <Typography className={styles.realGamers__text}>
          {translate("aboutUsPage.RealGamers.RealGamersText")}
        </Typography>
        <Typography className={styles.realGamers__advantage}>
          {translate("aboutUsPage.RealGamers.RealGamersAdvantage")}
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : (
          !!products.length && (
            <Box className={styles.realGamers__swiper}>
              <ProductSwiper products={products} alwaysMobile />
            </Box>
          )
        )}
      </Container>
    </Box>
  );
};

export default RealGamers;
