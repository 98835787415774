import { createSlice } from "@reduxjs/toolkit";
const notificationStore = createSlice({
  name: "notificationStore",
  initialState: {
    count: 0,
  },
  reducers: {
    setNotificationsCount: (state, action) => {
      return {
        ...state,
        count: Math.max(0, action?.payload?.count || 0),
      };
    },
  },
});

export const { setNotificationsCount } = notificationStore.actions;
export const notificationReducer = notificationStore.reducer;
