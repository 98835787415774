const ImportIcon = ({ color = "#EDF67D" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M14.7353 10.4697C15.0282 10.1768 15.5031 10.1768 15.796 10.4697C16.0888 10.7626 16.0888 11.2374 15.796 11.5303L12.796 14.5303C12.5031 14.8232 12.0282 14.8232 11.7353 14.5303L8.73529 11.5303C8.4424 11.2374 8.4424 10.7626 8.73529 10.4697C9.02819 10.1768 9.50306 10.1768 9.79596 10.4697L11.5156 12.1893V4C11.5156 3.58579 11.8514 3.25 12.2656 3.25C12.6798 3.25 13.0156 3.58579 13.0156 4V12.1893L14.7353 10.4697Z"
      fill={color}
    />
    <path
      d="M21.0156 12C21.0156 11.5858 20.6798 11.25 20.2656 11.25C19.8514 11.25 19.5156 11.5858 19.5156 12C19.5156 16.0041 16.2697 19.25 12.2656 19.25C8.26156 19.25 5.01562 16.0041 5.01562 12C5.01562 11.5858 4.67984 11.25 4.26563 11.25C3.85141 11.25 3.51562 11.5858 3.51562 12C3.51562 16.8325 7.43313 20.75 12.2656 20.75C17.0981 20.75 21.0156 16.8325 21.0156 12Z"
      fill={color}
    />
  </svg>
);

export default ImportIcon;
