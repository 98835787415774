import axios from "axios";
import Cookies from "js-cookie";

const baseURL =
  (process.env.NODE_ENV.includes("production") &&
    process.env.REACT_APP_SERVER_URL) ||
  "http://localhost:3030/api/";
const API = axios.create({ baseURL });

const header = () => {
  const token = Cookies.get("refreshToken");
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
};

// Register Ticket
export const registerTicket = (data) => API.post(`/compTickets/register`, data, header());

// Get total tickets of a competiton
export const getTickets = (compId) => API.get(`/compTickets/tickets/${compId}`);

// Check if user has ticket
export const checkTicket = (userId) => API.get(`/compTickets/check/${userId}`, header());

// Check if user has purchase of at least 500 rubles
export const checkPurchase = (userId) => API.get(`/compTickets/checkPurchase/${userId}`, header());

// check if user has refferal
export const checkReferal = (userId) => API.get(`/compTickets/checkReferal/${userId}`, header());


