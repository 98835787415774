import { createSlice } from '@reduxjs/toolkit';
const productTabStore = createSlice({
    name: 'productTabStore',
    initialState: { value: 0 },
    reducers: {
        setValue: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { setValue } = productTabStore.actions;
export const productTabReducer = productTabStore.reducer;
