import { Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useTranslate } from "../../../context/translate/translate.context";
import styles from "./styles.module.css";

const Step3 = () => {
  const { translate } = useTranslate();

  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/");
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Typography className={styles.form__emailSentMessage}>
      {translate("authentication.authenticationWithSuccess")}
    </Typography>
  );
};

export default Step3;
