import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Fade, Typography } from "@mui/material";
import clsx from "clsx";

import { TranslateText } from "../../../translate-text";
import styles from "./styles.module.css";
import { createDraw } from "../../../../api/draws/createDraw";
import AlertComponent from "../../../AlertComponent";
import { useState } from "react";

const UserInfoTabs = ({
  isCurrentUser,
  tabs,
  onTabChange,
  selectedTab,
  addProduct,
}) => {
  if (!tabs?.length) {
    return null;
  }

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState("success");

  // Get User from localStorage
  const userLocalStorage = JSON.parse(localStorage.getItem("user"));

  // Handle register promo button
  const handleRegisterPromo = async () => {
    const data = {
      user: userLocalStorage?._id,
      type: 'BrawlStarsBP'
    }
    await createDraw(data)
    .then((res)=>{
      setAlertSeverity("success")
      setAlertMessage('Вы успешно зарегистрировались!')
    })
    .catch((err)=>{
      // if status 400
      if(err.response.status === 400){
        setAlertSeverity("error")
        setAlertMessage(err.response.data.message)
      }
    })
  }

  return (
    <Box className={styles.container}>
      <AlertComponent
        message={alertMessage}
        severity={alertSeverity}
        resetMessageInParent={() => setAlertMessage(null)}
      />
      <Box className={styles.tabs_container}>
        {tabs.map((tab) => (
          <Button
            key={tab}
            className={clsx(
              styles.tab_item,
              tab === selectedTab && styles.tab_item__selected
            )}
            onClick={() => onTabChange(tab)}
          >
            <Typography>
              <TranslateText value={`vendorDashboard.tabHeaders.${tab}`} />
            </Typography>
          </Button>
        ))}
      </Box>

      {selectedTab === "listings" && isCurrentUser && (
        <Fade in>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            className={styles.add_new_button}
            onClick={addProduct}
          >
            <TranslateText value="addProductModal.addNewButtonLabel" />
          </Button>
        </Fade>
      )}

      {/* selectedTab === "purchases" && isCurrentUser && (
        <Fade in>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            className={styles.get_promo}
            onClick={handleRegisterPromo}
          >
            <TranslateText value="addProductModal.getPromo" />
          </Button>
        </Fade>
      ) */}
    </Box>
  );
};

export default UserInfoTabs;
