import * as React from "react"
const Applications = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={16}
    fill="none"
    {...props}
  >
    <rect width={16} height={16} x={0.5} fill="url(#a)" rx={1.707} />
    <rect
      width={14.482}
      height={14.482}
      x={1.258}
      y={0.759}
      fill="url(#b)"
      rx={1.707}
    />
    <path
      fill="url(#c)"
      d="M4.863 10.668a231.599 231.599 0 0 1 2.21-5.08c.2-.446.419-1.15.675-1.542a1.16 1.16 0 0 1 .564-.417.558.558 0 0 1 .408.032c.186.09.32.259.422.432.363.61.638 1.303.944 1.945l1.476 3.048c.208.436.413.873.627 1.305.124.252.276.495.358.764.084.277.085.54-.056.798-.081.148-.21.29-.375.34a.43.43 0 0 1-.352-.052.866.866 0 0 1-.286-.324c-.212-.37-.379-.777-.558-1.164l-.957-2.058C9.45 7.591 8.84 6.496 8.425 5.352 7.853 6.789 7.23 8.216 6.624 9.64c-.227.531-.582 1.186-.745 1.722-.14.335-.29.811-.65.965a.454.454 0 0 1-.387-.024.76.76 0 0 1-.241-.199 1.695 1.695 0 0 1-.084-.201c-.133-.405.175-.88.346-1.235Z"
    />
    <path
      fill="url(#d)"
      d="M4.035 7.472a28.91 28.91 0 0 1 1.713.007c-.174.312-.361.617-.532.932h-.248l-1.75-.002c.115-.244.245-.55.42-.753.118-.138.226-.172.397-.184Z"
    />
    <path
      fill="url(#e)"
      d="M12.965 7.472a28.912 28.912 0 0 0-1.713.007c.175.312.361.617.532.932h.248l1.75-.002c-.115-.244-.245-.55-.42-.753-.118-.138-.226-.172-.397-.184Z"
    />
    <path fill="url(#f)" d="M7.774 7.462h1.391l.443.949H7.395l.379-.949Z" />
    <defs>
      <linearGradient
        id="a"
        x1={11.523}
        x2={7.366}
        y1={15.298}
        y2={6.663}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.352} stopColor="#EFA414" />
        <stop offset={1} stopColor="#FCD707" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={8.499}
        x2={8.499}
        y1={0.759}
        y2={15.241}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#533C8E" />
        <stop offset={1} stopColor="#3C227D" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={9.275}
        x2={5.767}
        y1={13.349}
        y2={4.701}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FC9D18" />
        <stop offset={1} stopColor="#FFFB2A" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={4.547}
        x2={4.376}
        y1={8.476}
        y2={7.431}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFA80C" />
        <stop offset={1} stopColor="#FFDA16" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={12.453}
        x2={12.624}
        y1={8.476}
        y2={7.431}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFA80C" />
        <stop offset={1} stopColor="#FFDA16" />
      </linearGradient>
      <linearGradient
        id="f"
        x1={7.219}
        x2={8.128}
        y1={8.256}
        y2={7.295}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.361} stopColor="#EFA414" />
        <stop offset={1} stopColor="#FCD707" />
      </linearGradient>
    </defs>
  </svg>
)
export default Applications
