import { Box, Card, IconButton, InputBase, Typography } from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { FixedSizeList } from "react-window";

import { useTranslate } from "../../../../../context/translate/translate.context";
import { useChats } from "../../../../../hooks/chats";
import CreateChat from "../../../../svg/CreateChat";
import SearchIcon from "../../../../svg/SearchIcon";
import { TranslateText } from "../../../../translate-text";
import Conversation from "../../../Conversation";
import styles from "./styles.module.css";

export const ChatListItems = ({ selectedChat, openChat, full }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const chats = useChats();
  const { translate } = useTranslate();

  const [searchChatTerm, setSearchChatTerm] = useState("");
  const [listHeight, setListHeight] = useState(500); // Default height

  useEffect(() => {
    chats.getItems();
  }, [searchChatTerm]);

  useEffect(() => {
    // Update the list height on window resize or chat items change
    const updateListHeight = () => {
      setListHeight(Math.min(window.innerHeight - 350, chats.items.length * 52));
    };

    updateListHeight();
    window.addEventListener('resize', updateListHeight);
    
    return () => {
      window.removeEventListener('resize', updateListHeight);
    };
  }, [chats.items.length]);

  const renderChat = ({ index, style }) => {
    const chat = chats.items[index];
    const otherUser = (chat.users || []).find((u) => u._id !== user._id);

    return (
      <Box key={`${chat._id}-${index}`} onClick={() => openChat(chat)} style={style}>
        <Conversation
          chat={chat}
          receiver={otherUser}
          sender={user._id}
          unreadCount={chat.unread?.[0]?.count || 0}
          select={() => openChat(chat)}
          active={selectedChat?._id === chat._id}
        />
      </Box>
    );
  };

  return (
    <Card className={clsx(styles.container, full && styles.fullContainer)}>
      <Box className={styles.titleContainer}>
        <Typography className={styles.title}>
          <TranslateText value="chat.contacts.title" />
        </Typography>
        {/*
        <IconButton className={styles.createChatBtn}>
          <CreateChat />
        </IconButton> */}
      </Box>

      <Box className={styles.listContainer}>
        {/*
        <Box className={styles.inputContainer}>
          <InputBase
            className={styles.input}
            value={searchChatTerm}
            onChange={({ target }) => setSearchChatTerm(target.value)}
            placeholder={translate("chat.search.placeholder")}
          />
          <IconButton
            className={styles.iconContainer}
            aria-label="clear chat search input"
            onClick={() => setSearchChatTerm("")}
            disabled={!searchChatTerm}
          >
            {searchChatTerm ? <ClearIcon /> : <SearchIcon />}
          </IconButton>
        </Box> 
        */}

        <FixedSizeList
          height={listHeight}
          width="100%"
          itemSize={52}
          itemCount={chats.items.length}
          overscanCount={10}
        >
          {renderChat}
        </FixedSizeList>
      </Box>
    </Card>
  );
};
