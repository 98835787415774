import { Box, Button, Checkbox, FormControlLabel } from "@mui/material";
import clsx from "clsx";

import { useTranslate } from "../../../../../../../context/translate/translate.context";
import CheckMarkNewClosed from "../../../../../../svg/Checkmark/CheckMarkNewClosed";
import CheckMarkNewOpen from "../../../../../../svg/Checkmark/CheckMarkNewOpen";
import { TranslateText } from "../../../../../../translate-text";
import styles from "./styles.module.css";

const TopUpBalanceStep2 = ({ close, selectedPayment, onPaymentSelect }) => {
  const { translate } = useTranslate();

  const paymentMethods = [
    // { _id: "yookassa", name: "yookassa" },
    // { _id: "paybox", name: "paybox" },
    // { _id: "transfer", name: "transfer" },
    // { _id: "lava", name: "lava" },
    // { _id: "stripe", name: "stripe" },
    { _id: "tinkoff", name: "tinkoff" },
     // { _id: "yooMoney", name: "yooMoney" },
  ];

  return (
    <>
      <Box className={styles.selectPaymentContainer}>
        {paymentMethods.map((payment) => (
          <FormControlLabel
            sx={{ color: "#f6f6f6" }}
            control={
              <Checkbox
                key={payment._id}
                checked={selectedPayment?._id === payment._id}
                onChange={() => onPaymentSelect(payment)}
                icon={<CheckMarkNewClosed />}
                checkedIcon={<CheckMarkNewOpen />}
              />
            }
            label={translate(
              `promoteProductModal.modal2.paymentMethodInput.${payment.name}`
            )}
          />
        ))}
      </Box>

      <Button
        className={clsx(styles.button, styles.cancelButton)}
        onClick={close}
      >
        <TranslateText value="vendorDashboard.topUp.steps.step2.cancel" />
      </Button>
    </>
  );
};

export default TopUpBalanceStep2;
