import React, { useEffect, useState } from "react";

import {
  get2FAqr,
  validate2FaOTP,
} from "../../../../../../../../api/settings/2fa";
import MentupModal from "../../../../../../../MentupModal/MentupModal";
import TwoFACode from "../2FACode";
import TwoFAQR from "../2FAQR";

function Set2FAModal({ open, close, confirm, setAlertMessage }) {
  const [loading, setLoading] = useState(true);
  const [qr, setQr] = useState(null);
  const [step, setStep] = useState(0);

  const onCodeSubmit = async (code) => {
    try {
      await validate2FaOTP({ code });
      confirm();
    } catch (error) {
      setAlertMessage(error.response?.data?.error || error.message);
    } finally {
      setLoading(false);
    }
  };

  const getQRInformation = async () => {
    if (qr) {
      return;
    }
    try {
      setLoading(true);
      const response = await get2FAqr();
      setQr(response.data.qrCode);
    } catch (error) {
      setAlertMessage(error.response?.data?.error || error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      getQRInformation();
    } else {
      setStep(0);
      setQr(null);
    }
  }, [open]);

  return (
    <MentupModal open={open} close={close}>
      {step === 0 && (
        <TwoFAQR
          loading={loading}
          url={qr}
          close={close}
          confirm={() => setStep(1)}
        />
      )}
      {step === 1 && (
        <TwoFACode
          loading={loading}
          close={() => setStep(0)}
          confirm={onCodeSubmit}
        />
      )}
    </MentupModal>
  );
}

export default Set2FAModal;
