import { useTheme } from "@emotion/react";
import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Divider,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { getSingleOrderbyID } from "../../../api/order/OrdersApi";
import { useTranslate } from "../../../context/translate/translate.context";
import { isRuCountry } from "../../../lib/country";
import LocalButton from "../../UI/Button";
import ArrowLeft from "../../svg/ArrowLeft";
import { TranslateText } from "../../translate-text";
import OrderProduct from "../OrderProduct";
import styles from "./styles.module.css";

const OrderPage = () => {
  const { id } = useParams();

  const theme = useTheme();
  const { translate } = useTranslate();

  const [order, setOrder] = useState(null);

  const userRedux = useSelector((state) => state.user);
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  const getOrder = async (id) => {
    if (!id) {
      return;
    }

    try {
      const { data } = await getSingleOrderbyID(id, userRedux.country);
      if (data?.seller?._id !== user?._id) {
        navigate("/");
        return;
      }

      if(data.logs) {
        // if data.logs.text have entries that repeat themselves, remove them
        const logs = data.logs.map((log, index) => {
          if (index > 0 && log.text === data.logs[index - 1].text) {
            return null;
          }
          return log;
        }
        ).filter(log => log !== null);
        data.logs = logs;
      }
      setOrder(data);
    } catch (error) {
      navigate("/404");
    }
  };

  useEffect(() => {
    getOrder(id);
  }, [id]);

  const currencySign = () => {
    return isRuCountry(userRedux.country) ? "₽" : "$";
  };

  const handleGoBack = () => {
    if (window.history.length) {
      window.history.back();
    } else {
      window.location.href = "/";
    }
  };

  if (!order) {
    return;
  }

  // function to convert timestamp to date
  const convertTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  return (
    <Container className={styles.container}>
      <LocalButton
        color="white"
        startIcon={
          <ArrowLeft
            color={theme.palette.mode === "dark" ? "#F6F6F6" : "#1f1d22"}
          />
        }
        onClick={handleGoBack}
        aria-label={translate("products.productCodes.codesTable.back")}
      >
        <TranslateText value="products.productCodes.codesTable.back" />
      </LocalButton>
      <Box className={styles.frameContainer}>
        <Typography className={styles.frameTitle}>
          {translate("orders.orderPage.buyerInfo.title")}
        </Typography>

        <Card className={styles.frameCard}>
          <Box
            onClick={() => navigate(`/profile/${order?.buyer?.userSlug}`)}
            className={styles.buyerInfo}
          >
            <Avatar src={order.buyer.avatar} className={styles.buyerAvatar} />
            <Typography className={styles.buyerUserName}>
              {order?.buyer?.username}
            </Typography>
          </Box>

          <Box className={styles.buyerButtonsContainer}>
            <Button
              variant="outlined"
              className={styles.buyerStartChat}
              onClick={() => navigate(`/chat?u=${order?.buyer?._id}`)}
            >
              <TranslateText value="orders.orderPage.buyerInfo.startChatButtonLabel" />
            </Button>
            {/*
            <FeedbackModal
              type="sale"
              order={order}
              buyer={order?.buyer}
              seller={order?.seller}
              product={order?.productsInfo?.[0]?.product}
            />
            */}
          </Box>
        </Card>
      </Box>

      <Box className={styles.frameContainer}>
        <Typography className={styles.frameTitle}>
          <TranslateText value="orders.orderPage.orderSectionTitle" />
        </Typography>

        <Box className={styles.orderContainer}>
          <Card className={styles.productContainer}>
            {!!order?.productsInfo?.[0]?.product && (
              <OrderProduct
                order={order}
                buyer={order?.buyer}
                product={order?.productsInfo?.[0]}
                isPurchase={false}
                currencySign={currencySign()}
                reset={() => getOrder(id)}
              />
            )}
          </Card>

            <Card className={styles.transactionContainer}>
              <Typography className={styles.transactionTitle}>
                <TranslateText value="orders.orderPage.transaction.title" />
              </Typography>

              <Box className={styles.transactionList}>
                <Box className={styles.transactionListItem}>
                  <Typography>
                    <TranslateText value="orders.orderPage.transaction.paymentMethod.title" />
                  </Typography>
                  <Typography>
                    <TranslateText
                      value={`orders.orderPage.transaction.paymentMethod.${
                        order?.transaction?.paidWith || ""
                      }`}
                    />
                  </Typography>
                </Box>
                {/*
                <Box className={styles.transactionListItem}>
                  <Typography>
                    <TranslateText value="orders.orderPage.transaction.createdOn" />
                  </Typography>
                  <Typography>
                    {order.transaction.createdAt
                      .split("T")[0]
                      .replaceAll("-", ".")}
                  </Typography>
                </Box>
                */}
                <Box className={styles.transactionListItem}>
                  <Typography>
                    <TranslateText value="orders.orderPage.transaction.status.title" />
                  </Typography>
                  <Typography
                    sx={{
                      color:
                        order?.transaction?.status === "succeeded"
                          ? "green"
                          : "red",
                    }}
                  >
                    <TranslateText
                      value={`orders.orderPage.transaction.status.${order.transaction.status}`}
                    />
                  </Typography>
                </Box>
                {/*
                <Box className={styles.transactionListItem}>
                  <Typography>
                    <TranslateText value="orders.orderPage.transaction.currency" />
                  </Typography>
                  <Typography>{order.transaction.currency}</Typography>
                </Box>
                
                <Box className={styles.transactionListItem}>
                  <Typography>
                    <TranslateText value="orders.orderPage.transaction.conversionRate" />
                  </Typography>
                  <Typography>{order.transaction.conversionRate}</Typography>
                </Box>
                <Box className={styles.transactionListItem}>
                  <Typography>
                    <TranslateText value="orders.orderPage.transaction.marketplaceRate" />
                  </Typography>
                  <Typography>
                    {order.transaction.marketplaceRevenueRate}%
                  </Typography>
                </Box>
                */}
                <Box className={styles.transactionListItem}>
                  <Typography>
                    <TranslateText value="orders.orderPage.transaction.discountCode" />
                  </Typography>
                  <Typography>{order.transaction.discountCode || "-"}</Typography>
                </Box>

                <Divider />
              </Box>

              <Box className={styles.transactionListItem}>
                <Typography className={styles.totalTitle}>
                  <TranslateText value="orders.orderPage.transaction.total" />
                </Typography>

                <Typography className={styles.totalValue}>
                  {order?.transaction?.total
                    ? `${order.transaction.total.toFixed(2)} ` + currencySign()
                    : null}
                </Typography>
              </Box>

              <Button
                variant="outlined"
                className={styles.viewInWalletButton}
                onClick={() =>
                  navigate(
                    `/profile/${order.seller.userSlug}#wallet?id=${order.transaction._id}`
                  )
                }
              >
                <TranslateText value="orders.orderPage.transaction.viewInWalletButtonLabel" />
              </Button>
            </Card>
        </Box>
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row-reverse',
        gap: '10px',
        justifyContent: 'space-between',
        width: '100%',
        '@media (max-width: 600px)': {
          flexDirection: 'column',
        }
      }}>
      <Box className={styles.eventsContainer}>
        <Typography className={styles.eventsTitle}>
          События:
        </Typography>


        <Box className={styles.eventsList}>
          
          {order?.logs?.reverse().map((log, index) => (
            <Box key={index} className={styles.event}>
              <Typography className={styles.eventText}>
                {(order.logs.length - index) + 1}. {log.text}:
              </Typography>
              <Typography className={styles.eventTime}>
                {convertTimestamp(log.time)}
              </Typography>
            </Box>
          ))}

          <Box className={styles.event}>
            <Typography className={styles.eventText}>
              1. Заказ создан: 
            </Typography>
            <Typography className={styles.eventTime}>
              {convertTimestamp(order?.createdAt)}
            </Typography>       
          </Box>

        </Box>
      </Box>

      <Box sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        padding: "20px",
        maxWidth: "700px",
      }}>

        <Typography variant="h6">
          ❗️ Инструкция после покупки
        </Typography>

        <List sx={{ listStyleType: 'disc' }}>
          <ListItem sx={{ display: 'list-item' }}>
            Удержание средств: средства удерживаются на стороне площадки для безопасности вашей сделки.
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            Данные товара: ознакомьтесь с данными, которые продавец указал при публикации.
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            Чат с продавцом: если данных недостаточно и у вас возникли вопросы, или вам понадобилась дополнительная информация — свяжитесь с продавцом в чате сделки.
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            Решение проблемы: в случае возникновения проблем попробуйте решить их напрямую с продавцом. Сообщите о проблеме нам, если решить проблему с продавцом не удалось.
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            Выполнение заказа: у продавца есть 24 часа чтобы предоставить товар.
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            Проверка товара: проверьте товар после того, как продавец отметит заказ выполненным. Убедитесь, что товар соответствует описанию.
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            Подтверждение получения: продавец не получит оплату, пока вы не подтвердите получение товара.
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            Завершение сделки: после выполнения заказа сделка подтвердится автоматически поддержкой через 3 дня, если вы не сообщите о проблеме. После подтверждения получения товара сделка считается завершенной и продавцу перечисляется оплата.
          </ListItem>
        </List>

        <Typography variant="h6">
          ❗️ Для безопасности сделки:
        </Typography>

        <List sx={{ listStyleType: 'disc' }}>
          <ListItem>
            ⛔️ Не ведите переписку за пределами Mentup.
          </ListItem>
          <ListItem>
            ⛔️ Не отправляйте денежные средства на прямые реквизиты продавца.
          </ListItem>
          <ListItem>
            ⛔️ Не разглашайте данные, связанные с вашим профилем Mentup (пароль от почты профиля или код для входа в профиль).
          </ListItem>
          <ListItem>
            ⛔️ Не переходите по подозрительным ссылкам.
          </ListItem>
        </List>
        
        <Typography>
          🔰 Служба поддержки Mentup Support  готова помочь вам 24/7 в случае возникновения проблем.
        </Typography>

        <Typography>
          Желаем удачных сделок! 💜
        </Typography>
      </Box>
      </Box>
    </Container>
  );
};

export default OrderPage;
