const Logout = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="#F6F6F6"
  >
    <path d="M9.96323 0.833496C9.05151 0.833483 8.31663 0.833472 7.73865 0.911179C7.13858 0.991857 6.63333 1.16445 6.23205 1.56573C5.8821 1.91568 5.70527 2.34573 5.61245 2.85106C5.52225 3.3421 5.505 3.94303 5.50098 4.66405C5.49945 4.94019 5.72206 5.16529 5.99819 5.16682C6.27433 5.16836 6.49943 4.94575 6.50097 4.66961C6.50503 3.94062 6.52396 3.42389 6.59599 3.03173C6.6654 2.65385 6.77686 2.43514 6.93916 2.27284C7.12367 2.08833 7.38271 1.96803 7.8719 1.90226C8.37547 1.83456 9.04287 1.8335 9.99982 1.8335H10.6665C11.6234 1.8335 12.2908 1.83456 12.7944 1.90226C13.2836 1.96803 13.5426 2.08833 13.7271 2.27284C13.9117 2.45735 14.032 2.71639 14.0977 3.20558C14.1654 3.70914 14.1665 4.37655 14.1665 5.3335V10.6668C14.1665 11.6238 14.1654 12.2912 14.0977 12.7948C14.032 13.2839 13.9117 13.543 13.7271 13.7275C13.5426 13.912 13.2836 14.0323 12.7944 14.0981C12.2908 14.1658 11.6234 14.1668 10.6665 14.1668H9.99982C9.04287 14.1668 8.37546 14.1658 7.8719 14.0981C7.38271 14.0323 7.12367 13.912 6.93916 13.7275C6.77686 13.5652 6.6654 13.3465 6.59599 12.9686C6.52396 12.5764 6.50503 12.0597 6.50097 11.3307C6.49943 11.0546 6.27433 10.832 5.99819 10.8335C5.72206 10.835 5.49945 11.0601 5.50098 11.3363C5.505 12.0573 5.52225 12.6582 5.61245 13.1493C5.70527 13.6546 5.8821 14.0846 6.23205 14.4346C6.63333 14.8359 7.13858 15.0085 7.73865 15.0891C8.31663 15.1669 9.05151 15.1668 9.96324 15.1668H10.7031C11.6148 15.1668 12.3497 15.1669 12.9277 15.0891C13.5277 15.0085 14.033 14.8359 14.4343 14.4346C14.8355 14.0333 15.0081 13.5281 15.0888 12.928C15.1665 12.35 15.1665 11.6151 15.1665 10.7034V5.29692C15.1665 4.38519 15.1665 3.65031 15.0888 3.07233C15.0081 2.47226 14.8355 1.96701 14.4343 1.56573C14.033 1.16445 13.5277 0.991857 12.9277 0.911179C12.3497 0.833472 11.6148 0.833483 10.7031 0.833496H9.96323Z" />
    <path d="M9.99967 7.50016C10.2758 7.50016 10.4997 7.72402 10.4997 8.00016C10.4997 8.27631 10.2758 8.50016 9.99967 8.50016H2.68464L3.99174 9.62054C4.2014 9.80025 4.22568 10.1159 4.04597 10.3256C3.86626 10.5352 3.55061 10.5595 3.34095 10.3798L1.00761 8.37979C0.896789 8.2848 0.833008 8.14613 0.833008 8.00016C0.833008 7.8542 0.896789 7.71553 1.00761 7.62054L3.34095 5.62054C3.55061 5.44082 3.86626 5.46511 4.04597 5.67477C4.22568 5.88443 4.2014 6.20008 3.99174 6.37979L2.68464 7.50016H9.99967Z" />
  </svg>
);

export default Logout;
