import axios from "axios";
import Cookies from "js-cookie";

const API = axios.create({
  baseURL: process.env.NODE_ENV.includes("production")
    ? process.env.REACT_APP_SERVER_URL
      ? process.env.REACT_APP_SERVER_URL
      : ""
    : "http://localhost:3030/api/",
});

const header = () => {
  const token = Cookies.get("refreshToken");
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
};

export const getUser = (id) => API.get("/auth/user/" + id, header());

export const getUsersBySearch = (searchQuery) =>
  API.get("/auth/search/" + searchQuery, header());

export const getUsersByIds = (ids) =>
  API.post("/auth/search/ids", { ids }, header());
