import axios from 'axios';
import Cookies from 'js-cookie';

const API = axios.create({
    baseURL: process.env.NODE_ENV.includes('production')
        ? process.env.REACT_APP_SERVER_URL
            ? process.env.REACT_APP_SERVER_URL
            : ''
        : 'http://localhost:3030/api/',
});

export const checkCaptcha = (data) => {
    return API.post('/auth/login/check-captcha/', data, { credentials: 'same-origin' });
};

export const logInEmail = (data) => {
    return API.post('/auth/login/email', data, { credentials: 'same-origin' });
};

export const logInOtp = (data) => {
    return API.post('/auth/login/otp', data, { credentials: 'same-origin' });
};

export const logInGoogle = (data) => {
    return API.post('/auth/login/google/', data, { credentials: 'same-origin' });
};

export const logInVK = (data) => {
    return API.post('/auth/login/vk/', data, { credentials: 'same-origin' });
};
