import { IconButton } from "@mui/material";
import Facebook from "../../svg/socials/Facebook";
import Instagram from "../../svg/socials/Instagram";
import Telegram from "../../svg/socials/Telegram";
import YouTube from "../../svg/socials/YouTube";
import RussianCard from "../../svg/payments/RussianCard";
import Qiwi from "../../svg/payments/Qiwi";
import Yoomoney from "../../svg/payments/Yoomoney";
import Cripto from "../../svg/payments/Cripto";
import Visa from "../../svg/payments/Visa";
import Mastercard from "../../svg/payments/Mastercard";
import MNP from "../../svg/payments/MNP";
import VkSVG from "../../../lib/svg/vk";

const FOOTER_LINKS = {
  main: [
    {
      label: "footer.home",
      path: "/",
    },
    {
      label: "footer.blog",
      path: "/blog",
    },
    // {
    //   label: "footer.aboutUs",
    //   path: "/about-us",
    // },
    {
      label: "footer.contactUs",
      path: "/contact",
    },
    {
      label: "footer.rules",
      path: "/info/laws/rules",
    },
    {
      label: "footer.payments",
      path: "/info/payments",
    },
    {
      label: "footer.agencyContract",
      path: "/info/laws/agency-contract",
    },
    {
      label: "footer.licenseAgreement",
      path: "/info/laws/license-agreement",
    },
    {
      label: "footer.privacyPolicy",
      path: "/info/laws/privacy-policy",
    },
    {
      label: "Запрещённый товар",
      path: "/info/laws/products",
    },
    {
      label: "footer.sellers",
      path: '/sellers',
    },
    {
      label: "footer.aboutUs",
      path: '/about-us',
    },

  ],
  socials: [
    {
      icon: <Telegram />,
      path: "https://t.me/MenTup_store",
    },
    {
      icon: <YouTube />,
      path: "https://www.youtube.com/@Mentupstore",
    },
    {
      icon: <Instagram />,
      path: "https://www.instagram.com/mentupstore?igsh=MXRleDZ5b3E1NXRqbg==",
    },
    {
      icon: <VkSVG fill="#8142F6" />,
      path: "https://vk.com/mentupstore",
    },
  ],
  payments: [
    {
      icon: <RussianCard />,
      path: "#",
    },
    /*
    {
      icon: <Qiwi />,
      path: "#",
    },
    {
      icon: <Yoomoney />,
      path: "#",
    },
    {
      icon: <Cripto />,
      path: "#",
    },
    */
    {
      icon: <Visa />,
      path: "#",
    },
    {
      icon: <Mastercard />,
      path: "#",
    },
    {
      icon: <MNP />,
      path: "#",
    },
  ],
};

export default FOOTER_LINKS;
