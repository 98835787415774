import { useTheme } from "@emotion/react";
import { Box, IconButton, Typography, useMediaQuery } from "@mui/material";
import axios from "axios";
import clsx from "clsx";
import Cookies from "js-cookie";
import { useRef, useState } from "react";

import { useTranslate } from "../../../../context/translate/translate.context";
import { TranslateText } from "../../../translate-text";
import CoverImage from "./CoverImage";
import styles from "./styles.module.css";
import CameraIcon from "./svg/Camera";
import ShareIcon from "./svg/Share";
import { useNavigate } from "react-router-dom";

const UserInfoCard = ({ isCurrentUser, user, updateLocalUser }) => {
  const theme = useTheme();
  const { translate } = useTranslate();
  const navigate = useNavigate();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [isTextCopied, setIsTextCopied] = useState(false);

  const shareTextTimeout = useRef(null);

  const displayDate = (date) => {
    const d = new Date(date);
    return `${d.getDate()} ${translate(
      `date.months.${d.getMonth()}`
    )} ${d.getFullYear()}`;
  };

  const uploadPhoto = async (file, endUrl) => {
    const url = `${
      process.env.REACT_APP_SERVER_URL || "http://localhost:3030/api"
    }/${endUrl}`;
    const formData = new FormData();
    formData.append("image", file);

    const token = Cookies.get("refreshToken");
    const { data } = await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  };

  const handleAvatarChange = async (event) => {
    if (!event?.target?.files?.[0]) {
      return;
    }

    const file = event.target.files[0];
    const res = await uploadPhoto(file, "auth/updateAvatar");
    updateLocalUser({ avatar: res.avatar });
    // Update Header Avatar
    let currentUser = JSON.parse(localStorage.getItem('user'))
    currentUser.avatar = res.avatar
    localStorage.setItem('user', JSON.stringify(currentUser))
  };

  const handleCoverChange = async (event) => {
    if (!event?.target?.files?.[0]) {
      return;
    }

    const file = event.target.files[0];
    const res = await uploadPhoto(file, "auth/updateCoverImage");
    updateLocalUser({ coverImage: res.coverImage });
  };

  if (!user) {
    return null;
  }

  const localStorageUser = JSON.parse(localStorage.getItem("user"))

  const isMe = localStorageUser?._id === user._id

  const handleReviewsClick = async () => {
    // navigate to current location + #reviews
    if(user.ratingCount > 0)
      navigate(window.location.pathname + "#reviews")
  }

  const infoTopContainer = () => (
    <Box className={styles.infoTopContainer}>
      <Typography noWrap className={styles.username} sx={{ 
        fontSize: "24px", 
        color:
          user.status === "banned"
            ? "red"
            : theme.palette.mode === "dark"
            ? "#ffffff"
            : "#000000",
      }}>
        {user.username}
      </Typography>

      <Box className={styles.ratingContainer}>
        <Typography
          sx={{
            fontSize: "16px",
            lineHeight: "13px",
            fontWeight: 400,
            color: theme.palette.mode === "dark" ? "#f6f6f699" : "#1f1d2299",
          }}
        >
          <TranslateText value="userProfile.rating.title" />
        </Typography>

        <Box className={styles.rating}>
          <Box
            style={{ "--percent": `${(user.rating / 5) * 100}%` }}
            className={styles.reviews__rating}
            min={0}
            max={5}
            value={user.rating}
            component="meter"
          />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: theme.palette.mode === "dark" ? "#ffffff80" : "#00000080",
              '&:hover': {
                cursor: 'pointer',
                textDecoration: 'underline'
              }
            }}
            onClick={() => handleReviewsClick()}
          >
            {user.ratingCount}{" "}
            {translate(
              `userProfile.rating.count.${
                user.ratingCount === 1 ? "one" : "other"
              }`
            )}
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box className={styles.container}>
      <CoverImage image={user.coverImage} />

      <IconButton
        component="label"
        aria-label={translate("vendorDashboard.avatarUploadAriaLabel")}
        sx={{ display: isCurrentUser ? "flex" : "none" }}
        className={styles.camera}
      >
        <input
          hidden
          accept="image/*"
          type="file"
          onChange={handleCoverChange}
        />
        <CameraIcon
          color={theme.palette.mode === "dark" ? "#F6F6F6" : "#1f1d22"}
        />
      </IconButton>

      <IconButton
        component="label"
        aria-label={translate("vendorDashboard.avatarUploadAriaLabel")}
        className={styles.share}
        onClick={() => {
          navigator.clipboard.writeText(
            window.location.origin + '?invite=' + window.location.pathname.split('/')[2]
          );
          if (shareTextTimeout.current) {
            clearTimeout(shareTextTimeout.current);
          }

          setIsTextCopied(true);
          shareTextTimeout.current = setTimeout(() => {
            setIsTextCopied(false);
          }, 3000);
        }}
      >
        <Box
          className={clsx(styles.tooltip, isTextCopied && styles.tooltipCopied)}
        >
          <span className={styles.tooltiptext} id="myTooltip">
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                color: "rgba(255, 255, 255, 0.5)",
              }}
            >
              <TranslateText
                value={
                  isTextCopied
                    ? "vendorDashboard.shareLinkCopied"
                    : "vendorDashboard.shareLink"
                }
              />
            </Typography>
          </span>

          <ShareIcon
            color={theme.palette.mode === "dark" ? "#F6F6F6" : "#1f1d22"}
          />
        </Box>
      </IconButton>

      <Box className={styles.userContainer}>
        <Box
          sx={{
            display: "flex",
            gap: "24px",
          }}
        >
          <Box
            {...(user.avatar && {
              sx: { backgroundImage: `url(${user.avatar})` },
            })}
            className={styles.avatarContainer}
          >
            <IconButton
              component="label"
              aria-label={translate("vendorDashboard.avatarUploadAriaLabel")}
              sx={{ display: isCurrentUser ? "flex" : "none" }}
              className={styles.camera}
            >
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={handleAvatarChange}
              />
              <CameraIcon
                color={theme.palette.mode === "dark" ? "#F6F6F6" : "#1f1d22"}
              />
            </IconButton>
          </Box>

          {isMobile && infoTopContainer()}
        </Box>

        <Box className={styles.infoContainer}>
          {!isMobile && infoTopContainer()}

          <Box className={styles.bottomContainer}>
            <Box className={styles.bottomLeftContainer}>
              <Box className={styles.bottomItemLeftContainer}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "11px",
                    color:
                      theme.palette.mode === "dark" ? "#f6f6f699" : "#1f1d2299",
                  }}
                >
                  <TranslateText value="userProfile.registrationDate" />
                </Typography>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                    lineHeight: "14.33px",
                    color:
                      theme.palette.mode === "dark" ? "#ffffff" : "#000000",
                  }}
                >
                  {displayDate(user.createdAt)}
                </Typography>
              </Box>
            </Box>
            {isMe && (
              <Box className={styles.bottomRightContainer}>
                <Box className={styles.bottomItemRightContainer}>
                  <Typography
                    sx={{
                      fontSize: "24px",
                      fontWeight: 500,
                      lineHeight: "18.86px",
                      color:
                        theme.palette.mode === "dark" ? "#ffffff" : "#000000",
                    }}
                  >
                    {user.sales}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "11px",
                      color:
                        theme.palette.mode === "dark" ? "#f6f6f699" : "#1f1d2299",
                    }}
                  >
                    <TranslateText
                      value={`userProfile.sales.count.${
                        user.sales === 1 ? "one" : "other"
                      }`}
                    />
                  </Typography>
                </Box>
                <Box className={styles.divider} />
                <Box className={styles.bottomItemRightContainer}>
                  <Typography
                    sx={{
                      fontSize: "24px",
                      fontWeight: 500,
                      lineHeight: "18.86px",
                      color:
                        theme.palette.mode === "dark" ? "#ffffff" : "#000000",
                    }}
                  >
                    {user.purchases}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "11px",
                      color:
                        theme.palette.mode === "dark" ? "#f6f6f699" : "#1f1d2299",
                    }}
                  >
                    <TranslateText
                      value={`userProfile.purchases.count.${
                        user.purchases === 1 ? "one" : "other"
                      }`}
                    />
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UserInfoCard;
