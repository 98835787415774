import { configureStore } from "@reduxjs/toolkit";

import { cartReducer } from "./cartStore";
import currentTabSlice from "./currrentTabSlice"; // Import the currentTab reducer
import languageReducer from "./languageSlice"; // Import the language reducer
import { notificationReducer } from "./notificationStore";
import { productTabReducer } from "./productTabStore";
import { searchReducer } from "./searchStore";
import { userSettingsReducer } from "./userSettingsStore";
import { userReducer } from "./userStore";
import { walletReducer } from "./walletStore";

const reducer = {
  cart: cartReducer,
  currentTab: currentTabSlice,
  language: languageReducer,
  notification: notificationReducer,
  productTab: productTabReducer,
  search: searchReducer,
  user: userReducer,
  userSettings: userSettingsReducer,
  wallet: walletReducer,
};

const store = configureStore({ reducer });

export default store;
