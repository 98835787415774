import { Box, Typography } from "@mui/material";
import { useCallback } from "react";

import { TranslateText } from "../../../../../translate-text";
import styles from "./styles.module.css";

const TransactionListHeader = ({ dimensions, setDimensions }) => {
  const onDimensionDetect = useCallback(
    (status) => (ref) => {
      if (!!ref?.offsetWidth && dimensions[status] !== ref?.offsetWidth) {
        setDimensions((prev) => ({
          ...prev,
          [status]: Math.max(ref.offsetWidth, prev[status]),
        }));
      }
    },
    [...Object.values(dimensions)]
  );

  return (
    <Box className={styles.container}>
      <Box className={styles.summaryItem} sx={{ paddingLeft: "16px" }}>
        <Typography
          ref={onDimensionDetect("id")}
          sx={{ minWidth: `${dimensions.id}px` }}
          className={styles.id}
        >
          <TranslateText value="transactions.items.header.id" />
        </Typography>
        <Typography
          ref={onDimensionDetect("date")}
          sx={{ minWidth: `${dimensions.date}px`, textAlign: "center" }}
          className={styles.date}
        >
          <TranslateText value="transactions.items.header.date" />
        </Typography>
      </Box>
      <Box className={styles.summaryItem}>
        <Typography
          ref={onDimensionDetect("status")}
          sx={{ minWidth: `${dimensions.status}px`, textAlign: "center" }}
          className={styles.status}
        >
          <TranslateText value="transactions.items.header.status" />
        </Typography>
        <Typography
          ref={onDimensionDetect("type")}
          sx={{ minWidth: `${dimensions.type}px`, textAlign: "center" }}
          className={styles.type}
        >
          <TranslateText value="transactions.items.header.type" />
        </Typography>
        <Typography
          ref={onDimensionDetect("paidWith")}
          sx={{ minWidth: `${dimensions.paidWith}px`, textAlign: "center" }}
          className={styles.paidWith}
        >
          <TranslateText value="transactions.items.header.paidWith" />
        </Typography>
      </Box>
      <Box className={styles.summaryItem} sx={{ paddingRight: "16px" }}>
        <Typography
          ref={onDimensionDetect("sum")}
          sx={{ minWidth: `${dimensions.sum}px`, textAlign: "right" }}
          className={styles.sum}
        >
          <TranslateText value="transactions.items.header.sum" />
        </Typography>
      </Box>
    </Box>
  );
};

export default TransactionListHeader;
