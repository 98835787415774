import { Box, Typography } from '@mui/material'
import React from 'react'

const TermsOfSale = () => {
  return (
    <Box>
        <Typography variant="h6" gutterBottom>
            Пользовательское соглашение
        </Typography>

        <Typography variant="h6" gutterBottom>
            Термины и понятия
        </Typography>

        <Typography paragraph>
            Для целей настоящего Соглашения перечисленные ниже термины имеют следующие значения:
        </Typography>

        {/* Prohibited Information Products in the Russian Federation */}
        <Typography variant="h6" gutterBottom>
            Список запрещенных информационных товаров на территории РФ
        </Typography>

        <Typography paragraph>
            В соответствии с законодательством Российской Федерации, определенные виды информации или информационные продукты могут быть запрещены к распространению. Вот некоторые из них:
        </Typography>

        <Typography paragraph>
            Экстремистский материал
        </Typography>

        <Typography paragraph>
            Любой материал, призывающий к экстремизму или содержащий экстремистскую риторику.
        </Typography>

        <Typography paragraph>
            Детская порнография
        </Typography>

        <Typography paragraph>
            Любой материал или информационный продукт, который содержит изображения или описания детской порнографии, строго запрещен.
        </Typography>

        <Typography paragraph>
            Информация о наркотиках
        </Typography>

        <Typography paragraph>
            Материалы, содержащие инструкции или рекомендации по приготовлению или употреблению наркотических веществ.
        </Typography>

        <Typography paragraph>
            Личные данные
        </Typography>

        <Typography paragraph>
            Незаконное распространение или продажа личных данных без согласия лица.
        </Typography>

        <Typography paragraph>
            Несанкционированный контент
        </Typography>

        <Typography paragraph>
            Распространение материалов без соответствующих авторских прав или лицензий.
        </Typography>

        <Typography paragraph>
            Материалы, нарушающие честь и достоинство
        </Typography>

        <Typography paragraph>
            Информация, порочащая честь, достоинство или деловую репутацию граждан или юридических лиц.
        </Typography>

        <Typography paragraph>
            Призывы к массовым беспорядкам
        </Typography>

        <Typography paragraph>
            Материалы, призывающие к массовым беспорядкам или нарушению общественного порядка.
        </Typography>

        <Typography paragraph>
            Террористический контент
        </Typography>

        <Typography paragraph>
            Любая информация, поддерживающая или призывающая к терроризму.
        </Typography>

        <Typography paragraph>
            Запрещенные книги и публикации
        </Typography>

        <Typography paragraph>
            Различные тексты или публикации, которые были официально запрещены решением суда.
        </Typography>

        <Typography paragraph>
            Это лишь некоторые из видов информации, которые могут быть запрещены к распространению в Российской Федерации. Если у вас возникают вопросы относительно конкретного материала, рекомендуется консультация с юристом или проверка через официальные источники (<a href="http://www.kremlin.ru/acts/bank/9555" target="_blank" rel="noopener noreferrer">http://www.kremlin.ru/acts/bank/9555</a>).
        </Typography>
    </Box>
  )
}

export default TermsOfSale