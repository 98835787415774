import { useTheme } from "@emotion/react";
import { Box, CircularProgress, Paper, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { getCountryByIp } from "../../api/country/getCountryByIp";
import { useAlertServiceWorker } from "../../hooks/serviceWorker";
import StaticSeo from "../../lib/seo/StaticSeo";
import { setCountry } from "../../store/userStore";
import Footer from "./Footer";
import Header from "./Header";
import { PAGES_WITH_CUSTOM_FOOTER } from "./constants";

const Layout = ({ children, loading: topLoading }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const location = useLocation();

  useAlertServiceWorker();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      const { data } = await getCountryByIp();

      dispatch(setCountry(data));
      setLoading(false);
    };

    getData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const isCustomFooter = PAGES_WITH_CUSTOM_FOOTER.some((path) =>
    location.pathname.includes(path)
  );

  const isChat = window.location.pathname === '/chat'

  return (
    <>
      {loading || topLoading ? (
        <Box
          sx={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100vw",
            height: "100vh",
            background: "rgba(0,0,0,1)",
            zIndex: "100",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Paper
          data-mui-color-scheme={theme.palette.mode}
          elevation={0}
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "calc(100vh - 80px)",
            justifyContent: "space-between",
            maxWidth: "100vw",
            backgroundColor:
              theme.palette.mode === "dark"
                ? "primary.backgroundPaperDark"
                : "primary.four",
            padding: "0",
            paddingTop: "80px",
            margin: "0",

            "@media (max-width: 900px)": {
              paddingTop: "135px",
            },
          }}
        >
          <StaticSeo location={location} />

          <Header />
          <Box component="main">{children}</Box>
          {!isCustomFooter && !isChat && <Footer />}
        </Paper>
      )}
    </>
  );
};

export default Layout;
