import { Box, Button, Typography } from "@mui/material";

import { addTelegram } from "../../../api/settings/telegram";
import { useTranslate } from "../../../context/translate/translate.context";
import { TranslateText } from "../../translate-text";
import TelegramLoginButton from "../../vendor/DashboardComponents/UserInfoTabSettings/components/ConnectedAccounts/components/TelegramBtn";
import styles from "./styles.module.css";

const TelegramLinkAccount = ({ next, close }) => {
  const { translate } = useTranslate();

  const onTelegramAuthCallback = async (data) => {
    try {
      if (data.error) {
        setAlertMessage(data.error);
      } else {
        await addTelegram(data);

        next();
      }
    } catch (error) {
      //console.log("error", error);
      setAlertMessage(data.error);
    }
  };

  return (
    <Box className={styles.container}>
      <Typography variant="h5" className={styles.title}>
        {translate("checkout.stripe.steps.step1.title")}
      </Typography>

      <TelegramLoginButton
        onAuthCallback={onTelegramAuthCallback}
        requestAccess="write"
        botUsername={process.env.REACT_APP_TELEGRAM_BOT_NAME}
        buttonSize="large" // "large" | "medium" | "small"
        cornerRadius={5} // 0 - 20
        lang="en"
        widgetVersion={22}
      />

      <Button className={styles.cancelButton} onClick={close}>
        <TranslateText value="checkout.stripe.steps.step1.cancel" />
      </Button>
    </Box>
  );
};

export default TelegramLinkAccount;
