export const SETTINGS_TABS_DISABLED = ["email", "reviews", "help"];

export const SETTINGS_TABS = [
  "accounts",
  "2fa",
  "notifications",
  /*
  "email",
  "reviews",
  "help", */
];
