import { Container } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { resetCart } from "../../store/cartStore";
import VendorDashboard from "./VendorDashboard";
import { getLatestOrderByUserSlug } from "../../api/order/OrdersApi";

function VendorProfile() {

  const { userSlug } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cartItems = useSelector((state) => state.cart);


  useEffect(() => {

    // Use URLSearchParams for better URL parameter handling
    
    const params = new URLSearchParams(window.location.search);
    let payment = params.get('payment');

    if (!payment) {
      const searchParams = window.location.href.split("?")[1] || null
      if(searchParams !== null) {
        if(!searchParams.includes("id")) {
        const paymentQuery = searchParams.split("&")[0]
        payment = paymentQuery.split('=')[1]
      }
    }
    }
    if (payment === "succeeded" && userSlug) {
      if (cartItems.length > 0) {
        const formattedCartItems = cartItems.map((item) => {
          const price = parseFloat(item.product.price);
          const quantity = parseInt(item.quantity);
          const discount = item.product.promocode?.discount || 0;
          const totalPrice = price * quantity * (1 - discount / 100);
          return {
            id: item.product._id,
            name: item.product.name,
            price: parseFloat(totalPrice.toFixed(2)), // Ensure two decimal places
            quantity: parseInt(quantity),
            category: item.product.category?.name,
          };
        });
        // Check if dataLayer exists before pushing data
        if (window.dataLayer) {
          window.dataLayer.push({
            "ecommerce": {
              "currencyCode": "RUB",
              "purchase": {
                "actionField": {
                  "id": formattedCartItems[0]._id
                },
                "products": formattedCartItems
              }
            }
          });
        }
        dispatch(resetCart());
      } else {
        // make a call to get the latest order by userSlug
        //getLatestOrder(userSlug)
      }
      navigate(`/profile/${userSlug}?from=payment#purchases`);
    }
  }, [cartItems, dispatch, navigate, userSlug]);

  if (!userSlug) {
    return null;
  }

  return (
    <Container>
      <VendorDashboard key={userSlug} />
    </Container>
  );
}

export default VendorProfile;
