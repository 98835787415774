import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import {
  FormControl,
  InputBase,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setLanguage } from "../../store/languageSlice";
import styles from "./styles.module.css";

const LanguageSelector = () => {
  const theme = useTheme();

  const dispatch = useDispatch();

  const selectedLanguage = useSelector((state) => state.language);

  const handleLanguageChange = ({ target: { value } }) => {
    dispatch(setLanguage(value));
  };

  const handleValueRender = (value) =>
    value.charAt(0).toUpperCase() + value.slice(1);

  return (
    <FormControl className={styles.container}>
      {/* TODO: move in a separate generic select component and use it */}
      <Select
        MenuProps={{
          ["data-mui-color-scheme"]: theme.palette.mode,
          classes: {
            paper: styles.paper,
            list: styles.list,
          },
        }}
        value={selectedLanguage}
        onChange={handleLanguageChange}
        IconComponent={KeyboardArrowDown}
        renderValue={handleValueRender}
        input={<InputBase className={styles.input} />}
      >
        <MenuItem className={styles.item} value="en">
          English
        </MenuItem>
        <MenuItem className={styles.item} value="ru">
          Russian
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default LanguageSelector;
