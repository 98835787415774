import { Box, Typography } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import { useCallback, useEffect, useState } from "react";

import { useTranslate } from "../../../../../../context/translate/translate.context";
import MentupModal from "../../../../../MentupModal/MentupModal";
import MentupStepper from "../../../../../MentupStepper";
import { TranslateText } from "../../../../../translate-text";
import PromoteProductStep1 from "./components/step1";
import PromoteProductStep2 from "./components/step2";
import PromoteProductStep3 from "./components/step3";
import styles from "./styles.module.css";
import PromoteProductStep4 from "./components/step4";

const PromoteProductContent = ({ open, close, productId, productName }) => {
  const { translate } = useTranslate();

  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState();
  const [selectedPayment, setSelectedPayment] = useState();
  const [selectedFeatureType, setSelectedFeatureType] = useState();

  useEffect(() => {
    setStep(open ? 0 : -1);
    if (!open) {
      setSelectedPrice();
      setSelectedPayment();
      setSelectedFeatureType();
    }
  }, [open]);

  const handlePayPayBox = async (balanceUsed = 0) => {
    const requestUrl = `${process.env.REACT_APP_SERVER_URL}/payment/paybox/promote`;

    const body = {
      productId,
      balanceUsed,
      priceId: selectedPrice._id,
    };
    const response = await axios.post(requestUrl, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("refreshToken")}`,
      },
    });
    if (response.data?.redirectUrl) {
      window.location.replace(response.data.redirectUrl);
    }
  };

  const handlePayLava = async (balanceUsed = 0) => {
    const requestUrl = `${process.env.REACT_APP_SERVER_URL}/payment/lava/promote`;

    const body = {
      productId,
      balanceUsed,
      priceId: selectedPrice._id,
    };
    const response = await axios.post(requestUrl, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("refreshToken")}`,
      },
    });
    if (response.data?.redirectUrl) {
      window.location.replace(response.data.redirectUrl);
    }
  };

  const handlePayYooKassa = async (balanceUsed = 0) => {
    const requestUrl = `${process.env.REACT_APP_SERVER_URL}/payment/yookassa/promote`;

    const body = {
      productId,
      balanceUsed,
      priceId: selectedPrice._id,
    };
    const response = await axios.post(requestUrl, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("refreshToken")}`,
      },
    });
    if (response.data?.redirectUrl) {
      window.location.replace(response.data.redirectUrl);
    }
  };
  const handlePayTinkoff = async (balanceUsed = 0) => {
    const requestUrl = `${process.env.REACT_APP_SERVER_URL}/payment/tinkoff/promote`;


    const body = {
      productId,
      balanceUsed,
      priceId: selectedPrice._id,
    };
    const response = await axios.post(requestUrl, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("refreshToken")}`,
      },
    });
    if (response.data?.redirectUrl) {
      window.location.replace(response.data.redirectUrl);
    }
  };
  const handlePayYooMoney = async (balanceUsed = 0) => {
    const requestUrl = `${process.env.REACT_APP_SERVER_URL}/payment/yooMoney/promote`;

    const body = {
      productId,
      balanceUsed,
      priceId: selectedPrice._id,
    };
    const response = await axios.post(requestUrl, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("refreshToken")}`,
      },
    });
    if (response.data?.redirectUrl) {
      window.location.replace(response.data.redirectUrl);
    }
  };

  const handlePayTransfer = async (balanceUsed = 0) => {
    const requestUrl = `${process.env.REACT_APP_SERVER_URL}/payment/transfer/promote`;

    const body = {
      productId,
      balanceUsed,
      priceId: selectedPrice._id,
    };
    await axios
      .post(requestUrl, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("refreshToken")}`,
        },
      })
      .then((res) => {
        if (res.data?.paymentCode) {
          window.location.replace(`/payment/transfer/${res.data.paymentCode}`);
        }
        if (res.data?.redirectUrl) {
          // Update User Balance
          let currentUser = JSON.parse(localStorage.getItem("user"));
          const currentBalance = currentUser?.wallet.balance;
          let newBalance = currentBalance - res.data.totalPaid;
          currentUser.wallet.balance = newBalance;
          localStorage.setItem("user", JSON.stringify(currentUser));
          window.location.replace(res.data.redirectUrl);
        }
      });
  };

  const pay = useCallback(async () => {
    setLoading(true);
    // if (selectedPayment._id === "yookassa") {
    //   await handlePayYooKassa();
    // }
    // if (selectedPayment._id === "paybox") {
    //   await handlePayPayBox();
    // }
    if (selectedPayment._id === "lava") {
      await handlePayLava();
    }
    if (selectedPayment._id === "transfer") {
      await handlePayTransfer();
    }
    if (selectedPayment._id === "balance") {
      await handlePayTransfer(selectedPrice.price);
    }
    if (selectedPayment._id === "tinkoff") {
      await handlePayTinkoff();
    }
    if (selectedPayment._id === "yooMoney") {
      await handlePayYooMoney();
    }

    setLoading(false);
  }, [selectedPayment?._id, selectedPrice?.price]);

  const stepTitle = {
    0: "products.product.promote.steps.step1.selectFeatureType",
    1: "products.product.promote.steps.step2.selectPrice",
    2: "products.product.promote.steps.step3.selectPayment",
    3: "products.product.promote.steps.step4.selectPayment",
  };

  return (
    <MentupModal open={open} close={close}>
      <Box className={styles.confirmContainer}>
        <Box className={styles.headerContainer}>
          <Typography className={styles.title}>
            {translate("products.product.promote.title")}
          </Typography>
          <Typography className={styles.subtitle}>
            {translate("products.product.promote.subtitle")}
          </Typography>
        </Box>

        <Box sx={{ width: "100%" }}>
          <MentupStepper
            getStepTitle={(index) =>
              `${translate("products.product.promote.steps.stepper")} ${
                index + 1
              }`
            }
            current={step}
            steps={4}
          />
          <Typography className={styles.selectPrice}>
            <TranslateText value={stepTitle[step]} />
          </Typography>
        </Box>

        <Typography className={styles.productName}>{productName}</Typography>

        {step === 0 && (
          <PromoteProductStep1
            selectedFeatureType={selectedFeatureType}
            onSelectFeatureType={setSelectedFeatureType}
            next={() => setStep(1)}
          />
        )}
        {step === 1 && (
          <PromoteProductStep2
            productId={productId}
            selectedFeatureType={selectedFeatureType}
            selectedPrice={selectedPrice}
            onPriceSelect={setSelectedPrice}
            next={() => setStep(2)}
          />
        )}
        {step === 2 && (
          <PromoteProductStep3
            selectedPrice={selectedPrice}
            selectedPayment={selectedPayment}
            onPaymentSelect={setSelectedPayment}
            next={() => setStep(3)}
          />
        )}
        {step === 3 && (
          <PromoteProductStep4
            loading={loading}
            selectedPrice={selectedPrice}
            selectedPayment={selectedPayment}
            onPaymentSelect={setSelectedPayment}
            next={pay}
          />
        )}
      </Box>
    </MentupModal>
  );
};

export default PromoteProductContent;
