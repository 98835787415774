import { useTheme } from "@emotion/react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Box, Button, Card, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import NotesIcon from "../../svg/Notes";
import styles from "./styles.module.css";
import { TranslateText } from "../../translate-text";
import Arrow from "../../svg/Arrow";

const GameNews = ({ title, posts }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleBlogPostClick = (id) => navigate(`/blog/${id}`);

  const renderItem = (post) => {
    return (
      <Card
        key={post.id}
        className={styles.postContainer}
        onClick={() => navigate(`/blog/${post.id}`)}
      >
        <Box
          onClick={() => handleBlogPostClick(post.id)}
          className={styles.image}
          sx={{
            backgroundImage: `url(${post.attributes.mainImage.data[0].attributes.url})`,
          }}
        />

        <Box className={styles.infoContainer}>
          <Box className={styles.calendarContainer}>
            <CalendarMonthIcon
              sx={{
                fontSize: "14px",
                color: theme.palette.mode === "dark" ? "#f6f6f6" : "#1f1d22",
              }}
            />
            <Typography className={styles.calendarDate}>
              {post.attributes.publishedAt.split("T")[0]}
            </Typography>
          </Box>

          <Typography className={styles.postTitle}>
            {post.attributes.title}
          </Typography>

          {!!post.attributes.Description && (
            <Typography className={styles.postDescription}>
              {post.attributes.Description}
            </Typography>
          )}

          <Box className={styles.chipsContainer}>
            {post.attributes.Tags.Single.map((tag, index) => (
              <Button
                key={tag.id}
                variant="contained"
                sx={{
                  padding: "0.2rem",
                  paddingX: "0.8rem",
                  borderRadius: "10px",
                  fontWeight: "bold",
                  fontSize: "0.7rem",
                }}
              >
                {tag.TagName}
              </Button>
            ))}
          </Box>
        </Box>
      </Card>
    );
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.titleContainer}>
        <NotesIcon
          color={theme.palette.mode === "dark" ? "#EDF67D" : "#8142f6"}
        />
        <Typography className={styles.title}>{title}</Typography>
      </Box>

      <Box className={styles.itemsContainer}>{posts.map(renderItem)}</Box>

      <Button
        color="secondary"
        className={styles.button}
        onClick={() => navigate("/blog")}
      >
        <TranslateText value="games.posts.viewAll" />
        <Arrow color={theme.palette.mode === "dark" ? "#f6f6f6" : "#1f1d22"} />
      </Button>
    </Box>
  );
};

export default GameNews;
