import axios from 'axios'

const API = axios.create({
    baseURL: process.env.NODE_ENV.includes('production')
        ? process.env.REACT_APP_SERVER_URL
            ? process.env.REACT_APP_SERVER_URL
            : ''
        : 'http://localhost:3030/api/',
});

const header = (token) => {
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };
}

export const createWithdrawal = (userId, data, token, country) => API.post('/withdrawal/'+userId+'?country='+country, data, header(token));