export const LogoNew = ({ width = "53", height = "26", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 53 26"
    fill="none"
    {...props}
  >
    <path
      d="M17.3089 0C22.5525 -4.37587e-05 23.4778 9.94201 25.637 9.94201C27.7961 9.94201 29.9553 1.4445e-10 34.582 0C36.9272 0 41.7076 6.53094 46.0204 13.3859C48.5145 17.3501 52.4721 24.1017 52.4721 24.4031C52.4721 25.4219 47.8454 27.2953 41.6764 24.4031C39.9683 23.6023 37.1113 20.7878 34.8905 15.9675C33.3482 19.2814 29.3384 25.9094 25.637 25.9094C21.9356 25.9094 18.337 19.2814 17.0004 15.9675C15.2731 20.0648 11.9624 23.2984 10.523 24.4031C6.32805 27.2953 0.0356903 25.0056 0.03568 24.4031C-0.401338 24.1017 3.27025 17.8402 5.89622 13.3859C9.79361 6.77492 14.5385 2.31194e-05 17.3089 0Z"
      fill="#5719CA"
    />
    <path
      d="M17.3089 0C22.5525 -4.37587e-05 23.4778 9.94201 25.637 9.94201C27.7961 9.94201 29.9553 1.4445e-10 34.582 0C36.9272 0 41.7076 6.53094 46.0204 13.3859C48.5145 17.3501 52.4721 24.1017 52.4721 24.4031C52.4721 25.4219 47.8454 27.2953 41.6764 24.4031C39.9683 23.6023 37.1113 20.7878 34.8905 15.9675C33.3482 19.2814 29.3384 25.9094 25.637 25.9094C21.9356 25.9094 18.337 19.2814 17.0004 15.9675C15.2731 20.0648 11.9624 23.2984 10.523 24.4031C6.32805 27.2953 0.0356903 25.0056 0.03568 24.4031C-0.401338 24.1017 3.27025 17.8402 5.89622 13.3859C9.79361 6.77492 14.5385 2.31194e-05 17.3089 0Z"
      fill="url(#paint0_radial_1245_3298)"
      fillOpacity="0.3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.3095 1.44448e-10C20.5036 -2.66545e-05 22.0954 3.68885 23.3397 6.57259C24.1382 8.42306 24.7937 10.0586 25.6376 10.0586C26.514 10.0586 27.3903 8.30427 28.4316 6.3582C29.9559 3.50945 31.8647 1.41943e-10 34.5827 1.44448e-10C38.0942 1.47684e-10 41.7083 6.53094 46.0211 13.3859C39.6259 7.70538 35.9365 12.74 34.8911 15.9674C33.3489 19.2814 29.339 25.9094 25.6376 25.9094C21.9362 25.9094 18.3377 19.2814 17.0011 15.9674C12.8061 7.77289 7.85038 10.832 5.89687 13.3859C9.79425 6.77492 14.5391 2.31194e-05 17.3095 1.44448e-10Z"
      fill="#8142F6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.3095 1.44448e-10C20.5036 -2.66545e-05 22.0954 3.68885 23.3397 6.57259C24.1382 8.42306 24.7937 10.0586 25.6376 10.0586C26.514 10.0586 27.3903 8.30427 28.4316 6.3582C29.9559 3.50945 31.8647 1.41943e-10 34.5827 1.44448e-10C38.0942 1.47684e-10 41.7083 6.53094 46.0211 13.3859C39.6259 7.70538 35.9365 12.74 34.8911 15.9674C33.3489 19.2814 29.339 25.9094 25.6376 25.9094C21.9362 25.9094 18.3377 19.2814 17.0011 15.9674C12.8061 7.77289 7.85038 10.832 5.89687 13.3859C9.79425 6.77492 14.5391 2.31194e-05 17.3095 1.44448e-10Z"
      fill="url(#paint1_radial_1245_3298)"
      fillOpacity="0.3"
    />
    <defs>
      <radialGradient
        id="paint0_radial_1245_3298"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(24.4032 15.3649) rotate(90.9024) scale(19.5851 39.4985)"
      >
        <stop stopColor="#411F9D" />
        <stop offset="1" stopColor="#EDF67D" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_1245_3298"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(25.3292) rotate(87.9617) scale(26.0165 52.4666)"
      >
        <stop stopColor="#EDF67D" />
        <stop offset="1" stopColor="#EDF67D" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);
