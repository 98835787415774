const Delete = ({ color = "#F6F6F6" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.87356 1.50001H9.12785C9.27212 1.49992 9.3978 1.49984 9.51648 1.51879C9.98534 1.59366 10.3911 1.8861 10.6104 2.30723C10.6659 2.41382 10.7055 2.53307 10.7511 2.66996L10.8255 2.89323C10.8381 2.93102 10.8417 2.94172 10.8447 2.95014C10.9615 3.27289 11.2642 3.49106 11.6073 3.49976C11.6163 3.49999 11.6273 3.50003 11.6674 3.50003H13.6674C13.9435 3.50003 14.1674 3.72388 14.1674 4.00003C14.1674 4.27617 13.9435 4.50003 13.6674 4.50003H2.33398C2.05784 4.50003 1.83398 4.27617 1.83398 4.00003C1.83398 3.72388 2.05784 3.50003 2.33398 3.50003H4.33404C4.37407 3.50003 4.38512 3.49999 4.39414 3.49976C4.73724 3.49106 5.03992 3.2729 5.15667 2.95016C5.15974 2.94168 5.16327 2.93121 5.17593 2.89323L5.25033 2.66998C5.29586 2.5331 5.33553 2.41382 5.39104 2.30723C5.61034 1.8861 6.01607 1.59366 6.48494 1.51879C6.60362 1.49984 6.7293 1.49992 6.87356 1.50001ZM6.00608 3.50003C6.04043 3.43268 6.07086 3.36269 6.09704 3.29032C6.10498 3.26834 6.11278 3.24495 6.1228 3.21489L6.18933 3.01528C6.25011 2.83294 6.26411 2.79575 6.27799 2.76909C6.35109 2.62872 6.48633 2.53124 6.64262 2.50628C6.67231 2.50154 6.712 2.50003 6.90421 2.50003H9.0972C9.28941 2.50003 9.32911 2.50154 9.35879 2.50628C9.51508 2.53124 9.65032 2.62872 9.72342 2.76909C9.73731 2.79575 9.7513 2.83294 9.81208 3.01528L9.87858 3.21477L9.90438 3.29033C9.93056 3.3627 9.96099 3.43268 9.99533 3.50003H6.00608Z"
      fill={color}
    />
    <path
      d="M3.94404 5.63343C3.92568 5.3579 3.68742 5.14943 3.41189 5.1678C3.13636 5.18617 2.92789 5.42442 2.94626 5.69995L3.25522 10.3344C3.31222 11.1896 3.35826 11.8804 3.46624 12.4224C3.57851 12.986 3.76946 13.4567 4.16385 13.8256C4.55825 14.1946 5.04061 14.3538 5.61038 14.4284C6.1584 14.5 6.85068 14.5 7.70772 14.5H8.29365C9.15069 14.5 9.84301 14.5 10.391 14.4284C10.9608 14.3538 11.4432 14.1946 11.8376 13.8256C12.232 13.4567 12.4229 12.986 12.5352 12.4224C12.6432 11.8804 12.6892 11.1896 12.7462 10.3345L13.0552 5.69995C13.0735 5.42442 12.8651 5.18617 12.5895 5.1678C12.314 5.14943 12.0757 5.3579 12.0574 5.63343L11.7507 10.2328C11.6908 11.1314 11.6482 11.7566 11.5544 12.227C11.4635 12.6833 11.3367 12.9249 11.1544 13.0954C10.9721 13.2659 10.7227 13.3765 10.2613 13.4368C9.78573 13.499 9.15906 13.5 8.25849 13.5H7.74292C6.84236 13.5 6.21569 13.499 5.74009 13.4368C5.27875 13.3765 5.02931 13.2659 4.84704 13.0954C4.66476 12.9249 4.53787 12.6833 4.44697 12.227C4.35326 11.7566 4.31058 11.1314 4.25067 10.2328L3.94404 5.63343Z"
      fill={color}
    />
    <path
      d="M6.28429 6.83584C6.55907 6.80836 6.80409 7.00883 6.83156 7.28361L7.1649 10.6169C7.19238 10.8917 6.9919 11.1367 6.71713 11.1642C6.44236 11.1917 6.19734 10.9912 6.16986 10.7164L5.83653 7.38311C5.80905 7.10834 6.00952 6.86332 6.28429 6.83584Z"
      fill={color}
    />
    <path
      d="M9.71713 6.83584C9.9919 6.86332 10.1924 7.10834 10.1649 7.38311L9.83156 10.7164C9.80409 10.9912 9.55907 11.1917 9.28429 11.1642C9.00952 11.1367 8.80905 10.8917 8.83653 10.6169L9.16986 7.28361C9.19734 7.00883 9.44236 6.80836 9.71713 6.83584Z"
      fill={color}
    />
  </svg>
);

export default Delete;
