import { Accordion, Box } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";

import TransactionsDetails from "../TransactionDetails";
import TransactionItemHeader from "../TransactionItemHeader";
import TransactionListHeader from "../TransactionListHeader";
import styles from "./styles.module.css";

const TransactionsList = ({ defaultSelected = "", items, loading }) => {
  const [expanded, setExpanded] = useState(defaultSelected);

  const [dimensions, setDimensions] = useState({
    id: 0,
    date: 0,
    status: 0,
    type: 0,
    paidWith: 0,
    sum: 0,
  });

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box
      className={clsx(styles.itemsContainer, loading && styles.itemsLoading)}
    >
      <TransactionListHeader
        dimensions={dimensions}
        setDimensions={setDimensions}
      />

      {items.map((item) => (
        <Accordion
          key={item._id}
          className={styles.accordion}
          expanded={expanded === item._id}
          onChange={handleChange(item._id)}
        >
          <TransactionItemHeader
            item={item}
            opened={expanded === item._id}
            open={handleChange(item._id)}
            dimensions={dimensions}
            setDimensions={setDimensions}
          />
          <TransactionsDetails item={item} />
        </Accordion>
      ))}
    </Box>
  );
};

export default TransactionsList;
