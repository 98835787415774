import axios from "axios";

const API = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL || "http://localhost:3030/api/",
});

export const getAllBlogPostsByCategory = (id, page, limit, skip) => {
  return API.get(
    `blog/articles/category/` +
      id +
      "?page=" +
      page +
      "&limit=" +
      limit +
      "&skip=" +
      skip
  );
};

export const getLatest5PostsByCategory = (id) => {
  return API.get(`blog/articles/category/` + id + "?page=1&limit=5");
};
