import { Box, Button } from "@mui/material";
import clsx from "clsx";
import { useForm } from "react-hook-form";

import { useTranslate } from "../../../../../../../context/translate/translate.context";
import FormInput from "../../../../../../FormInput";
import styles from "./styles.module.css";

const TopUpBalanceStep1 = ({ next, close }) => {
  const { translate } = useTranslate();

  const { control, handleSubmit } = useForm();
  const formValidation = {
    sum: () => ({
      min: { value: 100 },
      max: { value: 1000000 },
      required: { value: true },
    }),
  };

  return (
    <>
      <Box
        className={styles.selectPriceContainer}
        component="form"
        onSubmit={handleSubmit(next)}
      >
        <FormInput
          name="sum"
          label={translate("vendorDashboard.topUp.steps.step1.placeholder")}
          control={control}
          autoComplete="username"
          rules={formValidation.sum()}
        />
        <Button
          className={clsx(styles.button, styles.submitButton)}
          type="submit"
        >
          {translate("vendorDashboard.topUp.steps.step1.submit")}
        </Button>
      </Box>

      <Button
        className={clsx(styles.button, styles.cancelButton)}
        onClick={close}
      >
        {translate("vendorDashboard.topUp.steps.step1.cancel")}
      </Button>
    </>
  );
};

export default TopUpBalanceStep1;
