const ChatOptions = ({ color = "#F6F6F6" }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.875 10.0002C1.875 8.73451 2.90101 7.7085 4.16667 7.7085C5.43232 7.7085 6.45833 8.73451 6.45833 10.0002C6.45833 11.2658 5.43232 12.2918 4.16667 12.2918C2.90101 12.2918 1.875 11.2658 1.875 10.0002ZM4.16667 8.9585C3.59137 8.9585 3.125 9.42487 3.125 10.0002C3.125 10.5755 3.59137 11.0418 4.16667 11.0418C4.74196 11.0418 5.20833 10.5755 5.20833 10.0002C5.20833 9.42487 4.74196 8.9585 4.16667 8.9585Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.70833 10.0002C7.70833 8.73451 8.73435 7.7085 10 7.7085C11.2657 7.7085 12.2917 8.73451 12.2917 10.0002C12.2917 11.2658 11.2657 12.2918 10 12.2918C8.73435 12.2918 7.70833 11.2658 7.70833 10.0002ZM10 8.9585C9.4247 8.9585 8.95833 9.42487 8.95833 10.0002C8.95833 10.5755 9.4247 11.0418 10 11.0418C10.5753 11.0418 11.0417 10.5755 11.0417 10.0002C11.0417 9.42487 10.5753 8.9585 10 8.9585Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8333 7.7085C14.5677 7.7085 13.5417 8.73451 13.5417 10.0002C13.5417 11.2658 14.5677 12.2918 15.8333 12.2918C17.099 12.2918 18.125 11.2658 18.125 10.0002C18.125 8.73451 17.099 7.7085 15.8333 7.7085ZM14.7917 10.0002C14.7917 9.42487 15.258 8.9585 15.8333 8.9585C16.4086 8.9585 16.875 9.42487 16.875 10.0002C16.875 10.5755 16.4086 11.0418 15.8333 11.0418C15.258 11.0418 14.7917 10.5755 14.7917 10.0002Z"
      fill={color}
    />
  </svg>
);

export default ChatOptions;
