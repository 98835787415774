import { createSlice } from '@reduxjs/toolkit';
const userStore = createSlice({
    name: 'userStore',
    initialState: {},
    reducers: {
        setUser: (state, action) => {
            return {...state, ...action.payload};
        },
        setTheme: (state, action) => {
            return { ...state, theme: action.payload };
        },
        setCountry: (state, action) => {
            return { ...state, country: action.payload.country, ip: action.payload.ip };
        },
        resetUser: (state, action) => {
            // save ip and countru state
            const { country, ip } = state;
            // reset state to empty object
            state = { country: country, ip: ip };
            return state;
            
        },
        setUserUpdate: (state, action) => {
            // kepp only country and ip in state, the rest update when localStorage user updates
            return { country: state.country, ip: state.ip, ...action.payload };
        }
    },
});

export const { setUser, setTheme, setCountry, resetUser, setUserUpdate} = userStore.actions;
export const userReducer = userStore.reducer;
