import React, { useState } from "react";
import { Card, Box, Typography, TextField, Button } from "@mui/material";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import { useTheme } from "@mui/material";

// Translation
import { useTranslate } from "../../context/translate/translate.context";
// imoirt yup
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";

// Components
import AlertComponent from "../AlertComponent";
import { sendContactEmail } from "../../api/contact/sendContactEmail";
import Contacts from '../user/Home/Contacts'

// import react-hook-form

const schema = yup.object().shape({
  name: yup.string().max(40).required(),
  email: yup.string().email().required(),
  message: yup.string().max(300).required(),
});

function contact() {
  const theme = useTheme();

  // Translation
  const { translate } = useTranslate();

  const [alertMessage, setAlertMessage] = useState(null);

  const resetAlertMessage = () => {
    setAlertMessage(null);
  };

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleSend = async (data) => {
      try {
          const body = {
        name: data.name,
        email: data.email,
        message: data.message,
      };
      await sendContactEmail(body).then(() => {
        setAlertMessage("Message was sent successfully");
      });
    } catch (error) {
      //console.log(error);
      setAlertMessage("Something went wrong, please try again later");
    }
  };
  
  return (
    <>
      <AlertComponent
        message={alertMessage}
        resetMessageInParent={resetAlertMessage}
      />
      {/*
      <Contacts /> {/*компонент КОНТАКТЫ user/home/contacts.jsx*/}
      
      <Card
        variant="customBackgroundCard"
        sx={{
          boxSizing: "border-box",
          backgroundColor: 'transparent',
          width: "100%",
          padding: "0 20px 40px 20px",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "space-between",
          "@media (max-width: 800px)": {
            flexDirection: "column-reverse",
            alignItems: "center",
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            "@media (max-width: 800px)": {
              maxWidth: "450px",
              minWidth: "100%",
            },
          }}
        >
          <Box
            sx={{
              marginBottom: "1.2rem",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              "@media (max-width: 800px)": {
                justifyContent: "center",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: "1.3rem",
                fontWeight: "bold",
              }}
            >
              {translate("contactPage.contactForm.title")}
            </Typography>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                rowGap: "1rem",
              }}
              component="form"
              onSubmit={handleSubmit(handleSend)}
            >
              <TextField
                required
                label={translate("contactPage.contactForm.namePlaceholder")}
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name?.message}
                {...register("name")}
                sx={{ width: "100%" }}
              />
              <TextField
                required
                label={translate("contactPage.contactForm.emailPlaceholder")}
                variant="outlined"
                error={!!errors.email}
                helperText={errors.email?.message}
                {...register("email")}
                sx={{ width: "100%" }}
              />
              <TextField
                required
                label={translate("contactPage.contactForm.messagePlaceholder")}
                variant="outlined"
                sx={{ width: "100%" }}
                multiline
                error={!!errors.message}
                helperText={errors.message?.message}
                id="message"
                name="message"
                {...register("message")}
                rows={3}
              />
              <Button
                variant={`contained`}
                type="submit"
                sx={{ marginTop: "1rem", fontWeight: "bold" }}
              >
                {translate("contactPage.contactForm.sendButtonLabel")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Card>
    </>
  );
}

export default contact;
