export const useVK = () => {
  const client_id = "51817836";

  const auth = () => {
    const apiMethodEndpoint = new URL("https://oauth.vk.com/authorize");
    const apiMethodParams = new URLSearchParams({
      client_id,
      display: "popup",
      redirect_uri: 'https://mentup-store.com/register',//window.location.href.split("?")[0],
      response_type: "token",
      scope: 'offline',
    });

    window.location.replace(
      `${apiMethodEndpoint.href}?${apiMethodParams.toString()}`
    );
  };

  return { auth };
};

export const useVKBuyNow = (productLink) => {
  const client_id = "51817836";

  const redirectLink = 'https://mentup-store.com'+productLink

  const auth = () => {
    const apiMethodEndpoint = new URL("https://oauth.vk.com/authorize");
    const apiMethodParams = new URLSearchParams({
      client_id,
      display: "popup",
      redirect_uri: redirectLink.toString(),//window.location.href.split("?")[0],
      response_type: "token",
      scope: 'offline',
    });

    window.location.replace(
      `${apiMethodEndpoint.href}?${apiMethodParams.toString()}`
    );
  };

  return { auth };
};

