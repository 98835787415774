import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

import {
  Box,
  Card,
  CircularProgress,
  Container,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { getAllCategoriesHome } from "../../../../api/categories/getAllCategories";
import { getAllProductType } from "../../../../api/product/ProductTypes";
import { useTranslate } from "../../../../context/translate/translate.context";
import useThrottledResize from "../../../../hooks/useThrottledResize";
import Arrow from "../../../svg/Arrow";
import styles from "../../../user/Home/styles.module.css";
import styless from "./styles.module.css";

const Games = () => {
  const { translate } = useTranslate();

  const navigate = useNavigate();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const swiperRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const [games, setGames] = useState([]);
  const [productTypes, setProductTypes] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState("all");

  const [disableArrows, setDisableArrows] = useState({
    left: true,
    right: true,
  });

  const setArrowDisability = () => {
    if (swiperRef.current) {
      const { isBeginning, isEnd } = swiperRef.current;

      setDisableArrows({
        left: isBeginning,
        right: isEnd,
      });
    }
  };

  const centerContainerOnMobile = () => {
    if (!swiperRef.current) {
      return;
    }

    const { el } = swiperRef.current;

    if (!isMobile) {
      el.firstChild.style.marginLeft = "0";
      return;
    }

    const { left } = el.getBoundingClientRect();

    el.firstChild.style.marginLeft = left > 20 ? "16px" : "0";
  };

  useThrottledResize(() => {
    setArrowDisability();
    centerContainerOnMobile();
  });

  useEffect(() => {
    const getFeatured = async () => {
      try {
        const [{ data: games }, { data }] = await Promise.all([
          getAllCategoriesHome(),
          getAllProductType(),
        ]);

        setGames(games);
        setProductTypes(data);
      } catch (error) {
        navigate('/404')
      } finally {
        setLoading(false);
      }
    };

    getFeatured();
  }, []);

  const handleNextSlides = () => {
    swiperRef.current.slideNext();
  };

  const handlePrevSlides = () => {
    swiperRef.current.slidePrev();
  };

  if (!loading && games.length === 0) {
    return null;
  }

  const buttons = (
    <Box className={styles.gamesContainer__swipeButtons}>
      <IconButton
        className={styles.leftButton}
        disabled={disableArrows.left}
        onClick={handlePrevSlides}
      >
        <Arrow />
      </IconButton>
      <IconButton
        className={styles.rightButton}
        disabled={disableArrows.right}
        onClick={handleNextSlides}
      >
        <Arrow />
      </IconButton>
    </Box>
  );

  return (
    <Box className={styles.gamesContainerWrapper}>
      <Container className={styles.gamesContainer} disableGutters>
        <Typography className={styless.marketplace__heading} variant="h3">
          {translate("aboutUsPage.marketplace.marketplaceHeading")}
        </Typography>
        <Typography className={styless.marketplace__text}>
          {translate("aboutUsPage.marketplace.marketplaceText")}
        </Typography>
        {loading ? (
          <CircularProgress
            size={64}
            style={{
              position: "absolute",
              zIndex: 10,
              top: "50%",
              left: "50%",
              translate: "-50% -50%",
            }}
          />
        ) : (
          <Box>
            <Box className={styles.gamesContainer__buttons}>
              <Box width="100%">
                <Tabs
                  value={selectedCategory}
                  onChange={(_, newValue) => setSelectedCategory(newValue)}
                  variant="scrollable"
                  scrollButtons="auto"
                  indicatorColor="secondary"
                  aria-label="categories tabs"
                >
                  <Tab
                    className={styles.tab}
                    value="all"
                    label={translate("home.allCategories")}
                  />
                  {productTypes.map(({ name, _id }) => (
                    <Tab
                      key={_id}
                      className={styles.tab}
                      value={name}
                      label={translate(name)}
                    />
                  ))}
                </Tabs>
              </Box>
              {!isMobile && buttons}
            </Box>
            <Box
              sx={{
                "@media (max-width: 900px)": {
                  display: "flex",
                },
              }}
            >
              <Swiper
                modules={[Grid, Pagination]}
                spaceBetween={16}
                slidesPerView="auto"
                slidesPerGroupAuto
                pagination={{
                  el: "#games-pagination",
                }}
                grid={{
                  rows: 2,
                  fill: "row",
                }}
                breakpoints={{
                  900: {
                    grid: {
                      rows: 1,
                    },
                  },
                }}
                onSlideChange={setArrowDisability}
                onTransitionEnd={setArrowDisability}
                onSwiper={(swiper) => {
                  swiperRef.current = swiper;

                  setArrowDisability();
                  centerContainerOnMobile();
                }}
              >
                {(selectedCategory === "all"
                  ? games
                  : games.filter(({ productTypes }) =>
                      productTypes.some(({ name }) => name === selectedCategory)
                    )
                ).map(
                  (
                    { name, media, thumb, description, slug, primaryProductType },
                    index
                  ) => (
                    <SwiperSlide key={name + index}>
                      <Box
                        className={styles.cardWrapper}
                        onClick={() =>
                          navigate(`/games/${slug}`)
                        }
                      >
                        <Card className={styles.card}>
                          <Box
                            className={styles.image}
                            component="img"
                            src={thumb ? thumb : media}
                          />
                          <Typography className={styles.title} variant="h4">
                            {name}
                          </Typography>
                          {description && (
                            <Typography className={styles.productDescription}>
                              {description}
                            </Typography>
                          )}
                        </Card>
                      </Box>
                    </SwiperSlide>
                  )
                )}
              </Swiper>
            </Box>
            <Box id={"games-pagination"} className={styles.pagination} />
            {isMobile && buttons}
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default Games;
