import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useState } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";

import { GlobalServiceProvider } from "./context/global-service";
import { SocketProvider } from "./context/socket";
import { TranslateProvider } from "./context/translate/translate.context";
import { ColorModeContext, externalTheme } from "./lib/theme";
import { AppRouter } from "./router";
import store from "./store/store";

const App = () => {
  const [mode, setMode] = useState("dark");

  // Check previous theme mode and setting it to a different one
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: (mode) => {
        setMode((prev) => (mode ? mode : prev === "dark" ? "light" : "dark"));
      },
    }),
    []
  );

  // Create + Wrap theme
  const theme = React.useMemo(() =>
    createTheme(
      {
        palette: { mode, primary: externalTheme.palette.primary },
        typography: externalTheme.typography,
        components: externalTheme.components,
      },
      [mode]
    )
  );

  return (
    <HelmetProvider>
      <Provider store={store}>
        <TranslateProvider>
          <GlobalServiceProvider>
            <SocketProvider>
              <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                  <AppRouter />
                </ThemeProvider>
              </ColorModeContext.Provider>
            </SocketProvider>
          </GlobalServiceProvider>
        </TranslateProvider>
      </Provider>
    </HelmetProvider>
  );
};

export default App;
