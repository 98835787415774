import { Box, Button, Card, Grid, Typography } from "@mui/material";

// Icons
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useNavigate } from "react-router-dom";

const Latest5Blog = ({posts}) => {

    const firstPost = posts[0];

    const next4Posts = posts.slice(1,5);


    const navigate = useNavigate();

    const handleBlogPostClick = (id) => {
        navigate('/blog/'+id)
    }

    return (
        <Box sx={{
            width: '100%',
            margin: '0 auto',
            overflow: 'hidden',
            borderRadius: '10px',   
        }}>
            {/* Header Blog Post */}
            {/* // Add an gradient overlay from left to right with opacity 0.2 */}
            <Box 
                onClick={() => handleBlogPostClick(firstPost.id)}
                sx={{
                    width: '100%',
                    height: '230px',
                    borderRadius: '10px',
                    backgroundImage: `url(${firstPost?.attributes?.mainImage.data[0].attributes.url})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    display: 'flex',
                    transition: 'all 0.6s ease-in-out',
                    '&:hover': {
                        cursor: 'pointer',
                        transform: 'scale(1.003)',
                        borderRadius: '20px',
                    },
                    '@media (max-width: 800px)': {
                        height: '260px',
                    },
                }}
            >
                <Box sx={{
                    width: '100%',
                    height: '100%',
                    background: 'linear-gradient(to right, rgba(0,0,0,0.9), rgba(0,0,0,0.0))',
                    opacity: '1',
                    borderRadius: '10px',
                    transition: 'border-radius 0.6s ease-in-out',
                    '&:hover': {
                        borderRadius: '20px',
                    }
                }}>
                    <Box sx={{
                        width: '50%',
                        paddingY: '1.5rem',
                        paddingX: '3rem',
                        '@media (max-width: 800px)': {
                            padding: '1.5rem',
                            width: '95%',
                        },  
                    }}>
                        <Box sx={{
                            marginBottom: '20px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '10px',
                            opacity: '0.85',
                            color: 'white',

                        }}>
                            <CalendarMonthIcon sx={{
                                fontSize: '1.4rem',
                                marginBottom: '0.15rem',
                            }}/>

                            <Typography sx={{
                                fontSize: '1.2rem',
                                fontWeight: 'bold',
                            }}>
                                {firstPost?.attributes?.publishedAt.split('T')[0]}
                            </Typography>
                        </Box>
                        <Box sx={{
                            flexWrap: 'wrap',
                            display: 'flex',
                            flexDirection: 'column',
                            opacity: '0.95',
                            color: 'white',
                            alignItems: 'left',
                            overflow: 'hidden',
                            '@media (max-width: 800px)': {
                                width: '100%',

                            },

                        }}>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontSize: '1.7rem',
                                    fontWeight: 'bold',
                                    width: '100%',
                                    // truncate text if too long and add ellipsis
                                    paddingRight: '10px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                {firstPost?.attributes?.title}
                            </Typography>
                        </Box>
                        <Box sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'start',
                            alignItems: 'left',
                            gap: '1rem',
                            marginTop: '1.5rem',
                            flexWrap: 'wrap',
                            '@media (max-width: 800px)': {
                                width: '100%',
                            },
                        }}>
                            
                            {firstPost?.attributes?.Tags.Single.slice(0,8).map((tag, index) => (
                                <Button
                                    key={tag.id}
                                    variant="outlined" 
                                    sx={{
                                        padding: '0.2rem',
                                        paddingX: '0.8rem',
                                        borderRadius: '10px',
                                        color: 'white',
                                        fontWeight: 'bold',
                                        fontSize: '0.78rem',
                                    }}
                                >
                                    {tag.TagName}
                                </Button>
                            ))}
                            
                            </Box>

                    </Box>
                </Box>
            </Box>

            {/* Other 4 Header Blog Posts */}
            <Grid
                container
                spacing={2}
                sx={{
                    marginTop: '1.5rem',
                    marginBottom: '1.5rem',
                }}
            >
                {next4Posts?.map((post, index) => (
                    <Grid item key={index} xs={12} md={3}>
                    <Card
                        sx={{
                        height: '100%',
                        borderRadius: '10px',
                        transition: 'all 0.6s ease-in-out',
                        '&:hover': {
                            cursor: 'pointer',
                            transform: 'scale(1.003)',
                        },
                        }}
                    >
                        <Box
                            onClick={() => handleBlogPostClick(post.id)}
                            sx={{
                                height: '180px',
                                backgroundImage: `url(${post.attributes.mainImage.data[0].attributes.url})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                display: 'flex',
                            }}
                        />
                        <Box
                            sx={{
                                paddingY: '1.5rem',
                                paddingX: '20px',
                            }}
                        >
                        <Box
                            sx={{
                            marginBottom: '5px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '10px',
                            opacity: '0.85',
                            }}
                        >
                            <CalendarMonthIcon
                            sx={{
                                fontSize: '1.1rem',
                                marginBottom: '0.15rem',
                            }}
                            />
                            <Typography
                            sx={{
                                fontSize: '1rem',
                                fontWeight: 'bold',
                            }}
                            >
                            {post.attributes.publishedAt.split('T')[0]}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            opacity: '0.95',
                            alignItems: 'left',
                            paddingRight: '20px',
                            }}
                        >
                            <Typography
                            variant="h6"
                            sx={{
                                fontSize: '1.1rem',
                                fontWeight: 'bold',
                                paddingRight: '20px',
                            }}
                            >
                            {post.attributes.title}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'start',
                                alignItems: 'left',
                                flexWrap: 'wrap',
                                gap: '1rem',
                                marginTop: '0.8rem',
                            }}
                        >
                            {post.attributes.Tags.Single.map((tag, index) => (
                            <Button
                                key={tag.id}
                                variant="outlined"
                                sx={{
                                padding: '0.2rem',
                                paddingX: '0.8rem',
                                borderRadius: '10px',
                                fontWeight: 'bold',
                                fontSize: '0.7rem',
                                }}
                            >
                                {tag.TagName}
                            </Button>
                            ))}
                        </Box>
                        </Box>
                    </Card>
                    </Grid>
                ))}
            </Grid>  
        </Box>
    )
}

export default Latest5Blog