import { useState } from 'react';
// MUI
import {
    Card,
    Box,
    Typography,
    FormControlLabel,
    Checkbox,
    Button,
    Divider,
} from '@mui/material';

import { useTheme } from '@mui/material';
// Router
import { useNavigate } from 'react-router-dom';

// API
import { changeRole } from '../../api/user/changeRole';

// REDUX
import { setUser } from '../../store/userStore';
import { setUserSettings } from '../../store/userSettingsStore';
import { useDispatch } from 'react-redux';

// Translation
import { useTranslate } from '../../context/translate/translate.context';

function BecomeSeller() {
    const theme = useTheme();
    const navigate = useNavigate();

    // Translation
    const { translate } = useTranslate();

    const [termsChecked, setTermsChecked] = useState(false);

    const handleCheckChange = (e) => {
        setTermsChecked(e.target.checked);
    };

    const dispatch = useDispatch();
    const changeUserRole = async () => {
        try {
            await changeRole()
                .then((res) => {
                    const user = res.data.user;
                    const userSettings = res.data.userSettings;
                    if(user && userSettings) {
                        localStorage.setItem('user', JSON.stringify(user));
                        localStorage.setItem('userSettings', JSON.stringify(userSettings));
                        dispatch(setUser(user));
                        dispatch(setUserSettings(userSettings));
                        window.location.reload();
                    }
                });
        }
        catch (err) {
            navigate('/404')
        }
    };

    const becomeSellerSubmission = (e) => {
        e.preventDefault();
        if (termsChecked) {
            changeUserRole();
        }
    };

   // console.log('BECOME SELLER CHECKED: ' + termsChecked);

    return (
        <Box>
        <Box>
<Box
    sx={{
        boxSizing: 'border-box',
        width: '100%',
        padding: '1rem',
        textAlign: 'center',
    }}
>

    {/* Seller's Rules */}
    <Box sx={{ width: '100%', marginTop: '0rem' }}>
        <Typography
            sx={{
                fontWeight: 'bold',
                fontSize: '1.3rem',
                marginBottom: '1.5rem',
                color: theme.palette.primary.main,
            }}
        >{translate("becomeSeller.sellerRules.title")}</Typography>

        <Typography sx={{}}>
            {translate("becomeSeller.sellerRules.rule1")}
        </Typography>

        <Typography sx={{}}>
            {translate("becomeSeller.sellerRules.rule2")}
        </Typography>

        <Typography sx={{}}>
            {translate("becomeSeller.sellerRules.rule3")}
        </Typography>

        <Typography sx={{}}>
            {translate("becomeSeller.sellerRules.rule4")}
        </Typography>

        <Typography sx={{}}>
            {translate("becomeSeller.sellerRules.rule5")}
        </Typography>

        <Typography sx={{}}>
            {translate("becomeSeller.sellerRules.rule6")}
        </Typography>

        <Divider sx={{ marginTop: '1rem', marginBottom: '1rem' }} />
        {/* Prohibited Actions */}
        <Typography
            sx={{
                fontWeight: 'bold',
                fontSize: '1.3rem',
                marginBottom: '1.5rem',
                color: theme.palette.primary.main,
            }}
        >{translate("becomeSeller.restrictions.title")}</Typography>
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            rowGap: '0.5rem',
        }}>

        
        <Typography sx={{}}>
            {translate("becomeSeller.restrictions.restriction1")}
        </Typography>

        <Typography sx={{}}>
            {translate("becomeSeller.restrictions.restriction2")}
        </Typography>

        <Typography sx={{}}>
            {translate("becomeSeller.restrictions.restriction3")}
        </Typography>

        <Typography sx={{}}>
            {translate("becomeSeller.restrictions.restriction4")}
        </Typography>

        <Typography sx={{}}>
            {translate("becomeSeller.restrictions.restriction5")}
        </Typography>
        </Box>
    </Box>
</Box>
                <form onSubmit={(e) => becomeSellerSubmission(e)}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            rowGap: '1rem',
                        }}
                    >
                        <FormControlLabel
                            required
                            control={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Checkbox
                                        onChange={(e) => handleCheckChange(e)}
                                        inputProps={{
                                            'aria-label':
                                            translate("becomeSeller.form.checkboxAriaLabel"),
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography>
                                            {translate("becomeSeller.form.iAccept")}
                                        </Typography>
                                        <Button
                                            variant={`text`}
                                            onClick={() =>
                                                navigate('/termsOfSale')
                                            }
                                            sx={{
                                                textTransform: 'none',
                                                padding: '0',
                                                paddingX: '0.3rem',
                                                minWidth: 'fit-content',
                                                fontSize: '1rem',
                                            }}
                                        >{translate("becomeSeller.form.termsOfSale")}</Button>
                                    </Box>
                                </Box>
                            }
                        />
                        <Button
                            disabled={!termsChecked}
                            variant='contained'
                            sx={{
                                fontWeight: 'bold',
                                width: 'fit-content',
                                marginTop: '1rem',
                                '@media (max-width: 580px)': {
                                    width: '100%',
                                },
                            }}
                            type='submit'
                        >
                            {translate("becomeSeller.form.becomeSellerButtonLabel")}
                        </Button>
                    </Box>
                </form>
            </Box>
        </Box>
    );
}

export default BecomeSeller;
