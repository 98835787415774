import styles from "./styles.module.css";

const Wallet = () => (
  <svg
    className={styles.icon}
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
  >
    <path d="M20.9938 11.7502C20.9735 11.75 20.9469 11.75 20.9036 11.75H18.3011C16.8777 11.75 15.8203 12.8087 15.8203 14C15.8203 15.1913 16.8777 16.25 18.3011 16.25H20.9036C20.9469 16.25 20.9735 16.25 20.9938 16.2498C21.013 16.2496 21.0183 16.2492 21.0183 16.2492C21.2244 16.2367 21.313 16.0976 21.3198 16.0139C21.3198 16.0139 21.32 16.0077 21.3201 15.9986C21.3203 15.9808 21.3203 15.9572 21.3203 15.9167V12.0833C21.3203 12.0609 21.3203 12.0437 21.3203 12.0297C21.3202 12.0185 21.3202 12.0093 21.3201 12.0014C21.32 11.9924 21.3198 11.9861 21.3198 11.9861C21.313 11.9024 21.2244 11.7633 21.0183 11.7508C21.0183 11.7508 21.0133 11.7504 20.9938 11.7502ZM20.9202 10.25C20.9866 10.25 21.0506 10.2499 21.1094 10.2535C21.9807 10.3066 22.7514 10.9638 22.8161 11.8818C22.8204 11.942 22.8204 12.0069 22.8203 12.067C22.8203 12.0725 22.8203 12.0779 22.8203 12.0833V15.9167C22.8203 15.9221 22.8203 15.9275 22.8203 15.933C22.8204 15.9931 22.8204 16.058 22.8161 16.1182C22.7514 17.0362 21.9807 17.6934 21.1094 17.7465C21.0506 17.7501 20.9866 17.75 20.9202 17.75C20.9147 17.75 20.9092 17.75 20.9036 17.75H18.3011C16.1559 17.75 14.3203 16.1224 14.3203 14C14.3203 11.8776 16.1559 10.25 18.3011 10.25H20.9036C20.9092 10.25 20.9147 10.25 20.9202 10.25Z" />
    <path d="M19.0703 14C19.0703 14.5523 18.6226 15 18.0703 15C17.518 15 17.0703 14.5523 17.0703 14C17.0703 13.4477 17.518 13 18.0703 13C18.6226 13 19.0703 13.4477 19.0703 14Z" />
    <path d="M20.9202 10.25C20.9866 10.25 21.0506 10.2499 21.1094 10.2535C21.3348 10.2672 21.5535 10.3214 21.755 10.4101C21.648 8.80363 21.3535 7.56563 20.4291 6.64124C19.6807 5.89288 18.7318 5.56076 17.5593 5.40313L17.517 5.39754C17.5065 5.38977 17.4958 5.38223 17.4848 5.37492L13.7493 2.89806C12.4461 2.03398 10.6945 2.03398 9.39133 2.89806L5.65585 5.37492C5.64484 5.38223 5.63408 5.38977 5.62359 5.39754L5.58129 5.40313C4.40887 5.56076 3.45992 5.89288 2.71156 6.64124C1.96319 7.38961 1.63108 8.33856 1.47345 9.51098C1.32028 10.6502 1.3203 12.1058 1.32031 13.9436V14.0564C1.3203 15.8942 1.32028 17.3498 1.47345 18.489C1.63108 19.6614 1.96319 20.6104 2.71156 21.3588C3.45992 22.1071 4.40887 22.4392 5.58129 22.5969C6.72051 22.75 8.17614 22.75 10.0139 22.75H13.1267C14.9645 22.75 16.4201 22.75 17.5593 22.5969C18.7318 22.4392 19.6807 22.1071 20.4291 21.3588C21.3535 20.4344 21.648 19.1964 21.755 17.5899C21.5535 17.6786 21.3348 17.7328 21.1094 17.7465C21.0506 17.7501 20.9866 17.75 20.9202 17.75L20.9036 17.75H20.2382C20.1244 19.0915 19.8669 19.7996 19.3684 20.2981C18.9452 20.7213 18.3656 20.975 17.3595 21.1102C16.3318 21.2484 14.9771 21.25 13.0703 21.25H10.0703C8.16349 21.25 6.80883 21.2484 5.78116 21.1102C4.77507 20.975 4.19543 20.7213 3.77222 20.2981C3.34901 19.8749 3.09534 19.2952 2.96007 18.2892C2.82191 17.2615 2.82031 15.9068 2.82031 14C2.82031 12.0932 2.82191 10.7385 2.96007 9.71085C3.09534 8.70476 3.34901 8.12511 3.77222 7.7019C4.19543 7.27869 4.77507 7.02502 5.78116 6.88976C6.80883 6.75159 8.16349 6.75 10.0703 6.75H13.0703C14.9771 6.75 16.3318 6.75159 17.3595 6.88976C18.3656 7.02502 18.9452 7.27869 19.3684 7.7019C19.8669 8.20043 20.1244 8.90854 20.2382 10.25H20.9036L20.9202 10.25ZM10.0139 5.25H13.1267C13.6432 5.24999 14.1295 5.24999 14.5871 5.25339L12.9204 4.14821C12.1196 3.61726 11.021 3.61726 10.2203 4.14821L8.55349 5.25339C9.01108 5.24999 9.4974 5.24999 10.0139 5.25Z" />
    <path d="M6.07031 9.25C5.6561 9.25 5.32031 9.58579 5.32031 10C5.32031 10.4142 5.6561 10.75 6.07031 10.75H10.0703C10.4845 10.75 10.8203 10.4142 10.8203 10C10.8203 9.58579 10.4845 9.25 10.0703 9.25H6.07031Z" />
    <path d="M19.0703 14C19.0703 14.5523 18.6226 15 18.0703 15C17.518 15 17.0703 14.5523 17.0703 14C17.0703 13.4477 17.518 13 18.0703 13C18.6226 13 19.0703 13.4477 19.0703 14Z" />
  </svg>
);

export default Wallet;
