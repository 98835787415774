import { useTheme } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import * as yup from "yup";

import { getAllCategories } from "../../../../../../api/categories/getAllCategories";
import { getAllSoftware } from "../../../../../../api/software/getAllSoftware";
import { useTranslate } from "../../../../../../context/translate/translate.context";
import styles from "./styles.module.css";
import { getAllTypeAttrs } from "../../../../../../api/typeAttrs/getAllTypeAttrs";

const AddProductModalStep1 = ({ defaultProduct, submit }) => {
  const theme = useTheme();
  const { translate } = useTranslate();

  const [softwares, setSoftwares] = useState([]);
  const [categories, setCategories] = useState([]);
  const [typeAttrs, setTypeAttrs] = useState([]);
  const [product, setProduct] = useState(defaultProduct);

  const displayCategories = useMemo(
    () =>
      categories.filter((category) =>
        product.software.some((soft) => category.software.includes(soft._id))
      ),
    [categories, product.software]
  );
  const displayTypes = useMemo(
    () => product.category?.productTypes || [],
    [product.category?.productTypes]
  );

  const displayTypeAttrs = useMemo(
    () =>
      typeAttrs.filter((typeAttr) =>
        typeAttr.productType === product?.type?._id && typeAttr.category === product?.category?._id && typeAttr.attributeGroup?.groupType !== "range"
      ),
    [typeAttrs, product?.type]
  );

  const groupedAttributes = useMemo(() => {
    // Filter and group attributes by groupType
    const groupedByType = typeAttrs.reduce((acc, typeAttr) => {
      const groupInfo = typeAttr.attributeGroup;
      // Check if the typeAttr matches the product's type and category, and exclude 'range' groupType
      if (
        typeAttr.productType === product?.type?._id &&
        typeAttr.category === product?.category?._id &&
        groupInfo?.groupType === 'single'
      ) {
        // If the groupType doesn't exist in the accumulator, create a new entry
        if (!acc[groupInfo?._id]) {
          acc[groupInfo?._id] = {
            ...groupInfo, // Copy all group information
            attributes: [],
          };
        }
  
        // Add the current attribute to the corresponding group's attributes array
        acc[groupInfo?._id].attributes.push(typeAttr);
      }
  
      return acc;
    }, {});
  
    // Convert the grouped object into an array of groups
    return Object.values(groupedByType);
  }, [typeAttrs, product?.type, product?.category]);
  

  const displayTypeAttrsRange = useMemo(
    () =>
      typeAttrs.filter((typeAttr) =>
        typeAttr.productType === product?.type?._id && typeAttr.category === product?.category?._id && typeAttr.attributeGroup?.groupType === "range"
      ),
    [typeAttrs, product?.type]
  );

  const inputStyle = {
    WebkitBoxShadow: `0 0 0 1000px ${theme.palette.primary.backgroundPaperDark} inset`,
  };
  const inputStyleDay = {
    WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.paper} inset`,
  };
  const isDarkTheme = theme.palette.mode === "dark";
  const selectedInputStyle = isDarkTheme ? inputStyle : inputStyleDay;

  // Define your validation schema here
  const formSchema = yup.object().shape({
    name: yup.string().min(5).max(150).required(),
    description: yup.string().max(700).required(),
    software: yup.array().required(),
    category: yup.object().required(),
    server: yup.object().required(),
    type: yup.object().required(
      translate("addProductModal.validationErrors.schema1.productTypeRequired")
    ),
    typeAttribute: yup.array(),
    attributes: yup.object(),
  });

  const getSoftwares = async () => {
    const response = await getAllSoftware();
    setSoftwares(response.data);
  };

  const getCategories = async () => {
    const response = await getAllCategories();
    setCategories(response.data);
  };

  const getTypeAttrs = async () => {
    const response = await getAllTypeAttrs();
    setTypeAttrs(response.data);
  };

  useEffect(() => {
    setProduct((prev) => ({ ...prev, ...defaultProduct }));
  }, [defaultProduct?._id]);

  useEffect(() => {
    getSoftwares();
    getCategories();
    getTypeAttrs();
  }, []);

  const {
    control,
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({
    values: product,
    resolver: yupResolver(formSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "typeAttribute",
  });

  return (
    <form onSubmit={handleSubmit(submit)} className={styles.form}>
      <Box className={styles.formContainer}>
        <TextField
          required
          label={translate("addProductModal.new.step1.inputs.name.label")}
          placeholder={translate(
            "addProductModal.new.step1.inputs.name.placeholder"
          )}
          variant="outlined"
          InputProps={{ style: selectedInputStyle }}
          autoComplete="off"
          error={Boolean(errors.name)}
          className={styles.input}
          helperText={errors.name?.message}
          {...register("name")}
          value={product.name}
          onChange={(e) =>
            setProduct((prev) => ({ ...prev, name: e.target.value }))
          }
        />
        <TextField
          required
          label={translate(
            "addProductModal.new.step1.inputs.description.label"
          )}
          placeholder={translate(
            "addProductModal.new.step1.inputs.description.placeholder"
          )}
          variant="outlined"
          InputProps={{ style: selectedInputStyle }}
          autoComplete="off"
          error={Boolean(errors.description)}
          className={styles.input}
          helperText={errors.description?.message}
          {...register("description")}
          value={product.description}
          onChange={(e) =>
            setProduct((prev) => ({ ...prev, description: e.target.value }))
          }
          multiline
          rows={3}
        />

        <Controller
          defaultValue={product.software}
          render={({ field: { onChange } }) => (
            <Autocomplete
              isOptionEqualToValue={(option, value) =>
                option?._id === value?._id
              }
              multiple
              disablePortal={false}
              options={softwares}
              getOptionLabel={(option) => option?.name || ""}
              sx={{
                width: "100%",
                "@media (max-width: 500px)": { width: "100%" },
              }}
              value={product.software}
              onChange={(_, software) => {
                setProduct((prev) => ({
                  ...prev,
                  software,
                  category: null,
                  server: null,
                  type: null,
                  typeAttribute: [],
                }));
                onChange(software);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={translate(
                    "addProductModal.new.step1.inputs.software.label"
                  )}
                  error={errors.software && true}
                  helperText={errors.software?.message}
                  sx={selectedInputStyle}
                  inputProps={{
                    ...params.inputProps,
                    style: selectedInputStyle,
                    required: !!softwares.length && !product.software?.length,
                  }}
                  required={true}
                />
              )}
            />
          )}
          name="software"
          control={control}
          rules={{ required: true }}
        />

        <Box className={styles.rowInputs}>
          <Controller
            defaultValue={product.category}
            render={({ field: { onChange } }) => (
              <Autocomplete
                disablePortal={false}
                options={displayCategories}
                getOptionLabel={(option) => option?.name || ""}
                sx={{
                  width: "100%",
                  "@media (max-width: 500px)": { width: "100%" },
                }}
                value={product.category}
                onChange={(_, category) => {
                  setProduct((prev) => ({
                    ...prev,
                    category,
                    server: null,
                    type: null,
                    typeAttribute: [],
                  }));
                  onChange(category);
                }}
                isOptionEqualToValue={(option, value) =>
                  option?._id === value?._id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={translate(
                      "addProductModal.new.step1.inputs.category.label"
                    )}
                    error={errors.category && true}
                    helperText={errors.category?.message}
                    sx={selectedInputStyle}
                    inputProps={{
                      ...params.inputProps,
                      style: selectedInputStyle,
                      required: true,
                    }}
                    required={true}
                  />
                )}
              />
            )}
            name="category"
            control={control}
            rules={{ required: true }}
          />
          <Controller
            defaultValue={product.type}
            render={({ field: { onChange } }) => (
              <Autocomplete
                disablePortal={false}
                options={displayTypes}
                getOptionLabel={(option) => option?.name || ""}
                sx={{
                  width: "100%",
                  "@media (max-width: 500px)": { width: "100%" },
                }}
                value={product.type}
                onChange={(_, type) => {
                  setProduct((prev) => ({
                    ...prev,
                    type,
                    typeAttribute: [],
                  }));
                  onChange(type);
                }}
                isOptionEqualToValue={(option, value) =>
                  option?._id === value?._id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={translate(
                      "addProductModal.new.step1.inputs.type.label"
                    )}
                    error={errors.type && true}
                    helperText={errors.type?.message}
                    sx={selectedInputStyle}
                    inputProps={{
                      ...params.inputProps,
                      style: selectedInputStyle,
                      required: true,
                    }}
                    required={true}
                  />
                )}
              />
            )}
            name="type"
            control={control}
            rules={{ required: true }}
          />
        </Box>

        
        <Box className={styles.rowInputs}>
          <Controller
            defaultValue={product.server}
            value={product.server}
            render={({ field: { onChange } }) => (
              <Autocomplete
                isOptionEqualToValue={(option, value) =>
                  option?._id === value?._id
                }
                disablePortal={false}
                options={product.category?.servers || []}
                getOptionLabel={(option) => option?.name || ""}
                sx={{
                  width: "100%",
                  "@media (max-width: 500px)": { width: "100%" },
                }}
                value={product.server}
                onChange={(e, server) => {
                  setProduct((prev) => ({
                    ...prev,
                    server,
                  }));
                  onChange(server);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={translate("Server")}
                    error={errors.server && true}
                    helperText={errors.server?.message}
                    sx={selectedInputStyle}
                    inputProps={{
                      ...params.inputProps,
                      style: selectedInputStyle,
                      required: true,
                    }}
                    required={true}
                  />
                )}
              />
            )}
            name="server"
            control={control}
            rules={{ required: true }}
          />
        </Box>
        {groupedAttributes?.map((group, index) => (
          <Box key={group._id} className={styles.rowInputs}>
            <Controller
              name={`typeAttribute.${index}`} // Use index directly for the array field
              control={control}
              defaultValue={product.typeAttribute?.[index] || ""}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  isOptionEqualToValue={(option, value) => option?._id === value?._id}
                  disablePortal={false}
                  options={group.attributes || []}
                  getOptionLabel={(option) => option?.name || ""}
                  sx={{
                    width: "100%",
                    "@media (max-width: 500px)": { width: "100%" },
                  }}
                  value={value || null}
                  onChange={(e, newValue) => {
                    setProduct((prev) => {
                      const currentTypeAttribute = Array.isArray(prev.typeAttribute) ? [...prev.typeAttribute] : [];
                      const updatedTypeAttribute = [...currentTypeAttribute];
                      if (index < updatedTypeAttribute.length) {
                        updatedTypeAttribute[index] = newValue;
                      } else {
                        updatedTypeAttribute.push(newValue);
                      }
                      return {
                        ...prev,
                        typeAttribute: updatedTypeAttribute,
                      };
                    });
                    onChange(newValue); // Update field value with the selected option
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={group.name}
                      error={Boolean(errors.typeAttribute?.[index])}
                      helperText={errors.typeAttribute?.[index]?.message}
                      sx={selectedInputStyle}
                      inputProps={{
                        ...params.inputProps,
                        style: selectedInputStyle,
                        required: true,
                      }}
                      required={true}
                    />
                  )}
                />
              )}
            />
          </Box>
        ))}

        {displayTypeAttrsRange?.map((typeAttr) => (
          <Box key={typeAttr.slug} className={styles.rowInputs}>
            <TextField
              required
              label={typeAttr.name}
              placeholder={typeAttr.name}
              variant="outlined"
              InputProps={{ style: selectedInputStyle }}
              autoComplete="off"
              error={Boolean(errors.attributes?.[typeAttr.slug])}
              className={styles.input}
              helperText={errors.attributes?.[typeAttr.slug]?.message}
              value={product.attributes?.[typeAttr.slug] || ""}
              onChange={(e) =>
                setProduct((prev) => ({
                  ...prev,
                  attributes: {
                    ...prev.attributes,
                    [typeAttr.slug]: e.target.value,
                  },
                }))
              }
            />
          </Box>
        ))}

      </Box>

      <Button
        type="submit"
        className={clsx(styles.button, styles.cancelButton)}
        endIcon={<ArrowRightIcon />}
      >
        {translate("addProductModal.new.step1.next")}
      </Button>
    </form>
  );
};

export default AddProductModalStep1;
