import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { vkVerify } from "../../../api/auth/register";
import { useTranslate } from "../../../context/translate/translate.context";
import FormInput from "../../FormInput";
import Button from "../../UI/Button";
import { FORM_VALIDATIONS } from "./constants";
import styles from "./styles.module.css";

const StepVk = ({ loading, setLoading, logIn }) => {
  const { translate } = useTranslate();

  const country = useSelector((state) => state.user.country);

  const { control, handleSubmit, setError } = useForm();

  const onSubmit = async (localData) => {
    setLoading(true);

    try {
      const searchParams = new URLSearchParams(
        window.location.hash.replace("#", "?")
      );
      const accessToken = searchParams.get("access_token");
      const userId = searchParams.get("user_id");

      const response = await vkVerify({
        ...localData,
        country,
        accessToken,
        userId,
      });

      logIn(response);
    } catch (error) {
      //console.error(error);
      
    }
  };

  return (
    <Box
      className={styles.form}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormInput
        name="username"
        label={translate("authentication.nickname")}
        control={control}
        autoComplete="username"
        disabled={loading}
        rules={FORM_VALIDATIONS.username(translate)}
      />
      <FormInput
        type="email"
        name="email"
        label={translate("authentication.email")}
        control={control}
        autoComplete="email"
        disabled={loading}
        rules={FORM_VALIDATIONS.email(translate)}
      />
      <Button
        className={styles.form__submit}
        type="submit"
        size="large"
        fullWidth
        loading={loading}
      >
        {translate("authentication.continue")}
      </Button>
      <Box className={styles.form__link} component={Link} to="/login">
        {translate("authentication.alreadyHaveAnAccount")}
      </Box>
    </Box>
  );
};

export default StepVk;
