import React from "react";
import styles from "./styles.module.css";
import { Container, Box, Typography, Chip, Paper } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { useNavigate } from "react-router-dom";

const Post = (postData) => {

  const post = postData.postData;
  const navigate = useNavigate()
  // Преобразование строки с датой в объект Date
  const createdAt = new Date(post.attributes.createdAt);

  // Получение форматированной даты и времени
  const formattedDate = createdAt.toLocaleDateString(undefined, {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });
  const formattedTime = createdAt.toLocaleTimeString(undefined, {
    hour: "numeric",
    minute: "numeric",
  });

  const handleBlogPostClick = () => {
    navigate('/blog/' + post.id);
  }

  return (
    <Paper className={styles.post} onClick={handleBlogPostClick}>
      <img
        className={styles.post__image}
        src={`${post.attributes.mainImage.data[0].attributes.url}`}
        alt=""
      />
      <Box className={styles.postTextBox}>
        <Typography className={styles.postTextBox__timestamp} color="GrayText">
          <CalendarTodayIcon className={styles.iconCalendar} />
          {formattedDate} в {formattedTime}
        </Typography>
        <Typography
          className={styles.postTextBox__heading}
          color="HighlightText"
        >
          {post.attributes.title}
        </Typography>
        <Typography className={styles.postTextBox__subheading} color="GrayText">
          {post.attributes.Description}
        </Typography>
        <Box className={styles.postTags}>
          {post.attributes.Tags.Single.map((tag) => (
            <Typography className={styles.tag} key={tag.id}>
              {tag.TagName}
            </Typography>
          ))}
        </Box>
      </Box>
    </Paper>
  );
};

export default Post;
