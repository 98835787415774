import axios from "axios";
import Cookies from "js-cookie";

const API = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL || "http://localhost:3030/api/",
});

const headers = () => {
  const token = Cookies.get("refreshToken");
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
};

/**
 * @param {string} code
 * @returns
 */
export const getCartByCode = (code) => {
  return API.get(`/cart/${code}`, headers());
};
