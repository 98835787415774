import { Box, CircularProgress, Container, Typography } from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import debounce from 'lodash.debounce';

import {
  getCategoriesBySoft,
} from "../../../api/categories/getAllCategories";
import { useTranslate } from "../../../context/translate/translate.context";
import BreadcrumbsNavigation from "../../BreadcrumbsNavigation";
import Button from "../../UI/Button";
import styles from "./styles.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTheme } from "@emotion/react";
import ComputerGames from "../../svg/ComputerGames";
import clsx from 'clsx';
import Applications from "../../svg/Applications";
import MobileGames from "../../svg/MobileGames";

const Catalog = () => {
  const { translate } = useTranslate();
  const navigate = useNavigate();
  const location = useLocation(); // Initialize useLocation

  const [total, setTotal] = useState(0);
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedSoft, setSelectedSoft] = useState('igri');

  const theme = useTheme();

  const handleNavigateBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    // Extract the "s" parameter from the URL
    const searchParams = new URLSearchParams(location.search);
    const softParam = searchParams.get("s");

    // If the "s" parameter exists, update the selectedSoft state
    if (softParam) {
      setSelectedSoft(softParam);
    }
  }, [location.search]);

  useEffect(() => {
    const getGames = async () => {
      try {
        setGames([]);
        setTotal(0);
        setLoading(true);
        const { data } = await getCategoriesBySoft({
          skip: 0,
          slug: selectedSoft,
          limit: 84,
        });

        setGames(data?.categories);
        setTotal(data?.total);
      } catch (error) {
        //console.error(error);
      } finally {
        setLoading(false);
      }
    };
    if (selectedSoft) getGames();
  }, [selectedSoft]);

  useEffect(() => {
    // Update the URL with the selectedSoft parameter using URLSearchParams
    if (selectedSoft) {
      setTimeout(() => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("s", selectedSoft);
        const newUrl = `?${searchParams.toString()}`;
        window.history.replaceState({}, '', newUrl);
      }, 500);
    }
  }, [selectedSoft]);

  // Define the debounced function using lodash.debounce
  const debouncedSetSelectedSoft = useCallback(
    debounce((data) => {
      setSelectedSoft(data);
    }, 300), // Adjust debounce delay as needed
    [] // Empty dependency array ensures the debounce function is created only once
  );

  const handleSoftClick = (data) => {
    debouncedSetSelectedSoft(data);
  };

  const handleButtonClick = async () => {
    try {
      setLoading(true);

      const { data } = await getCategoriesBySoft({
        skip: games.length,
        slug: selectedSoft,
        limit: 84,
      });

      setGames([...games, ...data.categories]);
    } catch (error) {
      //console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Box sx={{
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        marginTop: '20px',
        marginLeft: '2%'
      }}>
        <ArrowBackIcon sx={{
          cursor: "pointer",
          border: '1px solid black',
          borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
          borderRadius: '50%',
          padding: '6px',
          transition: '1s all easy-in-out',
        }} onClick={handleNavigateBack} />
        <BreadcrumbsNavigation
          breadcrumbs={[
            { label: "navigation.home", path: "/" },
            { label: "navigation.catalog" },
          ]}
          from={'catalog'}
        />
      </Box>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: '30px'
      }}>
        <Box sx={{
          display: 'flex',
          gap: '50px',
          "@media (max-width: 800px)": {
            flexDirection: 'column',
            alignItems: 'left',
            gap: '20px',
          },
        }}>
          <Typography sx={{
            fontSize: '26px'
          }}>
            Каталог игр
          </Typography>

          <Box sx={{
            display: 'flex',
            alignitems: 'center',
            gap: '1rem',
            "@media (max-width: 800px)": {
              flexWrap: 'wrap'
            },
          }}>
            <Box
              className={clsx(
                styles.buttonCategory,
                selectedSoft === 'igri' && styles.active
              )}
              onClick={() => handleSoftClick('igri')}
            >
              <ComputerGames />
              <Typography sx={{
                fontWeight: '500',
                textTransform: 'uppercase'
              }}>
                Игры
              </Typography>
            </Box>
            <Box
              className={clsx(
                styles.buttonCategory,
                selectedSoft === 'prilozheniia' && styles.active
              )}
              onClick={() => handleSoftClick('prilozheniia')}
            >
              <Applications />
              <Typography sx={{
                fontWeight: '500',
                textTransform: 'uppercase'
              }}>
                Приложения
              </Typography>
            </Box>
            <Box
              className={clsx(
                styles.buttonCategory,
                selectedSoft === 'mobilьniee-igrie' && styles.active
              )}
              onClick={() => handleSoftClick('mobilьniee-igrie')}
            >
              <MobileGames />
              <Typography sx={{
                fontWeight: '500',
                textTransform: 'uppercase'
              }}>
                Мобильные игры
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={styles.container}>
        <Box>
          <>
            {!games.length && loading ? (
              <CircularProgress
                size={64}
                color="primary"
                sx={{ marginTop: theme.spacing(5) }}
              />
            ) : (
              <>
                <Box sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '10px',
                  marginTop: '10px',
                  rowGap: '20px',
                  justifyContent: 'center',
                }}>
                  {games.map(({ name, slug, primaryProductType, productTypes, media, thumb }, index) => (
                    <Box
                      key={index}
                      className={styles.card2}
                      to={`/games/${slug}`}
                      component={Link}
                    >
                      <Box
                        className={styles.image2}
                        component="img"
                        src={thumb ? thumb : media}
                        loading="lazy"
                      />
                      <Typography className={styles.title} variant="h4">
                        {name}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                {games.length < total && (
                  <Button
                    className={styles.button}
                    fullWidth
                    loading={loading}
                    onClick={handleButtonClick}
                  >
                    {translate("catalog.button")}
                  </Button>
                )}
              </>
            )}
          </>
        </Box>
      </Box>
    </Container>
  );
};

export default Catalog;
