import { Box } from "@mui/material";
import WelcomeSeller from "./components/WelcomeSeller/WelcomeSeller";
import Advantages from './components/Advantages/Advantages';
import Register from "./components/Register/Register";
import Warranty from "./components/Warranty/Warranty";
import Fee from "./components/Fee/Fee";


const SellersPage = () => {

  return (
    <Box>
      <WelcomeSeller />
      <Advantages />
      <Register />
      <Warranty />
      <Fee />
    </Box>
  );
};

export default SellersPage;
