const Deliver = ({ color = "#EDF67D" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.6514 4.24794C29.7308 3.73244 28.7507 3.68051 27.7146 3.81C26.7305 3.93299 25.5333 4.24419 24.0944 4.61816L20.7307 5.49234C19.2925 5.86605 18.095 6.17723 17.1787 6.54818C16.2153 6.93822 15.3824 7.46226 14.8421 8.36991C14.2984 9.2833 14.2444 10.2597 14.3799 11.2796C14.5077 12.2412 14.8301 13.4081 15.2142 14.7984L16.1185 18.0715C16.5027 19.4624 16.825 20.6292 17.2098 21.5232C17.6183 22.4725 18.1641 23.2806 19.0901 23.7992C20.0107 24.3147 20.9908 24.3666 22.0269 24.2371C23.011 24.1141 24.2083 23.803 25.6471 23.429L29.0109 22.5548C30.4491 22.1811 31.6466 21.8699 32.5628 21.4989C33.5262 21.1089 34.3592 20.5849 34.8995 19.6772C35.4432 18.7638 35.4972 17.7874 35.3617 16.7675C35.2339 15.8059 34.9115 14.639 34.5273 13.2488L33.6231 9.9756C33.2389 8.58467 32.9166 7.41794 32.5318 6.5239C32.1232 5.57462 31.5774 4.76649 30.6514 4.24794ZM24.6416 7.059C26.1832 6.65838 27.2218 6.39105 28.0247 6.2907C28.794 6.19455 29.1623 6.27933 29.43 6.42924C29.6923 6.57612 29.9427 6.83218 30.2354 7.51223C30.5441 8.22947 30.8229 9.22807 31.2367 10.7258L32.094 13.8292C32.5079 15.3273 32.7811 16.3268 32.8834 17.0968C32.9806 17.828 32.8936 18.1593 32.7513 18.3985C32.6055 18.6433 32.3412 18.8916 31.6247 19.1817C30.8784 19.4838 29.8416 19.7559 28.2997 20.1566L25.0999 20.9881C23.5584 21.3888 22.5197 21.6561 21.7169 21.7564C20.9475 21.8526 20.5793 21.7678 20.3116 21.6179C20.0493 21.471 19.7988 21.215 19.5061 20.5349C19.1974 19.8177 18.9187 18.8191 18.5049 17.3213L17.6475 14.2179C17.2336 12.7198 16.9604 11.7203 16.8581 10.9504C16.761 10.2191 16.8479 9.88782 16.9903 9.64868C17.1361 9.40379 17.4004 9.15556 18.1169 8.86546C18.8631 8.56334 19.9 8.29127 21.4418 7.89056L24.6416 7.059Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.8345 7.87449C5.16928 7.68993 4.48038 8.07958 4.29582 8.74481C4.11125 9.41003 4.5009 10.0989 5.16613 10.2835L8.00563 11.0713C8.71496 11.2681 9.2432 11.8039 9.42365 12.4571L12.6758 24.229C12.5509 24.2536 12.426 24.282 12.3013 24.3144C9.00625 25.1708 6.99272 28.4854 7.89727 31.7596C8.79746 35.018 12.2202 36.8973 15.5023 36.0444C18.3745 35.2979 20.2729 32.6837 20.1139 29.8565L34.1481 26.2093C34.8162 26.0356 35.2171 25.3532 35.0435 24.6851C34.8698 24.0169 34.1874 23.616 33.5192 23.7897L19.4557 27.4445C18.6084 25.7989 17.0524 24.6545 15.2781 24.2603L11.8334 11.7914C11.4115 10.2643 10.2021 9.08628 8.674 8.6623L5.8345 7.87449ZM12.9301 26.734C14.9497 26.2092 16.9762 27.3814 17.4966 29.2649C18.0126 31.1327 16.8799 33.1033 14.8735 33.6247C12.8539 34.1496 10.8274 32.9774 10.307 31.0939C9.79099 29.2261 10.9237 27.2555 12.9301 26.734Z"
      fill={color}
    />
  </svg>
);

export default Deliver;
