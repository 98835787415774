import axios from 'axios'

const API = axios.create({
  baseURL: process.env.NODE_ENV.includes('production')
    ? process.env.REACT_APP_SERVER_URL
      ? process.env.REACT_APP_SERVER_URL
      : ''
    : 'http://localhost:3030/api/',
});


const getItemsOfCategory = (
  categoryName,
  limit,
  country,
  typeId,
  queryParams
) => API.get(`/item/type/cat/${categoryName}?limit=${limit}&country=${country}&type=${typeId}${(queryParams ? `&${queryParams}` : '')}`)

export default getItemsOfCategory;