import React from 'react'
import GamerShop from './components/GamerShop/GamerShop'
import { Box } from '@mui/material'
import Marketplace from './components/Marketplace/Marketplace'
import SteamAccounts from './components/steamAccounts/steamAccounts'
import RealGamers from './components/RealGamers/RealGamers'

const AboutUs = () => {
  return (
    <Box>
        <GamerShop />
        <RealGamers />
        <Marketplace />
        <SteamAccounts/>
    </Box>
  )
}

export default AboutUs