import { useEffect, useState } from "react";

export const useUnreadChats = (instance) => {
  const [count, setCount] = useState(0);

  const decrease = () => {
    setCount((prev) => Math.max(prev - 1, 0));
  };

  const initListeners = () => {
    instance.current.on("chats/unread", (data) => {
      setCount(data.count);
    });
    instance.current.emit("chats/request-unread");
  };

  useEffect(() => {
    const currentInstance = instance?.current;
    return () => {
      currentInstance?.off("chats/unread");
    };
  }, []);

  return { count, init: initListeners, decrease };
};
