import { useTheme } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, TextField, Typography } from "@mui/material";
import clsx from "clsx";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { deliverProductApi } from "../../../api/order/DeliverProduct";
import { useTranslate } from "../../../context/translate/translate.context";
import AlertComponent from "../../AlertComponent";
import MentupModal from "../../MentupModal/MentupModal";
import Deliver from "../../svg/Deliver";
import { TranslateText } from "../../translate-text";
import styles from "./styles.module.css";
import { LoadingButton } from "@mui/lab";

function DeliverOrderModal({
  open,
  close,
  orderId,
  productId,
  setNewStatus,
  orderStatus,
  productStatus,
}) {
  const theme = useTheme();
  const { translate } = useTranslate();

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const schema = yup.object().shape({
    details: yup.string().max(500).required(),
  });

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const submitProductDelivery = async (data) => {
    if (!data?.details) {
      return;
    }

    try {
      setLoading(true);
      const res = await deliverProductApi({
        sellerOrderId: orderId,
        productID: productId,
        message: data.details,
        productStatus: productStatus,
        orderStatus: orderStatus,
      });

      if (res.data?.statusChanged) {
        setAlertMessage(translate("orders.deliverOrderModal.statusChanged"));
        return;
      }

      if (!res?.data?.order?.productsInfo?.length) {
        setAlertMessage(translate("errorMessages.unexpectedError"));
      }

      const updatedProduct = res.data.order.productsInfo.find(
        (product) => product.product === productId
      );

      if (updatedProduct?.status === "delivered") {
        reset();
        close();
        setAlertMessage(translate("orders.deliverOrderModal.successMessage"));
        if (setNewStatus) {
          setNewStatus("delivered");
        }
      } else {
        setAlertMessage(translate("errorMessages.unexpectedError"));
      }
    } catch (err) {
      setAlertMessage(translate("errorMessages.unexpectedError"));
    } finally {
      setLoading(false);
    }
  };

  const resetAlertMessage = () => {
    setAlertMessage("");
  };

  return (
    <>
      <AlertComponent
        message={alertMessage}
        resetMessageInParent={resetAlertMessage}
      />

      <MentupModal open={open} close={close}>
        <Box className={styles.confirmContainer}>
          <Box className={styles.headerContainer}>
            <Deliver
              color={theme.palette.mode === "dark" ? "#EDF67D" : "#8142f6"}
            />
            <Typography className={styles.alertTitle}>
              <TranslateText value="orders.deliverOrderModal.title" />
            </Typography>
          </Box>

          <form
            style={{ width: "100%" }}
            onSubmit={handleSubmit(submitProductDelivery)}
          >
            <TextField
              required
              label={translate(
                "orders.deliverOrderModal.productDetailsInput.label"
              )}
              placeholder={translate(
                "orders.deliverOrderModal.productDetailsInput.placeholder"
              )}
              variant="outlined"
              sx={{ width: "100%" }}
              autoComplete="off"
              multiline
              rows={3}
              error={errors.details && true}
              helperText={
                errors.details?.message
                  ? translate(
                      "orders.deliverOrderModal.productDetailsInput.errorMessage"
                    )
                  : null
              }
              {...register("details")}
            />

            <Box className={styles.buttonsContainer}>
              <Button
                className={clsx(styles.button, styles.confirmButton)}
                onClick={close}
              >
                <TranslateText value="orders.deliverOrderModal.cancelButtonLabel" />
              </Button>
              <LoadingButton
                type="submit"
                loading={loading}
                className={clsx(styles.button, styles.cancelButton)}
              >
                <TranslateText value="orders.deliverOrderModal.submitButtonLabel" />
              </LoadingButton>
            </Box>
          </form>
        </Box>
      </MentupModal>
    </>
  );
}

export default DeliverOrderModal;
