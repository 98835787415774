import {
  Box,
  Container,
  List,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import clsx from "clsx";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import URI from "urijs";

import { searchGlobal } from "../../api/Search";
import { getAllBlogPostsByCategory } from "../../api/blog/getBlogPosts";
import { useTranslate } from "../../context/translate/translate.context";
import { setIsSearchScreen, setSearchValue } from "../../store/searchStore";
import Account from "../search/Account";
import Category from "../search/Category";
import Product from "../search/Product";
import Search from "../search/Search";
import { SEARCH_THRESHOLD } from "../search/Search/constants";
import { TranslateText } from "../translate-text";
import SearchNews from "./SearchNews";
import styles from "./styles.module.css";

const SearchPage = () => {
  const theme = useTheme();
  const { query } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { translate } = useTranslate();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const user = useSelector((state) => state.user);
  const searchRedux = useSelector((state) => state.search);

  const [data, setData] = useState(null);
  const [latest4Blog, setLatest4Blog] = useState([]);

  const getLatest4Blog = async () => {
    try {
      const response = await getAllBlogPostsByCategory(1, 0, 4, 0);

      if (response.status === 200) {
        setLatest4Blog(response.data.data);
      }
    } catch (error) {
      navigate('/404')
    }
  };

  useEffect(() => {
    dispatch(setIsSearchScreen(true));
    return () => {
      dispatch(setIsSearchScreen(false));
    };
  }, []);

  useEffect(() => {
    if (query) {
      const encodedSearchValue = URI.encode(query);
      dispatch(setSearchValue(encodedSearchValue));
    }
  }, [query]);

  const handleSearch = useCallback(async (value, country) => {
    if (!value || value.length < SEARCH_THRESHOLD) {
      return setData(null);
    }

    try {
      getLatest4Blog();
      const { data: searchData } = await searchGlobal(
        encodeURIComponent(value),
        country
      );

      setData(searchData);
    } catch (error) {
      navigate('/404')
    }
  }, []);

  const debouncedResults = useMemo(() => debounce(handleSearch, 300), []);

  useEffect(() => {
    debouncedResults(searchRedux.searchValue, user.country);

    return () => {
      debouncedResults?.cancel?.();
    };
  }, [searchRedux.searchValue]);

  return (
    <Container className={styles.container}>
      {!!isMobile && (
        <Search className={styles.drawerContainer__search} isMobile={true} />
      )}

      <Box className={styles.resultContainer}>
        {!!data?.categories?.length && (
          <Box component="section">
            <Box className={styles.header}>
              <Typography className={styles.header__heading} variant="h2">
                {translate("search.categories")}
              </Typography>
              <Link to="/games">
                {`${translate("search.seeAll")} ${translate(
                  "search.categories"
                )}`}
              </Link>
            </Box>

            <List className={styles.list}>
              {data.categories.slice(0, 3).map((category) => (
                <ListItem key={category._id} disablePadding>
                  <Category {...category} />
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        {!!data?.products?.length && (
          <Box component="section">
            <Box className={styles.header}>
              <Typography className={styles.header__heading} variant="h2">
                {translate("search.products")}
              </Typography>
              <Link to="/games">
                {`${translate("search.seeAll")} ${translate(
                  "search.products"
                )}`}
              </Link>
            </Box>

            <List className={clsx(styles.list, styles.list__products)}>
              {data.products.slice(0, 3).map((product) => (
                <ListItem
                  key={product._id}
                  className={styles.listItem__product}
                  disablePadding
                >
                  <Product {...product} />
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        {!!data?.accounts?.length && (
          <Box component="section">
            <Box className={styles.header}>
              <Typography className={styles.header__heading} variant="h2">
                {translate("search.accounts")}
              </Typography>
            </Box>

            <List className={clsx(styles.list, styles.list__accounts)}>
              {data.accounts.slice(0, 3).map((account) => (
                <ListItem
                  key={account._id}
                  className={styles.listItem__account}
                  disablePadding
                >
                  <Account {...account} />
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        {!!latest4Blog.length && (
          <Box component="section">
            <Box className={styles.header}>
              <Typography className={styles.header__heading} variant="h2">
                {translate("search.articles")}
              </Typography>
            </Box>

            <Box className={styles.news}>
              <SearchNews posts={latest4Blog} />
            </Box>
          </Box>
        )}

        {!data?.accounts?.length &&
          !data?.products?.length &&
          !data?.categories?.length &&
          !latest4Blog.length && (
            <Typography className={styles.noNotificationsTitle}>
              <TranslateText value="search.noResults" />
            </Typography>
          )}
      </Box>
    </Container>
  );
};

export default SearchPage;
