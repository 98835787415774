import { Box, Typography } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useCallback, useEffect, useState } from "react";

import {
  get2FAEnabled,
  set2FAEnabled,
} from "../../../../../../api/settings/2fa";
import { MentupSwitch } from "../../../../../../lib/theme";
import AlertComponent from "../../../../../AlertComponent";
import { TranslateText } from "../../../../../translate-text";
import Set2FAModal from "./components/Set2FAModal";
import Unset2FAModal from "./components/Unset2FAModal";
import styles from "./styles.module.css";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Change2FA = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [unsetModalOpen, setUnsetModalOpen] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [snackBar, setSnackBar] = useState({
    open: false,
    vertical: "bottom",
    horizontal: "right",
    message: "",
  });

  const onConfirm = async () => {
    setLoading(false);
    setModalOpen(false);

    await getEnabled();
  };

  const onConfirmUnset = async () => {
    await set2FAEnabled({ enabled: false });

    setLoading(false);
    setUnsetModalOpen(false);

    await getEnabled();
  };

  const changeEnabled = useCallback(async (v) => {
    try {
      setLoading(true);
      if (v) {
        setModalOpen(true);
      } else {
        //setUnsetModalOpen(true);
      }
    } catch (error) {
      //console.error(error);
      setAlertMessage(error.message);
    } finally {
      await getEnabled();
    }
  }, []);

  const getEnabled = async () => {
    try {
      setLoading(true);
      const response = await get2FAEnabled();
      setEnabled(response.data?.enabled);
    } catch (error) {
      //console.error(error);
      setAlertMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEnabled();
  }, []);

  return (
    <Box className={styles.container}>
      <Snackbar
        anchorOrigin={{
          vertical: snackBar.vertical,
          horizontal: snackBar.horizontal,
        }}
        open={snackBar.open}
        autoHideDuration={4000}
        onClose={() => setSnackBar({ ...snackBar, open: false })}
        key={snackBar.vertical + snackBar.horizontal}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          {snackBar.message}
        </Alert>
      </Snackbar>

      <AlertComponent
        message={alertMessage}
        resetMessageInParent={() => setAlertMessage("")}
      />

      <Box className={styles.twoFactor}>
        <Box className={styles.twoFactorTitleContainer}>
          <Typography className={styles.twoFactorTitle}>
            <TranslateText value="vendorDashboard.settings.2fa.twoFactorTitle" />
          </Typography>
        </Box>

        <Box className={styles.twoFactorSwitchContainer}>
          <MentupSwitch
            disabled={loading}
            defaultValue={enabled}
            checked={enabled}
            value={enabled}
            onChange={(_, value) => changeEnabled(value)}
          />

          <Box>
            <Typography className={styles.twoFactorSubtitle}>
              <TranslateText value="vendorDashboard.settings.2fa.twoFactor" />
            </Typography>
            <Typography className={styles.twoFactorMessage}>
              <TranslateText value="vendorDashboard.settings.2fa.twoFactorDescription" />
            </Typography>
          </Box>
        </Box>
      </Box>

      <Set2FAModal
        open={modalOpen}
        setAlertMessage={setAlertMessage}
        close={() => {
          setModalOpen(false);
          setLoading(false);
        }}
        confirm={onConfirm}
      />
      {/*
      <Unset2FAModal
        open={unsetModalOpen}
        setAlertMessage={setAlertMessage}
        close={() => {
          setUnsetModalOpen(false);
          setLoading(false);
        }}
        confirm={onConfirmUnset}
      /> */}
    </Box>
  );
};

export default Change2FA;
