import { Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import { useTranslate } from "../../context/translate/translate.context";
import styles from "./styles.module.css";

const BreadcrumbsNavigation = ({ breadcrumbs, from }) => {
  const { translate } = useTranslate();

  return (
    <Breadcrumbs className={styles.container} sx={{
      marginTop: from === 'catalog' ? '0px' : '28px'
    }}>
      {breadcrumbs.map(({ label, path }, index) =>
        !path ? (
          <Typography className={styles.text} key={index}>
            {translate(label)}
          </Typography>
        ) : (
          <Typography
            className={styles.link}
            key={index}
            component={Link}
            to={path}
          >
            {translate(label)}
          </Typography>
        )
      )}
    </Breadcrumbs>
  );
};

export default BreadcrumbsNavigation;
