import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, IconButton, Typography } from "@mui/material";

import styles from "./styles.module.css";

const ItemCounter = ({ quantity, increment, decrement }) => (
  <Box className={styles.container}>
    <IconButton className={styles.decrement} onClick={decrement}>
      <RemoveIcon />
    </IconButton>
    <Typography className={styles.counter}>{quantity}</Typography>
    <IconButton className={styles.increment} onClick={increment}>
      <AddIcon />
    </IconButton>
  </Box>
);

export default ItemCounter;
