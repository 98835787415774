import { Box, Button, Typography, useTheme } from "@mui/material";
import clsx from "clsx";

import { useTranslate } from "../../../../../../context/translate/translate.context";
import MentupModal from "../../../../../MentupModal/MentupModal";
import Danger from "../../../../../svg/Danger";
import styles from "./styles.module.css";

const HideItemConfirmContent = ({ open, close, confirm }) => {
  const theme = useTheme();
  const { translate } = useTranslate();

  return (
    <MentupModal open={open} close={close}>
      <Box className={styles.confirmContainer}>
        <Box className={styles.headerContainer}>
          <Danger
            color={theme.palette.mode === "dark" ? "#EDF67D" : "#8142f6"}
          />
          <Typography className={styles.alertTitle}>
            {translate("products.product.hide.confirmation.title")}
          </Typography>
          <Typography className={styles.alertInfo}>
            {translate("products.product.hide.confirmation.message")}
          </Typography>
        </Box>

        <Box className={styles.buttonsContainer}>
          <Button
            className={clsx(styles.button, styles.confirmButton)}
            onClick={confirm}
          >
            {translate("products.product.hide.confirmation.confirm")}
          </Button>
          <Button
            className={clsx(styles.button, styles.cancelButton)}
            onClick={close}
          >
            {translate("products.product.hide.confirmation.cancel")}
          </Button>
        </Box>
      </Box>
    </MentupModal>
  );
};

export default HideItemConfirmContent;
