import axios from "axios";
import Cookies from "js-cookie";

const API = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL || "http://localhost:3030/api/",
});

export const deleteMultipleImages = (imageNames) => {
  const token = Cookies.get("refreshToken");
  if (token && !!imageNames?.length) {
    return API.delete(`/image/deleteArray`, {
      data: { imageNames },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  }
};
