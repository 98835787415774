import "swiper/css";
import "swiper/css/navigation";

import { Box, CircularProgress, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useTranslate } from "../../../context/translate/translate.context";
import getFeaturedProducts from "../../../services/featuredProducts";
import ProductSwiper from "../../ProductsSwiper";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";

const FeaturedProducts = () => {
  const { translate } = useTranslate();

  const userRedux = useSelector((state) => state.user);

  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);

  const navigate = useNavigate()

  useEffect(() => {
    const getFeatured = async () => {
      try {
        let items = await getFeaturedProducts(userRedux.country);

        if(items.length > 0) {
          items = items.filter((item) => item.product !== null)
        }

        setProducts(items);
      } catch (error) {
        navigate('/404')
      } finally {
        setLoading(false);
      }
    };

    getFeatured();
  }, []);

  if (!loading && products.length === 0) {
    return null;
  }

  return (
    <Container className={styles.featuredProductsContainer} disableGutters>
      {/*
      <Typography
        className={styles.featuredProductsContainer__heading}
        variant="h3"
      >
        {translate("home.bestForYou")}
      </Typography> */}
      {loading ? (
        <Box
          sx={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100vw",
            height: "100vh",
            background: "rgba(0,0,0,1)",
            zIndex: "100",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            marginTop: "50px",
            marginBottom: "36px",
          }}
        >
          <ProductSwiper
            products={products.map(({ product }) => product)}
            cardVariant="large"
            play={true}
          />
        </Box>
      )}
    </Container>
  );
};

export default FeaturedProducts;
