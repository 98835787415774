const { CURRENCY_SIGN_BY_CURRENCY } = require("../../constants");

const priceLabel = (priceToShow, { currency, sign } = {}) =>
  `${`${priceToShow.toFixed(2)}`.replace(/\d(?=(\d{3})+\.)/g, "$&,")} ${
    sign || CURRENCY_SIGN_BY_CURRENCY[currency || "RUB"]
  }`;

module.exports = {
  priceLabel,
};
