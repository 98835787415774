import { Box, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getCartByCode } from "../../api/cart/getCartByCode";
import { useTranslate } from "../../context/translate/translate.context";
import { TranslateText } from "../translate-text";
import styles from "./styles.module.css";

export const Transfer = () => {
  const { translate } = useTranslate();

  const { code } = useParams();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cart, setCart] = useState();

  const getCart = async (c) => {
    setLoading(true);
    try {
      const response = await getCartByCode(c);
      if (response.data?._id) {
        setCart(response.data);
      } else {
        setError(true);
      }
    } catch (e) {
      setError(true);
      //console.error("getCart error", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (code) {
      getCart(code);
    }
  }, [code]);

  return (
    <Container
      className={styles.container}
      sx={{ "*": { boxSizing: "border-box" } }}
    >
      {error && (
        <Typography variant="h4">
          <TranslateText value="transfer.error" />
        </Typography>
      )}

      {!!cart && (
        <>
          <Typography variant="h4">
            <TranslateText value="transfer.title" />
          </Typography>

          <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
            <Typography variant="h5">
              <TranslateText value="transfer.subtitle" />
            </Typography>

            <Box sx={{ display: "flex", gap: "12px" }}>
              <Typography variant="h5">
                <TranslateText value="transfer.card" />
              </Typography>
              <Typography variant="h5">-</Typography>
              <Typography variant="h5">{cart.total} ₽</Typography>
            </Box>

            <Typography variant="h5">
              {translate("transfer.details").replace(
                "{description}",
                cart.code
              )}
            </Typography>
          </Box>
        </>
      )}
    </Container>
  );
};
