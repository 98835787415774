import { useTheme } from "@emotion/react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputBase,
  Pagination,
  Slider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import clsx from "clsx";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

import {
  getListings,
  getListingsFilters,
} from "../../../../api/personalOffice/requests";
import { useTranslate } from "../../../../context/translate/translate.context";
import FiltersSVG from "../../../../lib/svg/filters";
import ProductCard from "../../../ProductCard";
import ReviewsSwiper from "../../../ReviewsSwiper";
import SearchIcon from "../../../svg/SearchIcon";
import FilterDropdown from "../FilterDropdown";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";

const UserInfoTabListings = ({ user }) => {
  const theme = useTheme();
  const { translate } = useTranslate();

  const userRedux = useSelector((state) => state.user);

  const navigate = useNavigate()

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [loading, setLoading] = useState(true);
  const [pages, setTotalPages] = useState(0);
  const [products, setProducts] = useState([]);
  const [allFilters, setAllFilters] = useState();
  const [priceRadius, setPriceRadius] = useState([0, 0]);
  const [filters, setFilters] = useState({
    country: userRedux.country,
    text: "",
    limit: 8,
    page: 0,
    sort: { updatedAt: -1 },
    price: { min: 0, max: 0 },
    user: user._id,
    categories: [],
    soldOut: [true, false],
    discount: [true, false],
    reviews: [true, false],
  });

  const ref = useRef(null);

  const handlePriceChange = (_, newValue) => {
    setFilters((prev) => ({
      ...prev,
      price: { min: newValue[0], max: newValue[1] },
      page: 0,
    }));
  };

  const handleSearch = useCallback(
    async (f) => {
      if(user.status !== 'banned') {
        setLoading(true);
        const { data } = await getListings(f);
        setProducts(data.items);
        setTotalPages(data.totalPages);
        setLoading(false);
      }
    },
    [filters, filters.text]
  );

  const removeFromList = useCallback(
    (id) => {
      setProducts((prev) => prev.filter((product) => product._id !== id));
      if (products.length === 1) {
        setFilters((prev) => ({ ...prev, page: Math.max(0, prev.page - 1) }));
      }
    },
    [products.length]
  );

  const debouncedSearchText = useMemo(() => debounce(handleSearch, 300), []);

  const checkCategory = useCallback(
    async (id) => {
      const categories = filters.categories.includes(id)
        ? filters.categories.filter((categoryId) => categoryId !== id)
        : filters.categories.concat(id);

      setFilters((prev) => ({ ...prev, categories, page: 0 }));
    },
    [filters.categories]
  );

  const checkSoldOut = useCallback(
    async (v) => {
      const soldOut = filters.soldOut.includes(v)
        ? filters.soldOut.filter((soldOutV) => soldOutV !== v)
        : filters.soldOut.concat(v);

      setFilters((prev) => ({ ...prev, soldOut, page: 0 }));
    },
    [filters.soldOut]
  );

  const checkDiscount = useCallback(
    async (v) => {
      const discount = filters.discount.includes(v)
        ? filters.discount.filter((discountV) => discountV !== v)
        : filters.discount.concat(v);

      setFilters((prev) => ({ ...prev, discount, page: 0 }));
    },
    [filters.discount]
  );

  const checkReviews = useCallback(
    async (v) => {
      const reviews = filters.reviews.includes(v)
        ? filters.reviews.filter((reviewsV) => reviewsV !== v)
        : filters.reviews.concat(v);

      setFilters((prev) => ({ ...prev, reviews, page: 0 }));
    },
    [filters.reviews]
  );

  const checkSort = useCallback(
    async (sort) => setFilters((prev) => ({ ...prev, sort, page: 0 })),
    [filters.discount]
  );

  const getFilters = useCallback(async () => {
    try {
      const { data } = await getListingsFilters({ user });
      setAllFilters(data);
      setPriceRadius([data.price.min, data.price.max]);
      setFilters((prev) => ({
        ...prev,
        categories: data.categories.map(({ _id }) => _id),
        price: data.price,
      }));
    } catch (error) {
      navigate('/404')
    }
  }, [user]);

  useEffect(() => {
    getFilters();
  }, []);

  useEffect(() => {
    if (allFilters) {
      debouncedSearchText(filters);
    }
    return () => debouncedSearchText.cancel();
  }, [
    allFilters,
    filters.text,
    filters.limit,
    filters.page,
    filters.sort.updatedAt,
    filters.sort.name,
    filters.price.min,
    filters.price.max,
    filters.user,
    filters.categories,
    filters.soldOut,
    filters.discount,
    filters.reviews,
  ]);

  return (
    <Box className={styles.container}>
      <Box className={styles.filtersContainer}>
        <Box className={styles.nowrap}>
          <FiltersSVG
            color={theme.palette.mode === "dark" ? "#f6f6f6" : "#1f1d22"}
          />

          <Box className={styles.filters}>
            <FilterDropdown title="vendorDashboard.filters.category">
              {allFilters?.categories.map(({ _id, name }) => (
                <Box key={_id} className={styles.categoryItem}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={_id}
                        color="primary"
                        onChange={({ target }) => checkCategory(target.value)}
                        checked={filters.categories.includes(_id)}
                      />
                    }
                    label={name}
                  />
                </Box>
              ))}
            </FilterDropdown>
            <FilterDropdown title="vendorDashboard.filters.price.title">
              <Box className={styles.priceFilter}>
                <Typography
                  color="primary"
                  id="range-slider"
                  sx={{ width: "50px" }}
                >
                  {filters.price.min}
                </Typography>

                <Slider
                  min={priceRadius[0]}
                  max={priceRadius[1]}
                  getAriaLabel={() => "Price range"}
                  value={[filters.price.min, filters.price.max]}
                  onChange={handlePriceChange}
                  valueLabelDisplay="auto"
                  getAriaValueText={() => "Price range"}
                />

                <Typography
                  color="primary"
                  id="range-slider"
                  w
                  sx={{ width: "50px" }}
                >
                  {filters.price.max}
                </Typography>
              </Box>
            </FilterDropdown>
            {/*
            <FilterDropdown title="vendorDashboard.filters.inStock.title">
              <Box className={styles.categoryItem}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={true}
                      color="primary"
                      onChange={() => checkSoldOut(false)}
                      checked={filters.soldOut.includes(false)}
                    />
                  }
                  label={translate("vendorDashboard.filters.inStock.yes")}
                />
              </Box>
              <Box className={styles.categoryItem}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={false}
                      color="primary"
                      onChange={() => checkSoldOut(true)}
                      checked={filters.soldOut.includes(true)}
                    />
                  }
                  label={translate("vendorDashboard.filters.inStock.no")}
                />
              </Box>
            </FilterDropdown>
            */}
            <FilterDropdown title="vendorDashboard.filters.discount.title">
              <Box className={styles.categoryItem}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={true}
                      color="primary"
                      onChange={() => checkDiscount(true)}
                      checked={filters.discount.includes(true)}
                    />
                  }
                  label={translate("vendorDashboard.filters.discount.yes")}
                />
              </Box>
              <Box className={styles.categoryItem}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={false}
                      color="primary"
                      onChange={() => checkDiscount(false)}
                      checked={filters.discount.includes(false)}
                    />
                  }
                  label={translate("vendorDashboard.filters.discount.no")}
                />
              </Box>
            </FilterDropdown>
            {/*
            <FilterDropdown title="vendorDashboard.filters.updatedAt.title">
              <Box className={styles.categoryItem}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={-1}
                      color="primary"
                      onChange={() => checkSort({ updatedAt: -1 })}
                      checked={filters.sort.updatedAt === -1}
                    />
                  }
                  label={translate("vendorDashboard.filters.updatedAt.asc")}
                />
              </Box>
              <Box className={styles.categoryItem}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={1}
                      color="primary"
                      onChange={() => checkSort({ updatedAt: 1 })}
                      checked={filters.sort.updatedAt === 1}
                    />
                  }
                  label={translate("vendorDashboard.filters.updatedAt.desc")}
                />
              </Box>
            </FilterDropdown>

            <FilterDropdown title="vendorDashboard.filters.reviews.title">
              <Box className={styles.categoryItem}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={true}
                      color="primary"
                      onChange={() => checkReviews(true)}
                      checked={filters.reviews.includes(true)}
                    />
                  }
                  label={translate("vendorDashboard.filters.reviews.yes")}
                />
              </Box>
              <Box className={styles.categoryItem}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={false}
                      color="primary"
                      onChange={() => checkReviews(false)}
                      checked={filters.reviews.includes(false)}
                    />
                  }
                  label={translate("vendorDashboard.filters.reviews.no")}
                />
              </Box>
            </FilterDropdown>
            <FilterDropdown title="vendorDashboard.filters.name.title">
              <Box className={styles.categoryItem}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={-1}
                      color="primary"
                      onChange={() => checkSort({ name: -1 })}
                      checked={filters.sort.name === -1}
                    />
                  }
                  label={translate("vendorDashboard.filters.name.asc")}
                />
              </Box>
              <Box className={styles.categoryItem}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={1}
                      color="primary"
                      onChange={() => checkSort({ name: 1 })}
                      checked={filters.sort.name === 1}
                    />
                  }
                  label={translate("vendorDashboard.filters.name.desc")}
                />
              </Box>
            </FilterDropdown>
            */}
          </Box>
        </Box>
        {/*
        <Box className={styles.inputContainer}>
          <InputBase
            ref={ref}
            className={styles.input}
            value={filters.text}
            onChange={({ target }) =>
              setFilters((prev) => ({ ...prev, text: target.value, page: 0 }))
            }
            placeholder={translate("header.search")}
          />
          <IconButton
            className={styles.iconContainer}
            aria-label="clear search input"
            onClick={() => setFilters((prev) => ({ ...prev, text: "" }))}
            disabled={!filters.text}
          >
            {filters.text ? <ClearIcon /> : <SearchIcon />}
          </IconButton>
        </Box> */}
      </Box>

      <Box
        className={clsx(styles.itemsContainer, loading && styles.itemsLoading)}
      >
        {products.map((product) => (
          <ProductCard
            key={product._id}
            variant={'small'}
            product={product}
            onDelete={() => removeFromList(product._id)}
            isMobile={isMobile}
          />
        ))}
      </Box>

      {!!pages && (
        <Box className={styles.paginationContainer}>
          <Pagination
            count={pages}
            color="primary"
            onChange={(_, page) =>
              setFilters((prev) => ({ ...prev, page: page - 1 }))
            }
          />
        </Box>
      )}
      {user.status !== 'banned' && (
        <ReviewsSwiper user={user} />
      )}
    </Box>
  );
};

export default UserInfoTabListings;
