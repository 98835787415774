import * as _ from "lodash";
import { createContext, useContext } from "react";
import { useSelector } from "react-redux";

import * as EN from "../../translate/en.json";
import * as RU from "../../translate/ru.json";

const Context = createContext(null);

export const TranslationsData = {
  en: EN,
  ru: RU,
};

export const TranslationsDate = {
  en: 'en-US',
  ru: 'ru-RU',
};

export const TranslateProvider = ({ children }) => {
  const language = useSelector((state) => state.language);

  const translate = (key, words) => {
    const data = TranslationsData[language] || TranslationsData.en;
    const translation = _.get(data, key, key);

    return words
      ? Object.entries(words).reduce(
          (accumulator, [key, value]) =>
            accumulator.replaceAll(`$${key}`, value),
          translation
        )
      : translation;
  };

  return (
    <Context.Provider value={{ language, translate }}>
      {children}
    </Context.Provider>
  );
};

export const useTranslate = () => useContext(Context);
