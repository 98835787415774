import "swiper/css";
import "swiper/css/autoplay";

import {
  Box,
  CircularProgress,
  Container,
  Typography,
  useTheme,
} from "@mui/material";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { getAllAdsHome } from "../../../api/ads/getAllAdsHome";
import { useTranslate } from "../../../context/translate/translate.context";
import useThrottledResize from "../../../hooks/useThrottledResize";
import DiagonalArrow from "../../svg/DiagonalArrow";
import Button from "../../UI/Button";
import styles from "./styles.module.css";

const Hero = () => {
  const { translate } = useTranslate();

  const theme = useTheme();

  const navigate = useNavigate();

  const containerRef = useRef(null);
  const swiperRef = useRef(null);
  const adRef = useRef(0);

  const [heroSwiperPlaceholder, setHeroSwiperPlaceholder] = useState({
    left: 0,
    right: 0,
  });
  const [ads, setAds] = useState([]);
  const [activeAd, setActiveAd] = useState(0);
  const [loading, setLoading] = useState(true);

  // TODO: get content from ads
  // const content = ads[activeAd] ? ads[activeAd].content : DEFAULT_CONTENT;
  const content = {
    heading1: ads[activeAd]?.heading1 || "",
    heading2: ads[activeAd]?.heading2 || "",
    description1: ads[activeAd]?.description1 || "",
    description2: ads[activeAd]?.description2 || "",
    button1: {
      icon: ads[activeAd]?.button1.icon || "",
      text: ads[activeAd]?.button1.text || "",
      path: ads[activeAd]?.button1.path || "",
    },
    button2: {
      text: ads[activeAd]?.button2.text || "",
      path: ads[activeAd]?.button2.path || "",
    },
  };

  const calculateSwiperPlaceholder = () => {
    const { left, right, width } = containerRef.current.getBoundingClientRect();

    setHeroSwiperPlaceholder(
      width > 885
        ? {
            left: left + 24,
            right: right - 300,
          }
        : {
            left: (width - 276) / 2,
            right: (width - 276) / 2,
          }
    );
  };

  useThrottledResize(() => {
    calculateSwiperPlaceholder();

    calculateVisibleSlides();
  });

  useEffect(() => {
    adRef.current = activeAd;

    calculateVisibleSlides();
  }, [activeAd]);

  useEffect(() => {
    calculateSwiperPlaceholder();

    const getAds = async () => {
      try {
        const { data } = await getAllAdsHome();

        setAds(data);
      } catch (error) {
        navigate('/404')
      } finally {
        setLoading(false);
      }
    };
    getAds();
  }, []);

  const calculateVisibleSlides = () => {
    const { width } = containerRef.current.getBoundingClientRect();

    if (!swiperRef.current?.slides) {
      return;
    }

    const slides = [...swiperRef.current.slides].splice(
      adRef.current,
      width > 885 ? width / 300 : 1
    );

    for (const slide of swiperRef.current.slides) {
      slide.style.setProperty(
        "--background-color",
        slides.includes(slide) ? "transparent" : "rgba(0, 0, 0, 0.5)"
      );
    }
  };

  return (
    <Box
      sx={{
        transition: "background 0.2s ease-in-out",
        background: (() => {
          // Determine the image source (thumb or media)
          const imageSource = ads[activeAd]?.thumb || ads[activeAd]?.media;
      
          // Determine the base color depending on the theme
          const baseColor = theme.palette.mode === "dark" ? "#0F0F15" : "#F6F6F6";
          const baseGradientDark = "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 79.81%, #0F0F15 100%)";
          const baseGradientLight = "linear-gradient(180deg, rgba(246, 246, 246, 0) 79.81%, rgba(246, 246, 246, 0.416667) 90.64%, #F6F6F6 100%)";
          
          // Determine the overlay gradient depending on the theme
          const overlayGradient = theme.palette.mode === "dark"
            ? "linear-gradient(114deg, rgba(0, 0, 0, 0.60) 36.34%, rgba(0, 0, 0, 0.00) 44.39%)"
            : "linear-gradient(113.98deg, rgba(0, 0, 0, 0.6) 36.34%, rgba(0, 0, 0, 0) 44.39%)";
      
          // Construct the full background style if an image source is present
          if (imageSource) {
            return `${theme.palette.mode === "dark" ? baseGradientDark : baseGradientLight}, ${overlayGradient}, url(${imageSource}), ${baseColor} 50% / cover no-repeat`;
          }
      
          // Default to base color if no image source is available
          return baseColor;
        })(),
        backgroundSize: "cover",
        backgroundPosition: "center",
        '@media (max-width: 650px)': {
          backgroundPosition: "right"
        }
      }}
    >
      <Container ref={containerRef}>
        {loading ? (
          <Box
            sx={{
              position: "fixed",
              top: "0",
              left: "0",
              width: "100vw",
              height: "100vh",
              background: "rgba(0,0,0,1)",
              zIndex: "100",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box className={styles.heroContainer}>
            <Box className={styles.heroContentContainer}>
              <Typography variant="h2" className={styles.heroHeading}>
                {translate(content.heading1)}
                <>
                  <Box component="br" />
                  <Box component="span" sx={{
                    visibility: content.heading2 ? 'visible' : 'hidden' 
                  }}>{content.heading2}</Box>
                </>
              </Typography>
              <Typography
                variant="body1"
                style={{ whiteSpace: 'pre-line', wordBreak: 'break-word', overflowWrap: 'break-word'}}
                className={clsx(
                  styles.heroDescription,
                  styles.heroDescription__main
                )}
              >
                {content.description1}
              </Typography>
              <Typography 
                variant="body1" 
                className={styles.heroDescription}
                sx={{
                  visibility: content.description2 ? 'visible' : 'hidden'
                }}
                >
                {content.description2}
              </Typography>
              <Box className={styles.heroButtonsContainer}>
                <Button
                  className={clsx(styles.heroButton, styles.heroButton__main)}
                  startIcon={
                    <Box
                      dangerouslySetInnerHTML={{
                        __html: content.button1.icon,
                      }}
                    />
                  }
                  onClick={() => navigate(content.button1.path)}
                >
                  {translate(content.button1.text)}
                </Button>
                <Button
                  color="secondary"
                  className={styles.heroButton}
                  onClick={() => navigate(content.button1.path)}
                  sx={{
                    visibility: content.button2?.text ? 'visible' : 'hidden'
                  }}
                >
                  {translate(content.button2.text)}
                </Button>
              </Box>
            </Box>
            {/*
            <Box sx={{
              minWidth: '100vw',
              padding: 0,
              margin: 0,
            }}>
              {ads.map(({ media, thumb, name }, index) => (
                <div 
                  key={index} 
                  style={{ backgroundColor: `hsl(${index * 360 / ads.length}, 70%, 80%)` }}
                >
                  {name}
                </div>
              ))}
            </Box> */}
            {/*
            <Box className={styles.heroSwiper}>
              <Swiper
                style={{
                  paddingLeft: `${heroSwiperPlaceholder.left}px`,
                  paddingRight: `${heroSwiperPlaceholder.right}px`,
                }}
                modules={[Autoplay]}
                autoplay={{
                  delay: 12000,
                  disableOnInteraction: false,
                }}
                slidesPerView="auto"
                spaceBetween={16}
                onSlideChange={({ realIndex }) => {
                  setActiveAd(realIndex);
                  calculateVisibleSlides();
                }}
                onClick={({ clickedIndex }) => {
                  setActiveAd(clickedIndex);
                  swiperRef.current.slideTo(clickedIndex);
                }}
                onSwiper={(swiper) => {
                  swiperRef.current = swiper;
                  calculateVisibleSlides();
                }}
              >

                {ads.map(({ media, thumb, name }, index) => {
                  return (
                    <SwiperSlide key={name + index}>
                      <Box
                        className={clsx(
                          styles.heroItemContainerWrapper,
                          index === activeAd && styles.heroItemContainerWrapper_active
                        )}
                      >
                        <Box
                          sx={{
                            backgroundImage: `url(${thumb !== null && thumb !== undefined ? thumb : media})`,
                          }}
                          className={styles.heroItemContainer}
                        >
                          <Box className={styles.heroItem}>
                            <Typography className={styles.heroItem__title}>
                              {name}
                            </Typography>
                            <Box
                              className={clsx(
                                styles.heroItem__icon,
                                index === activeAd && styles.heroItem__icon_active
                              )}
                            >
                              <DiagonalArrow />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </SwiperSlide>
                  );
                })}

              </Swiper>
            </Box>
            */}
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default Hero;
