import { Box, Button, Card, Container, Typography } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import React, { useCallback, useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import {
  getNotifications,
  readAllNotifications,
} from "../../../api/notifications/getNotifications";
import { setNotificationsCount } from "../../../store/notificationStore";
import { TranslateText } from "../../translate-text";
import Notification from "../Notification";
import styles from "./styles.module.css";

function NotificationsPage() {
  const dispatch = useDispatch();
  const limit = 5;
  const tabs = [
    "all",
    "new",
    "paid",
    "completed",
    "canceled",
    "lotOver",
    "review",
  ];

  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [notifications, setNotifications] = useState([]);

  const getItems = useCallback(async () => {
    const response = await getNotifications(limit, page);

    setNotifications(response.data?.items || []);
    setTotalPages(response.data?.totalPages || 0);

    dispatch(setNotificationsCount({ count: response.data?.count }));
  }, [limit, page, activeTab]);

  const handlePageChange = (_, v) => setPage(v);

  const markAllAsRead = useCallback(async () => {
    await readAllNotifications();
    await getItems();
  }, [getItems]);

  useEffect(() => {
    getItems();
  }, [page, activeTab]);

  return (
    <Container
      sx={{
        padding: "16px",
        "*": {
          boxSizing: "border-box",
        },
      }}
    >
      <Box className={styles.titleContainer}>
        <Typography className={styles.title}>
          <TranslateText value="notificationsPage.title" />
        </Typography>

        <Button className={styles.markAsReadBtn} onClick={markAllAsRead}>
          <Typography className={styles.markAsRead}>
            <TranslateText value="notificationsPage.markAsReadButton" />
          </Typography>
        </Button>
      </Box>

      <Box className={styles.bottomContainer}>
        {/* <Box>
          <Card className={styles.tabsContainer}>
            {tabs.map((tab) => (
              <Button
                key={tab}
                className={clsx(styles.tab, tab === activeTab && styles.active)}
                onClick={() => setActiveTab(tab)}
              >
                <Typography className={styles.tabText}>
                  <TranslateText value={`notificationsPage.tabs.${tab}`} />
                </Typography>
              </Button>
            ))}
          </Card>
        </Box> */}
        <Card className={styles.notificationsContainer}>
          {notifications.length === 0 && (
            <Typography className={styles.noNotificationsTitle}>
              <TranslateText value="notificationsPage.noNotificationsTitle" />
            </Typography>
          )}

          {notifications.map((notification) => (
            <Notification key={notification._id} notification={notification} />
          ))}

          <Box className={styles.pagination}>
            <Pagination
              color="primary"
              count={totalPages}
              page={page}
              onChange={handlePageChange}
            />
          </Box>
        </Card>
      </Box>
    </Container>
  );
}

export default NotificationsPage;
