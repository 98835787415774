import axios from "axios";
import Cookies from "js-cookie";

const API = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL || "http://localhost:3030/api/",
});

const header = () => {
  const token = Cookies.get("refreshToken");
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
};

export const get2FAEnabled = () => API.get("/settings/2fa/enabled", header());
export const get2FAqr = () => API.get("/settings/2fa/qr", header());

export const set2FAEnabled = ({ enabled }) =>
  API.post("/settings/2fa/enabled", { enabled }, header());
export const validate2FaOTP = ({ code }) =>
  API.post("/settings/2fa/validate/otp", { code }, header());
