import { Box, Typography } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useTranslate } from "../../../../../context/translate/translate.context";
import MentupModal from "../../../../MentupModal/MentupModal";
import MentupStepper from "../../../../MentupStepper";
import { TranslateText } from "../../../../translate-text";
import TopUpBalanceStep1 from "./components/step1";
import TopUpBalanceStep2 from "./components/step2";
import TopUpBalanceStep3 from "./components/step3";
import styles from "./styles.module.css";

const TopUpBalanceContent = ({ open, close }) => {
  const { translate } = useTranslate();

  const userRedux = useSelector((state) => state.user);

  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sum, setSum] = useState(0);
  const [selectedPayment, setSelectedPayment] = useState();

  useEffect(() => {
    setStep(open ? 0 : -1);
    if (!open) {
      setSum(0);
      setSelectedPayment();
    }
  }, [open]);

  const handlePayYooKassa = async (depositAmmount) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const requestUrl = `${
      process.env.REACT_APP_SERVER_URL || "http://localhost:3030/api"
    }/payment/yookassa`;

    const body = {
      type: "deposit",
      cart: [],
      balanceUsed: 0,
      userId: user._id,
      country: userRedux.country,
      depositAmmount,
    };
    const response = await axios.post(requestUrl, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("refreshToken")}`, // Set token in header
      },
    });
    if (response.data?.redirectUrl) {
      window.location.replace(response.data.redirectUrl);
    }
  };

  const handlePayPayBox = async (depositAmmount) => {
    const requestUrl = `${
      process.env.REACT_APP_SERVER_URL || "http://localhost:3030/api"
    }/payment/paybox/deposit`;
    const response = await axios.post(
      requestUrl,
      { depositAmmount },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
    if (response.data?.redirectUrl) {
      window.location.replace(response.data.redirectUrl);
    }
  };

  const handlePayLava = async (depositAmmount) => {
    const requestUrl = `${
      process.env.REACT_APP_SERVER_URL || "http://localhost:3030/api"
    }/payment/lava/deposit`;
    const response = await axios.post(
      requestUrl,
      { depositAmmount },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
    if (response.data?.redirectUrl) {
      window.location.replace(response.data.redirectUrl);
    }
  };

  const handlePayTinkoff = async (depositAmmount) => {
    const requestUrl = `${
      process.env.REACT_APP_SERVER_URL || "http://localhost:3030/api"
    }/payment/tinkoff/deposit`;
    const response = await axios.post(
      requestUrl,
      { depositAmmount },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
    if (response.data?.redirectUrl) {
      window.location.replace(response.data.redirectUrl);
    }
  };
  const handlePayYooMoney = async (depositAmmount) => {
    const requestUrl = `${
      process.env.REACT_APP_SERVER_URL || "http://localhost:3030/api"
    }/payment/yooMoney/deposit`;
    const response = await axios.post(
      requestUrl,
      { depositAmmount },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
    if (response.data?.redirectUrl) {
      window.location.replace(response.data.redirectUrl);
    }
  };

  const handlePayTransfer = async (depositAmmount) => {
    const requestUrl = `${
      process.env.REACT_APP_SERVER_URL || "http://localhost:3030/api"
    }/payment/transfer/deposit`;
    const response = await axios.post(
      requestUrl,
      { depositAmmount },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
    if (response.data?.paymentCode) {
      window.location.replace(`/payment/transfer/${response.data.paymentCode}`);
    }
  };

  const pay = useCallback(async () => {
    setLoading(true);
    if (selectedPayment._id === "yookassa") {
      await handlePayYooKassa(sum);
    }
    if (selectedPayment._id === "lava") {
      await handlePayLava(sum);
    }
    if (selectedPayment._id === "paybox") {
      await handlePayPayBox(sum);
    }
    if (selectedPayment._id === "transfer") {
      await handlePayTransfer(sum);
    }
    if (selectedPayment._id === "tinkoff") {
      await handlePayTinkoff(sum);
    }
    if (selectedPayment._id === "yooMoney") {
      await handlePayYooMoney(sum);
    }

    setLoading(false);
  }, [selectedPayment?._id, sum]);

  return (
    <MentupModal
      open={open}
      close={close}
      sx={{ maxWidth: "400px", width: "100%" }}
    >
      <Box className={styles.confirmContainer}>
        <Box className={styles.headerContainer}>
          <Typography className={styles.title}>
            {translate("vendorDashboard.topUp.title")}
          </Typography>
        </Box>

        <Box sx={{ width: "100%" }}>
          <MentupStepper
            getStepTitle={(index) =>
              `${translate("vendorDashboard.topUp.steps.stepper")} ${index + 1}`
            }
            current={step}
            steps={3}
          />
          <Typography className={styles.selectPrice}>
            <TranslateText
              value={
                step === 0
                  ? "vendorDashboard.topUp.steps.step1.title"
                  : step === 1
                  ? "vendorDashboard.topUp.steps.step2.title"
                  : "vendorDashboard.topUp.steps.step3.title"
              }
            />
          </Typography>
        </Box>

        {step === 0 && (
          <TopUpBalanceStep1
            close={close}
            next={(v) => {
              setStep(1);
              setSum(v.sum);
            }}
          />
        )}
        {step === 1 && (
          <TopUpBalanceStep2
            selectedPayment={selectedPayment}
            onPaymentSelect={(v) => {
              setStep(2);
              setSelectedPayment(v);
            }}
          />
        )}
        {step === 2 && (
          <TopUpBalanceStep3
            loading={loading}
            selectedPayment={selectedPayment}
            onPaymentSelect={setSelectedPayment}
            close={close}
            next={pay}
          />
        )}
      </Box>
    </MentupModal>
  );
};

export default TopUpBalanceContent;
