import { useTheme } from "@emotion/react";
import { Container, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getChatById, getChatUser } from "../../../api/chat/ChatRequests";
import { getUsersBySearch } from "../../../api/user/UserRequests";
import { useChats } from "../../../hooks/chats";
import { ChatMessages } from "../components/ChatMessages";
import { ChatListItems } from "./components/ListItems";
import styles from "./styles.module.css";

export const Chat = () => {
  const theme = useTheme();
  /**
   * Chat ID (_id)
   * Used when chat is started from external route
   */
  const { id } = useParams();
  const chats = useChats();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [chat, selectChat] = useState();
  const [userToStartChat, selectUserToStartChat] = useState();

  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [searchedUsers, setSearchedUsers] = useState([]);

  useEffect(() => {
    chats.getItems();
  }, []);

  const getOtherUser = async (id) => {
    const response = await getChatUser(id);

    if (response.data?.chat) {
      selectChat(response.data.chat);
    } else if (response.data?.user) {
      selectUserToStartChat(response.data.user);
    }
  };

  const getOpenedChat = async (id) => {
    const response = await getChatById(id);

    if (response.data?.chat) {
      selectChat(response.data.chat);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(
      window.location.href.split("?")[1] || ""
    );

    const otherUserId = searchParams.get("u");
    if (otherUserId) {
      getOtherUser(otherUserId);
    }
  }, []);

  useEffect(() => {
    if (id) {
      getOpenedChat(id);
    }
  }, [id]);

  const handleUserSearch = async (searchTerm) => {
    if (!searchTerm.length) {
      return setSearchedUsers([]);
    }

    try {
      const { data } = await getUsersBySearch(searchTerm);
      const filteredData = currentUser
        ? data.filter((member) => member._id !== currentUser._id)
        : data;
      setSearchedUsers(filteredData);
    } catch (error) {
      // Handle Error
      //console.log(error);
    }
  };

  const getChat = async (otherUserId) => {
    const existingChat = chats.items.find(
      (chat) => chat.users.includes(otherUserId) && chat.users.length === 2
    );
    if (existingChat) {
      return existingChat;
    }

    const newChat = await chats.create([otherUserId]);
    selectChat(newChat);
    setSearchedUsers([]);
    return newChat;
  };

  return (
    <Container
      className={styles.container}
      sx={{
        "*": {
          boxSizing: "border-box",
        },
      }}
    >
      {isMobile &&
        (chat ? (
          <ChatMessages
            key={chat._id}
            chat={chat}
            otherUser={chat.users.find((user) => user._id !== currentUser._id)}
            unreadCount={chat.unread?.[0]?.count || 0}
            getChat={getChat}
            markAsRead={() => chats.markAsRead(chat?._id)}
            showBack={isMobile}
            close={() => {
              selectChat(null);
              selectUserToStartChat(null);
            }}
          />
        ) : userToStartChat ? (
          <ChatMessages
            otherUser={userToStartChat}
            unreadCount={0}
            getChat={getChat}
            markAsRead={() => null}
            showBack={isMobile}
          />
        ) : (
          <ChatListItems
            selectedChat={chat}
            openChat={selectChat}
            full={isMobile}
          />
        ))}

      {!isMobile && (
        <ChatListItems
          selectedChat={chat}
          openChat={selectChat}
          full={isMobile}
        />
      )}

      {!isMobile && !!chat && (
        <ChatMessages
          key={chat._id}
          chat={chat}
          otherUser={chat.users.find((user) => user._id !== currentUser._id)}
          unreadCount={chat.unread?.[0]?.count || 0}
          getChat={getChat}
          markAsRead={() => chats.markAsRead(chat?._id)}
        />
      )}
      {!isMobile && !chat && (
        <ChatMessages
          otherUser={userToStartChat}
          unreadCount={0}
          getChat={getChat}
          markAsRead={() => null}
        />
      )}
    </Container>
  );
};
