import { Container, Box, Button, Typography, useTheme } from "@mui/material";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getBlogPosts } from "../../../api/blog/getSinglePost";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import styles from "./styles.module.css";
import Clock from "../../svg/Blog";
import SEOHelmet from "../../../lib/SEOHelmet";

const BlogPost = () => {
  const theme = useTheme()
  const { id } = useParams();
  const [post, setPost] = useState(null);

  const navigate = useNavigate()
  useEffect(() => {
    const getPost = async () => {
      try {
        await getBlogPosts(id).then((res) => {
          setPost(res.data);
        });
      } catch (error) {
        navigate('/404')
      }
    };
    getPost();
  }, []);

  const postData = post?.data;

  const formatDate = (dateFromJson) => {
    const date = new Date(dateFromJson);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDate = `${day < 10 ? '0' + day : day}.${month < 10 ? '0' + month : month}.${year}`;
  return formattedDate;
  }



  return (
    <Container>
      <SEOHelmet 
        title={'Mentup - ' + postData?.attributes.title}
        description={postData?.attributes.Description}
        keywords={postData?.attributes.Tags?.Single?.map((tag) => tag.TagName).join(', ')}
      />
      <Link to={'/blog'}>
        <Button
          className={styles.backToNewsButton}
          variant="outlined"
          startIcon={<KeyboardBackspaceIcon />}
          >
          Все новости
        </Button>
      </Link>
      <Box 
        sx={{
          height: '300px',
          width: '100%',
          marginBottom: '40px',
          borderRadius: '10px',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            backgroundImage: `url(${
              postData?.attributes &&
              postData?.attributes?.mainImage?.data[0].attributes?.url
            })`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            borderRadius: '10px',
          }}
        />
      </Box>
      <Box className={styles.paddingBlock}>
        <Box className={styles.tags}>
          <Typography className={styles.tags__span} color="GrayText">
            Тэги:
          </Typography>
          <Box className={styles.tags__wrapper}>
            {postData?.attributes?.Tags?.Single?.map((tag) => {
              return (
                <Typography className={styles.tag} key={tag.id}>
                  {tag.TagName}
                </Typography>
              );
            })}
          </Box>
        </Box>
        <Typography className={styles.blogTitle}>{postData?.attributes.title}</Typography>
        <Typography className={styles.blogDescription}>{postData?.attributes.Description}</Typography>
        <Box className={styles.timeStamp}>
          <Typography className={styles.timeStamp__date}>{formatDate(postData?.attributes.createdAt)}</Typography>
          <Typography className={styles.timeStamp__readingTime}><Clock color={theme.palette.mode === 'dark' ? 'white' : 'black'}/> 5 min</Typography>
        </Box>
      </Box>
      <Box className={styles.dividerLine}></Box>

      <Box className={styles.paddingBlock}>
            <Typography className={styles.content}>{postData?.attributes.content}</Typography>
            <Box className={styles.imagesBox}>
              {/* <img src="" alt="" className={styles.imageBox__image} /> */}
            </Box>
      </Box>

    </Container>
  );
};

export default BlogPost;
